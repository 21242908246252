import { Component, OnInit, Input, EventEmitter, Output } from '@angular/core';

@Component({
	selector: 'pcg-edit-link',
	templateUrl: './edit-link.component.html',
	styleUrls: ['./edit-link.component.scss'],
})
export class EditLinkComponent implements OnInit {
	
	@Input() hasAccess = true;
	@Input() url: string;
	@Input() openNewWindow = false;
	@Output() routeChange = new EventEmitter();
	@Output() clickEvent = new EventEmitter();

	constructor() {}

	ngOnInit() {}

	onClick() {
		if (this.hasAccess) {
			this.routeChange.emit();
			this.clickEvent.emit();
		}
	}
}
