import { UntypedFormGroup, UntypedFormControl, UntypedFormArray, Validators } from '@angular/forms';

import { PcgTableColumn } from '../../table/pcg-table-column.interface';

// tslint:disable:quotemark
// tslint:disable:max-line-length
// tslint:disable:whitespace
export class ReleaseNotesVm {
	versionNumber?: string;
	goLiveDateString?: string;
	goLiveDate: Date;
	releaseNotes?: string;

	static get FormData() {
		return this.getFormData();
	}

	static getFormData(validators = {}) {
		return {
			'versionNumber': new UntypedFormControl(null, [...(validators['versionNumber'] ?? []), ...[]]),
			'goLiveDateString': new UntypedFormControl(null, [...(validators['goLiveDateString'] ?? []), ...[]]),
			'goLiveDate': new UntypedFormControl('', [...(validators['goLiveDate'] ?? []), ...[Validators.required]]),
			'releaseNotes': new UntypedFormControl(null, [...(validators['releaseNotes'] ?? []), ...[]])
		};
	}

	static get Form() {
		return new UntypedFormGroup(this.FormData);
	}

	static get ColumnDefs(): Map<string, PcgTableColumn> {
		return new Map([
			['versionNumber', {"orderId":0,"sortColumn":"goLiveDate","searchColumn":"versionNumber","includeInExcel":true,"exportColumn":null,"displayName":"Version Number","isVisible":true,"canSearch":true,"isNdc":false,"isNdc10":false,"multiSearchCellClasses":null}],
			['goLiveDateString', {"orderId":1,"sortColumn":"goLiveDate","searchColumn":"goLiveDateString","includeInExcel":true,"exportColumn":null,"displayName":"Go Live Date","isVisible":true,"canSearch":true,"isNdc":false,"isNdc10":false,"multiSearchCellClasses":null}],
			['releaseNotes', {"orderId":2,"sortColumn":"releaseNotes","searchColumn":"releaseNotes","includeInExcel":true,"exportColumn":null,"displayName":"Description of Change","isVisible":true,"canSearch":true,"isNdc":false,"isNdc10":false,"multiSearchCellClasses":null}]
		]);
	}

	static GetModel(fg: UntypedFormGroup): ReleaseNotesVmFromFg {
		return new ReleaseNotesVmFromFg(fg);
	}
}

export class ReleaseNotesVmFromFg {
	constructor(public fg: UntypedFormGroup) { }


	get versionNumber(): string {
		return this.fg.get('versionNumber').value;
	}
	set versionNumber(val) {
		this.fg.get('versionNumber').setValue(val);
	}

	get goLiveDateString(): string {
		return this.fg.get('goLiveDateString').value;
	}
	set goLiveDateString(val) {
		this.fg.get('goLiveDateString').setValue(val);
	}

	get goLiveDate(): Date {
		return this.fg.get('goLiveDate').value;
	}
	set goLiveDate(val) {
		this.fg.get('goLiveDate').setValue(val);
	}

	get releaseNotes(): string {
		return this.fg.get('releaseNotes').value;
	}
	set releaseNotes(val) {
		this.fg.get('releaseNotes').setValue(val);
	}
}
