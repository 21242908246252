<div
	class="pcg-sort-header-container"
	[class.pcg-sort-header-sorted]="_isSorted()"
	[class.pcg-sort-header-position-before]="arrowPosition == 'before'"
>
	<button
		class="pcg-sort-header-button"
		type="button"
		(focus)="_setIndicatorHintVisible(true)"
		(blur)="_setIndicatorHintVisible(false)"
	>
		<ng-content></ng-content>
	</button>

	<!-- Disable animations while a current animation is running -->
	<div
		class="pcg-sort-header-arrow"
		[@arrowOpacity]="_getArrowViewState()"
		[@arrowPosition]="_getArrowViewState()"
		[@allowChildren]="_getArrowDirectionState()"
		(@arrowPosition.start)="_disableViewStateAnimation = true"
		(@arrowPosition.done)="_disableViewStateAnimation = false"
	>
		<div class="pcg-sort-header-stem"></div>
		<div class="pcg-sort-header-indicator" [@indicator]="_getArrowDirectionState()">
			<div class="pcg-sort-header-pointer-left" [@leftPointer]="_getArrowDirectionState()"></div>
			<div class="pcg-sort-header-pointer-right" [@rightPointer]="_getArrowDirectionState()"></div>
			<div class="pcg-sort-header-pointer-middle"></div>
		</div>
	</div>
</div>
