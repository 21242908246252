<nav role="none" id="pcgMainNavigation" class="navbar">
	<!-- Mobile header -->
	<div 
		id="primaryNav" 
		class="d-flex align-items-center w-100"
		*ngIf="isMobile && isLoggedOn && !onChangePasswordScreen"
	>
		<button
			aria-label="Open menu"
			[attr.aria-expanded]="this.currMainNavMenu !== ''"
			aria-controls="pcgTopClickNavEl"
			#mainHamburger
			[class.d-none]="!navRoutes || navRoutes.length === 0"
			(click)="toggleNav()"
			id="mainHamburger"
			class="btn"
		>
			<fa-icon 
				[icon]="faIconName.faBars" 
				class="hamburger-icon"
			></fa-icon>
		</button>
		<div 
			role="banner" 
			class="navbar-header" 
			[routerLink]="['/']"
		>
			Schedule2.IT
			<div 
				*ngIf="mode !== 'Production'" 
				class="beta-text"
			></div>
		</div>
	</div>
	<!-- Secondary nav -->
	<div 
		*ngIf="currSelectedNavMenu && currSelectedNavMenu.id !== 'root'" 
		id="secondaryNav" 
		class="left-shift"
	>
		<div class="name-wrapper">
			<span class="current-section">{{ currSelectedNavMenu.name }}</span> <!--breadcrumbs-->
		</div>
		
		<!-- secondary nav items -->
		<pcg-top-secondary-nav
			role="navigation"
			aria-label="Secondary"
			[forceSelect]="currForceSelect"
			[rootNavId]="'root' + navDividerCharacter + currSelectedNavMenu.id.split(navDividerCharacter)[1]"
			[routeNavId]="currSelectedNavMenu.id"
			[navRoutes]="currSelectedNavMenu.children"
			[tabPanel]="tabPanel"
			(clickedNav)="setSubNavPosition()"
		></pcg-top-secondary-nav>
	</div>
</nav>