import { NavRoute } from '../../shared/navigation/nav.route.interface';

export const supplierNav: () => NavRoute = () => ({
	name: getName()
	, security: sec => sec.coreFeatures || sec.security
	, href: 'suppliers'
	, children: getSupplierAreas()
});

function getName() : string {
	var name: string;
	var url = window.location.href;
	
	if (
		url.indexOf('suppliers/edit-supplier/') > -1
		|| url.indexOf('suppliers/accounts/') > -1
	) { name = "Edit Supplier"; } 
	else { name = "Suppliers"; }

	return name;
}

function getSupplierAreas() : NavRoute[] {
	var id: string = "";
	var url = window.location.href;

	if (url.indexOf('suppliers/edit-supplier/') > -1) { id = url.substring(url.lastIndexOf('/') + 1); }
	if (url.indexOf('suppliers/accounts/') > -1) { id = url.substring(url.lastIndexOf('/') + 1); }

	if (id === "") { 
		return [
			{
				name: 'Suppliers'
				, exact: true
				, path: 'administration/suppliers/list'
			}
		]; 
	}

	if (+id === 0) {
		return [
			{
				name: 'Back'
				, exact: true
				, path: `administration/suppliers/list`
			},
			{
				name: 'Details'
				, exact: true
				, path: `administration/suppliers/edit-supplier/${id}`
			}
		];
	}

	return [
		{
			name: 'Back'
			, exact: true
			, path: `administration/suppliers/list`
		}
		, {
			name: 'Details'
			, exact: true
			, path: `administration/suppliers/edit-supplier/${id}`
		}
		, {
			name: 'Accounts'
			, exact: true
			, path: `administration/suppliers/accounts/${id}`
			, security: sec => sec.replenishment
		}
	];
}
