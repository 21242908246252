import { 
	AfterContentChecked
	, Component
	, EventEmitter
	, HostListener
	, Input
	, OnDestroy
	, OnInit
	, Output
	, TemplateRef
	, ViewChild 
} from '@angular/core';
import { Router } from '@angular/router';
import { 
	faAngleDown
	, faUser
	, faGear
	, faGears
	, faQuestionCircle
	, faClipboardList
	, faTruckField
	, faShelves
	, faStaffSnake
	, faBarcodeRead
	, faArrowLeft
	, faArrowRight
	, faPenField
} from '@fortawesome/pro-solid-svg-icons';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { HttpClient } from '@angular/common/http';

import { SelectItem } from '@pcg/pcg-shared/models/select-item';
import { GlobalService } from 'app/shared/service/global.service';
import { pcgSettings } from 'app/shared/generated/pcg-settings';
import { SecurityService } from 'app/core/security/security.service';
import { NavigationService } from '../navigation.service';
import { UserJsVm } from 'app/shared/generated/Administration/Models/UserJsVm';
import { GlobalVariablesService } from 'app/services/global-variables.service';
import { SwapClientVm } from 'app/shared/generated/Administration/Models/SwapClientVm';
import { SystemMessage } from 'app/core/system-message/system-message-service';
import { PermissionProfileEnum } from 'app/core/enums/generated/PermissionProfileEnum';
import { CheckNetworkService } from 'local-db/services/check-network.service';

@Component({
    selector: 'pcg-mat-side-nav',
    templateUrl: './mat-side-nav.component.html',
    styleUrls: ['./mat-side-nav.component.scss']
})
export class MatSideNavComponent implements OnInit, AfterContentChecked, OnDestroy {

    @ViewChild('modalChangeClient', { static: true }) modalChangeClient: TemplateRef<any>;

    @Input() user: UserJsVm;
	@Input() navFixedInput: boolean;
	@Output() navFixedOutput = new EventEmitter<boolean>();
	@Output() closeNav = new EventEmitter<boolean>();

    clientItems: SelectItem[] = [];
	inventories: any[] = [];
    formGroup = SwapClientVm.Form;
	model = SwapClientVm.GetModel(this.formGroup);

    mode = pcgSettings.mode;
    clientName: string = "";
	clientMedUseName = "Medication Use";
	fileLogoUrl = "../assets/images/logos/logo_small.png";
	clientId: number;
    currentClientId = 0;

    navOpened: boolean;  

    inventoriesSelected: boolean;
    suppliersSelected: boolean;
    reportsSelected: boolean;
    adminSelected: boolean;
	sysAdminSelected: boolean;
	searchCvSelected: boolean;
    helpSelected: boolean;
    userProfileSelected: boolean;
	formsSelected: boolean;

	showInventoriesSubMenu = false;
    showReportsSubMenu = false;
    showAdminSubMenu = false;
    showAdminSettingsSubMenu = false;
    showAdminUsersSubMenu = false;
    showAdminInventorySubMenu = false;
    showSysAdminSubMenu = false;
	showSysAdminFooterAdminSubMenu = false;
	showSysAdminSystemWideSubMenu = false;
	showSysAdminPermissionsInfoSubMenu = false;
    showProfileSubMenu = false;
	showInventorySubMenu: SelectItem[] = [];
	showHelpSubMenu = false;
	showFormsSubMenu = false;

	// Permission booleans
    isSysAdmin = false;
    isAdmin = false;
    isManager = false;
    isTechnician = false;

	// Font awesome
    faIconName = { 
		faAngleDown
        , faStaffSnake
		, faShelves
		, faTruckField
		, faClipboardList
		, faGear
		, faGears
		, faUser
		, faQuestionCircle
		, faBarcodeRead
		, faArrowLeft
		, faArrowRight
		, faPenField
	};

    xDown = null;
	yDown = null;

	clientShiftChangeName = "Shift Change";

    isMobile: boolean;
	@HostListener('window:resize')
	onResize() { this.isMobile = GlobalService.setIsMobile(window.innerWidth); }

    constructor(
        public router: Router
		, private sec: SecurityService
		, private navService: NavigationService
		, private modalService: NgbModal
		, private httpClient: HttpClient
		, public globalService: GlobalService
        , private globalVariablesService: GlobalVariablesService
    ) { }

    ngOnInit() {
		this.clientShiftChangeName = this.user?.clientShiftChangeName ?? "Shift Change"; 
		this.clientMedUseName = this.user?.clientMedUseName ?? "Medication Use";
        this.globalVariablesService.currentClientName.next(this.user?.clientName);
		this.globalVariablesService.currentClientName.subscribe((data)=>{ 
			if (
				data !== null 
				&& data !== "" 
				&& data !== undefined
			) { this.clientName = data; } 
			else { this.clientName = this.user?.clientName; }
		});	

		this.globalVariablesService.currentClientId.next(this.user?.currentClientId);		
		this.globalVariablesService.currentClientId.subscribe((data) => { 
			if (data !== 0) { this.currentClientId = data; } 
			else { this.currentClientId = this.user?.currentClientId; }
		});	

        this.isMobile = GlobalService.setIsMobile(window.innerWidth);	
        this.navService.navOpened.subscribe((data)=>{ this.navOpened = data; });
        this.router.events.subscribe(() => { this.parseUrl(this.router.url); }); 
		if (this.user?.permissionId === 4) { this.getClients(); }
		this.getInventories();
		this.clientId = this.user?.clientId;

		if (
			this.user?.clientLogoFilename !== "" 
			&& this.user?.clientLogoFilename !== undefined 
			&& this.user?.clientLogoFilename !== null
		) { this.fileLogoUrl = this.user?.clientLogoFilePath; }

        //event litseners for swiping away side nav in mobile
		document.addEventListener('touchstart', (evt) => this.handleTouchStart(evt), false);        
		document.addEventListener('touchmove', (evt) => this.handleTouchMove(evt), false);
    }

    ngAfterContentChecked() {
        this.isSysAdmin = this.sec.hasAccess(o => o.permissionProfile == PermissionProfileEnum.SysAdmin);
        this.isAdmin = this.sec.hasAccess(o => o.permissionProfile == PermissionProfileEnum.Admin);
        this.isManager = this.sec.hasAccess(o => o.permissionProfile == PermissionProfileEnum.Manager);
        this.isTechnician = this.sec.hasAccess(o => o.permissionProfile == PermissionProfileEnum.Technician);		
    }

    redirect(path: string) {
		// Navigate/redirect to new page
		this.router.navigateByUrl(path);	

		// Close all parent sidenav menus
		this.showInventoriesSubMenu = false;
		this.showReportsSubMenu = false;
		this.showAdminSubMenu = false;
		this.showSysAdminSubMenu = false;
		this.showProfileSubMenu = false;
		this.showHelpSubMenu = false;

		// And if nav is not fixed, collapse nav on redirect
		if (!this.navFixedInput) { 
			this.navOpened = false; 
			this.closeNav.emit(false);
		}
	}

    openInNewTab(namedRoute: string) {
		let newRelativeUrl = this.router.createUrlTree([namedRoute]);
		let baseUrl = window.location.href.replace(this.router.url, '');
		window.open(baseUrl + newRelativeUrl, '_blank');
		this.navOpened = false;
		this.closeNav.emit(false);
	}

	// Logic for active page indication
    parseUrl(url: string) {
        this.inventoriesSelected = false;
        this.suppliersSelected = false;
        this.reportsSelected = false;
		this.searchCvSelected = false;
        this.adminSelected = false;
		this.sysAdminSelected = false;
        this.helpSelected = false;
        this.userProfileSelected = false;
		this.formsSelected = false;
		var id: string = "";

		if (url.indexOf('users/edit-user/') > -1) { id = url.substring(url.lastIndexOf('/') + 1); }
		if (
			url.includes("inventory") 
			&& !url.includes("reports") 
			&& !url.includes("administration")
		) { this.inventoriesSelected = true; }
		if (url.includes("suppliers")) { this.suppliersSelected = true; }
		if (url.includes("reports")) { this.reportsSelected = true; }
		if (url.includes("forms") && !url.includes("sysadmin/drug-forms")) { 
			this.formsSelected = true; 
			this.reportsSelected = false; 
			this.inventoriesSelected = false; 
		}
		if (url.includes("control-value-report")) { 
			this.searchCvSelected = true; 
			this.reportsSelected = false;
		}
		if (
			url.includes("administration") 
			&& this.user?.id.toString() !== id
		) { this.adminSelected = true; }
		if (
			url.includes("sysadmin")
		) { this.sysAdminSelected = true; }
		if (
			url.includes("edit-user") 
			&& this.user?.id.toString() === id
		) { this.userProfileSelected = true; }
		if (
			url.includes("help") 
			|| url.includes("web-release-notes") 
			&& !url.includes("release-notes/")
		) { this.helpSelected = true; }
    }

    handleTouchStart(evt) {
		if (
			window.innerWidth < 993 
			&& this.navOpened === true
		) {
			let firstTouch = evt.touches || evt.originalEvent.touches;       
			this.xDown = firstTouch[0].clientX;                                      
			this.yDown = firstTouch[0].clientY; 
		}			                                   
	};                                                
																				
	handleTouchMove(evt) {
		if (
			this.isMobile 
			&& this.navOpened === true
		) {
			if (
				!this.xDown 
				|| !this.yDown
			) { return; }
	
			let xUp = evt.touches[0].clientX;                                    
			let yUp = evt.touches[0].clientY;
			let xDiff = this.xDown - xUp;
			let yDiff = this.yDown - yUp;
																				
			if (Math.abs(xDiff) > Math.abs(yDiff)) {/*most significant*/
				if (xDiff > 8) {
					/* right swipe */ 
					this.navOpened = false;
					this.closeNav.emit(false);
				} else { /* left swipe */ }                       
			} else {
				if (yDiff > 8) { /* down swipe */ } 
				else { /* up swipe */ }                                                                 
			}
			/* reset values */
			this.xDown = null;
			this.yDown = null;  
		}
	}

	toggleNavFixed() {
		this.navFixedInput = !this.navFixedInput;
		localStorage.setItem("isNavFixed", this.navFixedInput.toString());
		this.navFixedOutput.emit(this.navFixedInput);
	}

	toggleInventoriesSubMenu() {
		this.showInventoriesSubMenu = !this.showInventoriesSubMenu;
		this.showReportsSubMenu = false;
		this.showAdminSubMenu = false;
		this.showAdminSettingsSubMenu = false;
		this.showAdminUsersSubMenu = false;
		this.showAdminInventorySubMenu = false;
		this.showSysAdminSubMenu = false;
		this.showProfileSubMenu = false;
		this.showInventorySubMenu.map(o => o.value = false);
		this.showHelpSubMenu = false;
		this.showFormsSubMenu = false;
		this.showSysAdminFooterAdminSubMenu = false;
		this.showSysAdminSystemWideSubMenu = false;
		this.showSysAdminPermissionsInfoSubMenu = false;
	}

	toggleInventorySubNav(inventoryName: string) {
		this.showInventorySubMenu.map(o => {
			if (o.text === inventoryName) { o.value = !o.value; } 
			else { o.value = false; }
		});
	}

    toggleReportsSubMenu() {
		this.showReportsSubMenu = !this.showReportsSubMenu;
		this.showAdminSubMenu = false;
		this.showAdminSettingsSubMenu = false;
		this.showAdminUsersSubMenu = false;
		this.showAdminInventorySubMenu = false;
		this.showSysAdminSubMenu = false;
		this.showProfileSubMenu = false;
		this.showInventoriesSubMenu = false;
		this.showInventorySubMenu.map(o => o.value = false);
		this.showHelpSubMenu = false;
		this.showFormsSubMenu = false;
		this.showSysAdminFooterAdminSubMenu = false;
		this.showSysAdminSystemWideSubMenu = false;
		this.showSysAdminPermissionsInfoSubMenu = false;
    }

	toggleFormsSubMenu() {
		this.showFormsSubMenu = !this.showFormsSubMenu;
		this.showAdminSubMenu = false;
		this.showReportsSubMenu = false;
		this.showAdminSettingsSubMenu = false;
		this.showAdminUsersSubMenu = false;
		this.showAdminInventorySubMenu = false;
		this.showSysAdminSubMenu = false;
		this.showProfileSubMenu = false;
		this.showInventoriesSubMenu = false;
		this.showInventorySubMenu.map(o => o.value = false);
		this.showHelpSubMenu = false;
		this.showSysAdminFooterAdminSubMenu = false;
		this.showSysAdminSystemWideSubMenu = false;
		this.showSysAdminPermissionsInfoSubMenu = false;
    }

	toggleAdminSubMenu() {
		this.showAdminSubMenu = !this.showAdminSubMenu;
		this.showReportsSubMenu = false;
		this.showAdminSettingsSubMenu = false;
		this.showAdminUsersSubMenu = false;
		this.showAdminInventorySubMenu = false;
		this.showSysAdminSubMenu = false;
		this.showProfileSubMenu = false;
		this.showInventoriesSubMenu = false;
		this.showInventorySubMenu.map(o => o.value = false);
		this.showHelpSubMenu = false;
		this.showFormsSubMenu = false;
		this.showSysAdminFooterAdminSubMenu = false;
		this.showSysAdminSystemWideSubMenu = false;
		this.showSysAdminPermissionsInfoSubMenu = false;
	}

    toggleAdminSettingsSubMenu() {
		this.showAdminSettingsSubMenu = !this.showAdminSettingsSubMenu;
		this.showReportsSubMenu = false;
		this.showAdminUsersSubMenu = false;
		this.showAdminInventorySubMenu = false;
		this.showSysAdminSubMenu = false;
		this.showProfileSubMenu = false;
		this.showInventoriesSubMenu = false;
		this.showInventorySubMenu.map(o => o.value = false);
		this.showHelpSubMenu = false;
		this.showFormsSubMenu = false;
		this.showSysAdminFooterAdminSubMenu = false;
		this.showSysAdminSystemWideSubMenu = false;
		this.showSysAdminPermissionsInfoSubMenu = false;
    }

    toggleAdminUsersSubMenu() {
		this.showAdminUsersSubMenu = !this.showAdminUsersSubMenu;
		this.showReportsSubMenu = false;
		this.showAdminSettingsSubMenu = false;
		this.showAdminInventorySubMenu = false;
		this.showSysAdminSubMenu = false;
		this.showProfileSubMenu = false;
		this.showInventoriesSubMenu = false;
		this.showInventorySubMenu.map(o => o.value = false);
		this.showHelpSubMenu = false;
		this.showFormsSubMenu = false;
		this.showSysAdminFooterAdminSubMenu = false;
		this.showSysAdminSystemWideSubMenu = false;
		this.showSysAdminPermissionsInfoSubMenu = false;
    }

    toggleAdminInventorySubMenu() {
		this.showAdminInventorySubMenu = !this.showAdminInventorySubMenu;
		this.showReportsSubMenu = false;
		this.showAdminSettingsSubMenu = false;
		this.showAdminUsersSubMenu = false;
		this.showSysAdminSubMenu = false;
		this.showProfileSubMenu = false;
		this.showInventoriesSubMenu = false;
		this.showInventorySubMenu.map(o => o.value = false);
		this.showHelpSubMenu = false;
		this.showFormsSubMenu = false;
		this.showSysAdminFooterAdminSubMenu = false;
		this.showSysAdminSystemWideSubMenu = false;
		this.showSysAdminPermissionsInfoSubMenu = false;
    }

    toggleSysAdminSubMenu() {
		this.showSysAdminSubMenu = !this.showSysAdminSubMenu;
		this.showReportsSubMenu = false;
		this.showAdminSubMenu = false;
		this.showAdminSettingsSubMenu = false;
		this.showAdminUsersSubMenu = false;
		this.showAdminInventorySubMenu = false;
		this.showProfileSubMenu = false;
		this.showInventoriesSubMenu = false;
		this.showInventorySubMenu.map(o => o.value = false);
		this.showHelpSubMenu = false;
		this.showFormsSubMenu = false;
		this.showSysAdminFooterAdminSubMenu = false;
		this.showSysAdminSystemWideSubMenu = false;
		this.showSysAdminPermissionsInfoSubMenu = false;
    }

	toggleSysAdminFooterAdminSubMenu() {
		this.showSysAdminFooterAdminSubMenu = !this.showSysAdminFooterAdminSubMenu;
		this.showReportsSubMenu = false;
		this.showAdminUsersSubMenu = false;
		this.showAdminInventorySubMenu = false;
		this.showProfileSubMenu = false;
		this.showInventoriesSubMenu = false;
		this.showInventorySubMenu.map(o => o.value = false);
		this.showHelpSubMenu = false;
		this.showFormsSubMenu = false;
		this.showSysAdminSystemWideSubMenu = false;
		this.showSysAdminPermissionsInfoSubMenu = false;
	}

	toggleSysAdminSystemWideSubMenu() {
		this.showSysAdminSystemWideSubMenu = !this.showSysAdminSystemWideSubMenu;
		this.showReportsSubMenu = false;
		this.showAdminUsersSubMenu = false;
		this.showAdminInventorySubMenu = false;
		this.showProfileSubMenu = false;
		this.showInventoriesSubMenu = false;
		this.showInventorySubMenu.map(o => o.value = false);
		this.showHelpSubMenu = false;
		this.showFormsSubMenu = false;
		this.showSysAdminFooterAdminSubMenu = false;
		this.showSysAdminPermissionsInfoSubMenu = false;
	}

	toggleSysAdminPermissionsSubMenu() {
		this.showSysAdminPermissionsInfoSubMenu = !this.showSysAdminPermissionsInfoSubMenu;
		this.showReportsSubMenu = false;
		this.showAdminUsersSubMenu = false;
		this.showAdminInventorySubMenu = false;
		this.showProfileSubMenu = false;
		this.showInventoriesSubMenu = false;
		this.showInventorySubMenu.map(o => o.value = false);
		this.showHelpSubMenu = false;
		this.showFormsSubMenu = false;
		this.showSysAdminFooterAdminSubMenu = false;
		this.showSysAdminSystemWideSubMenu = false;
	}

    toggleProfileSubMenu() {
        this.showProfileSubMenu = !this.showProfileSubMenu;
		this.showAdminSubMenu = false;
		this.showAdminSettingsSubMenu = false;
		this.showAdminUsersSubMenu = false;
		this.showAdminInventorySubMenu = false;
		this.showSysAdminSubMenu = false;
		this.showInventoriesSubMenu = false;
		this.showInventorySubMenu.map(o => o.value = false);
		this.showHelpSubMenu = false;
		this.showFormsSubMenu = false;
		this.showSysAdminFooterAdminSubMenu = false;
		this.showSysAdminSystemWideSubMenu = false;
		this.showSysAdminPermissionsInfoSubMenu = false;
    }

	toggleHelpSubMenu() {
		this.showHelpSubMenu = !this.showHelpSubMenu;
        this.showProfileSubMenu = false;
		this.showAdminSubMenu = false;
		this.showAdminSettingsSubMenu = false;
		this.showAdminUsersSubMenu = false;
		this.showAdminInventorySubMenu = false;
		this.showSysAdminSubMenu = false;
		this.showInventoriesSubMenu = false;
		this.showInventorySubMenu.map(o => o.value = false);
		this.showFormsSubMenu = false;
		this.showSysAdminFooterAdminSubMenu = false;
		this.showSysAdminSystemWideSubMenu = false;
		this.showSysAdminPermissionsInfoSubMenu = false;
    }

    logout() {
		let isOnline = CheckNetworkService.checkNetworkStatus();
		if (
			isOnline
			|| (
				!isOnline
				&& confirm("Are you sure you want log out? You are offline and will not be able to log in until you are back online.")
			)
		) {
			this.globalVariablesService.isNavFixed.next(false);
			this.navOpened = false;
			this.closeNav.emit(false);
			this.sec.setSecurity(null, null, null);
			this.deleteAllCookies();
			localStorage.removeItem("subNavPosition");
			this.router.navigate(['/login']);
		}
	}

    deleteAllCookies() {
		const cookies = document.cookie.split(';');
		for (let i = 0; i < cookies.length; i++) {
			const cookie = cookies[i];
			const eqPos = cookie.indexOf('=');
			const name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie;
			document.cookie = name + '=;expires=Thu, 01 Jan 1970 00:00:00 GMT';
		}
	}

    ngOnDestroy() { }
    getClients() { this.httpClient.get(`api/Select/Clients`).subscribe((items: SelectItem[]) => { this.clientItems = items; }); }

	getInventories() {
		//when using hide tech inventories feature
		if (
			this.user?.useHideTechnicianInventories 
			&& this.user?.permissionId === 1
		) {
			this.user.inventorySites.map(o => {
				if (this.user?.responsibleInventories.includes(o.id)) {
					this.inventories.push(o);
					this.showInventorySubMenu.push({
						text: o.name
						, value: false
					});
				}
			})
		} else {
			this.inventories = this.user?.inventorySites;
			this.user?.inventorySites.map(o => {
				this.showInventorySubMenu.push({
					text: o.name
					, value: false
				})
			});
		}		
	}

	getInventoryActionName(inventory) {
		if (inventory.inventoryActions.length > 1) { return "Update Inventory"; } 
		else { return this.user?.clientMedUseName ?? "Medication Use"; }
	}

	openChangeClientModal() {
        this.modalService.open(this.modalChangeClient, { beforeDismiss: () => { return true; } });
        this.model = SwapClientVm.GetModel(this.formGroup);
		this.formGroup?.controls?.eventId?.patchValue(this.user?.currentClientId);
    }

    changeClient(val: number) {
		this.currentClientId = val;
		this.model.clientId = this.currentClientId;
		this.formGroup?.controls?.clientId?.patchValue(this.currentClientId);
	}

    saveChangeClient(modal: { dismiss: () => void; }) {
		this.httpClient.post('api/SetClient', this.formGroup.getRawValue())
            .subscribe(async (sm: SystemMessage) => {
                if (sm.isSuccessful) { 		
					if (sm.value !== null) {
						var newUserInfo: UserJsVm = sm.value;	
						this.sec.setSecurity(undefined, newUserInfo, undefined);
						this.clientName = newUserInfo?.clientName;
						this.globalVariablesService.currentClientName.next(newUserInfo?.clientName);
						this.currentClientId = newUserInfo?.currentClientId;
						this.globalVariablesService.currentClientId.next(newUserInfo?.currentClientId);
	
						// use window.location.href to force refresh on redirect
						this.router.navigateByUrl("/dashboard");
						modal.dismiss();
					}
				} 
            }
		);
	}
}
