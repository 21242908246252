import { NavRoute } from 'app/shared/navigation/nav.route.interface';
import { PermissionConst } from '../security/generated/Permission';
import { UserJsVm } from 'app/shared/generated/Administration/Models/UserJsVm';

/***************************************************************************************************
 * Global Reports Nav
 * All reports should be linked from here
 */
export const getReportsNav: () => NavRoute = () => {
	const user = getUser();
	var clientMedUseName = user?.clientMedUseName ?? "Medication Use";
	let invActionReportName = clientMedUseName + " Report";
	
	if (user?.controlValues) {
		return {
			name: 'Reports'
			, security: sec => (sec.replenishment || sec.coreFeatures)
			, href: 'reports/inventory-report'
			, children: [
				{
					name: 'Expiration Report'
					, exact: true
					, path: 'reports/expiration-report'
					, security: PermissionConst.coreFeatures
				}
				, {
					name: 'Inventory Report'
					, exact: true
					, path: 'reports/inventory-report'
					, security: PermissionConst.coreFeatures
				}
				, {
					name: invActionReportName
					, exact: true
					, path: 'reports/inventory-actions-report'
					, security: PermissionConst.coreFeatures
				}
				, {
					name: 'Replenishment Discrepancies'
					, exact: true
					, path: 'reports/overage-shortage-report'
					, security: PermissionConst.replenishment
				}
				, {
					name: 'Control Values Report'
					, exact: true
					, path: 'reports/control-value-report'
					, security: PermissionConst.coreFeatures
				}
			]
		};
	} else {
		return {
			name: 'Reports'
			, security: sec => (sec.replenishment || sec.coreFeatures)
			, href: 'reports/inventory-report'
			, children: [
				{
					name: 'Expiration Report'
					, exact: true
					, path: 'reports/expiration-report'
					, security: PermissionConst.coreFeatures
				}
				, {
					name: 'Inventory Report'
					, exact: true
					, path: 'reports/inventory-report'
					, security: PermissionConst.coreFeatures
				}
				, {
					name: invActionReportName
					, exact: true
					, path: 'reports/inventory-actions-report'
					, security: PermissionConst.coreFeatures
				}
				, {
					name: 'Replenishment Discrepancies'
					, exact: true
					, path: 'reports/overage-shortage-report'
					, security: PermissionConst.replenishment
				}
			]
		};
	}
};

/**
 * Get the currently logged in user.
 *
 * Should remove after refactoring to generate
 * nav routes in an Angular service and use
 * security service to get it.
 */
// eslint-disable-next-line prefer-arrow/prefer-arrow-functions
function getUser(): UserJsVm {
	const userJson: string = localStorage.getItem('user') !== null 
		? localStorage.getItem('user') 
		: null;
	if (userJson !== null) { return JSON.parse(userJson); }
	return null;
}
