import { NgModule } from '@angular/core';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { BrowserModule } from '@angular/platform-browser';
import { RouterModule } from '@angular/router';
import { JwtModule } from '@auth0/angular-jwt';
import { NgbDateParserFormatter, NgbDateNativeAdapter } from '@ng-bootstrap/ng-bootstrap';

import { CoreRouting } from './core.routing';
import { SharedModule } from '../shared/shared.module';
import { SystemMessageModule } from './system-message/system-message.module';
import { SystemMessageService } from './system-message/system-message-service';
import { LayoutModule } from './layout/layout.module';
import { HttpClientInterceptor } from './interceptors/http-client-interceptor';
import { SpinnerService } from './layout/spinner/spinner.service';
import { SecurityService } from './security/security.service';
import { NavigationService } from '../shared/navigation/navigation.service';
import { NgbDateNativeParserFormatter } from '../shared/form-elements/components/date-picker/ngb-date-native-parser-formatter';
import { WebAuthnService } from './security/multi-factor-auth/web-authn.service';
import { MultiFactorAuthModalComponent } from './pages/login/multi-factor-auth-modal/multi-factor-auth-modal.component';
import { InactivityTimeoutComponent } from './inactivity-timeout/inactivity-timeout.component';
import { InactivityTimeoutService } from './inactivity-timeout/inactivity-timeout.service';
import { GetPasswordComponent } from './security/get-password/get-password.component';
import { FilterSaveService } from '../shared/table/filter-save.service';
import { WebReleaseNotesComponent } from './pages/web-release-notes/web-release-notes.component';
import { MobileReleaseNotesComponent } from './pages/mobile-release-notes/mobile-release-notes.component';
import { CollapsibleTableService } from './collapsible-table/collapsible-table.service';
import { PinAuthComponent } from './security/pin-auth/pin-auth.component';

// eslint-disable-next-line prefer-arrow/prefer-arrow-functions
export function getToken() {	
    if (localStorage.getItem('jwt') !== null) { return localStorage.getItem('jwt'); } 
    else { document.cookie = 'JsonWebToken=; expires=Thu, 01 Jan 1970 00:00:01 GMT;'; } //expire the cookie
}

/***
 * Contains application-wide, single use components and services.
 * Imported once (in the AppModule) when the app starts and never imported anywhere else.
 */
@NgModule({
    declarations: [
        ...CoreRouting.declarations
        , MultiFactorAuthModalComponent
        , InactivityTimeoutComponent
        , GetPasswordComponent
        , WebReleaseNotesComponent
        , MobileReleaseNotesComponent
        , PinAuthComponent
    ],
    imports: [
        ...CoreRouting.imports
        , HttpClientModule
        , BrowserModule
        , SystemMessageModule
        , LayoutModule
        , SharedModule
        , JwtModule.forRoot({
            config: {
                tokenGetter: getToken
                , allowedDomains: [window.location.host]
            }
        })
    ],
    exports: [
        ...CoreRouting.exports
        , SharedModule
        , LayoutModule
        , HttpClientModule
        , RouterModule
        , SystemMessageModule
        , BrowserModule
    ],
    providers: [
        ...CoreRouting.providers
        , SystemMessageService
        , SpinnerService
        , SecurityService
        , WebAuthnService
        , InactivityTimeoutService
        , CollapsibleTableService
        , NavigationService
        , FilterSaveService
        , { 
            provide: NgbDateParserFormatter
            , useClass: NgbDateNativeParserFormatter 
        }
        , NgbDateNativeAdapter
        , { 
            provide: HTTP_INTERCEPTORS
            , useClass: HttpClientInterceptor, multi: true 
        }
    ]
})
export class CoreModule {}
