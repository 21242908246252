<!-- Delete WITHOUT comments -->
<button 
	*ngIf="!isUsingComment" 
	aria-label="Delete" 
	tabindex="-1" 
	class="delete-btn" 
	(click)="$event.stopPropagation(); deleteRecord()"
>
	<fa-icon 
		[icon]="faIconName.faTrashCan" 
		class="trash-can"
	></fa-icon>
</button>

<!-- Delete WITH comments -->
<button 
	*ngIf="isUsingComment" 
	aria-label="Delete" 
	tabindex="-1"
	class="delete-btn" 
	(click)="$event.stopPropagation(); openModal()"
>
	<fa-icon 
		[icon]="faIconName.faTrashCan" 
		class="trash-can"
	></fa-icon>
</button>


<!-- Comment modal -->
<ng-template #deleteModal let-modal>
	<div class="modal-header">
		<h5 class="modal-title" id="modal-basic-title">Add Comment for Delete</h5>
		<button 
			type="button" 
			class="close" 
			aria-label="Close" 
			(click)="modal.dismiss()"
		>
			<span aria-hidden="true">&times;</span>
		</button>
	</div>

	<div class="modal-body">
		<div *ngIf="noDeleteMessage" class="validation"> *A comment is required </div>
		<textarea 
			class="w-100" 
			[(ngModel)]="deleteComment" 
			maxlength="150"
		></textarea>		
		<div>Characters Left: {{150 - deleteComment.length}} </div>		
	</div>

	<div class="modal-footer" tabindex="-1">
		<button 
			type="button" 
			class="btn btn-cancel btn-lg" 
			(click)="modal.dismiss()"
		>Cancel</button>
		<button 
			type="submit" 
			class="btn btn-save btn-lg" 
			(click)="deleteWithComment()"
		>Continue</button>
	</div>
</ng-template>