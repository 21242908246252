// tslint:disable: quotemark
export enum FooterTypeEnum {
	EULA = 0,
	PrivacyPolicy = 1,
	TermsConditions = 2
}

export namespace FooterTypeEnum {
	export function toDescription(val: FooterTypeEnum) {
		switch (val) {
			case 0:
				return "EULA";
			case 1:
				return "Privacy Policy";
			case 2:
				return "Terms and Conditions";
		}
		return null;
	}
}
