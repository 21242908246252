import { HttpClient } from '@angular/common/http';
import { ValidatorFn, UntypedFormGroup, ValidationErrors, UntypedFormControl, Validators, AsyncValidatorFn } from '@angular/forms';
import { Observable } from 'rxjs';

export class ChangePasswordForm {

	constructor(
		private http: HttpClient
		, incCurrentPassword = false
	) {
		this.formGroup = new UntypedFormGroup({});
		if (incCurrentPassword) {
			this.formGroup.addControl('currentPassword', new UntypedFormControl('', {
				validators: [Validators.required],
				updateOn: 'blur'
			}));
		}
		this.formGroup.addControl('password', new UntypedFormControl('', {
			validators: [Validators.required, Validators.minLength(10), Validators.maxLength(250)],
			asyncValidators: this.commonPasswordValidator,
			updateOn: 'blur'
		}));
		this.formGroup.addControl('confirmPassword', new UntypedFormControl('', [Validators.required, this.passwordsEqualValidator]));
	}	

	// Our form group
	// eslint-disable-next-line @typescript-eslint/member-ordering
	formGroup: UntypedFormGroup = null;

	// Validator to ensure new password === confirmation password
	passwordsEqualValidator: ValidatorFn =  (control: UntypedFormGroup): ValidationErrors | null => {
		if (this.formGroup && this.formGroup.value) {
			const password = this.formGroup.value.password;
			const confirmPassword = control.value;
			return (password === confirmPassword) ? null : { 'passwordsNotEqual': true };
		}
		return null;
	}

	// Validator to prevent common passwords from being used, based on a dictionary
	commonPasswordValidator: AsyncValidatorFn =  (control: UntypedFormGroup): Observable<ValidationErrors> => 
		this.http.get('api/Account/IsCommonPassword?password=' + encodeURIComponent(control.value))
}
