export interface Permission {
	id: number;
	coreFeatures: boolean;
	manageUsers: boolean;
	replenishment: boolean;
	reconciliation: boolean;
	shiftChangeAuditor: boolean;
	applicationSettings: boolean;
	createAndEditInventories: boolean;
	createAndEditCustomFields: boolean;
	programsAndDepartments: boolean;
	verificationSettings: boolean;
	canChangePermissions: boolean;
	manageNdcDirectory: boolean;
	manageHelpDocumentation: boolean;
	releaseNotes: boolean;
	security: boolean;
	clients: boolean;
	globalAuditHistory: boolean;
	permissionProfile?: any;
	viewQuartzJobs: boolean;
}

export class PermissionConst {
	public static coreFeatures = "coreFeatures";
	public static manageUsers = "manageUsers";
	public static replenishment = "replenishment";
	public static reconciliation = "reconciliation";
	public static shiftChangeAuditor = "shiftChangeAuditor";
	public static applicationSettings = "applicationSettings";
	public static createAndEditInventories = "createAndEditInventories";
	public static createAndEditCustomFields = "createAndEditCustomFields";
	public static programsAndDepartments = "programsAndDepartments";
	public static verificationSettings = "verificationSettings";
	public static canChangePermissions = "canChangePermissions";
	public static manageNdcDirectory = "manageNdcDirectory";
	public static manageHelpDocumentation = "manageHelpDocumentation";
	public static releaseNotes = "releaseNotes";
	public static security = "security";
	public static clients = "clients";
	public static globalAuditHistory = "globalAuditHistory";
	public static viewQuartzJobs = "viewQuartzJobs";
}
