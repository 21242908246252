import { Injectable } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { BehaviorSubject, Subscription } from 'rxjs';
import { filter } from 'rxjs/operators';

import { NavRoute } from './nav.route.interface';
import { getMainNavRoutes } from 'app/core/main-nav-routes';
import { SecurityService } from 'app/core/security/security.service';

export const NavDividerCharacter = '>>';

@Injectable({
	providedIn: 'root'
})
export class NavigationService {

	// The currently OPEN primary navigation menu
	currOpenPrimaryNavMenu = new BehaviorSubject<string>('');
	currOpenPrimaryNavMenu$ = this.currOpenPrimaryNavMenu.asObservable();

	// The currently OPEN secondary navigation menu
	currOpenSecondaryNavMenu = new BehaviorSubject<NavRoute>({ name: 'Root', id: 'root' });
	currOpenSecondaryNavMenu$ = this.currOpenSecondaryNavMenu.asObservable();

	// The currently OPEN side navigation menu
	currOpenSideNavMenu = new BehaviorSubject<NavRoute>({ name: 'Root', id: 'root' });
	currOpenSideNavMenu$ = this.currOpenSideNavMenu.asObservable();

	// The currently SELECTED navigation menu
	currSelectedMainNavMenu = new BehaviorSubject<NavRoute>({ name: 'Root', id: 'root' });
	currSelectedMainNavMenu$ = this.currSelectedMainNavMenu.asObservable();

	// Force select an item in nav
	currForceSelect = new BehaviorSubject<string>(null);
	currForceSelect$ = this.currForceSelect.asObservable();

	navRoutes = new BehaviorSubject<NavRoute[]>([]);
	navRoutes$ = this.navRoutes.asObservable();

	navOpened: BehaviorSubject<boolean> = new BehaviorSubject(false);

	currNav: NavRoute;

	subscriptions: Subscription = new Subscription();

	constructor(
		router: Router
		, private sec: SecurityService
	) {
		// Subscribe to route changes and update the navs when they happen
		router.events.pipe(filter(event => event instanceof NavigationEnd)).subscribe((event: NavigationEnd) => {
			let menu = this.getMenuByUrl(event?.url?.substr(1).replace(/\?[^?]*$/, ''));
			this.currSelectedMainNavMenu.next(menu);

			// Move menu up tree if we have a side nav
			if (menu && menu.hasSideNav) {
				if (menu.parentNav) { menu = menu.parentNav; }
				if (menu.hasSideNav && menu.parentNav && !menu.parentNav.hasSideNav) { menu = menu.parentNav; }
			}

			if (menu !== undefined) {
				this.currOpenSecondaryNavMenu.next(menu);
				this.currForceSelect.next(null);
			}
		});

		// Update nav routes on security and user updates
		const setNavRoutes = () => {
			// Set nav routes
			const routes = sec.getSecureNavItems(getMainNavRoutes());
			this.navRoutes.next(routes);

			// Try to reload current nav to fix children
			const currNav = this.currSelectedMainNavMenu.getValue();
			const newSelectedMainNavMenu = this.getFlatMenu(routes).find(o => o.id === currNav?.id);
			if (newSelectedMainNavMenu) { this.currSelectedMainNavMenu.next(newSelectedMainNavMenu); }
		};
		this.subscriptions.add(sec.user$.subscribe(setNavRoutes));
		this.subscriptions.add(sec.security$.subscribe(setNavRoutes));
	}

	/**
	 * Set the currently open navigation menu.
	 * On pages, use setOpenSecondaryMenu instead.
	 * @param currMainNavMenu The current main nav menu as a dot separated string. Always starts with root.
	 * root. For example, 'root>>Style Guide>>Templates'
	 * @param isSecondary Whether or not to open secondary menu instead of primary menu
	 * @param isSideNav Whether or not to open side menu instead of primary menu
	 */
	setCurrOpenNavMenu(
		newMenu: string
		, isSecondary: boolean = false
		, isSideNav: boolean = false
	) {
		if (
			isSecondary 
			|| isSideNav
		) {
			const nextNav = this.getFlatMenu(this.sec.getSecureNavItems(getMainNavRoutes())).find(nav => nav.id === newMenu);
			if (isSecondary) { this.currOpenSecondaryNavMenu.next(nextNav); } 
			else if (isSideNav) { this.currOpenSideNavMenu.next(nextNav); }
		} else { this.currOpenPrimaryNavMenu.next(newMenu); }
	}

	/** Used to set active menu for pages */
	setActiveNav(newMenu: string[]) { this.setOpenSecondaryMenu(newMenu); }

	/** Used to set secondary menu for pages */
	setOpenSecondaryMenu(newMenu: string[]) {
		const menuList = this.getMenuNameWithRoot(newMenu);
		const flatMenu = this.getFlatMenu(this.sec.getSecureNavItems(getMainNavRoutes()));
		let menuId = menuList.join(NavDividerCharacter);
		const menuItem = flatMenu.find(o => o.id === menuId);
		if (menuItem?.path) {
			this.currForceSelect.next(menuId);
			menuId = menuList.splice(0, newMenu.length).join(NavDividerCharacter);
		} else { this.currForceSelect.next(null); }
		this.setCurrOpenNavMenu(menuId, true);
	}

	/** Used to set side menu for pages */
	setOpenSideMenu(newMenu: string[]) {
		const menuList = this.getMenuNameWithRoot(newMenu);
		this.setCurrOpenNavMenu(menuList.join(NavDividerCharacter), false, true);
	}

	/** Return a flattened menu that can more easily be filtered and searched */
	getFlatMenu(routes: NavRoute[], id = 'root', parentNav: NavRoute = null): NavRoute[] {
		let flatRoutes = [];
		// For each passed in route...
		for (let i = 0; i < routes.length; ++i) {
			// Set an id, which will be dot separated and begin with root
			// for example, root.Style Guide.Icons
			routes[i].id = id + NavDividerCharacter + routes[i].name;
			// Save the parent navigation menu
			routes[i].parentNav = parentNav;
			// If we have children...
			if (routes[i].children) {
				// Recursively call this function and add child routes to list
				const childRoutes = this.getFlatMenu(routes[i].children, routes[i].id, routes[i]);
				flatRoutes = flatRoutes.concat(childRoutes);
			}
			// Add the current route to the nav
			flatRoutes.push(routes[i]);
		}
		return flatRoutes;
	}

	/**
	 * Find a menu by URL.
	 * 
	 * @param url The URL to search by
	 */
	getMenuByUrl(url: string) {
		// An empty url indicates we are in the root nav
		if (url === '') { return null; }

		const flatMenu = this.getFlatMenu(this.sec.getSecureNavItems(getMainNavRoutes()));
		const myRoute = flatMenu.find(o => (o.path === url && o.exact) || (o.path && o.path.startsWith(url) && !o.exact));
		this.currNav = myRoute;
		if (myRoute && myRoute.parentNav) {	return myRoute.parentNav; }

		// If nothing was found, return undefined
		return undefined;
	}

	getCurrRouteIndex(navRoutes: NavRoute[]) {
		return navRoutes
			?.map((o, i) => ({ name: o.name, id: o.id, i: o.index ?? i, parent: o.parentNav }))
			?.find(o => o?.id === this.currNav?.parentNav?.id || o?.id === this.currForceSelect.getValue())?.i;
	}

	/**
	 * Used to determine whether or not route link
	 * should show in top and secondary navigations
	 */
	shouldShowRouteLink(route: NavRoute, navRoutes: NavRoute[], index: number = null) {
		return route && (route.path || route.path === '') && this.shouldDisplay(route, navRoutes, index);
	}

	shouldDisplay(route: NavRoute, navRoutes: NavRoute[], index: number = null) {
		return !route?.shouldDisplay || route?.shouldDisplay(index ?? this.getCurrRouteIndex(navRoutes));
	}

	/** Used in the function above to fix array for functions above */
	private getMenuNameWithRoot(newMenu: string[]) {
		let menuList = ['root'];
		if (newMenu[0] !== 'root') { menuList = menuList.concat(newMenu); } 
		else { menuList = newMenu; }
		return menuList;
	}
}
