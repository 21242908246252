import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { NgbModalModule } from '@ng-bootstrap/ng-bootstrap';

import { SharedModule } from 'app/shared/shared.module';
import { EditUserComponent } from './edit-user.component';
import { RegisterAuthDeviceModalComponent } from './register-auth-device-modal/register-auth-device-modal.component';
import { UserLoginHistoryComponent } from './user-login-history/user-login-history.component';
import { UserMultiFactorAuthenticationComponent } from './user-multi-factor-authentication/user-multi-factor-authentication.component';
import { UserChangePasswordModalComponent } from './user-change-password-modal/user-change-password-modal.component';
import { UserFingerprintAuthenticationComponent } from './user-fingerprint-authentication/user-fingerprint-authentication.component';
import { UserNotificationComponent } from './user-notifications/user-notifications.component';
import { UserPermissionsModalComponent } from './user-permissions-modal/user-permissions-modal.component';
import { DepartmentInventoriesModalComponent } from './department-inventories-modal/department-inventories-modal.component';
import { RegisterPinModalComponent } from './register-pin-modal/register-pin-modal.component';
import { UsersAssociateInventoriesComponent } from './users-associate-inventories/users-associate-inventories.component';
import { UserSecurityComponent } from './user-security/user-security.component';
import { UserAuditHistoryComponent } from './user-audit-history/user-audit-history.component';

@NgModule({
    declarations: [
        EditUserComponent
        , RegisterAuthDeviceModalComponent
        , UserChangePasswordModalComponent
        , UserLoginHistoryComponent
        , UserNotificationComponent
        , UserPermissionsModalComponent
        , DepartmentInventoriesModalComponent
        , RegisterPinModalComponent
        , UsersAssociateInventoriesComponent
        , UserSecurityComponent
        , UserMultiFactorAuthenticationComponent
        , UserFingerprintAuthenticationComponent
        , UserAuditHistoryComponent
    ],
    imports: [
        RouterModule
        , CommonModule
        , SharedModule
        , NgbModalModule
    ],
    exports: [
        EditUserComponent
        , UserLoginHistoryComponent
        , UserMultiFactorAuthenticationComponent
        , UserFingerprintAuthenticationComponent
        , UserNotificationComponent
        , UserAuditHistoryComponent
    ]
})
export class EditUserModule {}
