import { NavRoute } from 'app/shared/navigation/nav.route.interface';
import { UserJsVm } from 'app/shared/generated/Administration/Models/UserJsVm';

export const getInventoryNav: () => NavRoute = () => {
	const user = getUser();
		let navItems: NavRoute[] = [];
		
		navItems.push({
			name: 'Inventory Dashboard'
			, exact: true
			, path: 'inventory/inventory-list'
		});

		return {
			name: 'Inventories'
			, security: sec => !!user
			, children: navItems
			, href: 'inventory/inventory-list'
		};
};

/**
 * Get the currently logged in user.
 *
 * Should remove after refactoring to generate
 * nav routes in an Angular service and use
 * security service to get it.
 */
// eslint-disable-next-line prefer-arrow/prefer-arrow-functions
function getUser(): UserJsVm {
	const userJson: string = localStorage.getItem('user') !== null ? localStorage.getItem('user') : null;
	if (userJson !== null) { return JSON.parse(userJson); }
	return null;
}
