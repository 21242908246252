<div
	id="system-messages-wrapper"
	[@visibilityChanged]="model.messageOpen"
	(@visibilityChanged.start)="displayMessage = 'block'"
	(@visibilityChanged.done)="displayMessage = model.messageOpen ? 'block' : 'none'"
	[style.display]="displayMessage"
	[ngStyle]="model.centeredStyles"
	(click)="closeSystemMessage()"
>
	<div [ngClass]="{ 'd-none': model.firstLoad }">
		<span aria-hidden="true" id="sys-message-closer"> X </span>

		<div role="alert" aria-live="polite" aria-atomic="true" id="system-messages" [ngClass]="[model.messageClass]">
			<span id="sys-message-header">{{ model.messageClass }}!</span>
			{{ model.message }}
			<br />
			<a *ngIf="model.route != null" [href]="model.route" rel="noreferrer noopener" target="_blank"
				>{{ model.routeMessage }}
			</a>
		</div>
	</div>
</div>
