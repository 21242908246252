/* eslint-disable @typescript-eslint/dot-notation */
import { Directive, ElementRef, AfterViewInit, Renderer2, Optional, Self, Host } from '@angular/core';
import { NgControl, AbstractControl, FormGroupDirective, ControlContainer } from '@angular/forms';
import { merge, of, Subscription } from 'rxjs';

import { PcgSelectComponent } from '../components/pcg-select-component/select.component';

// This directive was originally copied from here:
// https://www.codeproject.com/Articles/1239543/Angular-Custom-Validation-Component
@Directive({
	selector: '[pcgControlRef]',
})
export class ControlRefDirective implements AfterViewInit {
	subscriptions: Subscription = new Subscription();

	constructor(
		private formControl: NgControl,
		@Host() @Self() @Optional() public pcgSelect: PcgSelectComponent,
		private elRef: ElementRef,
		private renderer: Renderer2,
		private fg: ControlContainer
	) {
		// Set the id based on the form control name, if none given
		if (elRef.nativeElement.attributes['formcontrolname']) {
			const el = this.elRef.nativeElement;
			if (pcgSelect) {
				pcgSelect.labelForId = elRef.nativeElement.attributes['formcontrolname'].value;
			} else if (!el.id) {
				el.id = elRef.nativeElement.attributes['formcontrolname'].value;
			}
		}
	}

	ngAfterViewInit() {
		if (this.formControl) {
			this.subscriptions.add(
				merge(of(this.formControl.status), this.formControl.statusChanges).subscribe(() => this.updateState())
			);
		}
	}

	get hasError() {
		return this.formControl.dirty && this.formControl.invalid;
	}

	get isRequired() {
		const validator = this.formControl.control.validator
			? this.formControl.control.validator({} as AbstractControl)
			: '';
		return validator && validator.required;
	}

	get errors() {
		if (this.hasError && this.formControl.errors) {
			return this.formControl.errors;
		}
		return '';
	}

	get id() {
		return this.pcgSelect ? this.pcgSelect.labelForId : this.elRef.nativeElement.id;
	}

	get form() {
		return this.fg.formDirective ? (this.fg.formDirective as FormGroupDirective).form : null;
	}

	updateState() {
		let el = this.elRef.nativeElement;
		if (el.tagName === 'PCG-SELECT') {
			if (this.pcgSelect?.showNativeSelect) {
				el = el.querySelector('select');
			} else {
				el = el.querySelector('input');
			}
		}
		this.renderer.setAttribute(el, 'aria-required', this.isRequired);
		if ((this.isRequired && this.formControl.value === null) || this.formControl.value === undefined) {
			this.renderer.setAttribute(el, 'aria-invalid', this.hasError.toString());
		} else {
			this.renderer.setAttribute(el, 'aria-invalid', (!this.formControl.valid).toString());
		}
	}
}
