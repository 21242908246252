// tslint:disable: quotemark
export enum ActivationEnum {
	Client = 0,
	User = 1,
	InventoryAction = 2,
	InventorySite = 3,
	Product = 4,
	GlobalNdc = 5,
	CustomField = 6,
	Supplier = 7,
	Department = 8,
	IntegrationUser = 9,
	IntegrationClient = 10,
	StateCustomField = 11
}

export namespace ActivationEnum {
	export function toDescription(val: ActivationEnum) {
		switch (val) {
			case 0:
				return "Client";
			case 1:
				return "User";
			case 2:
				return "Inventory Action";
			case 3:
				return "Inventory Site";
			case 4:
				return "Product";
			case 5:
				return "Global NDC";
			case 6:
				return "Custom Field";
			case 7:
				return "Supplier";
			case 8:
				return "Department";
			case 9:
				return "Integration User";
			case 10:
				return "Integration Client";
			case 11:
				return "State Custom Field";
		}
		return null;
	}
}
