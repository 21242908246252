import { Component } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { FooterTypeEnum } from 'app/core/enums/generated/FooterTypeEnum';

import { FooterVm } from 'app/shared/generated/Administration/Models/FooterVm';
import { FooterService } from '../footer.service';

@Component({
    selector: 'pcg-view-eula-footer',
    templateUrl: './view-eula-footer.component.html',
    styleUrl: './view-eula-footer.component.scss'
})
export class ViewEulaFooterComponent {

    footerDescription: string;

    constructor( 
        title: Title
        , private footerService: FooterService
    ) { title.setTitle('EULA'); }

    ngOnInit() {
        this.footerService.getFooterItem(FooterTypeEnum.EULA)
            .subscribe((vm: FooterVm) => {
                this.footerDescription = vm?.footerDescription;
            });
    }
}