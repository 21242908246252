import { Component, OnInit, OnDestroy } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Router } from '@angular/router';
import { timer } from 'rxjs';

import { SecurityService } from '../security/security.service';

@Component({
	selector: 'pcg-inactivity-timeout',
	templateUrl: './inactivity-timeout.component.html'
})
export class InactivityTimeoutComponent implements OnInit, OnDestroy {

	subscribeTimer: number;

	constructor(
		private http: HttpClient
		, public modal: NgbActiveModal
		, private sec: SecurityService
		, private router: Router
	) { }

	get timeLeft() {
		const now: any = new Date();
		if (localStorage.getItem('lastAccess')) {
			const lastAccess: any = new Date(JSON.parse(localStorage.getItem('lastAccess')));
			// 5 minute inactivity timer (300 seconds) compared to their local storage 'lastaccess' 
			// variable that's set in http-client-interceptor.ts, 
			// BaseController.cs, login.component.ts, and LoginVM.cs for all requests
			const theTimeLeft = 300 - (now - lastAccess) / 1000; 
			return parseInt(theTimeLeft.toString(), 10);
		} else { return 0; }
	}

	ngOnInit() {
		this.subscribeTimer = this.timeLeft;
		const source = timer(0, 1000);
		source.subscribe(() => {
			this.subscribeTimer = this.timeLeft;
			if (this.subscribeTimer <= 0) { // Times up
				this.sec.setSecurity(null, null, null); // Nullify token, security, and user info
				this.modal.dismiss(); // Close this modal
				// Redirect to login
				let redirectUrl = null;
				const redirect = window.location.href.replace(document.getElementsByTagName('base')[0].href, '');
				if (
					redirect !== null 
					&& redirect !== '' 
					&& redirect !== undefined
				) { redirectUrl = '/' + redirect; }	
						
				if (redirectUrl == null) { this.router.navigateByUrl(`/login`); }
				else { this.router.navigateByUrl(`/login?redirectUrl=${encodeURIComponent(redirectUrl)}`); }
			} else if (this.subscribeTimer > 30) { // Session was probably extended in another window
				this.modal.dismiss();
			}
		});
	}

	ngOnDestroy() {
		const user = this.sec.getUser();
		if (
			user 
			&& this.timeLeft <= 30
		) { // Need to do a server hit to reset timeout server-side
			this.http.get(`/api/Account/GetUserInfo/${user.id}`).subscribe();
		}
	}
}
