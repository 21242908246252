import { Injectable } from '@angular/core';
import { Validators, UntypedFormGroup, UntypedFormControl } from '@angular/forms';

import { StateCustomFieldEditVm } from '../../../generated/Administration/Models/States/StateCustomFieldEditVm';
import { CustomFieldTypeEnum } from '../../../../core/enums/generated/CustomFieldTypeEnum';

@Injectable({
    providedIn: 'root'
})
export class StateDynamicFormService {

    constructor() { }

    /**
	 * Get a form group containing dynamic fields
	 */
	getFormGroup(fields: StateCustomFieldEditVm[]) {
		const group: any = {};
		fields?.forEach(field => { group[field.key] = new UntypedFormControl('', this.getValidators(field)); });
		return new UntypedFormGroup(group);
	}

	/**
	 * Get a form group containing dynamic fields
	 */
	getFormGroupFromData(fgData: {}, fields: StateCustomFieldEditVm[]) {
		delete fgData['stateFields'];
		fgData['stateCustomFields'] = this.getFormGroup(fields);
		return new UntypedFormGroup(fgData);
	}

	/**
	 * Update form group's dynamic fields
	 */
	updateFormGroupFields(fg: UntypedFormGroup, fields: StateCustomFieldEditVm[]) {
		fg.removeControl('stateFields');
		fg.setControl('stateCustomFields', this.getFormGroup(fields));
		return fg;
	}

	/**
	 * Dynamically generate a list of validators.
	 * 
	 * @param field The dynamic form field provided by the user.
	 */
	getValidators(field: StateCustomFieldEditVm) {
		const validators = [];

		if (field.customFieldType === CustomFieldTypeEnum.Number) { validators.push(Validators.pattern('^-?\\d+(.\\d)?\\d*$')); }
		if (field.customFieldType === CustomFieldTypeEnum.Count) { validators.push(Validators.pattern('^[0-9]*$')); }
		if (field.isRequired) { validators.push(Validators.required); }
		if (field.stringLengthMax !== null) { validators.push(Validators.maxLength(field.stringLengthMax)); }
		if (field.stringLengthMin !== null) { validators.push(Validators.minLength(field.stringLengthMin)); }
		if (field.regex !== null) { validators.push(Validators.pattern(field.regex)); }
		if (field.numericMin !== null) { validators.push(Validators.min(field.numericMin)); }
		if (field.numericMax !== null) { validators.push(Validators.max(field.numericMax)); }
		if (field.isEmail) { validators.push(Validators.email); }

		return validators;
	}
}
