import { UserJsVm } from 'app/shared/generated/Administration/Models/UserJsVm';
import { NavRoute } from '../../shared/navigation/nav.route.interface';

const user = getUser();

export const usersNav: () => NavRoute = () => ({
	name: getName()
	, security: sec => sec.coreFeatures || sec.security
	, href: 'users'
	, children: getUserAreas()
});

function getName() : string {
	var name: string;
	var url = window.location.href;
	
	if (
		url.indexOf('users/edit-user/') > -1
		|| url.indexOf('users/edit-user/inventories/') > -1
		|| url.indexOf('users/edit-user/security/') > -1
		|| url.indexOf('users/edit-user/login-history/') > -1
		|| url.indexOf('users/edit-user/audit-history/') > -1
		|| url.indexOf('users/edit-user/notifications/') > -1		
	) { name = "Edit User"; } 
	else { name = "Users"; }

	return name;
}

function getUserAreas() : NavRoute[] {
	var id: string = "";
	var url = window.location.href;

	if (url.indexOf('users/edit-user/') > -1) { id = url.substring(url.lastIndexOf('/') + 1); }
	if (url.indexOf('users/edit-user/inventories/') > -1) { id = url.substring(url.lastIndexOf('/') + 1); }
	if (url.indexOf('users/edit-user/security/') > -1) { id = url.substring(url.lastIndexOf('/') + 1); }	
	if (url.indexOf('users/edit-user/login-history/') > -1) { id = url.substring(url.lastIndexOf('/') + 1); }
	if (url.indexOf('users/edit-user/audit-history/') > -1) { id = url.substring(url.lastIndexOf('/') + 1); }
	if (url.indexOf('users/edit-user/notifications/') > -1) { id = url.substring(url.lastIndexOf('/') + 1); }

	// Users List and Global Login History
	if (id === "") { 
		return [
			{
				name: 'All Users'
				, exact: true
				, path: 'administration/users/list'
			}
			, {
				name: 'Global Audit History'
				, exact: true
				, path: 'administration/global-audit-history'
			}
			, {
				name: 'Global Login History'
				, exact: true
				, path: 'administration/login-history'
			}
			, {
				name: 'User Custom Fields'
				, exact: true
				, path: 'administration/user-custom-fields'
			}
		]; 
	}

	// Creating a new User
	if (+id === 0) {
		return [
			{
				name: 'Back'
				, exact: true
				, path: `administration/users/list`
			},
			{
				name: 'Details'
				, exact: true
				, path: `administration/users/edit-user/details/${id}`
			}
		];
	}

	if (id.includes("isCreatingNewUser")) {
		return [
			{
				name: 'Back'
				, exact: true
				, path: `administration/users/list`
			},
			{
				name: 'Details'
				, exact: true
				, path: `administration/users/edit-user/details/${id}`
			},
			{
				name: 'Associated Inventories'
				, exact: true
				, path: `administration/users/edit-user/inventories/${id}?isCreatingNewUser=true`
				, security: security => security.manageUsers
			},
		]
	}

	// User details
	if (user?.id === Number(id) && user?.permissionId !== 1) {
		return [
			{
				name: 'Back'
				, exact: true
				, path: `administration/users/list`
			}
			, {
				name: 'Details'
				, exact: true
				, path: `administration/users/edit-user/details/${id}`
			}
			, {
				name: 'Associated Inventories'
				, exact: true
				, path: `administration/users/edit-user/inventories/${id}`
				, security: sec => sec.coreFeatures
			}
			, {
				name: 'Security'
				, exact: true
				, path: `administration/users/edit-user/security/${id}`
				, security: sec => sec.coreFeatures
			}
			, {
				name: 'Login History'
				, exact: true
				, path: `administration/users/edit-user/login-history/${id}`
				, security: sec => sec.coreFeatures
			}
			, {
				name: 'Audit History'
				, exact: true
				, path: `administration/users/edit-user/audit-history/${id}`
				, security: sec => sec.manageUsers
			}
			, {
				name: 'Notifications'
				, exact: true
				, path: `administration/users/edit-user/notifications/${id}`
			}
		]
	}
		
	if (user?.permissionId === 1) {
		return [
			{
				name: 'Back'
				, exact: true
				, path: `inventory/inventory-list`
			}
			, {
				name: 'Details'
				, exact: true
				, path: `administration/users/edit-user/details/${id}`
			}
			, {
				name: 'Associated Inventories'
				, exact: true
				, path: `administration/users/edit-user/inventories/${id}`
				, security: sec => sec.coreFeatures
			}
			, {
				name: 'Security'
				, exact: true
				, path: `administration/users/edit-user/security/${id}`
				, security: sec => sec.coreFeatures
			}
			, {
				name: 'Login History'
				, exact: true
				, path: `administration/users/edit-user/login-history/${id}`
				, security: sec => sec.coreFeatures
			}
			, {
				name: 'Notifications'
				, exact: true
				, path: `administration/users/edit-user/notifications/${id}`
			}
		]
	}

	return [
		{
			name: 'Back'
			, exact: true
			, path: `administration/users/list`
		}
		, {
			name: 'Details'
			, exact: true
			, path: `administration/users/edit-user/details/${id}`
		}
		, {
			name: 'Associated Inventories'
			, exact: true
			, path: `administration/users/edit-user/inventories/${id}`
			, security: sec => sec.coreFeatures
		}
		, {
			name: 'Security'
			, exact: true
			, path: `administration/users/edit-user/security/${id}`
			, security: sec => sec.coreFeatures
		}
		, {
			name: 'Login History'
			, exact: true
			, path: `administration/users/edit-user/login-history/${id}`
			, security: sec => sec.coreFeatures
		}
		, {
			name: 'Audit History'
			, exact: true
			, path: `administration/users/edit-user/audit-history/${id}`
			, security: sec => sec.coreFeatures
		}
		, {
			name: 'Notifications'
			, exact: true
			, path: `administration/users/edit-user/notifications/${id}`
		}
	];
}

function getUser(): UserJsVm {
	const userJson: string = localStorage.getItem('user') !== null ? localStorage.getItem('user') : null;
	if (userJson !== null) { return JSON.parse(userJson); }
	return null;
}