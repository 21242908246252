<pcg-table
	[canGlobalSearch]="false"
	[multipleSearch]="false"
	[pcgSort]="[['goLiveDateString', 'desc']]"
	dataSource="api/ReleaseNotes/GetWebReleaseNotesList"
	[columnDefs]="columns"
>
	<ng-container matColumnDef="versionNumber">
		<th class="text-center" mat-header-cell pcg-sort-header *matHeaderCellDef>
			{{ columns.get('versionNumber').displayName }}
		</th>
		<td class="text-center" mat-cell *matCellDef="let row" [attr.data-label]="columns.get('versionNumber').displayName">
			{{ row.versionNumber }}
		</td>
	</ng-container>

	<ng-container matColumnDef="goLiveDateString">
		<th class="text-center" mat-header-cell pcg-sort-header *matHeaderCellDef>
			{{ columns.get('goLiveDateString').displayName }}
		</th>
		<td class="text-center" mat-cell *matCellDef="let row" [attr.data-label]="columns.get('goLiveDateString').displayName">
			{{ row.goLiveDateString }}
		</td>
	</ng-container>

	<ng-container matColumnDef="releaseNotes">
		<th class="text-center" mat-header-cell *matHeaderCellDef>{{ columns.get('releaseNotes').displayName }}</th>
		<td mat-cell *matCellDef="let row" [attr.data-label]="columns.get('releaseNotes').displayName" [innerHTML]="row.releaseNotes" class="trix-content"></td>
	</ng-container>
</pcg-table>