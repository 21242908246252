import { NavRoute } from 'app/shared/navigation/nav.route.interface';
import { PermissionProfileEnum } from '../enums/generated/PermissionProfileEnum';
import { UserJsVm } from 'app/shared/generated/Administration/Models/UserJsVm';
import { PermissionConst } from '../security/generated/Permission';

const user = getUser();

//Every value should have a security lambda check.  Use sec.coreFeatures for all user access
export const getAdminNav: () => NavRoute = () => ({
	name: 'Admin'
	, security: sec => sec.coreFeatures
	, href: 'administration/dashboard'
	, children: [
		{ 
			name: 'Dashboard'
			, security: sec => sec.coreFeatures
			, exact: true
			, path: 'administration/dashboard' 
		}
		, {
			name: 'Suppliers'
			, path: 'administration/suppliers/list'
			, security: PermissionConst.replenishment
		}
		, { 
			name: 'Settings'
			, security: sec => sec.permissionProfile === PermissionProfileEnum.Admin || sec.permissionProfile === PermissionProfileEnum.SysAdmin
			, href: 'administration/dashboard'
			, children: getClientChildren()
		}
		, { 
			name: 'Users'
			, security: sec => sec.manageUsers || sec.security
			, href: 'administration/dashboard'
			, children: [
				{ 
					name: 'User List'
					, security: sec => sec.manageUsers
					, exact: true, path: 'administration/users/list' 
				}
				, { 
					name: 'User Custom Fields'
					, security: sec => sec.createAndEditCustomFields
					, path: 'administration/users/user-custom-fields' 
				}
				, { 
					name: 'Login History'
					, security: sec => sec.manageUsers
					, path: 'administration/users/login-history' 
				}				
			]
		}
		, { 
			name: 'Inventory'
			, security: sec => sec.coreFeatures
			, href: 'administration/inventory/inventory-desired-stock'
			, children: [
				{ 
					name: 'Custom Fields'
					, security: sec => sec.createAndEditCustomFields
					, exact: true
					, path: 'administration/inventory/inventory-custom-fields' 
				}
				, { 
					name: 'Departments'
					, security: sec => sec.programsAndDepartments
					, exact: true
					, path: 'administration/inventory/departments' 
				}
				, { 
					name: 'Inventory Actions'
					, security: sec => sec.createAndEditCustomFields
					, exact: true
					, path: 'administration/inventory/inventory-actions' 
				}
				, { 
					name: 'NDC Database'
					, security: sec => sec.coreFeatures
					, exact: true
					, href: 'administration/inventory/ndc-directory-list' 
					, children: getNdcChildren()
				}
				, { 
					name: 'Set PAR Levels'
					, security: sec => sec.createAndEditInventories
					, exact: true
					, path: 'administration/inventory/inventory-desired-stock' 
				}
			]
		}
		, { 
			name: 'Global Audit History'
			, security: sec => sec.globalAuditHistory
			, exact: true
			, path: 'administration/global-audit-history' 
		}
	]
});

function getClientChildren() {
	var id: string = "";
	var url = window.location.href;

	if (url.indexOf('settings/agency-info/') > -1) { id = url.substring(url.lastIndexOf('/') + 1); }
	if (url.indexOf('settings/verification-settings/') > -1) { id = url.substring(url.lastIndexOf('/') + 1); }
	if (url.indexOf('settings/admin-settings/') > -1) { id = url.substring(url.lastIndexOf('/') + 1); }	
	if (url.indexOf('settings/subscription/') > -1) { id = url.substring(url.lastIndexOf('/') + 1); }
	if (url.indexOf('settings/features/') > -1) { id = url.substring(url.lastIndexOf('/') + 1); }
	if (url.indexOf('settings/integrations/') > -1) { id = url.substring(url.lastIndexOf('/') + 1); }
	if (url.indexOf('settings/dea-info/') > -1) { id = url.substring(url.lastIndexOf('/') + 1); }
	if (url.indexOf('settings/audit-history/') > -1) { id = url.substring(url.lastIndexOf('/') + 1); }

	if (id === "") { id = user?.clientId?.toString(); }

	if (id === "0") {
		return [
			{
				name: 'Agency Info'
				, exact: true
				, path: `administration/settings/agency-info/${id}`
			}
		];
	}

	let navRoutes: Array<NavRoute> = [];

	navRoutes.push({
		name: 'Agency Info'
		, exact: true
		, path: `administration/settings/agency-info/${id}`
	});
	navRoutes.push({
		name: 'Verification'
		, exact: true
		, path: `administration/settings/verification-settings/${id}`
	});
	if (user?.permissionId == 4) {
		navRoutes.push({
			name: 'Admin Settings'
			, exact: true
			, path: `administration/settings/admin-settings/${id}`
		});
	}
	navRoutes.push({
		name: 'Subscription'
		, exact: true
		, path: `administration/settings/subscription/${id}`
	});
	navRoutes.push({
		name: 'Features'
		, exact: true
		, path: `administration/settings/features/${id}`
	});
	navRoutes.push({
		name: 'Integrations'
		, exact: true
		, path: `administration/settings/integrations/${id}`
	});
	navRoutes.push({
		name: 'DEA Info'
		, exact: true
		, path: `administration/settings/dea-info/${id}`
		, security: sec => user?.canAccessDeaInfo != null ? user?.canAccessDeaInfo : false,
	});
	navRoutes.push({
		name: 'Audit History'
		, exact: true
		, path: `administration/settings/audit-history/${id}`
	});

	return navRoutes;
}


function getNdcChildren() : NavRoute[] {
	var id: string = "";
	var url = window.location.href;
	if (url.indexOf('ndc-directory/ndc-audit-history') > -1) { id = url.substring(url.lastIndexOf('/') + 1); }
	if (url.indexOf('ndc-directory/edit-global-ndc/') > -1) { id = url.substring(url.lastIndexOf('/') + 1); }

	if (id === "") {
		return [
			{
				name: 'NDC Database'
				, exact: true
				, path: `administration/inventory/ndc-directory-list`
			}
		];
	}

	return [
		{
			name: 'NDC Database'
			, exact: true
			, path: `administration/inventory/ndc-directory-list`
		}
		, {
			name: 'NDC Database Info'
			, exact: true
			, path: `ndc-directory/edit-global-ndc/${id}`
		}
		, {
			name: 'Audit History'
			, exact: true
			, path: `ndc-directory/ndc-audit-history/${id}`
		}
	];
}

function getUser(): UserJsVm {
	const userJson: string = localStorage.getItem('user') !== null ? localStorage.getItem('user') : null;
	if (userJson !== null) { return JSON.parse(userJson); }
	return null;
}
