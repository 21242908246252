import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { tap } from 'rxjs/operators';
import { UntypedFormGroup } from '@angular/forms';

import { param } from 'app/shared/http-params';
import { SystemMessageService, SystemMessage } from 'app/core/system-message/system-message-service';
import { SecurityService } from '../security.service';
import { DualAuthModel } from 'app/shared/authentication/double-auth/double-auth.component';

@Injectable({
	providedIn: 'root'
})
export class WebAuthnService {

	constructor(private http: HttpClient, private ms: SystemMessageService, private sec: SecurityService) { }

	/**
	 * Make sure your Browser is compatible and you are using HTTPS
	 */
	isFidoCompatible() {
		if (window.location.protocol !== 'https:') {
			this.ms.setSystemMessage('U2F authentication is not supported without HTTPs.', 'error');
			return false;
		}

		if (window.PublicKeyCredential === undefined || typeof window.PublicKeyCredential !== 'function') {
			this.ms.setSystemMessage('U2F authentication is not supported on this browser.', 'error');
			return false;
		}
		return true;
	}

	/**
	 * Makes your user credential options by asking the FIDO controller for the options
	 *
	 * @param userId
	 * @param password
	 */
	makeCredentials(userId, password, userVerification = false) {
		return this.http.get('api/Fido/MakeCredentialOptions?' + param({
			userId,
			attType: 'none', // or direct, indirect
			authType: '', // or cross-platform, platform
			userVerification: userVerification ? 'preferred' : 'discouraged', // or required
			requireResidentKey: false,
			password
		})).pipe(
			tap(
				(makeCredentialOptions: any) => {
					if (makeCredentialOptions.status !== 'error') {
						// Turn the challenge back into the accepted format
						makeCredentialOptions.challenge = this.coerceToArrayBuffer(makeCredentialOptions.challenge);
						// Turn ID into a UInt8Array Buffer for some reason
						makeCredentialOptions.user.id = this.coerceToArrayBuffer(makeCredentialOptions.user.id);

						makeCredentialOptions.excludeCredentials = makeCredentialOptions.excludeCredentials.map((c) => {
							c.id = this.coerceToArrayBuffer(c.id);
							return c;
						});

						if (makeCredentialOptions.authenticatorSelection.authenticatorAttachment === null) {
							makeCredentialOptions.authenticatorSelection.authenticatorAttachment = undefined;
						}
					}
				}
			)
		);
	}

	/**
	 * Hits the FIDO controller and saves your credentials
	 *
	 * @param newCredential
	 * @param displayName
	 * @param userId
	 * @param password
	 * @param usedForSignature
	 */
	saveCredentials(newCredential, displayName: string, userId: number, password: string, usedForSignature = false) {
		// Move data into Arrays incase it is super long
		const attestationObject = new Uint8Array(newCredential.response.attestationObject);
		const clientDataJSON = new Uint8Array(newCredential.response.clientDataJSON);
		const rawId = new Uint8Array(newCredential.rawId);
		const attestationResponse = {
			id: newCredential.id,
			rawId: this.b64enc(rawId),
			type: newCredential.type,
			extensions: newCredential.getClientExtensionResults(),
			response: {
				AttestationObject: this.b64RawEnc(attestationObject),
				clientDataJson: this.b64RawEnc(clientDataJSON)
			}
		};
		const data = {
			attestationResponse,
			displayName,
			userId,
			password,
			usedForSignature
		};

		return this.http.post('api/Fido/MakeCredential', data);
	}

	/**
	 * Get options for web authn assertion request
	 *
	 * @param userName
	 * @param password
	 */
	getAssertionOptions(userName: string, password: string) {
		const data = { userName, password };
		return this.http.get('api/Fido/GetAssertionOptions?' + param(data)).pipe(
			tap((makeAssertionOptions: any) => {
				const challenge = makeAssertionOptions.challenge.replace(/-/g, '+').replace(/_/g, '/');
				makeAssertionOptions.challenge = this.coerceToArrayBuffer(challenge);

				makeAssertionOptions.allowCredentials.forEach((listItem) => {
					listItem.id = this.coerceToArrayBuffer(listItem.id);
				});
			})
		);
	}

	verifyAssertion(assertedCredential, userName: string, password: string) {
		// Move data into Arrays incase it is super long
		let clientResponse = {};
		if (assertedCredential) {
			const authData = new Uint8Array(assertedCredential.response.authenticatorData);
			const clientDataJSON = new Uint8Array(assertedCredential.response.clientDataJSON);
			const rawId = new Uint8Array(assertedCredential.rawId);
			const sig = new Uint8Array(assertedCredential.response.signature);
			clientResponse = {
				id: assertedCredential.id,
				rawId: this.b64enc(rawId),
				type: assertedCredential.type,
				extensions: assertedCredential.getClientExtensionResults(),
				response: {
					authenticatorData: this.b64RawEnc(authData),
					clientDataJson: this.b64RawEnc(clientDataJSON),
					signature: this.b64RawEnc(sig)
				}
			};
		}
		return this.ms.getHttpObservable(this, 'api/Fido/MakeAssertion', null, {
			userName, 
			password,
			clientResponse
		});
	}

	/**
	 * This function is used to verify FIDO2 devices of a user who is already logged in and update a signature.
	 * Returns a SystemMessage object, with the value property containing the user's default signature 
	 *
	 * @param form The formgroup to send
	 * @param dualAuthVm The dual authentication component view model containing the signature to set
	 * @param url The URL of the save event for the form
	 */
	async authorizedVerifyTwoFactor(form: UntypedFormGroup, dualAuthVm: DualAuthModel, url: string): Promise<SystemMessage> {
		if (!this.isFidoCompatible()) { return null; }

		//Create assertion options
		const assertionOptions = await this.http.get('api/Fido/AuthorizedGetAssertionOptions').pipe(
			tap((makeAssertionOptions: any) => {
				const challenge = makeAssertionOptions.challenge.replace(/-/g, '+').replace(/_/g, '/');
				makeAssertionOptions.challenge = this.coerceToArrayBuffer(challenge);

				makeAssertionOptions.allowCredentials.forEach((listItem) => {
					listItem.id = this.coerceToArrayBuffer(listItem.id);
				});
			})
		).toPromise();

		try {
			//use the assertion options and make the assertion
			const credentials: any = await navigator.credentials.get({publicKey: assertionOptions});
			const authData = new Uint8Array(credentials.response.authenticatorData);
			const clientDataJSON = new Uint8Array(credentials.response.clientDataJSON);
			const rawId = new Uint8Array(credentials.rawId);
			const sig = new Uint8Array(credentials.response.signature);
			const clientResponse = {
				id: credentials.id,
				rawId: this.b64enc(rawId),
				type: credentials.type,
				extensions: credentials.getClientExtensionResults(),
				response: {
					authenticatorData: this.b64RawEnc(authData),
					clientDataJson: this.b64RawEnc(clientDataJSON),
					signature: this.b64RawEnc(sig)
				}
			}
			dualAuthVm.fidoAssertion = clientResponse;

			const msg = await this.http.post<SystemMessage>(url, form.getRawValue()).toPromise();
			// Merge model into form, if provided
			if (msg.model !== null) { form.patchValue(msg.model); }
			// Show the system message, if a message was given
			if (!msg.isSuccessful || (msg.message !== null && msg.message !== '' && msg.model?.dualAuthVm?.signaturesComplete)) {
				this.ms.setSystemMessage(msg.message, !msg.messageClass ? 'success' : msg.messageClass);
			}
			return msg;
		} catch (error) {
			return null;
		}
	}

	// Don't drop any blanks
	b64RawEnc(buf) { return this.b64enc(buf); }

	b64enc(buf) { return this.coerceToBase64Url(buf); }

	coerceToBase64Url(thing) {
		// Array or ArrayBuffer to Uint8Array
		if (Array.isArray(thing)) { thing = Uint8Array.from(thing); }

		if (thing instanceof ArrayBuffer) { thing = new Uint8Array(thing); }

		// Uint8Array to base64
		if (thing instanceof Uint8Array) {
			let str = '';
			const len = thing.byteLength;

			for (let i = 0; i < len; i++) {
				str += String.fromCharCode(thing[i]);
			}
			thing = window.btoa(str);
		}

		if (typeof thing !== 'string') { throw new Error('could not coerce to string'); }

		// base64 to base64url
		// NOTE: "=" at the end of challenge is optional, strip it off here
		thing = thing.replace(/\+/g, '-').replace(/\//g, '_').replace(/=*$/g, '');

		return thing;
	};

	coerceToArrayBuffer(thing) {
		if (typeof thing === 'string') {
			// base64url to base64
			thing = thing.replace(/-/g, '+').replace(/_/g, '/');

			// base64 to Uint8Array
			const str = window.atob(thing);
			const bytes = new Uint8Array(str.length);
			for (let i = 0; i < str.length; i++) {
				bytes[i] = str.charCodeAt(i);
			}
			thing = bytes;
		}

		// Array to Uint8Array
		if (Array.isArray(thing)) { thing = new Uint8Array(thing); }

		// Uint8Array to ArrayBuffer
		if (thing instanceof Uint8Array) { thing = thing.buffer; }

		// error if none of the above worked
		if (!(thing instanceof ArrayBuffer)) { throw new TypeError('could not coerce to ArrayBuffer'); }

		return thing;
	}
}
