import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { NgbDatepickerModule, NgbPopoverModule, NgbTooltipModule } from '@ng-bootstrap/ng-bootstrap';
import { AmazingTimePickerModule } from './atp-library/atp-time-picker.module';
import { NgSelectModule } from '@ng-select/ng-select';
import { AngularDualListBoxModule } from 'angular-dual-listbox';
import { PcgSelectComponent } from './components/pcg-select-component/select.component';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';

import { CollapsibleDividerComponent } from './components/collapsible-divider/collapsible-divider.component';
import { ControlGroupComponent } from './components/control-group/control-group.component';
import { ControlRefDirective } from './directives/control-ref.directive';
import { PhoneExtDirective } from './directives/masking/phone-ext.directive';
import { StepsComponent } from './components/steps/steps.component';
import { SvgModule } from './svg/svg.module';
import { DateRangeComponent } from './components/date-range/date-range.component';
import { DateButtonPickerComponent } from './components/date-button-picker/date-button-picker.component';
import { DualListBoxComponent } from './components/dual-list-box/dual-list-box.component';
import { RadioButtonListComponent } from './components/radio-button-list/radio-button-list.component';
import { TouchRadioButtonListComponent } from './components/touch-radio-button-list/touch-radio-button-list.component';
import { FileUploadComponent } from './file-upload/file-upload.component';
import { FixedButtonsComponent } from './components/fixed-buttons/fixed-buttons.component';
import { NumericDirective } from './directives/masking/numeric.directive';
import { BackButtonDirective } from './directives/back-button/back-button.directive';
import { SubmitUrlDirective } from './directives/submit-url.directive';
import { DynamicFormComponent } from './components/dynamic-form/dynamic-form.component';
import { DynamicFormFieldComponent } from './components/dynamic-form/dynamic-form-field.component';
import { StateDynamicFormComponent } from './components/state-dynamic-form/state-dynamic-form.component';
import { StateDynamicFormFieldComponent } from './components/state-dynamic-form/state-dynamic-form-field.component';
import { DynamicFormService } from './components/dynamic-form/dynamic-form.service';
import { StateDynamicFormService } from './components/state-dynamic-form/state-dynamic-form.service';
import { EditCustomFieldComponent } from './edit-custom-field/edit-custom-field.component';
import { TableModule } from '../table/table.module';
import { DateNoYearDirective } from './directives/masking/date-no-year.directive';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { ShowHidePasswordComponent } from './components/show-hide-password/show-hide-password.component';
import { ButtonListGroupComponent } from './components/button-list-group/button-list-group.component';
import { CurrencyDirective } from './directives/masking/currency.directive';
import { CurrencyService } from './directives/masking/currency.service';
import { NoHashDirective } from './directives/masking/no-hash.directive';
import { DragDropFileUploadComponent } from './drag-drop-file-upload/drag-drop-file-upload.component';
import { DragDropDirective } from './directives/drag-drop.directive';
import { TextEditorComponent } from './text-editor/text-editor.component';
import { NdcDirective } from './directives/masking/ndc.directive';

@NgModule({
	declarations: [
		ControlGroupComponent
		, ControlRefDirective
		, CollapsibleDividerComponent
		, PhoneExtDirective
		, StepsComponent
		, DateRangeComponent
		, DateButtonPickerComponent
		, DualListBoxComponent
		, RadioButtonListComponent
		, TouchRadioButtonListComponent
		, FileUploadComponent
		, FixedButtonsComponent
		, NumericDirective
		, SubmitUrlDirective
		, DynamicFormComponent
		, DynamicFormFieldComponent
		, StateDynamicFormComponent
		, StateDynamicFormFieldComponent
		, EditCustomFieldComponent
		, BackButtonDirective
		, DateNoYearDirective
		, ShowHidePasswordComponent
		, ButtonListGroupComponent
		, PcgSelectComponent
		, CurrencyDirective
		, NoHashDirective 
		, DragDropDirective
		, DragDropFileUploadComponent
		, TextEditorComponent
		, NdcDirective
	]
	, imports: [
		CommonModule
		, ReactiveFormsModule
		, FormsModule
		, SvgModule
		, NgbDatepickerModule
		, AmazingTimePickerModule
		, AngularDualListBoxModule
		, NgbPopoverModule
		, NgSelectModule
		, NgbTooltipModule
		, RouterModule
		, TableModule
		, FontAwesomeModule
		, MatSlideToggleModule
	]
	, exports: [
		ControlGroupComponent
		, ControlRefDirective
		, CollapsibleDividerComponent
		, NumericDirective
		, StepsComponent
		, SvgModule
		, DateRangeComponent
		, DateButtonPickerComponent
		, DualListBoxComponent
		, RadioButtonListComponent
		, TouchRadioButtonListComponent
		, FileUploadComponent
		, FixedButtonsComponent
		, SubmitUrlDirective
		, DynamicFormComponent
		, DynamicFormFieldComponent
		, StateDynamicFormComponent
		, StateDynamicFormFieldComponent
		, EditCustomFieldComponent
		, BackButtonDirective
		, PcgSelectComponent
		, DateNoYearDirective
		, PhoneExtDirective
		, FontAwesomeModule
		, ShowHidePasswordComponent
		, ButtonListGroupComponent
		, CurrencyDirective
		, MatSlideToggleModule
		, NoHashDirective
		, DragDropDirective
		, DragDropFileUploadComponent
		, TextEditorComponent
		, NdcDirective
	]
	, providers: [
		DynamicFormService
		, StateDynamicFormService
		, CurrencyService
	]
})
export class FormElementsModule {}