<div class="modal-header">
	<h5 class="modal-title" id="modal-basic-title">Permissions</h5>
	<button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss()">
		<span aria-hidden="true">&times;</span>
	</button>
</div>
<div class="modal-body">
	<div [innerHTML]="editorString" class="trix-content"></div>
</div>
<div class = "permission-container">
	<table class="perm-table">
		<tr>
			<th class="left-align">Permission</th>
			@if (hasDescriptions){<th>Description</th>}
			<th>Technician</th>
			<th>Manager</th>
			<th>Admin</th>
			<th>System Admin</th>			
		</tr>
		<tr *ngFor="let permission of jsonData;">
			<td class="left-align">{{permission.PermissionName}}</td>
			@if (hasDescriptions){<td>{{permission.PermissionDescription}}</td> }
			<td><fa-icon *ngIf="checkPermissionLevel(permission.PermissionProfilePermissionInfos, 0)" [icon]="faCheck" style="color: green"></fa-icon></td>
			<td><fa-icon *ngIf="checkPermissionLevel(permission.PermissionProfilePermissionInfos, 1)" [icon]="faCheck" style="color: green"></fa-icon></td>
			<td><fa-icon *ngIf="checkPermissionLevel(permission.PermissionProfilePermissionInfos, 2)" [icon]="faCheck" style="color: green"></fa-icon></td>
			<td><fa-icon *ngIf="checkPermissionLevel(permission.PermissionProfilePermissionInfos, 3)" [icon]="faCheck" style="color: green"></fa-icon></td>
		</tr>
	</table>

	<div class="bottom-button">
		<button type="button" class="btn btn-cancel btn-lg" (click)="activeModal.dismiss()">Close</button>
	</div>
</div>
