<div class="tooltip-visibility">
    <label 
		*ngIf="label" 
		[ngbTooltip]="labelTooltip" 
		[ngClass]="{ 'required-field': isRequired && !hideRequired }" 
		[for]='controlId' 
		class="control-label for"
	>{{label}}</label>
</div>
<div class="icon-tooltip-visibility">
	<label 
		*ngIf="label" 
		[ngClass]="{ 'required-field': isRequired && !hideRequired }" 
		[for]='controlId' 
		class="control-label for"
	>
		{{label}}
		<fa-icon 
			*ngIf="labelTooltip" 
			class="icon-tooltip" 
			[icon]="faIconName.faInfoCircle" 
            ngbTooltip="" 
            [ngbTooltip]="labelTooltip" 
			triggers="click"
		></fa-icon>	
	</label>
</div>

<ng-content></ng-content>
<ng-container *ngIf='isError'>
	<br *ngIf="!label" />
	<span 
		class="text-danger" 
		*ngFor="let msg of errorMessages"
	> {{msg}}</span>
</ng-container>