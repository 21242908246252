<div class="top-shift">
    <div class="dash-background">
        <img 
            draggable="false" 
            src="assets/images/dashboard-image.png" 
            class="dash-image" 
        />
        <img 
            draggable="false" 
            src='assets/images/logos/s2-Logo-dropshadow.png' 
            class="s2-logo"
        />  <!-- S2 logo -->
    </div>

    <div class="non-background">
        <br />
        <div 
            *ngIf="!isInventories" 
            class="alert alert-warning text-center"
        >
            Currently no inventories will display because you are not responsible (oncoming {{ clientShiftChangeName }}) for any inventories
        </div>
        
        <div 
            *ngIf="
                downtimeNotification 
                && showDowntimeNotification
            " 
            class="alert alert-warning text-center no-products-msg"
        >
            <button 
                type="button" 
                class="close" 
                aria-label="Close" 
                (click)="dismiss(downtimeNotification)"
            >
                <span aria-hidden="true">&times;</span>
            </button>
            <h5>{{downtimeNotification?.title}}</h5>
            <div>{{downtimeNotification?.message}}</div>
            <div>The window will be from {{formattedStartDate}} to {{formattedEndDate}}</div>
        </div>
        
        <div 
            *ngIf="
                pqaModel?.shiftChangeEventId > 0 
                && showRegularPqaFromIops
            " 
            class="pqa"
        >
            <div class="text-center pqa-header">
                Welcome {{ pqaModel?.firstName }}
            </div>

            <div class="text-center pqa-possession">
                <div class="row">
                    <div class="col-md-12">
                        {{ pqaModel?.possessionPerson }} took possession of 
                        <pcg-edit-link 
                            [url]="'/inventory/products/' + pqaModel?.inventorySiteId" 
                            class="lnk"
                        >{{ pqaModel?.inventoryName }}</pcg-edit-link>
                        {{ pqaModel?.possessionDateStr }}
                    </div>
                </div>
                <br />

                <div class="row">
                    <div class="col-md-6">
                        <button 
                            type="button" 
                            class="btn btn-save btn-lg med-use-btn" 
                            (click)="createMedUse()"
                        >
                            <fa-icon [icon]="faIconName.faSyringe"></fa-icon>
                            <span class="web">&nbsp;{{ clientMedUseName }}</span>
                            <span class="mobile">&nbsp;{{ clientMedUseAbr }}</span>
                        </button>
                    </div>

                    <div class="col-md-6">
                        <button 
                            type="button" 
                            class="btn btn-save btn-lg" 
                            (click)="createShiftChange()"
                        >
                            <fa-icon 
                                [icon]="faIconName.faPeopleArrows" 
                                class="people-arrow"
                            ></fa-icon>
                            <span>&nbsp;{{ isMobile ? clientShiftChangeAbr : clientShiftChangeName }}</span>
                        </button>
                    </div>
                </div>
            </div>

            <div class="text-center pqa-drugs-on-hand-header">
                <span class="bigger">{{ pqaModel?.inventoryName }}</span>&nbsp;Drugs on Hand        
            </div>

            <div class="text-center pqa-drugs-on-hand">
                <table class="data-grid">
                    <tbody>
                        <tr *ngFor="let product of productList">
                            <td 
                                class="text-right" 
                                style="width: 30%;"
                            ><b>{{ product.vialsRemaining }}</b>&nbsp;-&nbsp;</td>
                            <td 
                                class="text-left" 
                                style="width: 70%;"
                            >{{ product.productName.split(' ')[0] }}</td>
                        </tr>
                    </tbody>
                </table>        
            </div>
        </div>
        
        <div 
            *ngIf="fromIops && iopsPqaShiftChanges?.length > 0" 
            class="pqa"
        >
            <div class="pqa-header">
                Welcome {{ iopsPqaName }}!
            </div>
            <h5>Pending {{ clientShiftChangeName }}s:</h5>

            <div class="pqa-li-ctnr">
                <div 
                    *ngFor="let shiftChange of iopsPqaShiftChanges" 
                    class="pqa-li" 
                    (click)="redirectToShiftChange(shiftChange.shiftChangeEventId, shiftChange.inventorySiteId)"
                >
                    <div class="pqa-text">
                        <div class="pqa-text-item">
                            <p>Inventory</p>
                            <h6>{{shiftChange.inventoryName}}</h6>
                        </div>
                        <div class="pqa-text-item">
                            <p>Off-going Name</p>
                            <h6>{{shiftChange.offGoingName}}</h6>
                        </div>
                    </div>

                    <button class="btn btn-save btn-sm">
                        Complete
                        <fa-icon 
                            [icon]="faIconName.faCircleCheck" 
                            class="ml-2"
                        ></fa-icon>
                    </button>
                </div>
            </div>
        </div>
    </div>
</div>