import { Component, OnInit, OnDestroy, ViewChild, TemplateRef } from '@angular/core';
import { trigger, state, animate, transition, style } from '@angular/animations';
import { HttpClient } from '@angular/common/http';
import { ActivatedRoute, Router, NavigationEnd } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Subscription } from 'rxjs';
import { faUnlock, faInfoCircle, faUser, faStaffSnake } from '@fortawesome/pro-solid-svg-icons';

import { EditUserVm } from 'app/shared/generated/Administration/Models/User/EditUser/ViewModel/EditUserVm';
import { SystemMessageService, SystemMessage } from 'app/core/system-message/system-message-service';
import { NavigationService } from 'app/shared/navigation/navigation.service';
import { SecurityService } from 'app/core/security/security.service';
import { DynamicFormService } from 'app/shared/form-elements/components/dynamic-form/dynamic-form.service';
import { DynamicFormFieldVm } from 'app/shared/generated/Models/DynamicFormFieldVm';
import { UserProfileTypeEnum } from 'app/core/enums/generated/UserProfileTypeEnum';
import { UserJsVm } from 'app/shared/generated/Administration/Models/UserJsVm';
import { UserPermissionsModalComponent } from './user-permissions-modal/user-permissions-modal.component';
import { DepartmentInventoriesModalComponent } from './department-inventories-modal/department-inventories-modal.component';
import { PermissionProfileEnum } from 'app/core/enums/generated/PermissionProfileEnum';
import { ActivationEnum } from 'app/core/enums/generated/ActivationEnum';
import { ActivationService } from 'app/shared/service/activation.service';
import { EditUserService } from 'app/administration/users/edit-user/editUser.service';
import { GlobalVariablesService } from 'app/services/global-variables.service';
import { CheckNetworkService } from 'local-db/services/check-network.service';
import { AppDatabase } from 'local-db/services/init.ldb.service';
import { SetLocaldbTablesService } from 'app/services/set-localdb-tables.service';

@Component({
	selector: 'pcg-edit-user',
	templateUrl: './edit-user.component.html',
	styleUrls: ['./edit-user.component.scss'],
	animations: [
		trigger('visibilityChanged', [
			state('true', style({ opacity: 1 })),
			state('false', style({ opacity: 0 })),
			transition('* => *', animate('.3s'))
		])
	],
})
export class EditUserComponent implements OnInit, OnDestroy {

	@ViewChild('pinModal', { static: true }) pinModal: TemplateRef<any>;

	userNameValidation = { pattern: "Only letters, numbers, and symbols ( . @ - _ ' ) are allowed" };
	fullNameValidation = { pattern: "Only letters and symbols - ' . are allowed" };
	phoneValidation = { pattern: "Invalid phone number"	};

	id: number;
	pin: string;
	iAmMe: boolean;
	isLockedOut = false;
	subscriptions = new Subscription();
	msg: SystemMessage;
	user: UserJsVm;

	// Custom Field variables
	formGroup = EditUserVm.Form;
	model = EditUserVm.GetModel(this.formGroup);
	faIconName = { faUnlock, faInfoCircle, faUser, faStaffSnake };
	fields: DynamicFormFieldVm[];
	customFieldsCount: number;
	departments = {};

	// Security variables
	isSysAdmin = false;
	isTechnician = false;
	canEdit = false;
	canManageUser = false;
	hasDepartmentPermissions = false;
	canChangePermissions = false;
	canSeePasswordButtons = false;
	isCurrentUser = false; 
	canManageNotifications: boolean;
	isNewUser = true;
	isOnline: boolean;

	viewModelString = 'Administration.Models.User.EditUser.ViewModel.EditUserVm';
    auditHistoryName = 'Edit User Audit History';
	
	displayMessage: 'block' | 'none' = 'none';
	firstLoad = false;
	timout: any

	constructor(
		private route: ActivatedRoute
		, private httpClient: HttpClient
		, public router: Router
		, private modalService: NgbModal
		, private ms: SystemMessageService
		, private nav: NavigationService
		, private sec: SecurityService
		, private dynForm: DynamicFormService
		, private activationService: ActivationService
		, private editUserService: EditUserService
		, private globalVariablesService: GlobalVariablesService
		, private localDb: AppDatabase
		, private localDbService: SetLocaldbTablesService
	) {}

	openWindow = (o: string) => { this.router.navigateByUrl('/' + o); };	

	ngOnInit() {
		this.isOnline = CheckNetworkService.checkNetworkStatus();
		this.user = this.sec.getUser();
		this.isSysAdmin = this.sec.getSecurity()?.permissionProfile === PermissionProfileEnum.SysAdmin;
		this.isTechnician = this.sec.getSecurity()?.permissionProfile === PermissionProfileEnum.Technician;
		this.canChangePermissions = this.sec.hasAccess(o => o.canChangePermissions);
		this.hasDepartmentPermissions = this.sec.hasAccess(o => o.programsAndDepartments);
		this.addSecuritySubscription();
		this.addRouteSubscription();
	}
  
	/** Update the local dynamic security object when the security updates */
	addSecuritySubscription() {
		this.subscriptions.add(
			this.sec.security$.subscribe(() => {
				this.canEdit = this.sec.hasAccess(o => o.manageUsers);
				this.canManageUser = this.sec.hasAccess(o => o.manageUsers);
				this.sec.setControlAccess(this.formGroup?.controls?.permissionId, o => o.canChangePermissions);
			})
		);
	}

	/** Subscribe to route to pick up id changes and update page accordingly */
	addRouteSubscription() {
		this.subscriptions.add(this.route.paramMap.subscribe(map => {
			// Allow user to view page if it is either their profile
			// or they have admin access to view other people's profiles
			const user = this.sec.getUser();
			this.id = parseInt(map.get('id'), 10);
			if (!this.id && this.id !== 0) { this.router.navigateByUrl('dashboard'); }

			this.iAmMe = user?.id === this.id;
			this.canManageNotifications = user?.userProfileType !== UserProfileTypeEnum.Emt;
			if (this.id !== user?.id && !this.canEdit) { this.router.navigate(['/']); }

			this.isCurrentUser = this.id === user?.id;
			if (this.isOnline) {
				this.editUserService.getUser(this.id).subscribe((model: EditUserVm) => {
					this.fields = model.fields;
					this.formGroup = this.dynForm.updateFormGroupFields(this.formGroup, model.fields);
					this.customFieldsCount = model.fields?.length ?? 0;
					this.formGroup.patchValue(model);
					this.model = EditUserVm.GetModel(this.formGroup);
					this.isNewUser = model.isNewUser;
					this.globalVariablesService.userName.next(model.fullName);
					this.nav.setOpenSecondaryMenu(['Edit User', 'Details']);
	
					this.canSeePasswordButtons =
						model.isActive &&
						!model.isLockedOut &&
						model.userId !== this.sec.getUser().id &&
						model.userId !== 0;
					this.isLockedOut = model.isLockedOut;
											
					//populate the selected associated inventories that get passed to the model.InventoryIds on load of the user
					this.formGroup.controls.inventoryIds.setValue(this.formGroup.value.inventoryIds);
				});
			} else { this.grabUserFromLocalDb(); }
			
		}));
	}

	/** The main save event for the page */
	onMessageSuccess(systemMessage: SystemMessage) {
		const isCreatingUser = this.id === 0;
		const model = this.formGroup.value;	
		this.id = model.userId;
		
		if (isCreatingUser) {
			this.router.navigateByUrl(`/administration/users/edit-user/inventories/${model.userId}?isCreatingNewUser=true`);
		} else if (this.canManageUser) {		
			this.router.navigateByUrl('/', { skipLocationChange: true }).then(() => { this.router.navigate([`/administration/users/edit-user/details/${model.userId}`]); });
		}
		this.canSeePasswordButtons = model.isActive 
			&& !model.isLockedOut 
			&& model.userId !== this.sec.getUser().id 
			&& model.userId !== 0;
		this.sec.setSecurity(
			undefined
			, systemMessage.value.userJsVm
			, systemMessage.value.dynamicSecurity
		);
	}

	// used for the back button on the Details page only for a new user
	handleBackDetails() { this.router.navigateByUrl(`/administration/users/list`); }

	/** Unlock user, called when the unlock svg clicked on locked user */
	unlockUser() {
		this.subscriptions.add(
			this.ms.getHttpObservable(this, `api/Administration/Users/UnlockUser/${this.id}`).subscribe(() => {
				this.isLockedOut = false;
			})
		);
	}

	activateDeactivate() { this.activationService.activateDeactivate(this.id, ActivationEnum.User, this.model.isActive ? false : true); }

	/** Called on click of the reset password and welcome email buttons */
	resetPassword(isWelcomeEmail: boolean, isSystemMessage: boolean) {
		this.subscriptions.add(
			this.httpClient
				.post('api/Account/ForgotPassword', {
					email: this.formGroup.value.email,
					isWelcomeEmail
				}).subscribe((msg: SystemMessage) => {
					if (msg.isSuccessful) {
						this.ms.setSystemMessage(
							`An email has been sent to the user with a ${isWelcomeEmail ? 'welcome' : 'reset password'} link.`
						);
					} else { this.ms.setSystemMessage('An error occurred when attempting to send the email.', 'error'); }
				})
		);
		if (isSystemMessage) {
			// timed out so that the user can see the confirmation system message before being redirected
			setTimeout(() => { this.router.navigateByUrl("administration/users/list") }, 500);
		}		
	}

	grabUserFromLocalDb() {
		// get data from localDb if offline and there is something in the localDb
		this.localDb.table("EditUserTbl").toArray()
			.then((response) => { 
				if (response.length > 0) {
					let filteredResponse = response.filter(o => o.userId === this.id && this.id !== 0);
					if (filteredResponse.length > 0) { 
						this.formGroup.patchValue(filteredResponse[filteredResponse.length - 1]);

						this.fields = filteredResponse[filteredResponse.length - 1].fields;
						this.formGroup = this.dynForm.updateFormGroupFields(this.formGroup, filteredResponse[filteredResponse.length - 1].fields);
						this.customFieldsCount = filteredResponse[filteredResponse.length - 1].fields?.length ?? 0;
						this.formGroup.patchValue(filteredResponse[filteredResponse.length - 1]);
						this.model = EditUserVm.GetModel(this.formGroup);
						this.isNewUser = filteredResponse[filteredResponse.length - 1].isNewUser;
						this.globalVariablesService.userName.next(filteredResponse[filteredResponse.length - 1].fullName);
						this.nav.setOpenSecondaryMenu(['Edit User', 'Details']);
		
						this.canSeePasswordButtons =
							filteredResponse[filteredResponse.length - 1].isActive &&
							!filteredResponse[filteredResponse.length - 1].isLockedOut &&
							filteredResponse[filteredResponse.length - 1].userId !== this.sec.getUser().id &&
							filteredResponse[filteredResponse.length - 1].userId !== 0;
						this.isLockedOut = filteredResponse[filteredResponse.length - 1].isLockedOut;
											
						//populate the selected associated inventories that get passed to the model.InventoryIds on load of the user
						this.formGroup.controls.inventoryIds.setValue(this.formGroup.value.inventoryIds);
					} else { // means a new user is being created offline
						this.formGroup.controls.isActive.setValue(true);
					}	
				}
			});
	}

	/** Opens the change password modal.
	 * Called on click of the Change Passoword button when user looking at their profile. */
	openPermissionsInfoModal() { this.modalService.open(UserPermissionsModalComponent, { size: 'xl' }); }
	openInfoModal() { this.modalService.open(DepartmentInventoriesModalComponent); }

	ngOnDestroy() {
		this.subscriptions.unsubscribe();
		this.formGroup = null;
	}
}