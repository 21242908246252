import { Component, OnInit, ViewChild } from '@angular/core';

import { TableComponent } from 'app/shared/table/table.component';
import { ReleaseNotesVm } from 'app/shared/generated/Models/ReleaseNotesVm';

@Component({
	selector: 'pcg-web-release-notes',
	templateUrl: './web-release-notes.component.html',
	styleUrls: ['./web-release-notes.component.scss']
})
export class WebReleaseNotesComponent implements OnInit {
	// Get table reference from the page
	@ViewChild(TableComponent, { static: true }) table: TableComponent<ReleaseNotesVm>;
	columns = ReleaseNotesVm.ColumnDefs;

	constructor() { }

	ngOnInit() {}
}