import { Injectable } from '@angular/core';
import Dexie, { TableSchema } from 'dexie';

import { IDexieTableSchema, ITableSchema, } from '../local-db-interfaces/ldb.interface';
import { LoadedStores } from '../tables/loaded.store';
import { DBStores } from './ldb.store.model';
import { IEditUserTbl } from '../tables/Administration/Models/User/EditUser/ViewModel/EditUserTbl';

@Injectable({
	providedIn: 'root'
})
export class AppDatabase extends Dexie {

	LoadedStores!: Dexie.Table<LoadedStores, number>;
	EditUserTbl!: Dexie.Table<IEditUserTbl, string>;
	versionNumber: number = 1;
	private dbName: string = 'local-db-app';

	constructor() {
		super('local-db-app');
		this.setLocalDbTable();
		this.seedData();
	}

	setLocalDbTable() {
		this.version(this.versionNumber).stores(this.setTablesSchema());
		console.log('database initialized');
		this.EditUserTbl = this.table(DBStores.EditUserTbl.TableName);
	}

	seedData() {
		this.on('populate', async () => { await this.LoadedStores.add(new LoadedStores()); });
	}

	async migrateDB() {
		if (await Dexie.exists(this.dbName)) {
			const declaredSchema = this.getCanonicalComparableSchema(this);
			const dynDb = new Dexie(this.dbName);
			const installedSchema = await dynDb.open().then(this.getCanonicalComparableSchema);
			dynDb.close();
			if (declaredSchema !== installedSchema) {
				console.log('Db schema is not updated, so deleting the db.');
				await this.clearDB();
			}
		}
	}

	getCanonicalComparableSchema(db: Dexie): string {
		const tableSchemas: ITableSchema[] = db.tables.map((table) => this.getTableSchema(table));
		return JSON.stringify(tableSchemas.sort((a, b) => (a.name < b.name ? 1 : -1)));
	}

	getTableSchema(table: { name: string; schema: IDexieTableSchema; }): ITableSchema {
		const { name, schema } = table;
		const indexSources = schema.indexes.map((idx) => idx.src).sort();
		const schemaString = [schema.primKey.src, ...indexSources].join(',');
		return { name, schema: schemaString };
	}

	async clearDB() {
		console.log('deleting DB...');
		this.close();
		await this.delete();
		await this.open();
		console.log('DB deleted.');
	}

	private setTablesSchema() {
		return Object.entries(DBStores).reduce((tables, [key, value]) => {
			tables[value.TableName] = value.Columns;
			return tables;
		}, {} as Record<string, string>);
	}

}
