<form *ngFor="let fields of groupedFields">
	<div class="row">
		<pcg-dynamic-form-field 
			*ngFor="let field of fields"
			[fg]="fg" 
			[fgName]="fgName" 
			[class]="'col-md-' + (12 / columnsPerRow)" 
			[field]="field"
		></pcg-dynamic-form-field>
	</div>
</form>