/* eslint-disable @typescript-eslint/dot-notation */
/* eslint-disable @typescript-eslint/ban-types */
import { EventEmitter, Injectable, Output } from '@angular/core';
import { TableFilterComponent } from '../table-filter/table-filter.component';

@Injectable({ providedIn: 'root' })
export class ButtonFilterService {

    @Output() resetFilterData: EventEmitter<any> = new EventEmitter()

    filterMap = { "isActive": "Is Active" }; // Commonly used filter

    private filters: TableFilterComponent[] = [];    

	sendResetFilterData(data: any): any {this.resetFilterData.emit(data) }

    // add modal to array of active modals
    addFilter(filter: any) { this.filters.push(filter); }

    // remove modal from array of active modals
    removeFilter(id: string) { this.filters = this.filters.filter(x => x.id !== id); }

    // open modal specified by id
    openFilter(id: string) {        
        const filter = this.filters.find(x => x.id === id);
        filter.open();
    }

    // close modal specified by id
    closeFilter(id: string) {        
        const filter = this.filters.find(x => x.id === id);
        filter.close();
    }

    /**
     * Get the applied filters to display above a table.
     * 
     * @param filters Filters used to for the table (ajaxData)
     * @param filterMap Key value array used to map filter properties to Display values. Ex. src\app\inventory\rx-fulfillment\shipment-list\shipment-list.component.ts
     * @param emptyFilters Filter array used for custom or misbehaving filters. Ex. src\app\inventory\rx-fulfillment\shipment-list\shipment-list.component.ts
     * @returns List of applied filters as a comma seperated string.
     */
    getActiveFilters(filters: {}, filterMap: {}, emptyFilters: {} = null) {
        // Empty array that will be populated with currently applied filters.
        const filtList = [];
        // Iterate through each of the filter objects, if any of them have a value add to the array.
        const c = Object.fromEntries(Object.entries(filters).filter(([key, val]) =>
            !emptyFilters
                ? (
                    val != null && val != "null"                          // Where value is not null
                    && val?.toString()?.trim() !== ""    // Where value is not an empty string 
                    && val !== "[]"                      // Where value is not an empty array
                    && (typeof (val) != 'object' || (val['dateBegin'] != null && val['dateEnd'] != null)) // Checking empty date ranges and UI switches
                ) : (key in emptyFilters && emptyFilters[key] !== val)));
        Object.entries(c).forEach(([key]) => { 
            if (filterMap[key.toString()] !== undefined) { filtList.push(filterMap[key.toString()]); } 
        });
        return filtList.join(", ");
    }
}