import { Component, ElementRef, ViewChild, AfterViewInit, HostListener, OnDestroy } from '@angular/core';
import { debounceTime } from 'rxjs/operators';
import { Subscription, Subject } from 'rxjs';

import { SpinnerService } from 'app/core/layout/spinner/spinner.service';

@Component({
	selector: 'pcg-fixed-buttons',
	templateUrl: './fixed-buttons.component.html',
	styleUrls: ['./fixed-buttons.component.scss'],
})
export class FixedButtonsComponent implements AfterViewInit, OnDestroy {

	@ViewChild('buttonContainer', { static: true }) buttonContainer: ElementRef;
	
	subscriptions = new Subscription();
	setFixed$: Subject<void> = new Subject<void>();	

	constructor(private elRef: ElementRef, private spinner: SpinnerService) {
		// Add subscription to setFixed$
		this.subscriptions.add(this.setFixed$.pipe(debounceTime(25)).subscribe(() => this.onSetFixed()));

		// Also make the nav fixed, if necessary, on AJAX calls
		this.subscriptions.add(
			this.spinner.loadingSource$.subscribe(spinnerVisible => {
				if (!spinnerVisible) { this.setFixed(); }
			})
		);
	}

	/** This will trigger the onSetFixed logic
	 *  It will be slightly delayed because of the debounce on the observable */
	@HostListener('document:click', ['$event'])
	setFixed() {
		this.setFixed$.next();
		setTimeout(() => { this.setFixed$.next(); }, 200);
	}

	@HostListener('document:scroll', ['$event'])
	@HostListener('window:resize', ['$event'])
	onSetFixed() {
		if (document.body.offsetHeight > this.elRef.nativeElement.getBoundingClientRect().top) {
			this.buttonContainer?.nativeElement?.classList?.remove('fixed-bottom-buttons');
		} else {
			this.buttonContainer?.nativeElement?.classList?.add('fixed-bottom-buttons');
		}
	}

	/** Check whether or not the nav needs to be fixed on render */
	ngAfterViewInit() { this.setFixed(); }
	ngOnDestroy() { this.subscriptions.unsubscribe(); }
}
