import { UntypedFormGroup, UntypedFormControl, UntypedFormArray, Validators } from '@angular/forms';

import { PcgTableColumn } from '../../../table/pcg-table-column.interface';

// tslint:disable:quotemark
// tslint:disable:max-line-length
// tslint:disable:whitespace
export class HelpModuleVm {
	moduleId: number;
	moduleName?: string;
	delete: boolean;

	static get FormData() {
		return this.getFormData();
	}

	static getFormData(validators = {}) {
		return {
			'moduleId': new UntypedFormControl(0, [...(validators['moduleId'] ?? []), ...[Validators.required]]),
			'moduleName': new UntypedFormControl(null, [...(validators['moduleName'] ?? []), ...[Validators.maxLength(100)]]),
			'delete': new UntypedFormControl(false, [...(validators['delete'] ?? []), ...[]])
		};
	}

	static get Form() {
		return new UntypedFormGroup(this.FormData);
	}

	static get ColumnDefs(): Map<string, PcgTableColumn> {
		return new Map([
			['moduleName', {"orderId":0,"sortColumn":"moduleName","searchColumn":"moduleName","includeInExcel":true,"exportColumn":null,"displayName":"Name","isVisible":true,"canSearch":true,"isNdc":false,"isNdc10":false,"multiSearchCellClasses":null}],
			['delete', {"orderId":1,"sortColumn":"delete","searchColumn":"delete","includeInExcel":true,"exportColumn":null,"displayName":"Delete","isVisible":true,"canSearch":true,"isNdc":false,"isNdc10":false,"multiSearchCellClasses":null}]
		]);
	}

	static GetModel(fg: UntypedFormGroup): HelpModuleVmFromFg {
		return new HelpModuleVmFromFg(fg);
	}
}

export class HelpModuleVmFromFg {
	constructor(public fg: UntypedFormGroup) { }


	get moduleId(): number {
		return this.fg.get('moduleId').value;
	}
	set moduleId(val) {
		this.fg.get('moduleId').setValue(val);
	}

	get moduleName(): string {
		return this.fg.get('moduleName').value;
	}
	set moduleName(val) {
		this.fg.get('moduleName').setValue(val);
	}

	get delete(): boolean {
		return this.fg.get('delete').value;
	}
	set delete(val) {
		this.fg.get('delete').setValue(val);
	}
}
