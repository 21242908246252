/* eslint-disable @typescript-eslint/prefer-for-of */
/* eslint-disable @typescript-eslint/dot-notation */
import { Component, Input, OnInit, ElementRef, OnDestroy, OnChanges, SimpleChanges, HostListener } from '@angular/core';
import { Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

import { NavRoute } from '../nav.route.interface';
import { NavigationService, NavDividerCharacter } from '../navigation.service';
import { SecurityService } from 'app/core/security/security.service';
import { SearchNavComponent } from '../search-nav/search-nav.component';
import { UserJsVm } from '../../generated/Administration/Models/UserJsVm';
import { Subscription } from 'rxjs';
import { GlobalService } from 'app/shared/service/global.service';
import { CheckNetworkService } from 'local-db/services/check-network.service';

/**
 * This component is intended to be used in the top header bar
 * to create a nested navigation. Dropdowns are opened by
 * click.
 */
@Component({
	selector: 'pcg-top-click-nav',
	templateUrl: './top-click-nav.component.html',
	styleUrls: ['./top-click-nav.component.scss'],
})
export class TopClickNavComponent implements OnInit, OnDestroy, OnChanges {
	@Input() navRoutes: NavRoute[] = [];
	@Input() menuItemId = 'root';
	@Input() isRoot = true;

	// rxjs Subscriptions
	subscriptions: Subscription = new Subscription();
	// Current user information
	user: UserJsVm;
	// Whether or not the curren nav menu is open
	isOpen: boolean;
	// Currently OPEN nav menu
	currNavMenuItem: string;
	// Currently SELECTED nav menu
	currSelectedNavMenuItem: string;
	// The currently OPEN secondary navigation menu
	currOpenSecondaryNavMenu: string;
	// Force select item in the nav
	currForceSelect: string;
	// Classes in the current ul of the nav menu
	ulClasses: string[];
	// Nav routes used in loop
	loopNavRoutes: NavRoute[] = [];
	// For user nav
	userNavOpen = false;
	// For enabling and disabling hyperlinks
	isMobile: boolean;

	constructor(public navService: NavigationService, public elRef: ElementRef, public sec: SecurityService
		, private router: Router, private modalService: NgbModal) {}

	@HostListener('window:resize', ['$event'])
	onWindowResize() { this.isMobile = GlobalService.setIsMobile(window.innerWidth); }

	ngOnInit() {
      	this.isMobile = GlobalService.setIsMobile(window.innerWidth);

		// Get the current user information
		this.subscriptions.add(this.sec.user$.subscribe(user => { this.user = user; }));
		// Triggred on update of currently OPEN menu item
		this.navService.currOpenPrimaryNavMenu$.subscribe(currNavMenuItem => {
			this.currNavMenuItem = currNavMenuItem;

			// Sub navs and current nav should be open
			this.isOpen = this.isSubNav(currNavMenuItem, this.menuItemId) || currNavMenuItem === this.menuItemId;

			this.userNavOpen = currNavMenuItem === ['root', 'user'].join(NavDividerCharacter);
			// The first ul should have classes indicating it is the root.
			// Nested ul elements, those at higher depths, are dropdown menus
			// and need classes indicating that.
			this.ulClasses = this.isRoot
				? ['nav', 'navbar-nav', 'top-nav-bg', this.isOpen ? 'nav-open' : '']
				: ['dropdown-menu', 'top-nav-bg', this.isOpen ? 'nav-open' : ''];
		});
		// Triggered on update of currently SELECTED menu item
		this.navService.currSelectedMainNavMenu$.subscribe(currSelectedNavMenu => {
			this.currSelectedNavMenuItem = currSelectedNavMenu ? currSelectedNavMenu.id : 'root';
		});
		this.navService.currOpenSecondaryNavMenu$.subscribe(currSelectedNavMenu => {
			this.currOpenSecondaryNavMenu = currSelectedNavMenu ? currSelectedNavMenu.id : 'root';
		});
		this.navService.currForceSelect$.subscribe(currForceSelect => {
			this.currForceSelect = currForceSelect;
			this.loopNavRoutes = this.getNavRoutes();
		});
	}

	isSubNav(currNavMenuItem, menuItemId) {
		// Get divider counts
		const divCountCurrNav = currNavMenuItem.split(NavDividerCharacter).length;
		const divCountMenuItem = menuItemId.split(NavDividerCharacter).length;

		// Sub navs and current nav should be open
		return divCountMenuItem < divCountCurrNav && currNavMenuItem.startsWith(this.menuItemId);
	}

	ngOnChanges(changes: SimpleChanges) {
		const chng = changes['navRoutes'];
		const cur = chng.currentValue;
		const prev = chng.previousValue;
		if (cur !== prev) { this.loopNavRoutes = this.getNavRoutes(); }
	}

	/**
	 * Creates an HTML friendly id from a route identifier
	 *
	 * @param routeId Unique identifier for the route
	 */
	getHtmlId(routeId: string) { return routeId.replace(/^[^a-z0-9]+|[^\w:.-]+/gi, ''); }

	// Show or hide submenu based on dropdown click
	clickDropdown(childMenuId: string) {
		if (childMenuId === 'user') { childMenuId = ['root', 'user'].join(NavDividerCharacter); }
		// If the submenu is open, close it
		if (this.currNavMenuItem.startsWith(childMenuId)) { this.navService.setCurrOpenNavMenu(this.menuItemId); }
		else { this.navService.setCurrOpenNavMenu(childMenuId); }
		return false;
	}

	openDropdown(childMenuId: string) {
		if (childMenuId === 'user') { childMenuId = ['root', 'user'].join(NavDividerCharacter); }
		this.navService.setCurrOpenNavMenu(childMenuId); 
		return false;
	}

	pointerClickDropdown(childMenuId: string, routeHref: string = "") {
		if (childMenuId === 'user') { childMenuId = ['root', 'user'].join(NavDividerCharacter); }
		this.navService.setCurrOpenNavMenu(childMenuId);
		if (routeHref !== '' && window.innerWidth > 992) { window.location.href = routeHref; }
		return false;
	}

	closeDropdown(childMenuId: string) {
		if (!this.isMobile) { 
			if (childMenuId === 'user') { childMenuId = ['root', 'user'].join(NavDividerCharacter); }
			this.navService.setCurrOpenNavMenu(this.menuItemId); 		
		}		
		return false;
	}

	clickLink() { this.navService.setCurrOpenNavMenu(''); }

	// Open the global navigation search
	openSearch() {
		this.navService.setCurrOpenNavMenu('');
		this.modalService.open(SearchNavComponent);
		return false;
	}

	// Logout removes all of our security variables
	// and redirects user to root
	logout() {
		let isOnline = CheckNetworkService.checkNetworkStatus();
		if (
			isOnline
			|| (
				!isOnline
				&& confirm("Are you sure you want log out? You are offline and will not be able to log in until you are back online.")
			)
		) {
			this.sec.setSecurity(null, null, null);
			this.deleteAllCookies();
			this.router.navigate(['']);
		}	
	}

	// This is used above to delete all cookies on logout
	deleteAllCookies() {
		const cookies = document.cookie.split(';');

		for (let i = 0; i < cookies.length; i++) {
			const cookie = cookies[i];
			const eqPos = cookie.indexOf('=');
			const name = eqPos > -1 ? cookie?.substr(0, eqPos) : cookie;
			document.cookie = name + '=;expires=Thu, 01 Jan 1970 00:00:00 GMT';
		}
	}

	// It's good practice to always unsubscribe from subscriptions
	ngOnDestroy() { this.subscriptions.unsubscribe(); }

	getNavRoutes() {
		const routeIndex = 0;
		return (
			this.navRoutes
				?.map((o, i) => ({ ...o, index: i }))
				.filter(route => !route?.shouldDisplay || route?.shouldDisplay(routeIndex)) ?? []
		);
	}
}
