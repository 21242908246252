import { Directive, Optional, ElementRef, HostListener } from '@angular/core';
import { NgControl } from '@angular/forms';

@Directive({
	selector: '[pcgNumeric]',
})
export class NumericDirective {

	constructor(private elRef: ElementRef, @Optional() private control: NgControl) {}

	@HostListener('keyup', ['$event'])
	keyEvent() { if (this.control) { this.control.control.setValue(this.elRef.nativeElement.value); } }

	@HostListener('change', ['$event'])
	changeEvent() { if (this.control) { this.control.control.setValue(this.elRef.nativeElement.value); } }

	@HostListener('keydown', ['$event'])
	onKeyDown(e: any) {
		//If the key pressed is not 0-9, numpad 0-9, backspace/delete, decimal/period, or arrow keys deny the keypress
		if (
			!(
				(e.key === '0' || e.key === '1' || e.key === '2' || e.key === '3' || e.key === '4' || e.key === '5' 
					|| e.key === '6' || e.key === '7' || e.key === '8' || e.key === '9') ||
				(e.keyCode > 36 && e.keyCode < 41) ||
				e.keyCode === 8 ||
				e.keyCode === 9 ||
				e.keyCode === 46 ||
				e.keyCode === 110
			)
		) { return false; }
	}
}
