import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, Subscription } from 'rxjs';

import { EditUserVm } from 'app/shared/generated/Administration/Models/User/EditUser/ViewModel/EditUserVm';

@Injectable({
    providedIn: 'root'
})
export class EditUserService {

    constructor(private httpClient: HttpClient) { }

    getUser(id: number) : Observable<EditUserVm> {
        return this.httpClient.get<EditUserVm>(`api/Administration/Users/GetUser/${id}`)
    }
}
