import { UntypedFormGroup, UntypedFormControl, UntypedFormArray, Validators } from '@angular/forms';

import { PcgTableColumn } from '../../../../../table/pcg-table-column.interface';

// tslint:disable:quotemark
// tslint:disable:max-line-length
// tslint:disable:whitespace
export class PermissionVm {
	id: number;
	permissionProfileString?: string;
	coreFeatures: boolean;
	manageUsers: boolean;
	replenishment: boolean;
	reconciliation: boolean;
	shiftChangeAuditor: boolean;
	applicationSettings: boolean;
	createAndEditInventories: boolean;
	createAndEditCustomFields: boolean;
	programsAndDepartments: boolean;
	verificationSettings: boolean;
	manageNdcDirectory: boolean;
	manageHelpDocumentation: boolean;
	releaseNotes: boolean;
	security: boolean;
	clients: boolean;
	globalAuditHistory: boolean;
	viewQuartzJobs: boolean;

	static get FormData() {
		return this.getFormData();
	}

	static getFormData(validators = {}) {
		return {
			'id': new UntypedFormControl(0, [...(validators['id'] ?? []), ...[Validators.required]]),
			'permissionProfileString': new UntypedFormControl(null, [...(validators['permissionProfileString'] ?? []), ...[]]),
			'coreFeatures': new UntypedFormControl(false, [...(validators['coreFeatures'] ?? []), ...[]]),
			'manageUsers': new UntypedFormControl(false, [...(validators['manageUsers'] ?? []), ...[]]),
			'replenishment': new UntypedFormControl(false, [...(validators['replenishment'] ?? []), ...[]]),
			'reconciliation': new UntypedFormControl(false, [...(validators['reconciliation'] ?? []), ...[]]),
			'shiftChangeAuditor': new UntypedFormControl(false, [...(validators['shiftChangeAuditor'] ?? []), ...[]]),
			'applicationSettings': new UntypedFormControl(false, [...(validators['applicationSettings'] ?? []), ...[]]),
			'createAndEditInventories': new UntypedFormControl(false, [...(validators['createAndEditInventories'] ?? []), ...[]]),
			'createAndEditCustomFields': new UntypedFormControl(false, [...(validators['createAndEditCustomFields'] ?? []), ...[]]),
			'programsAndDepartments': new UntypedFormControl(false, [...(validators['programsAndDepartments'] ?? []), ...[]]),
			'verificationSettings': new UntypedFormControl(false, [...(validators['verificationSettings'] ?? []), ...[]]),
			'manageNdcDirectory': new UntypedFormControl(false, [...(validators['manageNdcDirectory'] ?? []), ...[]]),
			'manageHelpDocumentation': new UntypedFormControl(false, [...(validators['manageHelpDocumentation'] ?? []), ...[]]),
			'releaseNotes': new UntypedFormControl(false, [...(validators['releaseNotes'] ?? []), ...[]]),
			'security': new UntypedFormControl(false, [...(validators['security'] ?? []), ...[]]),
			'clients': new UntypedFormControl(false, [...(validators['clients'] ?? []), ...[]]),
			'globalAuditHistory': new UntypedFormControl(false, [...(validators['globalAuditHistory'] ?? []), ...[]]),
			'viewQuartzJobs': new UntypedFormControl(false, [...(validators['viewQuartzJobs'] ?? []), ...[]])
		};
	}

	static get Form() {
		return new UntypedFormGroup(this.FormData);
	}

	static GetModel(fg: UntypedFormGroup): PermissionVmFromFg {
		return new PermissionVmFromFg(fg);
	}
}

export class PermissionVmFromFg {
	constructor(public fg: UntypedFormGroup) { }


	get id(): number {
		return this.fg.get('id').value;
	}
	set id(val) {
		this.fg.get('id').setValue(val);
	}

	get permissionProfileString(): string {
		return this.fg.get('permissionProfileString').value;
	}
	set permissionProfileString(val) {
		this.fg.get('permissionProfileString').setValue(val);
	}

	get coreFeatures(): boolean {
		return this.fg.get('coreFeatures').value;
	}
	set coreFeatures(val) {
		this.fg.get('coreFeatures').setValue(val);
	}

	get manageUsers(): boolean {
		return this.fg.get('manageUsers').value;
	}
	set manageUsers(val) {
		this.fg.get('manageUsers').setValue(val);
	}

	get replenishment(): boolean {
		return this.fg.get('replenishment').value;
	}
	set replenishment(val) {
		this.fg.get('replenishment').setValue(val);
	}

	get reconciliation(): boolean {
		return this.fg.get('reconciliation').value;
	}
	set reconciliation(val) {
		this.fg.get('reconciliation').setValue(val);
	}

	get shiftChangeAuditor(): boolean {
		return this.fg.get('shiftChangeAuditor').value;
	}
	set shiftChangeAuditor(val) {
		this.fg.get('shiftChangeAuditor').setValue(val);
	}

	get applicationSettings(): boolean {
		return this.fg.get('applicationSettings').value;
	}
	set applicationSettings(val) {
		this.fg.get('applicationSettings').setValue(val);
	}

	get createAndEditInventories(): boolean {
		return this.fg.get('createAndEditInventories').value;
	}
	set createAndEditInventories(val) {
		this.fg.get('createAndEditInventories').setValue(val);
	}

	get createAndEditCustomFields(): boolean {
		return this.fg.get('createAndEditCustomFields').value;
	}
	set createAndEditCustomFields(val) {
		this.fg.get('createAndEditCustomFields').setValue(val);
	}

	get programsAndDepartments(): boolean {
		return this.fg.get('programsAndDepartments').value;
	}
	set programsAndDepartments(val) {
		this.fg.get('programsAndDepartments').setValue(val);
	}

	get verificationSettings(): boolean {
		return this.fg.get('verificationSettings').value;
	}
	set verificationSettings(val) {
		this.fg.get('verificationSettings').setValue(val);
	}

	get manageNdcDirectory(): boolean {
		return this.fg.get('manageNdcDirectory').value;
	}
	set manageNdcDirectory(val) {
		this.fg.get('manageNdcDirectory').setValue(val);
	}

	get manageHelpDocumentation(): boolean {
		return this.fg.get('manageHelpDocumentation').value;
	}
	set manageHelpDocumentation(val) {
		this.fg.get('manageHelpDocumentation').setValue(val);
	}

	get releaseNotes(): boolean {
		return this.fg.get('releaseNotes').value;
	}
	set releaseNotes(val) {
		this.fg.get('releaseNotes').setValue(val);
	}

	get security(): boolean {
		return this.fg.get('security').value;
	}
	set security(val) {
		this.fg.get('security').setValue(val);
	}

	get clients(): boolean {
		return this.fg.get('clients').value;
	}
	set clients(val) {
		this.fg.get('clients').setValue(val);
	}

	get globalAuditHistory(): boolean {
		return this.fg.get('globalAuditHistory').value;
	}
	set globalAuditHistory(val) {
		this.fg.get('globalAuditHistory').setValue(val);
	}

	get viewQuartzJobs(): boolean {
		return this.fg.get('viewQuartzJobs').value;
	}
	set viewQuartzJobs(val) {
		this.fg.get('viewQuartzJobs').setValue(val);
	}
}
