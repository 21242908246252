import { HttpClient } from '@angular/common/http';
import { Component, OnInit, ViewChild, ElementRef, OnDestroy, HostListener } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { faPeopleArrows } from '@fortawesome/pro-duotone-svg-icons';
import { faSyringe, faCircleCheck } from '@fortawesome/pro-regular-svg-icons';
import { ActivatedRoute, Router } from '@angular/router';
import { SwUpdate } from '@angular/service-worker';

import { ParamedicQuickAccessVm } from 'app/shared/generated/Models/ParamedicQuickAccessVm';
import { IopsQuickAccessVm } from 'app/shared/generated/Models/IopsQuickAccessVm';
import { CurrentInventoryVm } from 'app/shared/generated/Inventory/Models/CurrentInventoryVm';
import { SystemMessage, SystemMessageService } from 'app/core/system-message/system-message-service';
import { GlobalService } from 'app/shared/service/global.service';
import { SecurityService } from 'app/core/security/security.service';
import { UserJsVm } from 'app/shared/generated/Administration/Models/UserJsVm';
import { EditDowntimeNotificationVm } from 'app/shared/generated/Administration/Models/DowntimeNotifications/EditDowntimeNotificationVm';
import { IopsShiftChangeVm } from 'app/shared/generated/Models/IopsShiftChangeVm';
import { PermissionProfileEnum } from 'app/core/enums/generated/PermissionProfileEnum';
import { CheckNetworkService } from 'local-db/services/check-network.service';

@Component({
	selector: 'pcg-dashboard',
	templateUrl: './dashboard.component.html',
	styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent implements OnInit, OnDestroy {

	@ViewChild('img', { static: true }) img: ElementRef;

	user: UserJsVm;	
	pqaModel: ParamedicQuickAccessVm;
	productList: CurrentInventoryVm[] = [];
	downtimeNotification: EditDowntimeNotificationVm;
	iopsPqaShiftChanges: IopsShiftChangeVm[];

	faIconName = { faPeopleArrows, faSyringe, faCircleCheck }
	
	origImgHeight: string;	
	formattedStartDate: string;
	formattedEndDate: string;	
	dateStr: string;
	iopsPqaName: string;
	inProgressInventoryActionName = "";
	clientShiftChangeName = "Shift Change";
	clientShiftChangeAbr = "Shift Chg";
	clientMedUseName = "Medication Use";
	clientMedUseAbr = "Med Use";
	shiftChangeBtn = "";
	shiftChangeBtnMobile = "";

	date: Date;

	isMobile: boolean;
	isInventories: boolean;	
	showDowntimeNotification = false;	
	isSysAdmin = false;
	fromIops: boolean;
	showRegularPqaFromIops = false;
	inProgressInventoryAction = false;	
	inProgressTransfer = false;
	inProgressRecon = false;

	inProgressInventoryActionId = 0;
	inProgressTransferId = 0;
	inProgressReconId = 0;
	isOnline: boolean;

	constructor(
		public title: Title
		, private httpClient: HttpClient
		, private newRoute: Router
		, private ms: SystemMessageService
		, private sec: SecurityService
		, private swUpdate: SwUpdate
		, public activatedRoute: ActivatedRoute
	) { title.setTitle('Schedule2.IT'); }

	// Update isMobile value on window resize
	@HostListener('window:resize')
	onResize() { this.isMobile = GlobalService.setIsMobile(window.innerWidth) }

	ngOnInit() {
		this.isOnline = CheckNetworkService.checkNetworkStatus();
		this.swUpdate.activateUpdate();
		this.user = this.sec.getUser();
		this.clientShiftChangeName = this.user?.clientShiftChangeName ?? "Shift Change";
		this.clientShiftChangeAbr = this.user?.clientShiftChangeAbr ?? "Shift Chg";
		this.clientMedUseName = this.user?.clientMedUseName ?? "Medication Use";
		this.clientMedUseAbr = this.user?.clientMedUseAbr ?? "Med Use";
		this.shiftChangeBtn = "shift-change-btn fa-icon:after { content: ' " + this.clientShiftChangeName + "'; }";
		this.shiftChangeBtnMobile = "shift-change-btn fa-icon:after { content: ' " + this.clientShiftChangeAbr + "'; }";
		this.fromIops = window.history.state.fromIops;
		this.httpClient.post('api/Account/GetUserInfo', {})
			.subscribe((model: any) => {
				if (model?.user != null && model?.security != null) { 
					this.sec.setSecurity(undefined, model.user, model.security); 
				}
			}
		);
		
		this.isMobile = GlobalService.setIsMobile(window.innerWidth);
		document.body?.classList.add('nav-background-color');
		document.getElementById('footer')?.classList.add('nav-text-color');
		if (!this.fromIops) { this.getPqa(); }	
		this.isInventories = GlobalService.checkForZeroInventories(this.user);
		
		if (this.isOnline) {
			this.date = new Date();
			this.dateStr = (this.date.getUTCMonth() + 1).toString() + '/' 
				+ this.date.getUTCDate().toString() + '/'
				+ this.date.getUTCFullYear().toString() + ' '
				+ this.date.getUTCHours().toString() + ':'
				+ this.date.getUTCMinutes().toString() + ':'
				+ this.date.getUTCSeconds().toString();
			this.checkForDowntimeNotification();
		}

		if (this.fromIops) { this.getIopsPqa(); }
		this.isSysAdmin = this.sec.hasAccess(o => o.permissionProfile == PermissionProfileEnum.SysAdmin);
	}

	getPqa() {
		this.httpClient.get(`api/Home/GetParamedicQuickAccess`)
			.subscribe((vm: ParamedicQuickAccessVm) => {
				this.pqaModel = vm;
				this.productList = vm.currentInventories;
				this.clientMedUseName = vm.inventoryActionText;
				this.clientMedUseAbr = vm.inventoryActionAbbr;
				if (this.pqaModel?.shiftChangeEventId > 0) { this.showRegularPqaFromIops = true; }
				// Redirect to inventory-list if user is not oncoming
				if (
					this.pqaModel?.shiftChangeEventId == null 
					&& !this.fromIops
				) { this.newRoute.navigateByUrl('/inventory/inventory-list'); }
			}
		);
	}

	getIopsPqa() {
		this.httpClient.get(`api/Home/GetIopsQuickAccess`)
			.subscribe((vm: IopsQuickAccessVm) => {			
				this.iopsPqaName = vm.firstName;
				this.iopsPqaShiftChanges = vm.shiftChanges;
				if (this.iopsPqaShiftChanges?.length === 0) {
					this.showRegularPqaFromIops = true;
					this.httpClient.get(`api/Home/GetParamedicQuickAccess`)
						.subscribe((vm: ParamedicQuickAccessVm) => {
							this.pqaModel = vm;
							this.productList = vm.currentInventories;
							this.clientMedUseName = vm.inventoryActionText;
							this.clientMedUseAbr = vm.inventoryActionAbbr;
							if (this.pqaModel?.shiftChangeEventId > 0) { this.showRegularPqaFromIops = true; }

							if (
								this.iopsPqaShiftChanges?.length === 0 
								&& vm?.shiftChangeEventId == null
							) { this.newRoute.navigate(['/inventory/inventory-list']); }
						}
					);
				}
			}
		);
	}

	createMedUse() { this.newRoute.navigateByUrl(`/inventory/inventory-actions/${this.pqaModel?.inventorySiteId}?medUse=true`); }

	createShiftChange() {
		if (this.pqaModel.signatureTwoId === null && this.pqaModel.outOfServiceOffGoing === null) {
			this.newRoute.navigateByUrl(`/inventory/shift-change/${this.pqaModel.shiftChangeEventId}/${this.pqaModel.inventorySiteId}`);
		} else {
			this.httpClient.get(`api/Inventory/ShiftChange/CreateShiftChange/?inventorySiteId=${this.pqaModel?.inventorySiteId}`)
				.subscribe((msg: SystemMessage) => {
					if (msg.isSuccessful) {
						this.newRoute.navigateByUrl(`/inventory/shift-change/${msg.value}/${this.pqaModel?.inventorySiteId}`);
					} else {
						if (Number.isInteger(msg.value)) {
							this.newRoute.navigateByUrl(`/inventory/shift-change/${msg.value}/${this.pqaModel?.inventorySiteId}`);
						} else if (msg.value) {							
							this.checkForOpenActions(msg.value);
							this.newRoute.navigateByUrl(`/inventory/shift-change/${this.pqaModel?.inventorySiteId}`, { 
								state: { 
									inProgressInventoryAction: this.inProgressInventoryAction,
									inProgressInventoryActionName: this.inProgressInventoryActionName,
									inProgressTransfer: this.inProgressTransfer,
									inProgressRecon: this.inProgressRecon,
									inProgressInventoryActionId: this.inProgressInventoryActionId,
									inProgressTransferId: this.inProgressTransferId,
									inProgressReconId: this.inProgressReconId
								}
							});
						} else {
							this.ms.setSystemMessage(msg.message, 'error', undefined, msg.route, msg.routeMessage);
						}
					}
				}
			);
		}
	}

	checkForOpenActions(actionsArray: any) {
		actionsArray.map(o => {
			if (o[0] === "InventoryAction") { 
				this.inProgressInventoryAction = true;
				this.inProgressInventoryActionName = this.clientMedUseName;
				this.inProgressInventoryActionId = o[2]; 
			}
			if (o[0] === "Transfer") { 
				this.inProgressTransfer = true; 
				this.inProgressTransferId = o[1];
			}
			if (o[0] === "Reconciliation") { 
				this.inProgressRecon = true; 
				this.inProgressReconId = o[1];
			}
		});
	}

	checkForDowntimeNotification() {
		this.httpClient.get(`api/Administration/DowntimeNotification/CheckForDowntimeNotification?dateStr=${this.dateStr}&ngsw-bypass=true`)
			.subscribe((model: EditDowntimeNotificationVm[]) => {
				var dismissedNotifications = JSON.parse(localStorage.getItem("downtimeNotification"));
				
				var notificationIds = [];
				for (var x = 0; x < model.length; x++) { notificationIds.push(model[x].downtimeNotificationId); }

				var notificationIdsNotDismissed = notificationIds.filter(o => !dismissedNotifications?.includes(o));
				this.downtimeNotification = model.find(o => o.downtimeNotificationId === notificationIdsNotDismissed[0]);

				if (this.downtimeNotification != null) {  
					if (!dismissedNotifications?.includes(notificationIds)) { this.showDowntimeNotification = true; }						

					const startDate = new Date(this.downtimeNotification.startDate);
					this.formattedStartDate = (startDate.getMonth() + 1).toString() + '/' 
						+ startDate.getDate().toString() + '/'
						+ startDate.getFullYear().toString() + ' '
						+ this.convertToStandardTime(startDate.getHours().toString() + ':' 
						+ startDate.getMinutes().toString());

					const endDate = new Date(this.downtimeNotification.endDate);
					this.formattedEndDate = (endDate.getMonth() + 1).toString() + '/' 
						+ endDate.getDate().toString() + '/'
						+ endDate.getFullYear().toString() + ' '
						+ this.convertToStandardTime(endDate.getHours().toString() + ':' 
						+ endDate.getMinutes().toString());
				}
			}
		);
	}

	dismiss(downtimeNotification: EditDowntimeNotificationVm) {
		var dismissedNotifications = JSON.parse(localStorage.getItem("downtimeNotification"));
		if (dismissedNotifications == null) {
			var notificationObject = [downtimeNotification.downtimeNotificationId];
			localStorage.setItem("downtimeNotification", JSON.stringify(notificationObject));
		} else {
			var notificationsPresent = dismissedNotifications;
			notificationsPresent.push(downtimeNotification.downtimeNotificationId);
			localStorage.setItem("downtimeNotification", JSON.stringify(notificationsPresent));
		}
		this.showDowntimeNotification = false;
	}

	convertToStandardTime(militaryTime: string) {
		var time = militaryTime.split(':');
		var hours = Number(time[0]);
		var minutes = Number(time[1]);
		var timeValue: string;

		if (hours > 0 && hours <= 12) { timeValue = "" + hours; } 
		else if (hours > 12) { timeValue = "" + (hours - 12); } 
		else if (hours == 0) { timeValue = "12"; }

		timeValue += (minutes < 10) ? ":0" + minutes : ":" + minutes;  // get minutes
		timeValue += (hours >= 12) ? " P.M." : " A.M.";  // get AM/PM

		return timeValue;
	}

	redirectToShiftChange(
		shiftChangeEventId: number
		, inventorySiteId: number
	) { this.newRoute.navigateByUrl(`inventory/shift-change/${shiftChangeEventId}/${inventorySiteId}`); }

	ngOnDestroy() {
		document.body?.classList.remove('nav-background-color');
		document.getElementById('footer').classList.remove('nav-text-color');
	}
}