import { NavRoute } from '../../shared/navigation/nav.route.interface';

export const statesNav: () => NavRoute = () => ({
	name: getName()
	, security: sec => sec.clients
	, href: 'states'
	, children: getStateAreas()
});

function getName() : string {
	var name: string;
	var url = window.location.href;
	
	if (
		url.indexOf('states/view-state/') > -1
		|| url.indexOf('states/custom-fields/') > -1
	) { name = "View State"; } 
	else { name = "States"; }

	return name;
}

function getStateAreas() : NavRoute[] {
	var id: string = "";
	var url = window.location.href;

	if (url.indexOf('states/view-state/') > -1) { id = url.substring(url.lastIndexOf('/') + 1); }
	if (url.indexOf('states/custom-fields/') > -1) { id = url.substring(url.lastIndexOf('/') + 1); }

	if (id === "") { 
		return [
			{
				name: 'States'
				, exact: true
				, path: 'sysadmin/states/list'
				, security: sec => sec.clients
			}
		]; 
	}

	if (+id === 0) {
		return [
			{
				name: 'Back'
				, exact: true
				, path: `sysadmin/states/list`
				, security: sec => sec.clients
			},
			{
				name: 'Details'
				, exact: true
				, path: `sysadmin/states/view-state/${id}`
				, security: sec => sec.clients
			}
		];
	}

	return [
		{
			name: 'Back'
			, exact: true
			, path: `sysadmin/states/list`
			, security: sec => sec.clients
		}
		, {
			name: 'Details'
			, exact: true
			, path: `sysadmin/states/view-state/${id}`
			, security: sec => sec.clients
		}
		, {
			name: 'Custom Fields'
			, exact: true
			, path: `sysadmin/states/custom-fields/${id}`
			, security: sec => sec.clients
		}
	];
}
