import { Component, Input, EventEmitter, Output, OnDestroy, ViewChild, TemplateRef } from '@angular/core';
import { Subscription } from 'rxjs';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { faTrashCan } from '@fortawesome/pro-solid-svg-icons';

import { SystemMessageService } from 'app/core/system-message/system-message-service';
import { TableComponent } from '../../table.component';
import { param } from '../../../http-params';

@Component({
	selector: 'pcg-delete-button',
	templateUrl: './delete-button.component.html',
	styleUrls: ['./delete-button.component.scss'],
})
export class DeleteButtonComponent<T> implements OnDestroy {

	@ViewChild('deleteModal') modal: TemplateRef<any>;

	@Input() url: string;
	@Input() id: string;
	@Input() confirmMessage = 'Are you sure you want to delete this?';
	@Input() isUsingComment = false;
	@Input() bypassConfirmMessage = false;
	@Input() table: TableComponent<T>;
	@Input() useTrashIcon: boolean;
	@Output() delete = new EventEmitter<any>();

	deleteComment = "";
	subscriptions = new Subscription();

	faIconName = { faTrashCan };

	noDeleteMessage = false;

	constructor(private ms: SystemMessageService, public modalService: NgbModal) {}

	deleteRecord() {
		if (this.bypassConfirmMessage === true || (!this.confirmMessage || confirm(this.confirmMessage))) {
			const idQueryStr =
				typeof this.id === 'string' || typeof this.id === 'number'
					? 'id=' + encodeURIComponent(this.id)
					: param(this.id);
			this.subscriptions.add(
				this.ms.getHttpObservable(this, this.url + '?' + idQueryStr).subscribe(obj => {
					this.delete.emit(obj);
					if (this.table) {
						this.table.ajaxReload();
					}
				})
			);
		}
	}

	deleteWithComment() {
		if(this.deleteComment.length == 0){
			this.noDeleteMessage = true;
			return 
		}
		var idQueryStr =
			typeof this.id === 'string' || typeof this.id === 'number'
				? 'id=' + encodeURIComponent(this.id)
				: param(this.id);
		idQueryStr += `&comment=${encodeURIComponent(this.deleteComment)}`;
		this.subscriptions.add(
			this.ms.getHttpObservable(this, this.url + '?' + idQueryStr).subscribe(obj => {
				this.delete.emit(obj);				
				if (this.table) {
					this.table.ajaxReload();
					this.modalService.dismissAll();
				}
			})
		);
	}

	openModal() { this.modalService.open(this.modal); }
	ngOnDestroy() { this.subscriptions.unsubscribe(); }
}