<div class="modal-header">
	<h5 class="modal-title" id="modal-basic-title">Departments and their Associated Inventories</h5>
	<button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss()">
		<span aria-hidden="true">&times;</span>
	</button>
</div>
<div class="di-container">
	<div class="di-row">
		<div class="bold">Department</div>
		<div class="bold">Associated Inventories</div>
	</div>
	<div *ngFor="let dep of departments; let j = index; let k = count">
		<div class="di-row">
			<div>{{ dep?.department }}</div>
			<div>{{ dep?.associatedInventories[0] }}</div>
		</div>
		<div *ngFor="let inventory of dep.associatedInventories; let i = index">
			<div class="di-row" *ngIf="i !== 0">
				<div></div>
				<div>{{ inventory }}</div>
			</div>
		</div>
		<div class="line" *ngIf="j !== k - 1"></div>
	</div>
</div>
