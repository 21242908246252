<h3 *ngIf="auditName !== ''">{{ auditName }} - Audit History</h3>
<br *ngIf="auditName !== ''" />
<pcg-table 
	#table 
	[ajaxData]="filters" 
	[pcgSort]="[['dateChanged', 'desc']]" 
	dataSource="api/AuditHistory/GetAuditHistory" 
    [columnDefs]="columns" 
	(tableReceive)="
		auditName = $event.value; 
		setClientName($event);
	" 
	[canExportTableToExcel]="true" 
	class="card-table"
>

	<ng-container matColumnDef="friendlyRecordId">
		<th 
			mat-header-cell 
			pcg-sort-header 
			*matHeaderCellDef
		>{{ columns.get('friendlyRecordId').displayName }}</th>
		<td 
			mat-cell 
			*matCellDef="let row" 
			[attr.data-label]="columns.get('friendlyRecordId').displayName"
		>{{ row.friendlyRecordId }}</td>
	</ng-container>

	<ng-container matColumnDef="dateChanged">
		<th 
			mat-header-cell 
			pcg-sort-header 
			*matHeaderCellDef
		>{{ columns.get('dateChanged').displayName }}</th>
		<td 
			mat-cell 
			*matCellDef="let row" 
			[attr.data-label]="columns.get('dateChanged').displayName"
		>{{ row.dateChangedFormatted }}</td>
	</ng-container>

	<ng-container matColumnDef="field">
		<th 
			mat-header-cell 
			pcg-sort-header 
			*matHeaderCellDef
		>{{ columns.get('field').displayName }}</th>
		<td 
			mat-cell 
			*matCellDef="let row" 
			[attr.data-label]="columns.get('field').displayName"
		>{{ row.field }}</td>
	</ng-container>

	<ng-container matColumnDef="changedFrom">
		<th 
			mat-header-cell 
			pcg-sort-header 
			*matHeaderCellDef
		>{{ columns.get('changedFrom').displayName }}</th>
		<td 
			mat-cell 
			*matCellDef="let row" 
			[attr.data-label]="columns.get('changedFrom').displayName"
		>{{ row.changedFrom }}</td>
	</ng-container>

	<ng-container matColumnDef="changedTo">
		<th 
			mat-header-cell 
			pcg-sort-header 
			*matHeaderCellDef
		>{{ columns.get('changedTo').displayName }}</th>
		<td 
			mat-cell 
			*matCellDef="let row" 
			[attr.data-label]="columns.get('changedTo').displayName"
		>{{ row.changedTo }}</td>
	</ng-container>

	<ng-container matColumnDef="changedBy">
		<th 
			mat-header-cell 
			pcg-sort-header 
			*matHeaderCellDef
		>{{ columns.get('changedBy').displayName }}</th>
		<td 
			mat-cell 
			*matCellDef="let row" 
			[attr.data-label]="columns.get('changedBy').displayName"
		>{{ row.changedBy }}</td>
	</ng-container>
    
</pcg-table>
