<!-- FIXED TOGGLE -->
<button 
    class="fixed-toggle-btn"
    (click)="toggleNavFixed()" 
    *ngIf="!isMobile"
>
    <div class="toggle-tooltip">
        <span *ngIf="navFixedInput">Undock nav</span>
        <span *ngIf="!navFixedInput">Dock nav</span>
    </div>
    <fa-icon 
        *ngIf="navFixedInput"
        [icon]="faIconName.faArrowLeft" 
        class="fa-lg"
    ></fa-icon>
    <fa-icon 
        *ngIf="!navFixedInput"
        [icon]="faIconName.faArrowRight" 
        class="fa-lg"
    ></fa-icon>
</button>

<div 
    (click)="redirect('/dashboard')" 
    class="nav-header"
>
	<div class="nav-image-ctnr">
		<img 
            [src]="fileLogoUrl" 
            height="35px" 
            width="35px"
        />
	</div>
	<div 
        *ngIf="mode !== 'Production'" 
        class="beta-text"
    ></div>
</div>

<mat-nav-list>
    <!-- INVENTORIES -->
    <mat-list-item 
        class="parent"
        [ngClass]="{'active-nav-parent-item': inventoriesSelected}"
        (click)="toggleInventoriesSubMenu()"
    >
        <fa-icon 
            [icon]="faIconName.faShelves" 
            class="parent-nav-icon"
        ></fa-icon>
        <span>Inventories</span>
        <fa-icon 
            [icon]="faIconName.faAngleDown" 
            [ngClass]="{'rotate': showInventoriesSubMenu}" 
            class="dropdown-icon"
        ></fa-icon>
    </mat-list-item>
    <div 
        *ngIf="showInventoriesSubMenu" 
        class="submenu" 
        [ngClass]="{'expanded': showInventoriesSubMenu}"
    >
        <mat-list-item (click)="redirect('inventory/inventory-list')">
            <span>Inventory Dashboard</span>
        </mat-list-item>
        <ng-container *ngFor="let inventory of inventories; index as i">
            <mat-list-item 
                class="parent" 
                (click)="toggleInventorySubNav(inventory.name)"
            >
                <span *ngIf="inventory.name.length < 17">{{inventory.name}}</span>
                <span *ngIf="inventory.name.length >= 17">{{inventory.name.slice(0, 17)}}...</span>
                <fa-icon 
			        [icon]="faIconName.faAngleDown" 
			        [ngClass]="{'rotate': showInventorySubMenu[i].value}" 
			        class="dropdown-icon"
		        ></fa-icon>
            </mat-list-item>
            <div 
                *ngIf="showInventorySubMenu[i].value" 
                class="submenu" 
                [ngClass]="{'expanded': showInventorySubMenu[i].value}"
            >
                <mat-list-item (click)="redirect('/inventory/products/' + inventory.id)">
                    <span>Products</span>
                </mat-list-item>
                <mat-list-item (click)="redirect('/inventory/shift-change/' + inventory.id)">
                    <span>{{ clientShiftChangeName }}</span>
                </mat-list-item>
                <mat-list-item (click)="redirect('/inventory/inventory-actions/' + inventory.id)">
                    <span>{{getInventoryActionName(inventory)}}</span>
                </mat-list-item>
                <mat-list-item (click)="redirect('/inventory/transfers/' + inventory.id)">
                    <span>Transfers</span>
                </mat-list-item>
                <mat-list-item 
                    *ngIf="
                        isManager 
                        || isAdmin 
                        || isSysAdmin
                    " 
                    (click)="redirect('/inventory/reconciliation/' + inventory.id)"
                >
                    <span>Reconciliation</span>
                </mat-list-item>
                <mat-list-item 
                    *ngIf="
                        inventory.inventoryType === 1 
                        && (
                            isManager 
                            || isAdmin 
                            || isSysAdmin
                        )
                    " 
                    (click)="redirect('/inventory/replenishment/' + inventory.id)"
                >
                    <span>Replenishment</span>
                </mat-list-item>
                <mat-list-item 
                    *ngIf="
                        inventory.inventoryType === 3 
                        && (
                            isManager 
                            || isAdmin 
                            || isSysAdmin
                        )
                    " 
                    (click)="redirect('/inventory/dea-form/' + inventory.id)"
                >
                    <span>DEA Form 41</span>
                </mat-list-item>
                <mat-list-item 
                    *ngIf="
                        inventory.inventoryType === 2 
                        && (
                            isManager 
                            || isAdmin 
                            || isSysAdmin
                        )
                    " 
                    (click)="redirect('/inventory/return-logs/' + inventory.id)"
                >
                    <span>Return Logs</span>
                </mat-list-item>
                <mat-list-item (click)="redirect('/inventory/site-transaction-history/' + inventory.id)">
                    <span>Transaction History</span>
                </mat-list-item>
            </div>
        </ng-container>
    </div>

    <!-- REPORTS -->
    <mat-list-item 
        class="parent"
        [ngClass]="{'active-nav-parent-item': reportsSelected}"
        (click)="toggleReportsSubMenu()" 
    >
        <fa-icon 
            [icon]="faIconName.faClipboardList" 
            class="parent-nav-icon fa-lg"
        ></fa-icon>
        <span>Reports</span>
        <fa-icon 
			[icon]="faIconName.faAngleDown" 
			[ngClass]="{'rotate': showReportsSubMenu}" 
			class="dropdown-icon"
		></fa-icon>	
    </mat-list-item>
    <div 
        *ngIf="showReportsSubMenu" 
        class="submenu" 
        [ngClass]="{'expanded': showReportsSubMenu}"
    >
        <mat-list-item (click)="redirect('/reports/expiration-report')">
			<span>Expiration</span>
		</mat-list-item>
        <mat-list-item (click)="redirect('/reports/inventory-report')">
			<span>Inventory</span>
		</mat-list-item>
        <mat-list-item (click)="redirect('/reports/inventory-actions-report')">
			<span>{{ clientMedUseName }}</span>
		</mat-list-item>
        <mat-list-item 
            *ngIf="!isTechnician" 
            (click)="redirect('/reports/overage-shortage-report')"
        >
			<span>Replenishment Discrepancies</span>
		</mat-list-item>
        <mat-list-item 
            *ngIf="user?.controlValues" 
            (click)="redirect('/reports/control-value-report')"
        >
			<span>Control Values</span>
		</mat-list-item>
    </div>

    <!-- FORMS -->
    <mat-list-item 
        *ngIf="!isTechnician" 
        class="parent"
        [ngClass]="{'active-nav-parent-item': formsSelected}"
        (click)="toggleFormsSubMenu()" 
    >
        <fa-icon 
            [icon]="faIconName.faPenField" 
            class="parent-nav-icon fa-lg"
        ></fa-icon>
        <span>Forms</span>
        <fa-icon 
			[icon]="faIconName.faAngleDown" 
			[ngClass]="{'rotate': showFormsSubMenu}" 
			class="dropdown-icon"
		></fa-icon>	
    </mat-list-item>
    <div 
        *ngIf="showFormsSubMenu" 
        class="submenu" 
        [ngClass]="{'expanded': showFormsSubMenu}"
    >
        <mat-list-item 
            *ngIf="!isTechnician"
            (click)="redirect('/forms/biennial-inventory-reports')"
        >
            <span>Biennial Inventory Reports</span>
        </mat-list-item>
        <!-- Only admins and system admins who have access to at least 1 form can see forms -->
        <mat-list-item 
            *ngIf="
                (isAdmin || isSysAdmin)
                 && (user?.clientStateAbr == 'NY')
            " 
            (click)="redirect('/forms/ny-form-3848')"
        >
            <span>NY Form 3848</span>
        </mat-list-item>
    </div>

    <!-- ADMIN -->
    <mat-list-item 
        class="parent"
        [ngClass]="{'active-nav-parent-item': adminSelected}"
        (click)="toggleAdminSubMenu()" 
    >
        <fa-icon 
            [icon]="faIconName.faGear" 
            class="parent-nav-icon fa-lg"
        ></fa-icon>
        <span>Admin</span>
        <fa-icon 
            [icon]="faIconName.faAngleDown" 
            [ngClass]="{'rotate': showAdminSubMenu}" 
            class="dropdown-icon"
        ></fa-icon>	
    </mat-list-item>
    <div 
        *ngIf="showAdminSubMenu" 
        class="submenu" 
        [ngClass]="{'expanded': showAdminSubMenu}"
    >
        <mat-list-item (click)="redirect('/administration/dashboard')">
			<span>Dashboard</span>
		</mat-list-item>
        <mat-list-item 
            *ngIf="
                isManager 
                || isAdmin 
                || isSysAdmin
            " 
            (click)="redirect('/administration/suppliers/list')"
        >
            <span>Suppliers</span>
        </mat-list-item>
        <!-- ADMIN SETTINGS -->
        <mat-list-item 
            *ngIf="
                isAdmin 
                || isSysAdmin
            "
            class="parent"
            (click)="toggleAdminSettingsSubMenu()"
        >
			<span>Settings</span>
            <fa-icon 
                [icon]="faIconName.faAngleDown" 
                [ngClass]="{'rotate': showAdminSettingsSubMenu}" 
                class="dropdown-icon"
            ></fa-icon>
		</mat-list-item>
        <div 
            *ngIf="
                showAdminSettingsSubMenu 
                && (
                    isAdmin 
                    || isSysAdmin
                )
            " 
            class="submenu" 
            [ngClass]="{'expanded': showAdminSettingsSubMenu}"
        >
            <mat-list-item (click)="redirect('/administration/settings/agency-info/' + clientId)">
				<span>Agency Info</span>
			</mat-list-item>
            <mat-list-item (click)="redirect('/administration/settings/verification-settings/' + clientId)">
				<span>Verification</span>
			</mat-list-item>
            <mat-list-item 
                *ngIf="isSysAdmin"
                (click)="redirect('/administration/settings/admin-settings/' + clientId)">
				<span>Admin Settings</span>
			</mat-list-item>
            <mat-list-item (click)="redirect('/administration/settings/subscription/' + clientId)">
				<span>Subscription</span>
			</mat-list-item>
            <mat-list-item (click)="redirect('/administration/settings/features/' + clientId)">
				<span>Features</span>
			</mat-list-item>
            <mat-list-item (click)="redirect('/administration/settings/integrations/' + clientId)">
				<span>Integrations</span>
			</mat-list-item>
            <mat-list-item 
                *ngIf="user?.canAccessDeaInfo" 
                (click)="redirect('/administration/settings/dea-info/' + clientId)"
            >
				<span>DEA Info</span>
			</mat-list-item>
            <mat-list-item (click)="redirect('/administration/settings/audit-history/' + clientId)">
				<span>Audit History</span>
			</mat-list-item>
        </div>
        <!-- ADMIN USERS -->
        <mat-list-item 
            *ngIf="
                isAdmin 
                || isSysAdmin 
                || isManager
            "
            class="parent"
            (click)="toggleAdminUsersSubMenu()"
        >
			<span>Users</span>
            <fa-icon 
                [icon]="faIconName.faAngleDown" 
                [ngClass]="{'rotate': showAdminUsersSubMenu}" 
                class="dropdown-icon"
            ></fa-icon>
		</mat-list-item>
        <div 
            *ngIf="
                showAdminUsersSubMenu 
                && (
                    isAdmin 
                    || isSysAdmin 
                    || isManager
                )
            " 
            class="submenu" 
            [ngClass]="{'expanded': showAdminUsersSubMenu}"
        >
            <mat-list-item 
                *ngIf="
                    isAdmin 
                    || isSysAdmin 
                    || isManager
                " 
                (click)="redirect('/administration/users/list')"
            >
				<span>User List</span>
			</mat-list-item>
            <mat-list-item 
                *ngIf="
                    isAdmin 
                    || isSysAdmin
                " 
                (click)="redirect('/administration/users/user-custom-fields')"
            >
				<span>User Custom Fields</span>
			</mat-list-item>
            <mat-list-item 
                *ngIf="
                    isAdmin 
                    || isSysAdmin 
                    || isManager
                " 
                (click)="redirect('/administration/users/login-history')"
            >
				<span>Login History</span>
			</mat-list-item>
        </div>
        <!-- ADMIN INVENTORY -->
        <mat-list-item 
            class="parent"
            (click)="toggleAdminInventorySubMenu()"
        >
            <span>Inventory</span>
            <fa-icon 
                [icon]="faIconName.faAngleDown" 
                [ngClass]="{'rotate': showAdminInventorySubMenu}" 
                class="dropdown-icon"
            ></fa-icon>	
        </mat-list-item>
        <div 
            *ngIf="showAdminInventorySubMenu" 
            class="submenu" 
            [ngClass]="{'expanded': showAdminInventorySubMenu}"
        >
            <mat-list-item 
                *ngIf="
                    isAdmin 
                    || isSysAdmin
                " 
                (click)="redirect('/administration/inventory/inventory-custom-fields')"
            >
				<span>Custom Fields</span>
			</mat-list-item>
            <mat-list-item 
                *ngIf="
                    isAdmin 
                    || isSysAdmin
                " 
                (click)="redirect('/administration/inventory/departments')"
            >
				<span>Departments</span>
			</mat-list-item>
            <mat-list-item 
                *ngIf="
                    isAdmin 
                    || isSysAdmin
                " 
                (click)="redirect('/administration/inventory/inventory-actions')"
            >
				<span>Inventory Actions</span>
			</mat-list-item>
            <mat-list-item (click)="redirect('/administration/inventory/ndc-directory-list')">
				<span>NDC Database</span>
			</mat-list-item>
            <mat-list-item 
                *ngIf="
                    isAdmin 
                    || isSysAdmin
                " 
                (click)="redirect('/administration/inventory/inventory-desired-stock')"
            >
				<span>Set PAR Levels</span>
			</mat-list-item>
        </div>
        <!-- Global Audit History -->
        <mat-list-item 
            *ngIf="
                isAdmin 
                || isSysAdmin
            " 
            (click)="redirect('/administration/global-audit-history')"
        >
            <span>Global Audit History</span>
        </mat-list-item>
    </div>

    <!-- SYS ADMIN -->
    <mat-list-item 
        *ngIf="isSysAdmin"
        class="parent"
        [ngClass]="{'active-nav-parent-item': sysAdminSelected}"
        (click)="toggleSysAdminSubMenu()"
    >
        <fa-icon 
            [icon]="faIconName.faGears" 
            class="parent-nav-icon fa-lg"
        ></fa-icon>
        <span>SysAdmin</span>
        <fa-icon 
            [icon]="faIconName.faAngleDown" 
            [ngClass]="{'rotate': showSysAdminSubMenu}" 
            class="dropdown-icon"
        ></fa-icon>	
    </mat-list-item>
    <div 
        *ngIf="
            showSysAdminSubMenu 
            && isSysAdmin
        " 
        class="submenu" 
        [ngClass]="{'expanded': showSysAdminSubMenu}"
    >
        <mat-list-item (click)="redirect('/sysadmin/clients')">
            <span>Customers</span>
        </mat-list-item>
        <mat-list-item (click)="redirect('/sysadmin/integrations/list')">
            <span>Integrations</span>
        </mat-list-item>
        <mat-list-item (click)="redirect('/sysadmin/global-user-list')">
            <span>Global Users</span>
        </mat-list-item>
        <mat-list-item (click)="redirect('/sysadmin/states/list')">
            <span>States</span>
        </mat-list-item>
        <!-- Footer Admin -->
        <mat-list-item 
            *ngIf="isSysAdmin"
            class="parent"
            (click)="toggleSysAdminFooterAdminSubMenu()"
        >
            <span>Footer Admin</span>
            <fa-icon 
                [icon]="faIconName.faAngleDown" 
                [ngClass]="{'rotate': showSysAdminFooterAdminSubMenu}" 
                class="dropdown-icon"
            ></fa-icon>
        </mat-list-item>
        <div 
            *ngIf="
                showSysAdminFooterAdminSubMenu 
                && isSysAdmin
            " 
            class="submenu" 
            [ngClass]="{'expanded': showSysAdminFooterAdminSubMenu}"
        >
            <mat-list-item (click)="redirect('/sysadmin/release-notes/web-release-notes-list')">
				<span>Release Notes</span>
			</mat-list-item>
            <mat-list-item (click)="redirect('/sysadmin/edit-footer-item?item=eula')">
				<span>EULA</span>
			</mat-list-item>
            <mat-list-item (click)="redirect('/sysadmin/edit-footer-item?item=privacy-policy')">
				<span>Privacy Policy</span>
			</mat-list-item>
            <mat-list-item (click)="redirect('/sysadmin/edit-footer-item?item=terms-and-conditions')">
				<span>Terms and Conditions</span>
			</mat-list-item>
        </div>
        <!-- System Wide -->
        <mat-list-item 
            *ngIf="isSysAdmin"
            class="parent"
            (click)="toggleSysAdminSystemWideSubMenu()"
        >
            <span>System Wide</span>
            <fa-icon 
                [icon]="faIconName.faAngleDown" 
                [ngClass]="{'rotate': showSysAdminSystemWideSubMenu}" 
                class="dropdown-icon"
            ></fa-icon>
        </mat-list-item>
        <div 
            *ngIf="
                showSysAdminSystemWideSubMenu 
                && isSysAdmin
            " 
            class="submenu" 
            [ngClass]="{'expanded': showSysAdminSystemWideSubMenu}"
        >
            <mat-list-item (click)="redirect('/sysadmin/sys-admin-settings')">
				<span>System Settings</span>
			</mat-list-item>
            <mat-list-item (click)="redirect('/sysadmin/downtime-notifications')">
				<span>Downtime Notifications</span>
			</mat-list-item>
            <mat-list-item (click)="redirect('/sysadmin/drug-forms')">
				<span>Consolidate Drug Forms</span>
			</mat-list-item>
        </div>
        <!-- Permissions Info -->
        <mat-list-item 
            *ngIf="isSysAdmin"
            class="parent"
            (click)="toggleSysAdminPermissionsSubMenu()"
        >
            <span>Permission Info</span>
            <fa-icon 
                [icon]="faIconName.faAngleDown" 
                [ngClass]="{'rotate': showSysAdminPermissionsInfoSubMenu}" 
                class="dropdown-icon"
            ></fa-icon>
        </mat-list-item>
        <div 
            *ngIf="
                showSysAdminPermissionsInfoSubMenu 
                && isSysAdmin
            " 
            class="submenu" 
            [ngClass]="{'expanded': showSysAdminPermissionsInfoSubMenu}"
        >
            <mat-list-item (click)="redirect('/sysadmin/view-permission-list')">
				<span>Permissions</span>
			</mat-list-item>
            <mat-list-item (click)="redirect('/sysadmin/permission-audit-history')">
				<span>Permissions Audit History</span>
			</mat-list-item>
        </div>
        <!-- ADMIN QUARTZ -->
		<mat-list-item 
            *ngIf="isSysAdmin"
            class="parent" 
            (click)="openInNewTab('/quartz')"
        >
            <span>Quartz</span>
        </mat-list-item>
    </div>

    <!-- SEARCH CONTROL VALUES -->
    <mat-list-item 
        *ngIf="user?.controlValues"
		class="parent" 
		(click)="redirect('/reports/control-value-report')" 
		[ngClass]="{'active-nav-parent-item': searchCvSelected}"
	>
		<fa-icon 
			[icon]="faIconName.faBarcodeRead" 
			class="parent-nav-icon fa-lg"
		></fa-icon>
		<span>Search Control Values</span>
	</mat-list-item>
    <hr />

    <!-- USER PROFILE -->
	<mat-list-item 
        (click)="toggleProfileSubMenu()" 
        class="parent" 
        [ngClass]="{'active-nav-parent-item': userProfileSelected}"
    >
        <fa-icon 
            [icon]="faIconName.faUser" 
            class="parent-nav-icon fa-lg"
        ></fa-icon>
        <span class="long-nav-item">{{ user?.fullName }}</span>
        <fa-icon 
            [icon]="faIconName.faAngleDown" 
            [ngClass]="{'rotate': showProfileSubMenu}" 
            class="dropdown-icon"
        ></fa-icon>	
    </mat-list-item>
    <div 
        *ngIf="showProfileSubMenu"
        class="submenu" 
        [ngClass]="{'expanded': showProfileSubMenu}"
    >
        <!-- My Profile -->
        <mat-list-item 
            (click)="redirect('administration/users/edit-user/details/' + user?.id)"
        ><span>My Profile</span></mat-list-item>
        <!-- Logout -->
        <mat-list-item (click)="logout()"><span>Logout</span></mat-list-item>
    </div>

    <!-- HELP -->
    <mat-list-item 
		class="parent" 
		(click)="toggleHelpSubMenu()" 
		[ngClass]="{'active-nav-parent-item': helpSelected}"
	>
		<fa-icon 
			[icon]="faIconName.faQuestionCircle" 
			class="parent-nav-icon fa-lg"
		></fa-icon>
		<span>Help</span>
        <fa-icon 
            [icon]="faIconName.faAngleDown" 
            [ngClass]="{'rotate': showHelpSubMenu}" 
            class="dropdown-icon"
        ></fa-icon>	
	</mat-list-item>
    <div 
        *ngIf="showHelpSubMenu" 
        class="submenu" 
        [ngClass]="{'expanded': showHelpSubMenu}"
    >
        <mat-list-item (click)="redirect('/help')">
			<span>Help Documents</span>
		</mat-list-item>
        <mat-list-item (click)="redirect('/web-release-notes')">
			<span>Release Notes</span>
		</mat-list-item>
    </div>
    <hr />

    <!-- CLIENT -->
    <!-- SysAdmin -->
    <mat-list-item 
        *ngIf="isSysAdmin"
		class="parent customer-container" 
		(click)="openChangeClientModal();"
	>
		<fa-icon 
			[icon]="faIconName.faStaffSnake" 
			class="parent-nav-icon fa-lg"
		></fa-icon>
		<span class="customer-name">{{ clientName }}</span>
		<fa-icon 
            [icon]="faIconName.faAngleDown" 
            class="dropdown-icon" 
            style="transform: rotate(-90deg);"
        ></fa-icon>	
	</mat-list-item>
    <!-- Non-SysAdmin -->
    <mat-list-item 
        *ngIf="!isSysAdmin"
		class="parent no-hover customer-container" 
	>
		<fa-icon 
			[icon]="faIconName.faStaffSnake" 
			class="parent-nav-icon fa-lg"
		></fa-icon>
		<span class="customer-name">{{ clientName }}</span>
	</mat-list-item>
</mat-nav-list>

<ng-template 
    #modalChangeClient 
    let-modal
>
    <div class="modal-header">
		<h5 
            class="modal-title" 
            id="modal-basic-title"
        >Change Customer</h5>
		<button 
			type="button" 
			class="close" 
			aria-label="Close" 
			(click)="modal.dismiss()"
		><span aria-hidden="true">&times;</span></button>
	</div>

    <div class="modal-body">
        <select 
			#selectEvent 
			class="form-control" 
			(change)="changeClient(selectEvent.value)"
			ngDefaultControl
			[(ngModel)]="currentClientId"
			[ngModelOptions]="{ standalone: true }"
		>
			<option 
				*ngFor="let item of clientItems" 
				[value]="item.value" 
				class="form-control"
			>{{ item.text }}</option>   
		</select>
    </div>

    <div class="modal-footer">
		<button 
            type="button" 
            class="btn btn-cancel btn-lg" 
            (click)="modal.dismiss()"
        >Cancel</button>
		<button 
            type="button" 
            class="btn btn-save btn-lg" 
            (click)="saveChangeClient(modal)"
        >Change Customer</button>
	</div>
</ng-template>