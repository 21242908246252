import { Component, OnInit, ViewChild, TemplateRef } from '@angular/core';
import { TableComponent } from '../shared/table/table.component';
import { Subscription } from 'rxjs';
import { Router } from '@angular/router';
import { SafeHtml } from '@angular/platform-browser';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { HttpClient } from '@angular/common/http';

import { SystemMessageService } from '../core/system-message/system-message-service';
import { HelpFilesVm } from 'app/shared/generated/HelpFiles/Models/HelpFilesVm';
import { HelpModuleVm } from 'app/shared/generated/HelpFiles/Models/HelpModuleVm';
import { SecurityService } from 'app/core/security/security.service';

@Component({
	selector: 'pcg-help-files',
	templateUrl: './help-files.component.html',
	styleUrls: ['./help-files.component.scss'],
})
export class HelpFilesComponent implements OnInit {

	@ViewChild(TableComponent, { static: true }) table: TableComponent<HelpFilesVm>;
	@ViewChild(TableComponent, { static: true }) tableModules: TableComponent<HelpModuleVm>;
	@ViewChild('helpfile', { static: true }) modalContent: TemplateRef<any>;
	@ViewChild('helpvideo', { static: true }) modalVideoContent: TemplateRef<any>;
	@ViewChild('newmodule', { static: true }) modalNewModule: TemplateRef<any>;

	columns = HelpFilesVm.ColumnDefs;
	columnsModule = HelpModuleVm.ColumnDefs;
	formGroup = HelpFilesVm.Form;
	modules = HelpModuleVm.Form;
	strName: SafeHtml;
	newModuleName: string;

	subscriptions = new Subscription();

	canCreateEditDelete = false;

	isError = false;
	errorMessage: string;

	constructor(
		private httpClient: HttpClient
		, public modalService: NgbModal
		, public ms: SystemMessageService
		, private sec: SecurityService
		, public router: Router
	) { }

	ngOnInit() {
		this.canCreateEditDelete = this.sec.hasAccess(o => o.manageHelpDocumentation);
		if (!this.canCreateEditDelete) {
			this.columns.delete('delete');
			this.columns.delete('helpFileId');
		}
	}

	generateUrl(name: string, videoid: number) { return '/help/help-video/' + videoid; }

	openModal(id: number, videoId: number = null) {
		// Remove validators for source file
		this.formGroup.controls.sourceFile.clearValidators();
		this.formGroup.controls.sourceFile.updateValueAndValidity();

		if (videoId !== null) {
			this.formGroup.controls.file.clearValidators();
			this.formGroup.controls.file.updateValueAndValidity();
			this.modalService.open(this.modalVideoContent, {
				beforeDismiss: () => {
					this.formGroup = HelpFilesVm.Form;
					return true;
				}
			});
		} else {
			this.formGroup.controls.videoId.clearValidators();
			this.formGroup.controls.videoId.updateValueAndValidity();
			this.modalService.open(this.modalContent, {
				beforeDismiss: () => {
					this.formGroup = HelpFilesVm.Form;
					return true;
				}
			});
		}		

		if (id !== 0) {
			this.subscriptions.add(
				this.httpClient.get(`api/HelpFiles/HelpFiles/GetDetails/${id}`).subscribe((model: HelpFilesVm) => {
					this.formGroup.patchValue(model);
					this.formGroup.controls.file.clearValidators();
					this.formGroup.controls.file.updateValueAndValidity();
					if (videoId !== null) {
						this.formGroup.controls.sourceFile.clearValidators();
						this.formGroup.controls.sourceFile.updateValueAndValidity();
					} else {
						this.formGroup.controls.videoId.clearValidators();
						this.formGroup.controls.videoId.updateValueAndValidity();
					}
				})
			);
		}
	}

	addNewModuleName(newModuleName) {
		this.subscriptions.add(this.httpClient.get(`api/HelpFiles/HelpFiles/SaveModuleName/?moduleName=${newModuleName}`)
			.subscribe((model: HelpModuleVm) => {
				this.newModuleName = model.moduleName;	
				this.tableModules.ajaxReload();		
			}));		
	}

	openModuleModal () {
		this.modalService.open(this.modalNewModule, {
			beforeDismiss: () => {
				this.modules = HelpModuleVm.Form;
				return true;
			}
		});
	}

	setValidation(msg) {
		this.isError = true;
		this.errorMessage = msg.message
	}

	resetValidation() {
		this.isError = false;
		this.errorMessage = "";
	}

	// Clickable row
	openHelp(row: HelpFilesVm) {
		if (row.videoId) { this.router.navigateByUrl(`/help/help-video/${row.helpFileId}`); }
		else { window.open(`${window.location.origin}/uploads/helpfiles/${row.fileName}`);}
	}
}