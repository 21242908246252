import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject } from 'rxjs';

import { PcgTableInput, PcgTableInputColumn } from '../../app/shared/table/table.component';
import { AppDatabase } from '../../local-db/services/init.ldb.service';
import { param } from '../shared/http-params';
import { EditUserVm } from 'app/shared/generated/Administration/Models/User/EditUser/ViewModel/EditUserVm';
import { SecurityService } from 'app/core/security/security.service';
import { PermissionProfileEnum } from 'app/core/enums/generated/PermissionProfileEnum';

@Injectable({ providedIn: 'root' })
export class CachePrimerService {

    tableInput: PcgTableInput = {
        columns: null
        , start: 0
        , length: 100
        , searchText: ""
        , exactMatch: false
        , exportType: null
        , reportName: null
    }

    constructor(
        private http: HttpClient
        , private localDb: AppDatabase
        , private sec: SecurityService
    ) { }

    primeCache() {
        this.primeSelectControllerCache();
        if (this.sec.hasAccess(o => o.permissionProfile != PermissionProfileEnum.Technician)) {
            this.primeUserListCache();
        }
    }

    setPcgTableColumns(
        vm: any
        , sortField: string
        , sortDirection: string = 'asc'
    ) {
        let perColumnSearchSource = new BehaviorSubject<string[]>([]);
        perColumnSearchSource.next(Array.from(new Array(Array.from(vm.ColumnDefs.keys()).length), () => ''));
        let perColSearches = perColumnSearchSource.value;

        const columns: PcgTableInputColumn[] = [];
        const colNames = Array.from(vm.ColumnDefs.keys());
        for (let i = 0; i < perColSearches.length; ++i) {
            const theCol: PcgTableInputColumn = { searchText: perColSearches[i] };
            const sortData = [[sortField, sortDirection]]
            const colName = colNames[i];
            const mySort = sortData.find(o => o[0] === colName);
            if (mySort) {
                theCol.sortColumnNum = 0;
                theCol.sortDirection = mySort[1];
            } else {
                theCol.sortColumnNum = null;
                theCol.sortDirection = null;
            }
            columns.push(theCol);
        }
        return columns
    }

    jsonStringify(columns: any) {
        return JSON.stringify(
            columns.map((o: { sortColumnNum: null; searchText: string; sortDirection: null; }, index: number) => {
                const newObj: any = {};
                if (index > 0) { o.sortColumnNum = null; }
                if (
                    o.searchText !== null 
                    && o.searchText !== ''
                ) { newObj.searchText = o.searchText; }
                if (o.sortColumnNum !== null) { newObj.sortColumnNum = o.sortColumnNum; }
                if (o.sortDirection !== null) { newObj.sortDirection = o.sortDirection; }
                return newObj;
            })
        );
    }

    primeUserListCache() {
        const columns = this.setPcgTableColumns(EditUserVm, 'fullName')

        let filters = { 
            isActive: true,
            permissionProfile: null,
            daysAgo: "", // set as string for reset button functionality
            userType: null
        };
        let userAjaxDataString = param(filters);

        this.http.get(
            `api/Administration/Users/GetUserList` 
            + `?${param(this.tableInput, true)}` 
            + `&${userAjaxDataString}` 
            + `&excelPassword=${encodeURIComponent("")}` 
            + `&columnJson=${encodeURIComponent(this.jsonStringify(columns))}`
        ).subscribe((d: any) => {
            for (let x = 0; x < d.data.length; x++) {
                this.localDb.EditUserTbl.put({
                    userId: d.data[x].userId,
                    fullName: d.data[x].fullName,
                    userName: d.data[x].userName,
                    phoneNumber: d.data[x].phoneNumber,
                    email: d.data[x].email,
                    stateLicenseNumber: d.data[x].stateLicenseNumber,
                    userProfileIds: d.data[x].userProfileIds,
                    userProfiles: d.data[x].userProfiles,
                    isActive: d.data[x].isActive,
                    isLockedOut: d.data[x].isLockedOut,
                    inactivityTimeout: d.data[x].inactivityTimeout,
                    permissionId: d.data[x].permissionId,
                    permission: d.data[x].permission,
                    permissionProfile: d.data[x].permissionProfile,
                    clientId: d.data[x].clientId,
                    clientName: d.data[x].clientName,
                    departmentIds: d.data[x].departmentIds,
                    inventoryIds: d.data[x].inventoryIds,
                    activeInventoryIds: d.data[x].activeInventoryIds,
                    inventories: d.data[x].inventories,
                    inventorySiteAssociation: d.data[x].inventorySiteAssociation,
                    activeInventories: d.data[x].activeInventories,
                    hasBiometricClientSettings: d.data[x].hasBiometricClientSettings,
                    hasFidoClientSettings: d.data[x].hasFidoClientSettings,
                    fields: d.data[x].fields,
                    customFields: d.data[x].customFields,
                    friendlyId: d.data[x].friendlyId,
                    userSignature: d.data[x].userSignature,
                    userPin: d.data[x].userPin,
                    showDepartments: d.data[x].showDepartments,
                    isNewUser: d.data[x].isNewUser,
                    isFromIops: d.data[x].isFromIops,
                    userType: d.data[x].userType,
                    logoFileName: d.data[x].logoFileName,
                    companyName: d.data[x].companyName,
                    lastLoginDate: d.data[x].lastLoginDate,
                    lastLoginDateStr: d.data[x].lastLoginDateStr,
                    isUpdatedOffline: d.data[x].isUpdatedOffline
                });
            }
        });
    }

    primeSelectControllerCache() {
        this.http.get("api/Select/Permissions").subscribe((response: any) => {});
        this.http.get("api/Select/Departments").subscribe((response: any) => {});
    }  
}
