import { Injectable } from '@angular/core';
import { UserJsVm } from '../generated/Administration/Models/UserJsVm';

@Injectable({
    providedIn: 'root'
})
export class GlobalService {

    constructor() { }

    static setIsMobile(getScreenWidth: any) {
		/* Previously was getScreenWidth < 1025px
		 * Needs to be 993 because 993px matches the bootstrap 'lg' breakpoint
		*/
		if (getScreenWidth < 993) { return true; }	
		else { return false; }
	}

    static checkForZeroInventories(user: UserJsVm) {
		if (
			user?.useHideTechnicianInventories 
			&& user?.permissionId === 1
		) {
			if (user.responsibleInventories.length === 0) { return false; } 
			else { return true; }
	    } else { return true; }
    }

	static isAndroidDevice() {
		var userAgent = navigator.userAgent;
		if (/android/i.test(userAgent)) { return true; }
		return false;
	}

	static checkImageExists (src: string) {
		var imgExists = false;
		var img = new Image();
		img.src = src;
		img.onload = function() { imgExists = true; }
		img.onerror = function() { imgExists = false; }
		return imgExists;
	}
}
