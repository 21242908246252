import { HttpClient, HttpParams } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

import { SilkierLogMessageVm } from 'app/shared/generated/Silkier/Models/SilkierLogMessageVm'

@UntilDestroy()
@Component({
    selector: 'pcg-view-log-modal',
    templateUrl: './view-log-modal.component.html',
    styleUrls: ['./view-log-modal.component.scss']
})
export class ViewLogModalComponent implements OnInit {
    id: number;
    logMsg: string;

    constructor(public modal: NgbActiveModal, private httpClient: HttpClient ) { }

    ngOnInit() { this.getLog(); }

    public getLog() {
        const httpParams = new HttpParams().set('fireInstanceId', this.id);
        this.httpClient.get('api/Silkier/SilkierHistory/GetLog', { params: httpParams })
            .pipe(untilDestroyed(this)).subscribe((resp: SilkierLogMessageVm) => {
            if (resp != null) { this.logMsg =  resp.logMessage; }
        })
    }
}
