import { Directive, HostListener, Input, EventEmitter, Output } from '@angular/core';
import { FormGroupDirective } from '@angular/forms';

import { SystemMessageService, SystemMessage } from 'app/core/system-message/system-message-service';
import { validateForm } from '../form-validateForm.function';
import { SetLocaldbTablesService } from 'app/services/set-localdb-tables.service';
import { CheckNetworkService } from 'local-db/services/check-network.service';

@Directive({
	selector: '[pcgSubmitUrl]',
})
export class SubmitUrlDirective {

	@Input('pcgSubmitUrl') url: string;
	@Input() additionalFields: any;
	@Input() localDbTbl: string = "";

	@Output() messageReceive: EventEmitter<SystemMessage> = new EventEmitter<SystemMessage>();
	@Output() messageSuccess: EventEmitter<SystemMessage> = new EventEmitter<SystemMessage>();
	@Output() messageError: EventEmitter<SystemMessage> = new EventEmitter<SystemMessage>();
	@Output() offlineMessageReceive: EventEmitter<any> = new EventEmitter();

	constructor(
		public formGroupDirective: FormGroupDirective
		, public ms: SystemMessageService
		, private localDbService: SetLocaldbTablesService
	) { }

	@HostListener('submit', ['$event']) onSubmit($event) {
		if (this.formGroupDirective.form.valid) {
			var isOnline = CheckNetworkService.checkNetworkStatus();

			if (isOnline) {
				this.ms.getHttpObservable(this, this.url, this.formGroupDirective.form, this.additionalFields)
					.subscribe((msg: SystemMessage) => {
						this.messageReceive.emit(msg);
						if (msg && msg.isSuccessful) { this.messageSuccess.emit(msg); }
						if (msg && !msg.isSuccessful) { this.messageError.emit(msg); }
					}
				);
			} else if (this.localDbTbl !== "") {
				this.ms.setSystemMessage("Data Saved", "success");
				var setOfflineId = this.formGroupDirective.form.controls["offlineId"] != null;
				this.localDbService.setLocalDbTable(this.localDbTbl, this.formGroupDirective.form.getRawValue(), true, setOfflineId);
				this.offlineMessageReceive.emit();
			}
		} else { validateForm(this.formGroupDirective.form); }
	}
}
