<div id="informativeContent">
	<div id="actionElements">
		<input
			type="button"
			id="btnNewOption"
			*ngIf="this.canCreateEditDelete"
			value="Add Help File"
			(click)="openModal(0)"
			class="btn btn-save btn-sm inline-add-btn"
		/>
	</div>
	<div id="actionElements">
		<input
			type="button"
			id="btnNewVideo"
			*ngIf="this.canCreateEditDelete"
			value="Add Help Video"
			(click)="openModal(0, 0)"
			class="btn btn-save btn-sm inline-add-btn"
		/>
	</div>
	<div id="actionElements">
		<input
			type="button"
			id="btnNewCategories"
			*ngIf="this.canCreateEditDelete"	
			value="Add Module"
			(click)="openModuleModal()"
			class="btn btn-save btn-sm inline-add-btn"
		/>
	</div>
</div>

<pcg-table
	#table
	[pcgSort]="[ ['moduleName', 'asc'], ['name', 'asc'] ]"
	dataSource="api/HelpFiles/HelpFiles/GetHelpFilesList"
	[columnDefs]="columns"
	[isRowClickable]="true"
	(clickRow)="openHelp($event)"
>
	<ng-container matColumnDef="helpFileId">
		<th class="text-center" mat-header-cell *matHeaderCellDef>{{ columns.get('helpFileId').displayName }}</th>
		<td class="text-center" mat-cell *matCellDef="let row" [attr.data-label]="columns.get('helpFileId').displayName">
			<pcg-edit-link *ngIf="canCreateEditDelete" (click)="openModal(row.helpFileId, row.videoId); $event.stopPropagation();">
				<img alt="Edit" style="width: 25px; cursor: pointer" class="mobileImg" src="assets/images/actions/edit.png"	/>
			</pcg-edit-link>
		</td>
	</ng-container>

	<ng-container matColumnDef="name">
		<th mat-header-cell pcg-sort-header *matHeaderCellDef>{{ columns.get('name').displayName }}</th>
		<td mat-cell *matCellDef="let row" [attr.data-label]="columns.get('name').displayName">
			<span *ngIf="!row.videoId">{{ row.name }}</span>
			<span *ngIf="row.videoId">
				<img style="width: 20px; cursor: pointer" class="pr-1" src="assets/images/informational/movie.svg"/>
				{{ row.name }}
			</span>
		</td>
	</ng-container>

	<ng-container matColumnDef="moduleName">
		<th mat-header-cell pcg-sort-header *matHeaderCellDef>{{ columns.get('moduleName').displayName }}</th>
		<td mat-cell *matCellDef="let row" [attr.data-label]="columns.get('moduleName').displayName">{{ row.moduleName }}</td>
	</ng-container>
	
	<ng-container matColumnDef="description">
		<th mat-header-cell pcg-sort-header *matHeaderCellDef>{{ columns.get('description').displayName }}</th>
		<td mat-cell *matCellDef="let row" [attr.data-label]="columns.get('description').displayName">
			<pcg-ellipsis [size]="250" [data]="row.description"></pcg-ellipsis>
		</td>
	</ng-container>

	<ng-container *ngIf="this.canCreateEditDelete" matColumnDef="delete">
		<th class="text-center" mat-header-cell *matHeaderCellDef>Delete</th>
		<td class="text-center" mat-cell *matCellDef="let row" [attr.data-label]="columns.get('delete').displayName">
			<pcg-delete-button
				[table]="table"
				[confirmMessage]="'Are you sure you want to delete this?'"
				url="api/HelpFiles/HelpFiles/DeleteHelpFile"
				[id]="row.helpFileId"
			></pcg-delete-button>
		</td>
	</ng-container>
</pcg-table>

<ng-template #helpfile let-modal>
	<form 
		[formGroup]="formGroup" 
		pcgSubmitUrl="api/HelpFiles/HelpFiles/SaveFile"
		(messageSuccess)="modal.dismiss(); resetValidation(); this.table.ajaxReload()" 
		(messageError)="setValidation($event)"
	>

		<div class="modal-header">
			<h5 class="modal-title" id="modal-basic-title">Help File</h5>
			<button type="button" class="close" aria-label="Close" (click)="modal.dismiss()">
				<span aria-hidden="true">&times;</span>
			</button>
		</div>

		<div class="modal-body">
			<div class="row">
				<pcg-control-group class="col-md-6" label="Name">
					<input 
						pcgControlRef 
						formControlName="name" 
						maxlength="101"
						class="form-control" 
						type="text" 
					/>
				</pcg-control-group>

				<pcg-control-group class="col-md-6" label="Module">
					<pcg-select
						pcgControlRef
						[multiple]="false"
						dataSource="api/Select/HelpModules"
						formControlName="moduleId"
						placeholder="Select a Module"
						(click)="resetValidation()"
					></pcg-select>
					<span class="text-danger" *ngIf="isError">{{errorMessage}}</span>
				</pcg-control-group>
			</div>

			<div class="row">
				<pcg-control-group class="col-md-12" label="Description">
					<textarea 
						pcgControlRef 
						formControlName="description" 
						maxlength="1001"
						class="form-control"
					></textarea>
				</pcg-control-group>
			</div>

			<div *ngIf="this.formGroup.controls.helpFileId.value === 0" class="row">
				<pcg-control-group class="col-md-12" label="Help File Upload">
					<label>This is the Help file that all users will be able to see and access.</label>
					<pcg-file-upload 
						pcgControlRef 
						formControlName="file" 
						type="file"
					></pcg-file-upload>
				</pcg-control-group>
			</div>

			<div *ngIf="this.formGroup.controls.helpFileId.value !== 0" class="row">
				<pcg-control-group class="col-md-12" label="Overwrite Help File (optional)">
					<label>This is the Help file that all users will be able to see and access.</label>
					<pcg-file-upload 
						pcgControlRef 
						formControlName="file" 
						type="file"
					></pcg-file-upload>
				</pcg-control-group>
			</div>

			<div *ngIf="this.formGroup.controls.helpFileId.value === 0" class="row">
				<pcg-control-group class="col-md-12" label="Source File Upload">
					<label>This is the file that will be used to make edits/updates.</label>
					<pcg-file-upload 
						pcgControlRef 
						formControlName="sourceFile" 
						type="file"
					></pcg-file-upload>
				</pcg-control-group>
			</div>

			<div *ngIf="this.formGroup.controls.helpFileId.value !== 0" class="row">
				<pcg-control-group class="col-md-12" label="Overwrite Source File (optional)">
					<label>This is the file that will be used to make edits/updates.</label>
					<pcg-file-upload 
						pcgControlRef 
						formControlName="sourceFile" 
						type="file"
					></pcg-file-upload>
				</pcg-control-group>
			</div>

			<div *ngIf="this.formGroup.controls.helpFileId.value !== 0 && this.formGroup.controls.sourceFileName.value !== null" class="row">
				<div class="col-md-12" label="Download Source File">
					<a target="_blank" [href]="'/Uploads/HelpFiles/' + this.formGroup.controls.sourceFileName.value">Download Source File</a>
				</div>
			</div>
		</div>
		
		<div class="modal-footer">
			<button type="button" class="btn btn-cancel btn-lg" (click)="modal.dismiss()">Cancel</button>
			<button type="submit" class="btn btn-save btn-lg">Save</button>
		</div>
	</form>
</ng-template>

<ng-template #helpvideo let-modal>
	<form 
		[formGroup]="formGroup" 
		pcgSubmitUrl="api/HelpFiles/HelpFiles/SaveFile"
		(messageSuccess)="modal.dismiss(); this.table.ajaxReload()" 
		(messageError)="setValidation($event)"
	>

		<div class="modal-header">
			<h5 class="modal-title" id="modal-basic-title">Help Video</h5>
			<button type="button" class="close" aria-label="Close" (click)="modal.dismiss()">
				<span aria-hidden="true">&times;</span>
			</button>
		</div>

		<div class="modal-body">
			<div class="row">
				<pcg-control-group class="col-md-6" label="Name">
					<input 
						pcgControlRef 
						formControlName="name" 
						maxlength="101"
						class="form-control" 
						type="text" 
					/>
				</pcg-control-group>

				<pcg-control-group class="col-md-6" label="Module">
					<pcg-select
						pcgControlRef
						[multiple]="false"
						dataSource="api/Select/HelpModules"
						formControlName="moduleId"
						placeholder="Select a Module"
						(click)="resetValidation()"
					></pcg-select>
					<span class="text-danger" *ngIf="isError">{{errorMessage}}</span>
				</pcg-control-group>
			</div>

			<div class="row">
				<pcg-control-group class="col-md-12" label="Description">
					<textarea 
						pcgControlRef 
						formControlName="description" 
						maxlength="1001"
						class="form-control"
					></textarea>
				</pcg-control-group>
			</div>

			<div class="row">
				<pcg-control-group class="col-md-12" label="Video ID"
					labelTooltip="Input the Vimeo video ID here. This ID can be found in the published Vimeo video URL.">
					<input 
						pcgControlRef 
						pcgNumeric
						formControlName="videoId" 
						class="form-control" 
						maxlength="21"
						type="text" 
					/>
				</pcg-control-group>
			</div>
		</div>

		<div class="modal-footer">
			<button type="button" class="btn btn-cancel btn-lg" (click)="modal.dismiss()">Cancel</button>
			<button type="submit" class="btn btn-save btn-lg">Save</button>
		</div>
	</form>
</ng-template>

<ng-template #newmodule #tableModules let-modal>
	<div style="padding: 3%;">
		<div class="modal-header">
			<h5 class="modal-title" id="modal-basic-title">New Module</h5>
			<button type="button" class="close" aria-label="Close" (click)="modal.dismiss()">
				<span aria-hidden="true">&times;</span>
			</button>
		</div>

		<pcg-new-help-module-button [table]="tableModules"></pcg-new-help-module-button> <!--Has to be it's component to ajax.reload()-->
		
		<pcg-table
			#tableModules
			[showPagination]="false"
			[showTableTop]="false"		
			[showNumRows]="false"	
			dataSource="api/HelpFiles/HelpFiles/GetHelpModulesList"
			[columnDefs]="columnsModule"
			[pcgSort]="[['moduleName', 'asc']]"
		>	

			<ng-container matColumnDef="moduleName">
				<th class="text-center" mat-header-cell *matHeaderCellDef>{{ columns.get('moduleName').displayName }}</th>
				<td class="text-center" mat-cell *matCellDef="let row" [attr.data-label]="columns.get('moduleName').displayName">{{ row.moduleName }}</td>
			</ng-container>

			<ng-container matColumnDef="delete">
				<th class="text-center" mat-header-cell *matHeaderCellDef>{{ columns.get('delete').displayName }}</th>
				<td class="text-center" mat-cell *matCellDef="let row" [attr.data-label]="columns.get('delete').displayName">
					<pcg-delete-button
						[table]="tableModules"
						[confirmMessage]="'Are you sure you want to delete this?'"
						url="api/HelpFiles/HelpFiles/DeleteHelpModule"
						[id]="row.moduleId"
					></pcg-delete-button>
				</td>
			</ng-container>

		</pcg-table>

		<div class="flex-footer">
			<button type="button" class="btn btn-cancel btn-lg" (click)="modal.dismiss()">Cancel</button>
		</div>		
	</div>
</ng-template>