import { Component, OnInit, Input } from '@angular/core';
import { faEye } from '@fortawesome/pro-solid-svg-icons';

import { PcgTableColumn } from '../../pcg-table-column.interface';

@Component({
	selector: 'pcg-button-hide-show',
	templateUrl: './button-hide-show.component.html',
	styleUrls: ['./button-hide-show.component.scss'],
})
export class ButtonHideShowComponent implements OnInit {
	@Input() columnDefs: Map<string, PcgTableColumn>;

	colVisActive = false;

	// Font awesome
	faIconName = { faEye };

	constructor() {}

	getColDefs = () => Array.from(this.columnDefs.keys()).filter(key => !this.columnDefs.get(key).hide);	

	ngOnInit() {
		this.columnDefs.forEach((value, key) => {
			if (value.isVisible !== false) { value.isVisible = true; }
		});
	}
}