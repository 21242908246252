import { Component, Input } from '@angular/core';

@Component({
  selector: 'pcg-table-top-tooltip',
  templateUrl: './table-top-tooltip.component.html',
  styleUrls: ['./table-top-tooltip.component.scss']
})
export class TableTopTooltipComponent {
  @Input() tooltipContent: string;
  
}
