import { HttpClient } from '@angular/common/http';
import { Component, Input } from '@angular/core';
import { Subscription } from 'rxjs';

import { SystemMessage } from 'app/core/system-message/system-message-service';
import { SystemMessageService } from 'app/core/system-message/system-message-service';
import { HelpModuleVm } from 'app/shared/generated/HelpFiles/Models/HelpModuleVm';
import { TableComponent } from 'app/shared/table/table.component';

@Component({
    selector: 'pcg-new-help-module-button',
    templateUrl: './new-help-module-button.component.html',
    styleUrls: ['./new-help-module-button.component.scss']
})
export class NewHelpModuleButtonComponent {

    @Input() table: TableComponent<HelpModuleVm>;;

    newModuleName: string;
    subscriptions = new Subscription();
    isError = false;
    errorMessage = "";

    constructor(private httpClient: HttpClient, private ms: SystemMessageService) { }

    addNewModuleName(newModuleName: string) {
        if (newModuleName == null || newModuleName === "" || newModuleName.trim().length === 0) {
            this.newModuleName = "";
            this.isError = true;
            this.errorMessage = "Module Name required.";
            return;
        }
        
        this.subscriptions.add(this.httpClient.get(`api/HelpFiles/HelpFiles/SaveModuleName/?moduleName=${newModuleName}`)
            .subscribe((message: SystemMessage) => {
                if (message.isSuccessful) { this.ms.setSystemMessage("Module has been saved."); }	
                this.newModuleName = "";
                this.table.ajaxReload();	
                this.isError = false;
                this.errorMessage = "";
            }));      
    }

    resetValidation() {
        this.isError = false;
        this.errorMessage = "";
    }
}
