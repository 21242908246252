/* eslint-disable @typescript-eslint/ban-types */
import { Injectable } from '@angular/core';

@Injectable({
	providedIn: 'root'
})
export class FilterSaveService {
	constructor() { }

	/**
	 * Save filters into sessionStorage.
	 * 
	 * @param filters The filters to save.
	 * @param scopeName The name of the session variable. If null, automatically generate from URL.
	 */
	saveFilters(filters: object, scopeName: string = null) {
		sessionStorage.setItem(this.getScopeName(scopeName), JSON.stringify(filters));
	}

	/**
	 * Remove filters from sessionStorage.
	 * 
	 * @param scopeName The name of the session variable. If null, automatically generate from URL.
	 */
	removeFilters(scopeName: string = null) {
		sessionStorage.removeItem(this.getScopeName(scopeName));
	}

	/**
	 * Retrieve filters from sessionStorage.
	 * 
	 * @param scopeName The name of the session variable. If null, automatically generate from URL.
	 */
	getFilters(scopeName: string = null) {
		return JSON.parse(sessionStorage.getItem(this.getScopeName(scopeName)));
	}

	/**
	 * A place to centralize how we determine the scope name in session storage
	 * 
	 * @param scopeName The name of the session variable. If null, automatically generate from URL.
	 */
	 private getScopeName(scopeName: string) {
		if (scopeName !== null) {
			return scopeName;
		}
		return window.location.href + ':filters';
	}
}
