import { NavRoute } from '../shared/navigation/nav.route.interface';
import { getAdminNav } from './nav-areas/admin-nav';
import { getSysAdminNav } from './nav-areas/sys-admin-nav';
import { getInventoryNav } from './nav-areas/inventory-nav';
import { getReportsNav } from './nav-areas/reports-nav';
import { getHelpFilesNav } from './nav-areas/help-files-nav';
import { usersNav } from './nav-areas/users-nav';
import { inventorySiteNav } from './nav-areas/inventory-site-nav';
import { productNav } from './nav-areas/product-nav';
import { supplierNav } from './nav-areas/supplier-nav';
import { statesNav } from './nav-areas/states-nav';
import { inventoryActionsNav } from './nav-areas/inventory-action-nav';
import { formsNav } from './nav-areas/forms-nav'

let user = JSON.parse(localStorage.getItem("user"));

export const getMainNavRoutes: () => NavRoute[] = function () {

	if (user?.controlValues) {
		return [
			getAdminNav()
			, getSysAdminNav()
			, getInventoryNav()
			, getReportsNav()
			, getHelpFilesNav()
			, inventorySiteNav()
			, usersNav()
			, productNav()
			, supplierNav()
			, statesNav()
			, inventoryActionsNav()
			, formsNav()
		];
	} else {
		return [
			getAdminNav()
			, getSysAdminNav()
			, getInventoryNav()
			, getReportsNav()
			, getHelpFilesNav()
			, inventorySiteNav()
			, usersNav()
			, productNav()
			, supplierNav()
			, statesNav()
			, inventoryActionsNav()
			, formsNav()
		];
	}
};