import { NavRoute } from '../../shared/navigation/nav.route.interface';
import { PermissionConst } from '../security/generated/Permission';

export const getHelpFilesNav: () => NavRoute = () => ({
	name: 'Help'
	, path: 'help/'
	, security: PermissionConst.coreFeatures
	, isInUserNav: false
	, children: getHelpChildren()
});

function getHelpChildren() : NavRoute[] {
	var videoId: string = "";
	var isViewingHelpDoc: boolean = false;
	var url = window.location.href;

	if (url.indexOf('help/help-video/') > -1) { 
		videoId = url.substring(url.lastIndexOf('/') + 1); 
		isViewingHelpDoc = true;
	}

	let navRoutes: Array<NavRoute> = [];
	if (isViewingHelpDoc === false) {
		navRoutes.push({
			name: 'Help Documents'
			, exact: true
			, path: `help`
		});
		navRoutes.push({
			name: 'Release Notes'
			, exact: true
			, path: `web-release-notes`
		});
	} else {
		navRoutes.push({
			name: 'Back'
			, exact: true
			, path: `help`
		});
		navRoutes.push({
			name: 'Help Video'
			, exact: true
			, path: `help/help-video/${videoId}`
		});
	}
	
	return navRoutes; 
}