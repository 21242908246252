import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';

@Injectable({
	providedIn: 'root'
})
export class SpinnerService {
	private loadingSource = new Subject<boolean>();
	// eslint-disable-next-line @typescript-eslint/member-ordering
	loadingSource$ = this.loadingSource.asObservable();
	
	constructor() { }	

	showSpinner() {
		this.loadingSource.next(true);
	}

	hideSpinner() {
		this.loadingSource.next(false);
	}
}
