import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { HttpClient } from '@angular/common/http';
import { Router, ActivatedRoute } from '@angular/router';
import { Title } from '@angular/platform-browser';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { client } from '@passwordless-id/webauthn' 
import { server } from '@passwordless-id/webauthn' 
import { RegistrationEncoded, RegistrationParsed } from '@passwordless-id/webauthn/dist/esm/types';

import { UserJsVm } from 'app/shared/generated/Administration/Models/UserJsVm';
import { LoginVm } from 'app/shared/generated/Models/LoginVm';
import { SecurityService } from 'app/core/security/security.service';
import { SystemMessageService, SystemMessage } from 'app/core/system-message/system-message-service';
import { validateForm } from 'app/shared/form-elements/form-validateForm.function';
import { WebAuthnService } from 'app/core/security/multi-factor-auth/web-authn.service';
import { MultiFactorAuthModalComponent } from './multi-factor-auth-modal/multi-factor-auth-modal.component';
import { EditDowntimeNotificationVm } from 'app/shared/generated/Administration/Models/DowntimeNotifications/EditDowntimeNotificationVm';
import { GlobalVariablesService } from 'app/services/global-variables.service';
import { CheckNetworkService } from 'local-db/services/check-network.service';
import { CachePrimerService } from 'app/services/cache-primer.service';

@Component({
	selector: 's2-login',
	templateUrl: './login.component.html',
	styleUrls: ['./login.component.scss'],
	providers: [
		{
		  provide: NG_VALUE_ACCESSOR,
		  multi:true,
		  useExisting: LoginComponent
		}
	]
})
export class LoginComponent implements OnInit {

	@ViewChild('myForm', { static: true }) myForm: ElementRef;

	loginForm = LoginVm.Form;
	downtimeNotification: EditDowntimeNotificationVm;
	user: UserJsVm;
	sm: SystemMessage;

	id: number;

	isClientAvailable = false;
	isLocalAuthenticator = false;
	isPasswordlessLogin = false;
	isCurrentUser = true;
	isNewUser: boolean;
	showDowntimeNotification = false;
	fromIops = false;
	isError = false;
	isPasswordlessError = false;
	
	date: Date;

	dateStr: string;
	formattedStartDate: string;
	formattedEndDate: string;
	userNameParam: string;
	userGuidParam: string;	
	clientIdParam: string;
	errorString = "";
	passwordlessErrorString = "";
	iosVersion = "";
	isOnline: boolean;

	constructor(
		private route: ActivatedRoute
		, private ms: SystemMessageService
		, private sec: SecurityService
		, private httpClient: HttpClient
		, private router: Router
		, private modalService: NgbModal
		, private webAuthn: WebAuthnService
		, private globalVariablesService: GlobalVariablesService
		, private cachePrimerService: CachePrimerService
		, title: Title
	) { title.setTitle('Login'); }

	async ngOnInit(): Promise<void> {
		this.isLocalAuthenticator = await client.isLocalAuthenticator();
		//this.iosVersion = this.iOSversion();
		
		this.userNameParam = this.route.snapshot.queryParams.username;
		this.userGuidParam = this.route.snapshot.queryParams.userguid;
		this.clientIdParam = this.route.snapshot.queryParams.clientId;
		this.globalVariablesService.isNavFixed.next(false);
		this.isOnline = CheckNetworkService.checkNetworkStatus();

		if (
			this.userNameParam !== null 
			&& this.userNameParam !== undefined 
			&& this.userNameParam !== "" 
			&& this.userGuidParam !== null 
			&& this.userGuidParam !== undefined 
			&& this.userGuidParam !== ""
		) { 
			this.fromIops = true;
			this.onSubmit() 
		}

		const user = this.sec.getUser();
		if (
			user 
			&& !this.fromIops
		) { this.loginRedirect(); }
		else { 
			this.date = new Date();
			this.dateStr = (this.date.getUTCMonth() + 1).toString() + '/' 
				+ this.date.getUTCDate().toString() + '/'
				+ this.date.getUTCFullYear().toString() + ' '
				+ this.date.getUTCHours().toString() + ':'
				+ this.date.getUTCMinutes().toString() + ':'
				+ this.date.getUTCSeconds().toString();
		}
	}

	async onSubmit(): Promise<void> {
		this.isError = false;
		this.errorString = "";

		if (
			this.userNameParam != null 
			&& this.userGuidParam != null
		) { 
			// means there coming from a company we've integrated with
			try {
				this.sec.setLocalStorage('lastAccess', new Date());
				this.loginForm.controls.userName.setValue(this.userNameParam);
				this.loginForm.controls.userGuid.setValue(this.userGuidParam);
				this.loginForm.controls.clientId.setValue(this.clientIdParam);
				this.loginForm.controls.password.setValue("password");
				this.loginForm?.controls?.fromIntegration?.setValue(true);
				this.ms.getHttpObservable(
					this
					, 'api/Account/Login'
					, this.loginForm
				).subscribe((msg: SystemMessage) => { 
					this.sm = msg;
					if (!msg.isSuccessful) {
						this.isError = true;
						this.errorString = msg.message;
					} else { this.doLogin(); }
				});
			} catch (ex: any) { throw ex; }
		} else {
			try {
				if (this.loginForm.valid) {
					this.sec.setLocalStorage('lastAccess', new Date());
					this.ms.getHttpObservable(
						this
						, 'api/Account/Login'
						, this.loginForm
					).subscribe(async msg => {
						this.sm = msg;
						const val = msg.value;
						if (
							msg.isSuccessful 
							&& this.isClientAvailable 
							&& val
						) {						
							if (localStorage.getItem('webAuthnRemember') === null) { this.isPasswordlessLogin = true; } 
							else if (localStorage.getItem('webAuthnRemember') === "false") { this.doLogin(); } 
							else { this.doLogin(); }
						} else if (!msg.isSuccessful) {
							this.isError = true;
							this.errorString = msg.message;
						} else { this.doLogin(); }}
					);
				} else { validateForm(this.loginForm); }
			} catch (ex: any) { throw ex; }
		}		
	}

	doLogin() {
		try {
            const val = this.sm.value;
			if (!val) { return; }
			if (val.hasTwoFactorAuth) {
				// Need this hack to avoid change detection error on modal open
				this.myForm.nativeElement.ownerDocument.activeElement.blur();
				// Open the 2FA modal
				this.modalService.open(MultiFactorAuthModalComponent);
				// Handle 2FA
				this.doTwoFactorAuth();
				return;
			}
			this.loginForm.reset();
			this.sec.setSecurity(val.token, val.user, val.security);
			this.loginRedirect();
        } catch (ex: any) { throw ex; }
	}

	async registerPasswordlessLogin(msg: SystemMessage): Promise<void>  {
		this.isPasswordlessError = false;
		this.passwordlessErrorString = "";
		const val = msg.value;
		let registrationParsed: RegistrationParsed;
		let registration: RegistrationEncoded;
		let challenge = "";
		
		try {
			challenge = val.challenge;
			registration = await client.register(
				this.loginForm.controls.userName.value
				, challenge
				, {
					authenticatorType: "auto"
					, userVerification: "required"
					, timeout: 60000
					, attestation: false
					, userHandle: "recommended to set it to a random 64 bytes value"
					, debug: false
				}
			);
			const expected = {
				challenge: challenge // whatever was randomly generated by the server
				, origin: val.baseUrl
			};
			registrationParsed = await server.verifyRegistration(registration, expected);
			
			// Store webAuthn in local storage
			if (registrationParsed === null) { localStorage.removeItem("webAuthn"); } 
			else { localStorage.setItem("webAuthn", JSON.stringify(registrationParsed)); }

			this.doLogin();
		} catch (ex: any) {
			this.isPasswordlessError = true;
			this.passwordlessErrorString = ex.message;
		}
	}

	async passwordlessLogin(): Promise<void> {
		if (localStorage.getItem('webAuthn') !== null) {
			try {
				let registrationParsed = JSON.parse(localStorage.getItem('webAuthn'));
				let clientParsed = registrationParsed?.client;
				let challenge = clientParsed?.challenge;

				if (
					challenge !== "" 
					&& challenge !== null 
					&& challenge !== undefined
				) {
					let res = await client.authenticate([], challenge);
					if (res !== null) {
						const webAuthn = JSON.parse(localStorage.getItem('webAuthn'));
						this.loginForm.controls.userName.patchValue(webAuthn.username);
						this.loginForm.controls.concurrencyStamp.patchValue(challenge);
						this.loginForm.controls.password.patchValue(webAuthn.username);
						this.ms.getHttpObservable(this, 'api/Account/Login', this.loginForm)
							.subscribe(async msg => { 
								this.sm = msg;
								this.doLogin();
							}
						);
					}
				}
			} catch (ex: any) { }			
		}
	}

	async yesClick(): Promise<void> { await this.registerPasswordlessLogin(this.sm); }

	noClick() {
		localStorage.removeItem("webAuthnRemember");
		localStorage.setItem("webAuthnRemember", "false");
		this.doLogin(); 
	}

	continueClick() { this.doLogin(); }

	loginRedirect() {
		try {
			this.cachePrimerService.primeCache();
			this.user = this.sec.getUser();
			this.id = this.user.id; 
			this.isNewUser = this.user?.isNewUser;
			let redirectUrl = this.route.snapshot.queryParamMap.get('redirectUrl') || sessionStorage.getItem('redirectUrl');
			if (redirectUrl?.match(/redirectUrl/g)?.length > 1) { redirectUrl = ""; }

			if (localStorage.getItem("isNavFixed") === "true") { this.globalVariablesService.isNavFixed.next(true); }	
			this.globalVariablesService.onChangePasswordScreen.next(false);

			if (
				this.isNewUser === true
				|| !this.user.isAcknowledged
			) { this.router.navigateByUrl('administration/users/edit-user/security/' + this.id); }
			else if (redirectUrl) {
				const baseUrl = document.getElementsByTagName('base')[0].href;
				redirectUrl = redirectUrl.replace(/^\//, '');
				if (redirectUrl.startsWith('uploads/')) { window.location.href = baseUrl + redirectUrl; }
				else {
					this.router.navigateByUrl(redirectUrl);
					sessionStorage.removeItem('redirectUrl');
				}
			} else { this.router.navigate(['/dashboard'], { state: { fromIops: this.fromIops }}); }
		} catch (ex: any) { throw ex; }
	}

	doTwoFactorAuth() {
		try {
            if (!this.webAuthn.isFidoCompatible()) {
				this.modalService.dismissAll();
				return;
			}
	
			// Save user credentials and clear form
			const userName = this.loginForm.value.userName;
			const password = this.loginForm.value.password;
			this.loginForm.reset();
	
			// Get assertion options from the server for user
			this.webAuthn.getAssertionOptions(userName, password)
				.subscribe(makeAssertionOptions => {
					navigator.credentials.get({ publicKey: makeAssertionOptions }).then((credential) => {
						this.sec.setLocalStorage('lastAccess', new Date());
						this.webAuthn.verifyAssertion(
							credential
							, userName
							, password
						).subscribe((msg: SystemMessage) => {
							this.sm = msg;
							this.modalService.dismissAll();
							this.doLogin();
						});
					}).catch((err) => {
						this.modalService.dismissAll();
						// Do this so there is a failed login history rercord:
						this.webAuthn.verifyAssertion(
							null
							, userName
							, password
						).subscribe((msg: SystemMessage) => { 
							this.sm = msg;
							this.doLogin(); 
						});
					});
				}
			);			
        } catch (ex: any) { throw ex; }
	}

	checkForDowntimeNotification() {
		if (this.isOnline) {
			this.httpClient.get(
				`api/Administration/DowntimeNotification/CheckForDowntimeNotification?dateStr=${this.dateStr}&ngsw-bypass=true`
			).subscribe((model: EditDowntimeNotificationVm[]) => {
				var dismissedNotifications = JSON.parse(localStorage.getItem("downtimeNotification"));
				var notificationIds = [];

				for (var x = 0; x < model.length; x++) { notificationIds.push(model[x].downtimeNotificationId); }

				var notificationIdsNotDismissed = notificationIds.filter(o => !dismissedNotifications?.includes(o));
				this.downtimeNotification = model.find(o => o.downtimeNotificationId === notificationIdsNotDismissed[0]);

				if (this.downtimeNotification != null) {  
					if (!dismissedNotifications?.includes(notificationIds)) { this.showDowntimeNotification = true; }						

					const startDate = new Date(this.downtimeNotification.startDate);
					this.formattedStartDate = (startDate.getMonth() + 1).toString() + '/' 
						+ startDate.getDate().toString() + '/'
						+ startDate.getFullYear().toString() + ' '
						+ this.convertToStandardTime(startDate.getHours().toString() + ':' 
						+ startDate.getMinutes().toString());

					const endDate = new Date(this.downtimeNotification.endDate);
					this.formattedEndDate = (endDate.getMonth() + 1).toString() + '/' 
						+ endDate.getDate().toString() + '/'
						+ endDate.getFullYear().toString() + ' '
						+ this.convertToStandardTime(endDate.getHours().toString() + ':' 
						+ endDate.getMinutes().toString());
				}
			});		
		}
	}

	dismiss(downtimeNotification: EditDowntimeNotificationVm) {
		var dismissedNotifications = JSON.parse(localStorage.getItem("downtimeNotification"));
		if (dismissedNotifications == null) {
			var notificationObject = [downtimeNotification.downtimeNotificationId];
			localStorage.setItem("downtimeNotification", JSON.stringify(notificationObject));
		} else {
			var notificationspresent = dismissedNotifications;
			notificationspresent.push(downtimeNotification.downtimeNotificationId);
			localStorage.setItem("downtimeNotification", JSON.stringify(notificationspresent));
		}
		this.showDowntimeNotification = false;
	}

	convertToStandardTime(militaryTime: string) {
		var time = militaryTime.split(':');
		var hours = Number(time[0]);
		var minutes = Number(time[1]);
		var timeValue: string;

		if (hours > 0 && hours <= 12) { timeValue = "" + hours;  } 
		else if (hours > 12) { timeValue = "" + (hours - 12); } 
		else if (hours == 0) { timeValue = "12"; }

		timeValue += (minutes < 10) ? ":0" + minutes : ":" + minutes;  // get minutes
		timeValue += (hours >= 12) ? " P.M." : " A.M.";  // get AM/PM

		return timeValue;
	}

	ios() {
		var isIOS = /(iPad|iPhone|iPod)/g.test(navigator.userAgent) 
			|| /iP(hone|od|ad)/.test(navigator.platform)
			|| /iPad|iPhone|iPod/.test(navigator.userAgent);
		var isAppleDevice = navigator.userAgent.includes('Macintosh');
		var isTouchScreen = navigator.maxTouchPoints >= 1; // true for iOS 13 (and hopefully beyond)

		return isIOS || (isAppleDevice && isTouchScreen);
	}

	iOSversion() {
		var returnMsg = "";
		if (this.ios()) {
			// iOS version will be like iPhone OS 4_3_3, you will get three digits
			var v = navigator.appVersion.match(/OS (\d+)_(\d+)_?(\d+)?/);
			var vIos = /(iPhone|iPad|iPod) OS ([1-9]*)/g.exec(window.navigator.userAgent)?.[2] || "";
			console.log("Deprecated version " + v);
			console.log("Not deprecated version " + vIos);
			if (parseInt(v[1], 10) < 16) {
				returnMsg = parseInt(v[1], 10).toString() 
					+ "." 
					+ parseInt(v[2], 10).toString()
					+ "." 
					+ parseInt(v[3] || (0 as any), 10).toString();
			}			
		} 
		return returnMsg;
	}	
}
