import { Component, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Subscription } from 'rxjs';
import { DepartmentInventoryVm } from '../../../../shared/generated/Administration/Models/DepartmentInventoryVm';
import { UntypedFormGroup } from '@angular/forms';

@Component({
	selector: 'pcg-department-inventories-modal',
	templateUrl: './department-inventories-modal.component.html',
	styleUrls: ['./department-inventories-modal.component.scss'],
})
export class DepartmentInventoriesModalComponent implements OnInit {
	subscriptions: Subscription = new Subscription();
	formGroup: UntypedFormGroup = DepartmentInventoryVm.Form;
	model = DepartmentInventoryVm.GetModel(this.formGroup);
	departments: DepartmentInventoryVm[];

	constructor(public activeModal: NgbActiveModal, private httpClient: HttpClient) {}

	ngOnInit(): void {
		this.getDepartments();
	}

	getDepartments() {
		this.httpClient
			.get('api/Administration/Departments/GetDepartmentsAndInventoriesList')
			.subscribe((model: DepartmentInventoryVm[]) => {
				this.departments = model;
			});
	}
}
