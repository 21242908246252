import { UntypedFormGroup, UntypedFormControl, UntypedFormArray, Validators } from '@angular/forms';

import { PcgTableColumn } from '../../../table/pcg-table-column.interface';
import { NavInventorySiteVm, NavInventorySiteVmFromFg } from '../../Administration/Models/NavInventorySiteVm';

// tslint:disable:quotemark
// tslint:disable:max-line-length
// tslint:disable:whitespace
export class UserJsVm {
	id: number;
	fullName?: string;
	email?: string;
	userName?: string;
	canChangeSecurity: boolean;
	inactivityTimeout: boolean;
	isAcknowledged: boolean;
	twoFactorEnabled: boolean;
	canChangeClient: boolean;
	clientId?: number;
	currentClientId: number;
	clientName?: string;
	clientLogoFilename?: string;
	clientLogoFilePath?: string;
	clientShiftChangeName?: string;
	clientShiftChangeAbr?: string;
	clientInventoryActionsCount: number;
	clientMedUseName?: string;
	clientMedUseAbr?: string;
	clientUseRunNumber: boolean;
	clientIsRunNumberNumeric: boolean;
	clientIsRunNumberRequired: boolean;
	clientRunNumberName?: string;
	useMedicationUseReview: boolean;
	useUpdateInventoryTechSafegaurd: boolean;
	useUpdateInventoryManagerSafegaurd: boolean;
	useHideTechnicianInventories: boolean;
	controlValues: boolean;
	controlValueScanning: boolean;
	inventorySites?: NavInventorySiteVm[];
	securityAccessDate?: Date;
	isNewUser: boolean;
	userProfileType?: any;
	biometricFingerprintVersion: number;
	permissionId: number;
	isUsingBoxSeal: boolean;
	isUsingBoxReseal: boolean;
	clientUseSecondSeal: boolean;
	clientIsSecondSealRequired: boolean;
	clientSecondSealName?: string;
	clientNonUserName?: string;
	responsibleInventories?: number[];
	clientIsOnboarding: boolean;
	canAccessDeaInfo: boolean;
	isUsingOos: boolean;
	iopsId?: number;
	isUsingQa: boolean;
	replenishmentType?: any;
	clientStateAbr?: string;
	currentVersion?: string;

	static get FormData() {
		return this.getFormData();
	}

	static getFormData(validators = {}) {
		return {
			'id': new UntypedFormControl(0, [...(validators['id'] ?? []), ...[Validators.required]]),
			'fullName': new UntypedFormControl(null, [...(validators['fullName'] ?? []), ...[]]),
			'email': new UntypedFormControl(null, [...(validators['email'] ?? []), ...[]]),
			'userName': new UntypedFormControl(null, [...(validators['userName'] ?? []), ...[]]),
			'canChangeSecurity': new UntypedFormControl(false, [...(validators['canChangeSecurity'] ?? []), ...[]]),
			'inactivityTimeout': new UntypedFormControl(false, [...(validators['inactivityTimeout'] ?? []), ...[]]),
			'isAcknowledged': new UntypedFormControl(false, [...(validators['isAcknowledged'] ?? []), ...[]]),
			'twoFactorEnabled': new UntypedFormControl(false, [...(validators['twoFactorEnabled'] ?? []), ...[]]),
			'canChangeClient': new UntypedFormControl(false, [...(validators['canChangeClient'] ?? []), ...[]]),
			'clientId': new UntypedFormControl(null, [...(validators['clientId'] ?? []), ...[]]),
			'currentClientId': new UntypedFormControl(0, [...(validators['currentClientId'] ?? []), ...[Validators.required]]),
			'clientName': new UntypedFormControl(null, [...(validators['clientName'] ?? []), ...[]]),
			'clientLogoFilename': new UntypedFormControl(null, [...(validators['clientLogoFilename'] ?? []), ...[]]),
			'clientLogoFilePath': new UntypedFormControl(null, [...(validators['clientLogoFilePath'] ?? []), ...[]]),
			'clientShiftChangeName': new UntypedFormControl(null, [...(validators['clientShiftChangeName'] ?? []), ...[]]),
			'clientShiftChangeAbr': new UntypedFormControl(null, [...(validators['clientShiftChangeAbr'] ?? []), ...[]]),
			'clientInventoryActionsCount': new UntypedFormControl(0, [...(validators['clientInventoryActionsCount'] ?? []), ...[Validators.required]]),
			'clientMedUseName': new UntypedFormControl(null, [...(validators['clientMedUseName'] ?? []), ...[]]),
			'clientMedUseAbr': new UntypedFormControl(null, [...(validators['clientMedUseAbr'] ?? []), ...[]]),
			'clientUseRunNumber': new UntypedFormControl(false, [...(validators['clientUseRunNumber'] ?? []), ...[]]),
			'clientIsRunNumberNumeric': new UntypedFormControl(false, [...(validators['clientIsRunNumberNumeric'] ?? []), ...[]]),
			'clientIsRunNumberRequired': new UntypedFormControl(false, [...(validators['clientIsRunNumberRequired'] ?? []), ...[]]),
			'clientRunNumberName': new UntypedFormControl(null, [...(validators['clientRunNumberName'] ?? []), ...[]]),
			'useMedicationUseReview': new UntypedFormControl(false, [...(validators['useMedicationUseReview'] ?? []), ...[]]),
			'useUpdateInventoryTechSafegaurd': new UntypedFormControl(false, [...(validators['useUpdateInventoryTechSafegaurd'] ?? []), ...[]]),
			'useUpdateInventoryManagerSafegaurd': new UntypedFormControl(false, [...(validators['useUpdateInventoryManagerSafegaurd'] ?? []), ...[]]),
			'useHideTechnicianInventories': new UntypedFormControl(false, [...(validators['useHideTechnicianInventories'] ?? []), ...[]]),
			'controlValues': new UntypedFormControl(false, [...(validators['controlValues'] ?? []), ...[]]),
			'controlValueScanning': new UntypedFormControl(false, [...(validators['controlValueScanning'] ?? []), ...[]]),
			'inventorySites': new UntypedFormArray([]),
			'securityAccessDate': new UntypedFormControl(null, [...(validators['securityAccessDate'] ?? []), ...[]]),
			'isNewUser': new UntypedFormControl(false, [...(validators['isNewUser'] ?? []), ...[]]),
			'userProfileType': new UntypedFormControl('', [...(validators['userProfileType'] ?? []), ...[Validators.required]]),
			'biometricFingerprintVersion': new UntypedFormControl(0, [...(validators['biometricFingerprintVersion'] ?? []), ...[Validators.required]]),
			'permissionId': new UntypedFormControl(0, [...(validators['permissionId'] ?? []), ...[Validators.required]]),
			'isUsingBoxSeal': new UntypedFormControl(false, [...(validators['isUsingBoxSeal'] ?? []), ...[]]),
			'isUsingBoxReseal': new UntypedFormControl(false, [...(validators['isUsingBoxReseal'] ?? []), ...[]]),
			'clientUseSecondSeal': new UntypedFormControl(false, [...(validators['clientUseSecondSeal'] ?? []), ...[]]),
			'clientIsSecondSealRequired': new UntypedFormControl(false, [...(validators['clientIsSecondSealRequired'] ?? []), ...[]]),
			'clientSecondSealName': new UntypedFormControl(null, [...(validators['clientSecondSealName'] ?? []), ...[]]),
			'clientNonUserName': new UntypedFormControl(null, [...(validators['clientNonUserName'] ?? []), ...[]]),
			'responsibleInventories': new UntypedFormControl([], [...(validators['responsibleInventories'] ?? []), ...[]]),
			'clientIsOnboarding': new UntypedFormControl(false, [...(validators['clientIsOnboarding'] ?? []), ...[]]),
			'canAccessDeaInfo': new UntypedFormControl(false, [...(validators['canAccessDeaInfo'] ?? []), ...[]]),
			'isUsingOos': new UntypedFormControl(false, [...(validators['isUsingOos'] ?? []), ...[]]),
			'iopsId': new UntypedFormControl(null, [...(validators['iopsId'] ?? []), ...[]]),
			'isUsingQa': new UntypedFormControl(false, [...(validators['isUsingQa'] ?? []), ...[]]),
			'replenishmentType': new UntypedFormControl('', [...(validators['replenishmentType'] ?? []), ...[Validators.required]]),
			'clientStateAbr': new UntypedFormControl(null, [...(validators['clientStateAbr'] ?? []), ...[]]),
			'currentVersion': new UntypedFormControl(null, [...(validators['currentVersion'] ?? []), ...[]])
		};
	}

	static get Form() {
		return new UntypedFormGroup(this.FormData);
	}

	static GetModel(fg: UntypedFormGroup): UserJsVmFromFg {
		return new UserJsVmFromFg(fg);
	}
}

export class UserJsVmFromFg {
	constructor(public fg: UntypedFormGroup) { }


	get id(): number {
		return this.fg.get('id').value;
	}
	set id(val) {
		this.fg.get('id').setValue(val);
	}

	get fullName(): string {
		return this.fg.get('fullName').value;
	}
	set fullName(val) {
		this.fg.get('fullName').setValue(val);
	}

	get email(): string {
		return this.fg.get('email').value;
	}
	set email(val) {
		this.fg.get('email').setValue(val);
	}

	get userName(): string {
		return this.fg.get('userName').value;
	}
	set userName(val) {
		this.fg.get('userName').setValue(val);
	}

	get canChangeSecurity(): boolean {
		return this.fg.get('canChangeSecurity').value;
	}
	set canChangeSecurity(val) {
		this.fg.get('canChangeSecurity').setValue(val);
	}

	get inactivityTimeout(): boolean {
		return this.fg.get('inactivityTimeout').value;
	}
	set inactivityTimeout(val) {
		this.fg.get('inactivityTimeout').setValue(val);
	}

	get isAcknowledged(): boolean {
		return this.fg.get('isAcknowledged').value;
	}
	set isAcknowledged(val) {
		this.fg.get('isAcknowledged').setValue(val);
	}

	get twoFactorEnabled(): boolean {
		return this.fg.get('twoFactorEnabled').value;
	}
	set twoFactorEnabled(val) {
		this.fg.get('twoFactorEnabled').setValue(val);
	}

	get canChangeClient(): boolean {
		return this.fg.get('canChangeClient').value;
	}
	set canChangeClient(val) {
		this.fg.get('canChangeClient').setValue(val);
	}

	get clientId(): number {
		return this.fg.get('clientId').value;
	}
	set clientId(val) {
		this.fg.get('clientId').setValue(val);
	}

	get currentClientId(): number {
		return this.fg.get('currentClientId').value;
	}
	set currentClientId(val) {
		this.fg.get('currentClientId').setValue(val);
	}

	get clientName(): string {
		return this.fg.get('clientName').value;
	}
	set clientName(val) {
		this.fg.get('clientName').setValue(val);
	}

	get clientLogoFilename(): string {
		return this.fg.get('clientLogoFilename').value;
	}
	set clientLogoFilename(val) {
		this.fg.get('clientLogoFilename').setValue(val);
	}

	get clientLogoFilePath(): string {
		return this.fg.get('clientLogoFilePath').value;
	}
	set clientLogoFilePath(val) {
		this.fg.get('clientLogoFilePath').setValue(val);
	}

	get clientShiftChangeName(): string {
		return this.fg.get('clientShiftChangeName').value;
	}
	set clientShiftChangeName(val) {
		this.fg.get('clientShiftChangeName').setValue(val);
	}

	get clientShiftChangeAbr(): string {
		return this.fg.get('clientShiftChangeAbr').value;
	}
	set clientShiftChangeAbr(val) {
		this.fg.get('clientShiftChangeAbr').setValue(val);
	}

	get clientInventoryActionsCount(): number {
		return this.fg.get('clientInventoryActionsCount').value;
	}
	set clientInventoryActionsCount(val) {
		this.fg.get('clientInventoryActionsCount').setValue(val);
	}

	get clientMedUseName(): string {
		return this.fg.get('clientMedUseName').value;
	}
	set clientMedUseName(val) {
		this.fg.get('clientMedUseName').setValue(val);
	}

	get clientMedUseAbr(): string {
		return this.fg.get('clientMedUseAbr').value;
	}
	set clientMedUseAbr(val) {
		this.fg.get('clientMedUseAbr').setValue(val);
	}

	get clientUseRunNumber(): boolean {
		return this.fg.get('clientUseRunNumber').value;
	}
	set clientUseRunNumber(val) {
		this.fg.get('clientUseRunNumber').setValue(val);
	}

	get clientIsRunNumberNumeric(): boolean {
		return this.fg.get('clientIsRunNumberNumeric').value;
	}
	set clientIsRunNumberNumeric(val) {
		this.fg.get('clientIsRunNumberNumeric').setValue(val);
	}

	get clientIsRunNumberRequired(): boolean {
		return this.fg.get('clientIsRunNumberRequired').value;
	}
	set clientIsRunNumberRequired(val) {
		this.fg.get('clientIsRunNumberRequired').setValue(val);
	}

	get clientRunNumberName(): string {
		return this.fg.get('clientRunNumberName').value;
	}
	set clientRunNumberName(val) {
		this.fg.get('clientRunNumberName').setValue(val);
	}

	get useMedicationUseReview(): boolean {
		return this.fg.get('useMedicationUseReview').value;
	}
	set useMedicationUseReview(val) {
		this.fg.get('useMedicationUseReview').setValue(val);
	}

	get useUpdateInventoryTechSafegaurd(): boolean {
		return this.fg.get('useUpdateInventoryTechSafegaurd').value;
	}
	set useUpdateInventoryTechSafegaurd(val) {
		this.fg.get('useUpdateInventoryTechSafegaurd').setValue(val);
	}

	get useUpdateInventoryManagerSafegaurd(): boolean {
		return this.fg.get('useUpdateInventoryManagerSafegaurd').value;
	}
	set useUpdateInventoryManagerSafegaurd(val) {
		this.fg.get('useUpdateInventoryManagerSafegaurd').setValue(val);
	}

	get useHideTechnicianInventories(): boolean {
		return this.fg.get('useHideTechnicianInventories').value;
	}
	set useHideTechnicianInventories(val) {
		this.fg.get('useHideTechnicianInventories').setValue(val);
	}

	get controlValues(): boolean {
		return this.fg.get('controlValues').value;
	}
	set controlValues(val) {
		this.fg.get('controlValues').setValue(val);
	}

	get controlValueScanning(): boolean {
		return this.fg.get('controlValueScanning').value;
	}
	set controlValueScanning(val) {
		this.fg.get('controlValueScanning').setValue(val);
	}

	get securityAccessDate(): Date {
		return this.fg.get('securityAccessDate').value;
	}
	set securityAccessDate(val) {
		this.fg.get('securityAccessDate').setValue(val);
	}

	get isNewUser(): boolean {
		return this.fg.get('isNewUser').value;
	}
	set isNewUser(val) {
		this.fg.get('isNewUser').setValue(val);
	}

	get userProfileType(): any {
		return this.fg.get('userProfileType').value;
	}
	set userProfileType(val) {
		this.fg.get('userProfileType').setValue(val);
	}

	get biometricFingerprintVersion(): number {
		return this.fg.get('biometricFingerprintVersion').value;
	}
	set biometricFingerprintVersion(val) {
		this.fg.get('biometricFingerprintVersion').setValue(val);
	}

	get permissionId(): number {
		return this.fg.get('permissionId').value;
	}
	set permissionId(val) {
		this.fg.get('permissionId').setValue(val);
	}

	get isUsingBoxSeal(): boolean {
		return this.fg.get('isUsingBoxSeal').value;
	}
	set isUsingBoxSeal(val) {
		this.fg.get('isUsingBoxSeal').setValue(val);
	}

	get isUsingBoxReseal(): boolean {
		return this.fg.get('isUsingBoxReseal').value;
	}
	set isUsingBoxReseal(val) {
		this.fg.get('isUsingBoxReseal').setValue(val);
	}

	get clientUseSecondSeal(): boolean {
		return this.fg.get('clientUseSecondSeal').value;
	}
	set clientUseSecondSeal(val) {
		this.fg.get('clientUseSecondSeal').setValue(val);
	}

	get clientIsSecondSealRequired(): boolean {
		return this.fg.get('clientIsSecondSealRequired').value;
	}
	set clientIsSecondSealRequired(val) {
		this.fg.get('clientIsSecondSealRequired').setValue(val);
	}

	get clientSecondSealName(): string {
		return this.fg.get('clientSecondSealName').value;
	}
	set clientSecondSealName(val) {
		this.fg.get('clientSecondSealName').setValue(val);
	}

	get clientNonUserName(): string {
		return this.fg.get('clientNonUserName').value;
	}
	set clientNonUserName(val) {
		this.fg.get('clientNonUserName').setValue(val);
	}

	get clientIsOnboarding(): boolean {
		return this.fg.get('clientIsOnboarding').value;
	}
	set clientIsOnboarding(val) {
		this.fg.get('clientIsOnboarding').setValue(val);
	}

	get canAccessDeaInfo(): boolean {
		return this.fg.get('canAccessDeaInfo').value;
	}
	set canAccessDeaInfo(val) {
		this.fg.get('canAccessDeaInfo').setValue(val);
	}

	get isUsingOos(): boolean {
		return this.fg.get('isUsingOos').value;
	}
	set isUsingOos(val) {
		this.fg.get('isUsingOos').setValue(val);
	}

	get iopsId(): number {
		return this.fg.get('iopsId').value;
	}
	set iopsId(val) {
		this.fg.get('iopsId').setValue(val);
	}

	get isUsingQa(): boolean {
		return this.fg.get('isUsingQa').value;
	}
	set isUsingQa(val) {
		this.fg.get('isUsingQa').setValue(val);
	}

	get replenishmentType(): any {
		return this.fg.get('replenishmentType').value;
	}
	set replenishmentType(val) {
		this.fg.get('replenishmentType').setValue(val);
	}

	get clientStateAbr(): string {
		return this.fg.get('clientStateAbr').value;
	}
	set clientStateAbr(val) {
		this.fg.get('clientStateAbr').setValue(val);
	}

	get currentVersion(): string {
		return this.fg.get('currentVersion').value;
	}
	set currentVersion(val) {
		this.fg.get('currentVersion').setValue(val);
	}
}
