import { BrowserModule } from '@angular/platform-browser';
import { ErrorHandler, NgModule } from '@angular/core';
import { QuicklinkModule } from 'ngx-quicklink';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ServiceWorkerModule } from '@angular/service-worker';
import { ToastrModule, ToastContainerDirective } from 'ngx-toastr';
import { ModalModule } from 'ngx-bootstrap/modal';

import { RollbarService, rollbarFactory, RollbarErrorHandler } from '../rollbar_config';
import { AppComponent } from './app.component';
import { CoreModule } from './core/core.module';
import { HandleUnrecoverableStateService  } from 'app/services/handle-unrecoverable-state.service';
import { CheckForUpdateService } from 'app/services/check-for-update.service';
import { LogUpdateService } from 'app/services/log-update.service';
import { PromptUpdateService } from 'app/services/prompt-update.service';
import { DatadogErrorHandler } from 'datadog_config';
import { NewVersionCheckerComponent } from './new-version-checker/new-version-checker.component';

/**
 * This is the first module loaded. It bootstraps
 * the rest of the application.
 */
@NgModule({
	declarations: [	 
		AppComponent
		, NewVersionCheckerComponent
   	]
	, imports: [
		BrowserModule.withServerTransition({ appId: 'ng-cli-universal' })
		, CoreModule
		, QuicklinkModule
		, BrowserAnimationsModule
		, ToastrModule.forRoot()
		, ToastContainerDirective
		, ServiceWorkerModule.register(
			'ngsw-worker.js'
			, {
				enabled: true
				, registrationStrategy: 'registerImmediately'
			}
		)
		, ModalModule.forRoot()
	]
	, providers: [
		CheckForUpdateService
		, LogUpdateService
		, PromptUpdateService
		, HandleUnrecoverableStateService
		, { 
			provide: ErrorHandler
			, useClass: RollbarErrorHandler 
		}
		, { 
			provide: ErrorHandler
			, useClass: DatadogErrorHandler
		} 
		, { 
			provide: RollbarService
			, useFactory: rollbarFactory 
		}
	]
	, bootstrap: [ AppComponent ]
})
export class AppModule { }
