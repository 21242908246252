/* eslint-disable @typescript-eslint/ban-types */
/* eslint-disable @typescript-eslint/dot-notation */
import { Injectable } from '@angular/core';
import { Validators, UntypedFormGroup, UntypedFormControl } from '@angular/forms';

import { DynamicFormFieldVm } from '../../../generated/Models/DynamicFormFieldVm';
import { CustomFieldTypeEnum } from '../../../../core/enums/generated/CustomFieldTypeEnum';

@Injectable({
	providedIn: 'root',
})
export class DynamicFormService {
	constructor() {}

	/**
	 * Get a form group containing dynamic fields
	 */
	getFormGroup(fields: DynamicFormFieldVm[]) {
		const group: any = {};

		fields?.forEach(field => {
			group[field.key] = new UntypedFormControl('', this.getValidators(field));
		});
		return new UntypedFormGroup(group);
	}

	/**
	 * Get a form group containing dynamic fields
	 */
	getFormGroupFromData(fgData: {}, fields: DynamicFormFieldVm[]) {
		delete fgData['fields'];
		fgData['customFields'] = this.getFormGroup(fields);
		return new UntypedFormGroup(fgData);
	}

	/**
	 * Update form group's dynamic fields
	 */
	updateFormGroupFields(fg: UntypedFormGroup, fields: DynamicFormFieldVm[]) {
		fg.removeControl('fields');
		fg.setControl('customFields', this.getFormGroup(fields));
		return fg;
	}

	/**
	 * Dynamically generate a list of validators.
	 * 
	 * @param field The dynamic form field provided by the user.
	 */
	getValidators(field: DynamicFormFieldVm) {
		const validators = [];

		if (field.customFieldType === CustomFieldTypeEnum.Number) {
			validators.push(Validators.pattern('^-?\\d+(.\\d)?\\d*$'));
		}
		if (field.customFieldType === CustomFieldTypeEnum.Count) {
			validators.push(Validators.pattern('^[0-9]*$'));
		}
		if (field.isRequired) {
			validators.push(Validators.required);
		}
		if (field.stringLengthMax !== null) {
			validators.push(Validators.maxLength(field.stringLengthMax));
		}
		if (field.stringLengthMin !== null) {
			validators.push(Validators.minLength(field.stringLengthMin));
		}
		if (field.regex !== null) {
			validators.push(Validators.pattern(field.regex));
		}
		if (field.numericMin !== null) {
			validators.push(Validators.min(field.numericMin));
		}
		if (field.numericMax !== null) {
			validators.push(Validators.max(field.numericMax));
		}
		if (field.isEmail) {
			validators.push(Validators.email);
		}

		return validators;
	}
}
