import { Injectable } from '@angular/core';
import { Router, NavigationStart } from '@angular/router';
import { AbstractControl, UntypedFormGroup } from '@angular/forms';
import { JwtHelperService } from '@auth0/angular-jwt';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { BehaviorSubject, Observable } from 'rxjs';
import { filter } from 'rxjs/operators';

import { UserJsVm } from 'app/shared/generated/Administration/Models/UserJsVm';
import { Permission } from './generated/Permission';
import { NavRoute } from 'app/shared/navigation/nav.route.interface';
import { GetPasswordComponent } from './get-password/get-password.component';

@Injectable({
	providedIn: 'root',
})
export class SecurityService {

	user$: Observable<UserJsVm>;
	security$: Observable<Permission>;

	private user: UserJsVm;
	private security: Permission;
	private userSource: BehaviorSubject<UserJsVm>;
	private securitySource: BehaviorSubject<Permission>;	

	constructor(
		private jwtHelper: JwtHelperService
		, private router: Router
		, private modal: NgbModal
	) {
		this.security = localStorage.getItem('dynamicSecurity') !== null 
			? JSON.parse(localStorage.getItem('dynamicSecurity')) 
			: null;
		this.user = localStorage.getItem('user') !== null 
			? JSON.parse(localStorage.getItem('user')) 
			: null;
		this.userSource = new BehaviorSubject<UserJsVm>(this.user);
		this.securitySource = new BehaviorSubject<Permission>(this.security);
		this.user$ = this.userSource.asObservable();
		this.security$ = this.securitySource.asObservable();

		// Subscribe to route changes and update the security when they happen
		this.router.events
			.pipe(filter(event => event instanceof NavigationStart))
			.subscribe((event: NavigationStart) => {
				this.setSecurity(
					this.getToken(),
					localStorage.getItem('user') !== null 
						? JSON.parse(localStorage.getItem('user')) 
						: null,
					localStorage.getItem('dynamicSecurity') !== null 
						? JSON.parse(localStorage.getItem('dynamicSecurity')) 
						: null
				);
			}
		);
	}

	/**
	 * Set the globally used auth token, user, and dynamic security.
	 * Setting any parameter as undefined will leave it as it is.
	 * Setting any parameter to null will remove the value.
	 * 
	 * @param jwt The token to user for authorization
	 * @param user User information to be used in components
	 * @param permission The permission object
	 */
	setSecurity(
		jwt: string
		, user: UserJsVm
		, permission: Permission
	) {
		// Set token
		if (jwt !== undefined) {
			this.setLocalStorage('jwt', jwt);
			if (jwt === null) { document.cookie = 'JsonWebToken=; expires=Thu, 01 Jan 1970 00:00:01 GMT;'; }
		}

		// Set user
		if (user !== undefined) {
			this.setLocalStorage('user', user);
			this.user = user;
			this.userSource.next(user);
		}

		// Set security
		if (permission !== undefined) {
			this.setLocalStorage('dynamicSecurity', permission);
			this.security = permission;
			this.securitySource.next(permission);
		}
	}

	setLocalStorage(
		name: string
		, value: any
	) {
		if (value === null) { localStorage.removeItem(name); } 
		else { localStorage.setItem(name, JSON.stringify(value)); }
	}

	/** Get the JSON web token */
	getToken() { return localStorage.getItem('jwt') !== null ? JSON.parse(localStorage.getItem('jwt')) : null; }

	/** Get the user JS object */
	getUser() { return this.user; }

	/** Get the current dynamic security object */
	getSecurity() { return this.security; }

	/** Check whether or not the user is logged on */
	isLoggedOn() {
		const token = this.getToken();
		if (
			(
				token 
				&& this.jwtHelper.isTokenExpired(token)
			) 
			|| (
				!token 
				&& this.user
			)
		) { this.setSecurity(null, null, null); }
		return token && !this.jwtHelper.isTokenExpired(token);
	}

	/**
	 * Check whether or not the user has access to an area
	 * 
	 * @param securityArea Either a function that returns a bool or a string
	 *  that says which security setting is needed. If it's a string, it should
	 *  be in the form "Module.SecuritySetting"
	 */
	hasAccess(
		permission: string | ((perm: Permission) => boolean)
		, requireLogin = true
	) {
		// If they are not logged on, they do not have access
		if (
			requireLogin 
			&& !this.isLoggedOn()
		) { return false; }
		// If no security information passed in, we are only making sure user
		// is logged in, so return true
		else if (!permission) { return true; }
		// Otherwise, check to see if the user
		// has access to the security area
		else {
			// If they passed in a function, just
			// call it to determine their access
			if (typeof permission === 'function') {
				const secVal = !!permission(this.getSecurity() ?? ({} as Permission));
				return secVal;
			}
			// If the security area is passed in as
			// a string, then we need to use that
			else if (
				this.security 
				&& typeof permission === 'string'
			) { return this.getSecurity()[permission]; }
		}
		return false;
	}

	/**
	 * Check for access to security area. Redirect to root if they do not have access.
	 * 
	 * @param securityArea Either a function that returns a bool or a string
	 *  that says which security setting is needed. If it's a string, it should
	 *  use the PermissionConst object
	 */
	checkSecurity(permission: string | ((perm: Permission) => boolean) = null) {
		// If they have access to the area, return true
		if (this.hasAccess(permission)) { return true; }
		// They do not have access, so redirect to root and return false
		this.router.navigate(['/']);
		return false;
	}

	/**
	 * Get a list of nav items with valid security.
	 * 
	 * @param routes A list of nav items to check the security of.
	 */
	getSecureNavItems(routes: NavRoute[]) {
		const newNavItems = [];
		for (let i = 0; i < routes.length; ++i) {
			if (this.hasAccess(routes[i].security, false)) {
				newNavItems.push(routes[i]);
				if (routes[i].children) {
					newNavItems[newNavItems.length - 1].children = this.getSecureNavItems(routes[i].children);
				}
			}
		}
		return newNavItems;
	}

	/**
	 * Set the form control security.
	 * 
	 * @param control The control to set. Get with formGroup.controls[formGroupName]
	 * @param permission The security area to base security on. Can either be a string or function, with the same syntax has hasAccess.
	 */
	setControlAccess(
		control: AbstractControl
		, permission: string | ((perm: Permission) => boolean)
		, requireLogin = true
	) {
		if (this.hasAccess(permission, requireLogin)) { control.enable(); } 
		else { control.disable(); }
	}

	/**
	 * Set the form security.
	 * 
	 * @param control The form to set. Pass in the FormGroup.
	 * @param permission The security area to base security on. Can either be a string or function, with the same syntax has hasAccess.
	 */
	setFormAccess(
		form: UntypedFormGroup
		, permission: string | ((perm: Permission) => boolean)
	) {
		if (this.hasAccess(permission)) { form.enable(); } 
		else { form.disable(); }
	}

	/**
	 * Prompt for a secure password to use when encrypting exports.
	 * 
	 * @param func The function to run after a password has been chosen.
	 * @param message The message to display when prompting for a password.
	 * @param title The title of the password prompt.
	 * @param buttonText Text to display on the green continue button.
	 * @param showPasswordInfo Whether or not to show password requirement text.
	 */
	promptPassword(
		func: (str: string) => any
		, message = 'In order to download this file, it must be password protected.'
		, title = 'Choose a password'
		, promptForUsersPassword = false
	) {
		const modRef = this.modal.open(GetPasswordComponent);
		(modRef.componentInstance as GetPasswordComponent).message = message;
		(modRef.componentInstance as GetPasswordComponent).title = title;
		(modRef.componentInstance as GetPasswordComponent).func = func;
		(modRef.componentInstance as GetPasswordComponent).promptForUsersPassword = promptForUsersPassword;
	}
}
