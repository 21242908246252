import { Component, Input, OnInit } from '@angular/core';
import { FormControlName, UntypedFormGroup } from '@angular/forms';
import { faEye, faEyeSlash } from '@fortawesome/pro-regular-svg-icons';

@Component({
    selector: 'pcg-show-hide-password',
    templateUrl: './show-hide-password.component.html',
    styleUrls: ['./show-hide-password.component.scss']
})
export class ShowHidePasswordComponent implements OnInit{

    @Input() fcName: FormControlName;
    @Input() parentForm: UntypedFormGroup;
    @Input() validations: { [index: string]: string } = {};
    @Input() label: string;
    @Input() inputCssClass: string;

    isNotSmallScreen : boolean;
    faIconName = { faEye, faEyeSlash };
    showPassword = false;

    constructor() { }

    ngOnInit() { this.isNotSmallScreen = this.notSmallScreen(); }
    toggleShowHidePassword() { this.showPassword = !this.showPassword; }
    notSmallScreen() : boolean { return !this.inputCssClass.includes('mx-sm-1');  }
}
