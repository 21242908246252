<form [formGroup]="fg">
	<pcg-control-group 
		[formGroupName]="fgName" 
		[ngSwitch]="field.customFieldType" 
		class="disabled-as-text"
	>
		<!-- #region Label -->
		<label 
			*ngIf="field.name" 
			[ngbTooltip]="field.tooltip" 
			[ngClass]="{ 'required-field': field.isRequired }"
			[for]="field.key" 
			class="control-label for"
		>{{ field.name }}</label>
		<!-- #endregion -->
			
		<!-- #region Date picker -->
		<input 
			*ngSwitchCase="CustomFieldTypeEnum.Date" 
			[labelForId]="field.key" 
			[formControlName]="field.key" 
			type="date" 
			class="form-control form-control-date" 
			[value]="field.key | date:'yyyy-MM-dd'" 
		/>
		<!-- #endregion -->

		<!-- #region MultiSelect / dropdown -->
		<pcg-select 
			*ngSwitchCase="CustomFieldTypeEnum.MultiSelect" 
			pcgControlRef 
			[id]="field.key" 
			[formControlName]="field.key" 
			[items]="items" 
			[multiple]="true"
		></pcg-select>
		<!-- #endregion -->

		<!-- #region Select / dropdown -->
		<ng-select 
			*ngSwitchCase="CustomFieldTypeEnum.DropDown" 
			pcgControlRef 
			[id]="field.key" 
			[formControlName]="field.key"
			[items]="items" placeholder="Select a value"
		></ng-select>
		<!-- #endregion -->

		<!-- #region Textbox for numeric input -->
		<input 
			*ngSwitchCase="CustomFieldTypeEnum.Number"
			pcgControlRef 
			[id]="field.key" 
			class="form-control small-control" 
			[formControlName]="field.key"
			type="text" 
			inputmode="numeric" 
		/>
		<!-- #endregion -->

		<!-- #region Textbox for natural numeric -->
		<input 
			*ngSwitchCase="CustomFieldTypeEnum.Count"
			pcgControlRef 
			pcgNumberDecimal 
			[id]="field.key" 
			class="form-control" 
			min="0" [formControlName]="field.key"
			type="number" 
			step=".01" 
			inputmode="numeric" 
		/>
		<!-- #endregion -->

		<!-- #region Text area for multi-line input -->
		<textarea 
			*ngSwitchCase="CustomFieldTypeEnum.TextArea"
			class="form-control" 
			pcgControlRef 
			[id]="field.key" 
			[formControlName]="field.key" 
			type="text"
		></textarea>
		<!-- #endregion -->

		<!-- #region Textbox and all masking options -->
		<ng-container *ngSwitchCase="CustomFieldTypeEnum.TextBox">
			<!-- #region Default -->
			<input 
				*ngIf="field.customFieldMask === null"
				class="form-control" 
				pcgControlRef 
				[id]="field.key" 
				[formControlName]="field.key" 
				type="text" 
			/>
			<!-- #endregion -->

			<!-- #region Phone -->
			<input 
				*ngIf="field.customFieldMask === CustomFieldMaskEnum.Phone"
				pcgPhone 
				class="form-control" 
				pcgControlRef 
				[id]="field.key" 
				[formControlName]="field.key" 
				type="text" 
			/>
			<!-- #endregion -->

			<!-- #region Phone /w extension -->
			<input 
				*ngIf="field.customFieldMask === CustomFieldMaskEnum.PhoneExt"
				pcgPhoneExt 
				class="form-control" 
				pcgControlRef 
				[id]="field.key" 
				[formControlName]="field.key" 
				type="text" 
			/>
			<!-- #endregion -->

			<!-- #region Zip Code -->
			<input 
				*ngIf="field.customFieldMask === CustomFieldMaskEnum.ZipCode"
				pcgZipCode 
				class="form-control" 
				pcgControlRef 
				[id]="field.key" 
				[formControlName]="field.key" 
				type="text" 
			/>
			<!-- #endregion -->

			<!-- #region Currency -->
			<input 
				*ngIf="field.customFieldMask === CustomFieldMaskEnum.Currency"
				pcgCurrency 
				class="form-control" 
				pcgControlRef 
				[id]="field.key" 
				[formControlName]="field.key" 
				type="text" 
			/>
			<!-- #endregion -->

			<!-- #region Percentage -->
			<input 
				*ngIf="field.customFieldMask === CustomFieldMaskEnum.Percentage"
				pcgPercentage 
				class="form-control" 
				pcgControlRef 
				[id]="field.key" 
				[formControlName]="field.key" 
				type="text" 
			/>
			<!-- #endregion -->

			<!-- #region NDC -->
			<input 
				*ngIf="field.customFieldMask === CustomFieldMaskEnum.Ndc" 
				pcgNumeric 
				class="form-control" 
				pcgControlRef [id]="field.key" 
				[formControlName]="field.key" 
				type="text" 
			/>
			<!-- #endregion -->
		</ng-container>
		<!-- #endregion -->
	</pcg-control-group>
</form>