<button 
	#btnColVis
	type="button"
	aria-label="Show or hide columns"	
	(keydown.escape)="colVisActive = false"
	(click)="colVisActive = !colVisActive"
	class="btn btn-sm btn-outline-secondary hide-on-print show-hide-btn"
>
	<fa-icon [icon]="faIconName.faEye"></fa-icon>
	Show / Hide
</button>

<div *ngIf="colVisActive" class="btn-collection" role="menu" [style.left]="btnColVis.offsetLeft + 'px'">
	<label *ngFor="let key of getColDefs()" class="btn-hide-show d-block btn" ngbButtonLabel [class.active]="columnDefs.get(key).isVisible">
		<span>{{ columnDefs.get(key).displayName || key }}</span>
		<input
			(keydown.escape)="colVisActive = false"
			type="checkbox"
			[(ngModel)]="columnDefs.get(key).isVisible"
			(change)="columnDefs.get(key).isVisible = $event.target.checked"
			ngbButton
		/>
	</label>
</div>

<div *ngIf="colVisActive" (click)="colVisActive = false" class="dt-button-background"></div>