// In order to keep core.module.ts as small as possible, router functionality is kept in this file.
// This is not done with other modules because it is conventient to have 'ng generate component' automatically add a component to
// the appropriate module by running it in that directory.

import { RouterModule, Routes } from '@angular/router';
import { QuicklinkStrategy } from 'ngx-quicklink';

import { AuthGuard } from './security/auth.guard';
import { DashboardComponent } from './pages/dashboard/dashboard.component';
import { Error404Component } from './pages/error404/error404.component';
import { LoginComponent } from './pages/login/login.component';
import { ForgotPasswordComponent } from './pages/forgot-password/forgot-password.component';
import { ChangePasswordComponent } from './pages/change-password/change-password.component';
import { EditUserComponent } from '../administration/users/edit-user/edit-user.component';
import { EditUserModule } from '../administration/users/edit-user/edit-user.module';
import { HelpFilesComponent } from '../help/help-files.component';
import { HelpVideosComponent } from '../help/help-videos/help-videos.component';
import { WebReleaseNotesComponent } from './pages/web-release-notes/web-release-notes.component';
import { MobileReleaseNotesComponent } from './pages/mobile-release-notes/mobile-release-notes.component';
import { SilkierHistoryComponent } from 'app/silkier/silkier-history/silkier-history.component';
import { ViewEulaFooterComponent} from  '../sysadmin/footer/view-eula-footer/view-eula-footer.component';
import { ViewPrivacyPolicyFooterComponent } from '../sysadmin/footer/view-privacy-policy-footer/view-privacy-policy-footer.component'
import { ViewTermsConditionsFooterComponent } from '../sysadmin/footer/view-terms-conditions-footer/view-terms-conditions-footer.component'

// This is the root of our navigation menu route definitions
const routes: Routes = [
	{ 
		path: ''
		, component: DashboardComponent
	}
	, { 
		path: 'dashboard'
		, component: DashboardComponent
		, canActivate: [ AuthGuard ] 
	}
	, { 
		path: 'login'
		, component: LoginComponent 
	}
	, { 
		path: 'web-release-notes'
		, component: WebReleaseNotesComponent
		, canActivate: [ AuthGuard ] 
	}
	, { 
		path: 'mobile-release-notes'
		, component: MobileReleaseNotesComponent
		, canActivate: [ AuthGuard ] 
	}
	,{
		path: 'eula'
		, component: ViewEulaFooterComponent
	}
	, { 
		path: 'privacy-policy'
		, component: ViewPrivacyPolicyFooterComponent 
	}
	, { 
		path: 'terms-conditions'
		, component: ViewTermsConditionsFooterComponent 
	} 	
	, { 
		path: 'administration/users/edit-user/details/:id'
		, component: EditUserComponent
		, canActivate: [ AuthGuard ] 
	}
	, { 
		path: 'forgot-password'
		, component: ForgotPasswordComponent
	}
	, { 
		path: 'change-password/:id'
		, component: ChangePasswordComponent 
	}
	, { 
		path: 'quartz/pcg-history'
		, component: SilkierHistoryComponent
		, canActivate: [ AuthGuard ]
	}
	, { 
		path: 'administration'
		, canLoad: [ AuthGuard ]
		, loadChildren: () => import('../administration/administration.module').then(m => m.AdministrationModule) 
	}
	, { 
		path: 'sysadmin'
		, canLoad: [ AuthGuard ]
		, loadChildren: () => import('../sysadmin/sysadmin.module').then(m => m.SysadminModule) 
	}
	, { 
		path: 'reports'
		, canLoad: [ AuthGuard ]
		, loadChildren: () => import('../reports/reports.module').then(m => m.ReportsModule) 
	}
	, { 
		path: 'forms'
		, canLoad: [ AuthGuard ]
		, loadChildren: () => import('../forms/forms.module').then(m => m.FormsModule) 
	}
	, { 
		path: 'help'
		, canActivate: [ AuthGuard ]
		, component: HelpFilesComponent 
	}
	, { 
		path: 'help/help-video/:id'
		, canActivate: [ AuthGuard ]
		, component: HelpVideosComponent 
	}
	, { 
		path: 'ndc-directory'
		, canActivate: [ AuthGuard ]
		, loadChildren: () => import('../administration/inventory/ndc-directory/ndc-directory.module').then(m => m.NdcDirectoryModule) 
	}
	, { 
		path: 'inventory'
		, canLoad: [ AuthGuard ]
		, loadChildren: () => import('../inventory/inventory.module').then(m => m.InventoryModule) 
	}
	, {
		path: 'shared'
		, canActivate: [ AuthGuard ]
		, canLoad: [ AuthGuard ]
		, loadChildren: () => import('../shared/shared.module').then(m => m.SharedModule)
	}
	, { 
		path: '**'
		, redirectTo: '' 
	}
];

export class CoreRouting {
	static declarations = [
		DashboardComponent
		, LoginComponent
		, Error404Component
		, ForgotPasswordComponent
		, ChangePasswordComponent
		, HelpFilesComponent
		, HelpVideosComponent
		, SilkierHistoryComponent
	];
	static imports = [
		RouterModule.forRoot(routes
			, {
				scrollPositionRestoration: 'enabled'
				, 	preloadingStrategy: QuicklinkStrategy
			}
		)
		, EditUserModule
	];
	static exports = [ RouterModule ];
	static providers = [ AuthGuard ];
}