import { HttpClient, HttpParams } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

import { SilkierExceptionMessageVm } from 'app/shared/generated/Silkier/Models/SilkierExceptionMessageVm'

@UntilDestroy()
@Component({
    selector: 'pcg-view-exception-modal',
    templateUrl: './view-exception-modal.component.html',
    styleUrls: ['./view-exception-modal.component.scss']
})
export class ViewExceptionModalComponent implements OnInit {
    id: number;
    exceptionMsg: string;

    constructor(public modal: NgbActiveModal, private httpClient: HttpClient) { }

    ngOnInit() { this.getException() }

    public getException() {
        const httpParams = new HttpParams().set('fireInstanceId', this.id);
        this.httpClient.get('api/Silkier/SilkierHistory/GetException', {
            params: httpParams,
        }).pipe(untilDestroyed(this)).subscribe((resp : SilkierExceptionMessageVm) => {
            if (resp != null) { this.exceptionMsg = resp.exceptionMessage; }
        })
    }
}
