import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { NgbPaginationModule, NgbTooltipModule } from '@ng-bootstrap/ng-bootstrap';
import { MatTableModule } from '@angular/material/table';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';

import { TableComponent } from './table.component';
import { SortModule } from './sort/sort.module';
import { TableTopComponent } from './table-top/table-top.component';
import { ButtonHideShowComponent } from './table-top/button-hide-show/button-hide-show.component';
import { EditLinkComponent } from './row-components/edit-link/edit-link.component';
import { DeleteButtonComponent } from './row-components/delete-button/delete-button.component';
import { EllipsisComponent } from './row-components/ellipsis/ellipsis.component';
import { ButtonFilterComponent } from './table-top/button-filter/button-filter.component';
import { ButtonResetFilterComponent } from './table-top/button-reset-filter/button-reset-filter.component';
import { TableFilterComponent } from './table-top/table-filter/table-filter.component';
import { TableTopTooltipComponent } from './table-top-tooltip/table-top-tooltip.component';

@NgModule({
	declarations: [ 
		TableComponent
		, TableTopComponent
		, ButtonHideShowComponent
		, ButtonFilterComponent
		, ButtonResetFilterComponent
		, EditLinkComponent
		, DeleteButtonComponent
		, EllipsisComponent
		, TableFilterComponent 
		, TableTopTooltipComponent
	]
	, imports: [
		CommonModule
		, FormsModule
		, RouterModule
		, NgbPaginationModule
		, SortModule
		, NgbTooltipModule
		, MatTableModule
		, MatButtonToggleModule
		, FontAwesomeModule
	]
	, exports: [ 
		TableComponent
		, MatTableModule
		, MatButtonToggleModule
		, SortModule
		, TableFilterComponent
		, TableTopTooltipComponent
		, EditLinkComponent
		, DeleteButtonComponent
		, EllipsisComponent 
	]
})
export class TableModule { }