import { Component, OnInit, Input, OnChanges, SimpleChanges, HostListener, Output, EventEmitter } from '@angular/core';
import { faArrowLeft } from '@fortawesome/pro-solid-svg-icons';
import { Router } from '@angular/router';

import { NavRoute } from '../nav.route.interface';
import { NavigationService, NavDividerCharacter } from '../navigation.service';
import { GlobalService } from 'app/shared/service/global.service';

@Component({
	selector: 'pcg-top-secondary-nav',
	templateUrl: './top-secondary-nav.component.html',
	styleUrls: ['./top-secondary-nav.component.scss']
})
export class TopSecondaryNavComponent implements OnInit, OnChanges {

	@Input() navRoutes: NavRoute[] = [];
	@Input() rootNavId: string;
	@Input() routeNavId: string;
	@Input() forceSelect: string;
	@Input() tabPanel: any;

	@Output() clickedNav = new EventEmitter<boolean>();

	loopNavRoutes: NavRoute[] = [];
	hrefLocation: string = ""

	faIconName = { faArrowLeft };

	isMobile: boolean;
	@HostListener('window:resize')
	onResize() { this.isMobile = GlobalService.setIsMobile(window.innerWidth); }

	constructor(
		public navService: NavigationService
		, public router: Router
	) { }

	ngOnInit() { 
		this.isMobile = GlobalService.setIsMobile(window.innerWidth);
		this.loopNavRoutes = this.getNavRoutes(); 
	}

	ngOnChanges(changes: SimpleChanges) {	
		this.getActiveLink();	
		const chng = changes['navRoutes'];
		const cur = chng.currentValue;
		const prev = chng.previousValue;
		if (cur !== prev) { this.loopNavRoutes = this.getNavRoutes(); }
	}

	// Show or hide submenu based on dropdown click
	clickDropdown(childMenuId: string, back = false) {
		if (back) {
			const regexp = new RegExp(`${NavDividerCharacter}[^${NavDividerCharacter}]+$`);
			childMenuId = childMenuId.replace(regexp, '');
		}
		this.navService.setCurrOpenNavMenu(childMenuId, true);
		return false;
	}

	redirect(route) { 
		if (!route.openNewTab) { this.router.navigateByUrl(route.path); }
		else { this.openInNewTab(route.path); }
	}

	openInNewTab(namedRoute: string) {
		let newRelativeUrl = this.router.createUrlTree([namedRoute]);
		let baseUrl = window.location.href.replace(this.router.url, '');
		window.open(baseUrl + newRelativeUrl, '_blank');
	}

	setClientAuditHistory(route: NavRoute) {
		if (route.path.includes("settings/audit-history")) {
			localStorage.setItem("vmType", "Clients.Models.ClientDetailVm");
			localStorage.setItem("auditName", "Client Detail");
		} else if (route.path.includes("integrations/audit-history")) {
			localStorage.setItem("vmType", "Administration.Models.Integrations.IntegrationApiClientVm");
			localStorage.setItem("auditName", "Integration Detail");
		}
	}

	getActiveLink() {
		// parse URL for active link in subnav
		let urlSegments = window.location.href.split(document.getElementsByTagName('base')[0].href)[1].split('/');
		if (
			!isNaN(Number(urlSegments[urlSegments.length - 1])) 
			&& !isNaN(Number(urlSegments[urlSegments.length - 2]))
		) {
			this.hrefLocation = urlSegments.slice(0, urlSegments.length - 2).join("/");
			if (this.hrefLocation.includes("/shift-change-edit")) { 
				this.hrefLocation = "inventory/shift-change"; 
			}
			if (this.hrefLocation.includes("inventory/edit-dea-form")) { 
				this.hrefLocation = "inventory/dea-form"; 
			}			
			if (this.hrefLocation.includes("inventory/edit-return-log")) { 
				this.hrefLocation = "inventory/return-log"; 
			}
			if (this.hrefLocation.includes("/replenishment-audit-history")) { 
				this.hrefLocation = "inventory/replenishment"; 
			}
			if (this.hrefLocation.includes("/inventory/edit-action-field")) { 
				this.hrefLocation = "administration/inventory/inventory-actions"; 
			}
		} else if (
			!isNaN(Number(urlSegments[urlSegments.length - 1])) 
			&& isNaN(Number(urlSegments[urlSegments.length - 2]))
		) {
			this.hrefLocation = urlSegments.slice(0, urlSegments.length - 1).join("/"); 
			if (this.hrefLocation.includes("/transaction-history")) { 
				this.hrefLocation = "inventory/site-transaction-history"; 
			}
			if (this.hrefLocation.includes("ndc-directory/edit-global-ndc")) { 
				this.hrefLocation = "ndc-directory/edit-global-ndc"; 
			}
			if (this.hrefLocation.includes("administration/users/edit-user-custom-field")) { 
				this.hrefLocation = "administration/users/user-custom-fields"; 
			}
			if (this.hrefLocation.includes("administration/inventory/edit-inventory-custom-field")) { 
				this.hrefLocation = "administration/inventory/inventory-custom-fields"; 
			}
			if (this.hrefLocation.includes("forms/edit-ny-form-3848")) { 
				this.hrefLocation = "forms/ny-form-3848"; 
			}
			if (this.hrefLocation.includes("forms/edit-biennial-inventory-report")) { 
				this.hrefLocation = "forms/biennial-inventory-report"; 
			}
		} else { this.hrefLocation = urlSegments.join("/"); }

		if (this.hrefLocation.includes("?medUse=true")) { 
			this.hrefLocation = "inventory/inventory-actions"; 
		}
	}

	getNavRoutes() {
		const routeIndex = this.navService.getCurrRouteIndex(this.navRoutes);
		return this.navRoutes?.map((o, i) => {
			return { ...o, index: i };
		}).filter(route => 
			!route?.shouldDisplay 
			|| route.ellipseAfter 
			|| route.ellipseBefore 
			|| route?.shouldDisplay(routeIndex)
		) ?? [];
	}
}