/* eslint-disable @typescript-eslint/dot-notation */
import { Component, ContentChild, HostBinding, AfterContentInit, Input } from '@angular/core';
import { faInfoCircle } from '@fortawesome/pro-solid-svg-icons';

import { ControlRefDirective } from '../../directives/control-ref.directive';

// This component was originally copied from here:
// https://www.codeproject.com/Articles/1239543/Angular-Custom-Validation-Component
@Component({
	selector: 'pcg-control-group',
	templateUrl: './control-group.component.html',
	styleUrls: ['./control-group.component.scss'],
})
export class ControlGroupComponent implements AfterContentInit {

	@Input() label: string;
	@Input() labelTooltip: any;
	@Input() validations: { [index: string]: string } = {};
	@Input() info: string;
	@Input() hideRequired: boolean;

	@ContentChild(ControlRefDirective) control: ControlRefDirective;
	@HostBinding('class.form-group') isFormGroup = true;

	faIconName = { faInfoCircle };

	isRequired = false;
	controlId = '';

	constructor() {}

	get isError() { return this.control?.hasError; }

	get errorMessages() {
		const errors = this.control.errors;
		const messages = [];
		if (errors['required']) {
			messages.push(this.validations['required'] ? this.validations['required'] : 'Required.');
		} else if (errors['minlength']) {
			messages.push(
				this.validations['minlength']
					? this.validations['minlength']
					: `The minimum length is ${errors['minlength']['requiredLength']}.`
			);
		} else if (errors['maxlength']) {
			messages.push(
				this.validations['maxlength']
					? this.validations['maxlength']
					: `The maximum length is ${errors['maxlength']['requiredLength']}.`
			);
		} else if (errors['max']) {
			messages.push(this.validations['max'] ? this.validations['max'] : `The maximum value is ${errors['max']['max']}.`);
		} else if (errors['min']) {
			messages.push(this.validations['min'] ? this.validations['min'] : `The minimum value is ${errors['min']['min']}.`);
		} else if (errors['email']) {
			messages.push(this.validations['email'] ? this.validations['email'] : 'Not a valid email address.');
		} else if (errors['pattern']) {
			messages.push(this.validations['pattern'] ? this.validations['pattern'] : 'Not valid.');
		} else if (this.validations) {
			const keys = Object.keys(this.validations);
			keys.forEach(key => { if (errors[key]) { messages.push(this.validations[key]); } });
		}

		return messages;
	}

	ngAfterContentInit() {
		this.isRequired = this.control?.isRequired;
		this.controlId = this.control?.id;
	}
}
