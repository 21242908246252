import { Injectable } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { timer } from 'rxjs';
import { addMinutes, addSeconds } from 'date-fns';
import { CheckNetworkService } from '../../../local-db/services/check-network.service'

import { InactivityTimeoutComponent } from './inactivity-timeout.component';
import { SecurityService } from '../security/security.service';

@Injectable({
	providedIn: 'root'
})
export class InactivityTimeoutService {

	modalOpen = false;

	constructor(
		private modal: NgbModal
		, private sec: SecurityService
	) { this.startTimer(); }

	startTimer() {
		const source = timer(0, 1000);
		let isOnline = CheckNetworkService.checkNetworkStatus();
		source.subscribe(() => {
			const user = this.sec.getUser();
			if (
				user 
				&& user.inactivityTimeout
				&& isOnline
			) {
				if (localStorage.getItem('lastAccess')) {
					const lastAccessedDate = new Date(JSON.parse(localStorage.getItem('lastAccess')));
					// start a 30 second countdown timer at 4 min 30 seconds left in our 5 min timout window to display in the modal
					const endDate = addSeconds(addMinutes(lastAccessedDate, 4), 30); 
					if ((new Date()) >= endDate) {
						if (!this.modalOpen) {
							this.modalOpen = true;
							this.modal.dismissAll();
							this.modal.open(
								InactivityTimeoutComponent
								, { 
									beforeDismiss: () => { 
										this.modalOpen = false; 
										return true; 
									} 
								}
							);
						}
					}
				} else { this.sec.setSecurity(null, null, null); }
			}
		});
	}
}
