import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

@Injectable({
	  providedIn: 'root',
})
export class PermissionService {
	  constructor(private httpClient: HttpClient) {}

	  getPermissions(method) {
		    return this.httpClient.get('api/Administration/Permission/GetPermissionVms').subscribe(method);
	  }
}
