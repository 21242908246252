import { Injectable } from '@angular/core';
import { AppDatabase } from '../../local-db/services/init.ldb.service';

@Injectable({
  providedIn: 'root'
})
export class SetLocaldbTablesService {

  constructor(private localDb: AppDatabase) { }

  // since the VMs and localDb tables are exact matches from codeGen project, 
    // data can be passed in as formGroup.value or some replica of that object   
    setLocalDbTable(
      tableName: string
      , data: any
      , isUpdatedOffline: boolean
      , setOfflineId: boolean = false
  ) {
      var obj = {};

      this.localDb.table(tableName).schema.indexes.map(o => {
          obj[this.localDb.table(tableName).schema.primKey.name] = data[this.localDb.table(tableName).schema.primKey.name];
          obj[o.name] = data[o.name];
          obj["isUpdatedOffline"] = isUpdatedOffline;
          if (setOfflineId) { obj["offlineId"] = crypto.randomUUID(); }
      });

      this.localDb.table(tableName).put(obj, obj[this.localDb.table(tableName).schema.primKey.name]);
  }
}
