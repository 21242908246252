import { AfterContentChecked, Component, ElementRef, HostListener, Inject, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { NgbModalConfig } from '@ng-bootstrap/ng-bootstrap';
import { 
	faAngleDown
	, faUser
	, faGear
	, faGears
	, faQuestionCircle
	, faClipboardList
	, faShelves
	, faStaffSnake
	, faBarcodeRead 
	, faPenField
} from '@fortawesome/pro-solid-svg-icons';
import { Subscription } from 'rxjs';
import { SwUpdate } from '@angular/service-worker';
import Rollbar from 'rollbar';
import { RollbarService } from '../rollbar_config';
import { datadogLogs } from '@datadog/browser-logs';
import { User as ddUser } from '@datadog/browser-core';
import { SetLocaldbTablesService } from '../app/services/set-localdb-tables.service'
import { HttpClient } from '@angular/common/http';

import { GlobalService } from './shared/service/global.service';
import { SecurityService } from './core/security/security.service';
import { UserJsVm } from './shared/generated/Administration/Models/UserJsVm';
import { pcgSettings } from './shared/generated/pcg-settings';
import { NavigationService } from './shared/navigation/navigation.service';
import { SystemMessageService } from './core/system-message/system-message-service';
import { GlobalVariablesService } from './services/global-variables.service';
import { AppDatabase } from 'local-db/services/init.ldb.service';
import { PermissionProfileEnum } from './core/enums/generated/PermissionProfileEnum';
import { CheckForUpdateService } from './services/check-for-update.service';

/**
 * This is the application's root component.
 */
@Component({
	selector: 'pcg-root',
	templateUrl: './app.component.html',
	styleUrls: ['./app.component.scss'],
	providers: [ GlobalVariablesService ]
})
export class AppComponent implements OnInit, AfterContentChecked, OnDestroy {

	@ViewChild('tabPanel') tabPanel: any;

	updateCheckText = '';
	fileLogoUrl = "../assets/images/logos/logo_small.png";
	errorCache: any = {};
	footerType: string;    

	// The following are error codes thrown as a result of system security
	// and should not be logged to rollbar.
	ignoredErrorCodes: number[] = [
		0 		// Network Error / Aborted Request
		, 401 	// Login redirect
		, 403 	// Access denied
		, 467	// 2fa not enabled
		, 499	// First time Inventory Setup custom redirect
	];

	isMobile: boolean;
	@HostListener('window:resize')
	onResize() { 
		this.isMobile = GlobalService.setIsMobile(window.innerWidth); 
		if (this.isMobile) {
			localStorage.setItem("isNavFixed", "false"); 
			this.isNavFixed = false;
			this.navOpened = false;
		}
	}

	@HostListener("window:online", ["$event"])
	onlineEvent(event) { this.uploadUsersFromLocalDb(event); }

	// Nav
	navOpened: boolean;
	isNavFixed = false;
	isLoggedOn: boolean;
	onChangePasswordScreen: boolean;
	mode = pcgSettings.mode; //for responsive BETA text

	// My Profile
	user: UserJsVm;

	// Fa icons
	faIconName = { 
		faAngleDown
		, faShelves
		, faClipboardList
		, faGear
		, faGears
		, faUser
		, faQuestionCircle
		, faStaffSnake
		, faBarcodeRead
		, faPenField
	};

	// For nav selected items
	inventoriesSelected: boolean;
	reportsSelected: boolean;
	adminSelected: boolean;
	sysAdminSelected: boolean;
	helpSelected: boolean;
	userProfileSelected: boolean;
	searchCvSelected: boolean;
	formsSelected: boolean;
	isSysAdmin = false;
    isAdmin = false;
    isManager = false;
    isTechnician = false;

	// Inactivity timeout needs to be injected here to have a constant timeout going in the background
	constructor(
		private swUpdate: SwUpdate
		, private router: Router
		, private sec: SecurityService
		, private modalConfig: NgbModalConfig
		, private elRef: ElementRef
		, private navService: NavigationService
		, private ms: SystemMessageService
		, public globalService: GlobalService
		, public globalVariablesService: GlobalVariablesService
		, private localDb: AppDatabase
		, private localDbService: SetLocaldbTablesService
		, private http: HttpClient
		, private sw: CheckForUpdateService
		, @Inject(RollbarService) private rollbar: Rollbar
	) {
		this.updateCheck();
		this.configureDatadog();
		this.configureRollbar(); // Set global rollbar settings
		this.configureModal(); // Set default modal settings
		this.setIsLoggedInHtmlClass(); // Handle is-logged-in HTML class

		// Dirty AngularJS style global router used in index.html
		window['$router'] = router;
	}

	ngOnInit() { 
		this.isMobile = GlobalService.setIsMobile(window.innerWidth);
		// Check isNavFixed global variable on init for pages without sidenav
		this.globalVariablesService.isNavFixed.subscribe((data) => { 
			if (
				data !== null 
				&& typeof data !== "undefined"
			) {
				this.isNavFixed = data;
				this.navOpened = data;
			} 
		})
		this.sec.user$.subscribe(user => { this.user = user; }); // For 'My Profile' nav functionality
		this.router.events.subscribe(() => { this.parseUrl(this.router.url); }); 
		this.badBrowserCheck(); 
		this.onChangePasswordScreen = window.location.href.includes("code=fromEmail");

		// Remember if nav is fixed
		if (this.isMobile) { 
			localStorage.setItem("isNavFixed", "false"); 
			this.isNavFixed = false;
			this.navOpened = false;
		} else {
			if (localStorage.getItem("isNavFixed") === "true") {
				this.isNavFixed = true;
				this.navOpened = true;
			} else {
				this.isNavFixed = false;
				this.navOpened = false;
			}			
		}

		if (window.location.href.includes("code=fromEmail")) {
			this.isNavFixed = false;
			this.navOpened = false;
		}
		this.globalVariablesService.onChangePasswordScreen.subscribe(data => {
			if (
				data !== null 
				&& typeof data !== "undefined"
			) { this.onChangePasswordScreen = data; }
		});
	}

	ngAfterContentChecked() {
        this.isSysAdmin = this.sec.hasAccess(o => o.permissionProfile == PermissionProfileEnum.SysAdmin);
        this.isAdmin = this.sec.hasAccess(o => o.permissionProfile == PermissionProfileEnum.Admin);
        this.isManager = this.sec.hasAccess(o => o.permissionProfile == PermissionProfileEnum.Manager);
        this.isTechnician = this.sec.hasAccess(o => o.permissionProfile == PermissionProfileEnum.Technician);
		if (
			this.user?.clientLogoFilename !== "" 
			&& this.user?.clientLogoFilename !== undefined 
			&& this.user?.clientLogoFilename !== null
		) { this.fileLogoUrl = this.user?.clientLogoFilePath; }
    }

	ngDoCheck() { this.isLoggedOn = this.sec.isLoggedOn(); }

	updateCheck(): void {
		this.sw.checkForUpdates();
		/* this.swUpdate.checkForUpdate()
			.then(() => this.updateCheckText = 'resolved')
			.catch(err => this.updateCheckText = `rejected: ${err.message}`); */
	}

	/** Set default ng-bootstrap modal options */
	configureModal() {
		// Disable ng bootstrap modal animations by default
		this.modalConfig.animation = false;
		this.modalConfig.ariaLabelledBy = 'modal-basic-title';
	}

	/** Configure rollbar throughout the website */
	configureRollbar() {
		this.rollbar.configure({
			// Set initial user on load (updated in subscription below)
			payload: this.getRollbarPayload(this.sec.getUser())
			, checkIgnore: (uncaught, args, item) => {
				// Prevent duplicate message/URL combinations for the session
				// Don't want to flood rollbar with errors if Angular starts looping
				// an error message.
				const theItem = item as any;
				const errorKey = (theItem?.body?.message?.body ?? '') 
					+ (theItem?.body?.message?.extra?.url?.split('?')[0] ?? '') 
					+ window.location.href;
				if (this.errorCache[errorKey]) { return true; }
				// Do not report these errors as they are results of intented system behavior.
				if (this.ignoredErrorCodes.includes(theItem?.body?.message?.extra?.status)) { return true; }
				this.errorCache[errorKey] = true;

				// Prevent errors in local development
				if (pcgSettings.mode === 'Development') { return true; }

				return false;
			}
		});

		// Update user if it changes
		this.sec.user$.subscribe(newUser => { this.rollbar.configure({ payload: this.getRollbarPayload(newUser) }); });
	}

	/**
	 * Creates a rollbar payload with user added
	 * 
	 * @param user The user to add
	 */
	getRollbarPayload(user: UserJsVm): Rollbar.Payload {
		return {
			environment: pcgSettings.mode
			, client: {
				javascript: {
					source_map_enabled: true // true by default
					, code_version: '33d2516'
					, guess_uncaught_frames: true
				}
			}
			, person: !user
				? undefined
				: {
					id: user.id.toString()
					, username: user.userName
					, email: user.email
				}
		};
	}

	/** Sets the is-logged-in HTML class, which is used to hide the release notes links */
	setIsLoggedInHtmlClass() {
		// Add / remove is-logged-in class
		this.sec.user$.subscribe(user => {
			const isLoggedIn = user ? true : false;
			if (isLoggedIn) { this.elRef.nativeElement.classList.add('is-logged-in'); } 
			else { this.elRef.nativeElement.classList.remove('is-logged-in'); }
		});
	}

	myBrowser() {
		if (
			(
				navigator.userAgent.indexOf('Opera') 
				|| navigator.userAgent.indexOf('OPR')
			) !== -1
		) { return 'Opera'; } 
		else if (navigator.userAgent.indexOf('Chrome') !== -1) { return 'Chrome'; } 
		else if (navigator.userAgent.indexOf('Safari') !== -1) { return 'Safari'; } 
		else if (navigator.userAgent.indexOf('Firefox') !== -1) { return 'Firefox'; } 
		else if (
			navigator.userAgent.indexOf('MSIE') !== -1 
			|| navigator.userAgent.indexOf('trident') !== -1
		) { return 'IE'; } 
		else { return 'unknown'; }
	}

	getBrowserVersion() {
		const userAgent = navigator.userAgent;
		let tem: any[];
		let matchTest = userAgent.match(/(opera|chrome|safari|firefox|msie|trident(?=\/))\/?\s*(\d+)/i) || [];

		if (/trident/i.test(matchTest[1])) {
			tem = /\brv[ :]+(\d+)/g.exec(userAgent) || [];
			return 'IE ' + (tem[1] || '');
		}
		if (matchTest[1] === 'Chrome') {
			tem = userAgent.match(/\b(OPR|Edge)\/(\d+)/);
			if (tem != null) { return tem.slice(1).join(' ').replace('OPR', 'Opera'); }
		}
		matchTest = matchTest[2] 
			? [matchTest[1], matchTest[2]] 
			: [navigator.appName, navigator.appVersion, '-?'];
		if ((tem = userAgent.match(/version\/(\d+)/i)) != null) { matchTest.splice(1, 1, tem[1]); }
		return matchTest.join(' ');
	}

	badBrowserCheck() {
		if (
			navigator.userAgent.indexOf('MSIE') !== -1 
			|| navigator.userAgent.indexOf('trident') !== -1
		) {
			this.ms.setSystemMessage(
				'Internet Explorer (IE) is an unsupported browser. Please use a supported browser.'
				, 'error'
			);
		}
	}

	setNavOpened(event: boolean) { 
		this.navOpened = event; 
		this.navService.navOpened.next(this.navOpened); 
	}

	onNavOpenedChange(val: boolean) { 
		this.navOpened = val;
		this.navService.navOpened.next(val); 
	}

	setNavFixed(event: boolean) { this.isNavFixed = event; }

	// Logic for active page indication
	parseUrl(url: string) {
		this.inventoriesSelected = false;
        this.reportsSelected = false;
        this.adminSelected = false;
		this.sysAdminSelected = false;
		this.searchCvSelected = false;
        this.helpSelected = false;
        this.userProfileSelected = false;
		this.formsSelected = false;
		var id: string = "";

		if (url.indexOf('users/edit-user/') > -1) { id = url.substring(url.lastIndexOf('/') + 1); }
		if (
			url.includes("inventory") 
			&& !url.includes("reports") 
			&& !url.includes("administration")
		) { this.inventoriesSelected = true; }
		if (url.includes("reports")) { this.reportsSelected = true; }
		if (url.includes("forms") && !url.includes("sysadmin/drug-forms")) { 
			this.formsSelected = true; 
			this.inventoriesSelected = false; 
			this.reportsSelected = false; 
		}
		if (url.includes("control-value-report")) { 
			this.searchCvSelected = true; 
			this.reportsSelected = false; 
		}
		if (
			(
				url.includes("administration") 
				|| url.includes("suppliers")
			) 
			&& this.user?.id.toString() !== id
		) { this.adminSelected = true; }
		if (
			(
				url.includes("sysadmin")
			)
		) {this.sysAdminSelected = true; }
		if (
			url.includes("edit-user") 
			&& this.user?.id.toString() === id
		) { this.userProfileSelected = true; }
		if (
			url.includes("help") 
			|| url.includes("web-release-notes") 
			&& !url.includes("release-notes/")
		) { this.helpSelected = true; }
	}

	toReleaseNotes() { this.router.navigateByUrl("/web-release-notes"); }

	configureDatadog() {
		if (pcgSettings.mode.toLowerCase() !== 'development') {
			datadogLogs.setGlobalContext({ env: 'S2' + pcgSettings.mode });
			datadogLogs.setGlobalContextProperty('referrer', document.referrer);
			datadogLogs.logger.setLevel('warn');

			this.initDataDog();
			datadogLogs.setUser(this.getDatadogUser(this.sec.getUser()));
	
			this.sec.user$.subscribe(newUser => { 				
				this.initDataDog();
				datadogLogs.setUser(this.getDatadogUser(newUser));
			});
		}
	}

	initDataDog() {
		try {
			datadogLogs.clearUser();
			const initConfig = datadogLogs.getInitConfiguration();
			if (initConfig === null || initConfig === undefined) { // stop datadog init if it is already initialized
				datadogLogs.init({
					beforeSend: (log) => {
						// discard ignored error codes
						if (this.ignoredErrorCodes.includes(log?.http?.status_code)) { return false; }
					}
					, clientToken: "pubb88d62dcd0ff96b9521be101ea167e0c"
					, trackingConsent: 'granted'
					, site: "us5.datadoghq.com"
					, service: "S2" + pcgSettings.mode
					, version: "33d2516"
					, env: "S2" + pcgSettings.mode
					, forwardErrorsToLogs: true
					, sessionSampleRate: 100			
				});
				console.log("datadogLogs initialized!");
			} 
		} catch (ex) { 
			console.error('Failed to initialize datadog:', ex); 
		}
	}

	getDatadogUser(userJs: UserJsVm): ddUser {
		let ddUser : ddUser = { 
			id: userJs?.id?.toString()
			, email: userJs?.email
			, name: `${userJs?.fullName}` 
		}
		return ddUser;
	}

	uploadUsersFromLocalDb(event) { // ran when user comes back online
		if (event.type == "online") {
			this.localDb.table("EditUserTbl").toArray()
				.then((response) => { 
					let filteredResponse = response.filter(o => o.isUpdatedOffline);
					if (filteredResponse.length > 0) { // means there are entries that have been updated
						for (let x = 0; x < filteredResponse.length; x++) {
							this.http.post("api/Administration/Users/SaveUser", filteredResponse[x])
								.subscribe((response: any) => { 
									this.localDbService.setLocalDbTable("EditUserTbl", response.model, false);
								}
							)
						}
					}
				}
			);
		}
	}

	ngOnDestroy(): void { 
		this.isAdmin = false;
		this.isSysAdmin = false;
		this.isManager = false;
		this.isTechnician = false;
	}
}
