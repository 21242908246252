import { UntypedFormGroup, UntypedFormControl, UntypedFormArray, Validators } from '@angular/forms';

import { PcgTableColumn } from '../../table/pcg-table-column.interface';

// tslint:disable:quotemark
// tslint:disable:max-line-length
// tslint:disable:whitespace
export class LoginVm {
	userName?: string;
	password?: string;
	userGuid?: string;
	clientId?: number;
	fromIntegration?: boolean;

	static get FormData() {
		return this.getFormData();
	}

	static getFormData(validators = {}) {
		return {
			'userName': new UntypedFormControl(null, [...(validators['userName'] ?? []), ...[Validators.required]]),
			'password': new UntypedFormControl(null, [...(validators['password'] ?? []), ...[Validators.required]]),
			'userGuid': new UntypedFormControl(null, [...(validators['userGuid'] ?? []), ...[]]),
			'clientId': new UntypedFormControl(null, [...(validators['clientId'] ?? []), ...[]]),
			'fromIntegration': new UntypedFormControl(null, [...(validators['fromIntegration'] ?? []), ...[]])
		};
	}

	static get Form() {
		return new UntypedFormGroup(this.FormData);
	}

	static GetModel(fg: UntypedFormGroup): LoginVmFromFg {
		return new LoginVmFromFg(fg);
	}
}

export class LoginVmFromFg {
	constructor(public fg: UntypedFormGroup) { }


	get userName(): string {
		return this.fg.get('userName').value;
	}
	set userName(val) {
		this.fg.get('userName').setValue(val);
	}

	get password(): string {
		return this.fg.get('password').value;
	}
	set password(val) {
		this.fg.get('password').setValue(val);
	}

	get userGuid(): string {
		return this.fg.get('userGuid').value;
	}
	set userGuid(val) {
		this.fg.get('userGuid').setValue(val);
	}

	get clientId(): number {
		return this.fg.get('clientId').value;
	}
	set clientId(val) {
		this.fg.get('clientId').setValue(val);
	}

	get fromIntegration(): boolean {
		return this.fg.get('fromIntegration').value;
	}
	set fromIntegration(val) {
		this.fg.get('fromIntegration').setValue(val);
	}
}
