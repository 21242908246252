<div *ngIf="isLoading" class="spinner-background fade-in">
	<svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"
		 width="50px" height="50px" viewBox="0 0 50 50" enable-background="new 0 0 50 50" xml:space="preserve">
		<g>
			<circle cx="25.618" cy="14.907" r="3.082"/>
			<circle cx="25.618" cy="36.326" r="1.849"/>
			<circle cx="18.044" cy="18.043" r="2.774"/>
			<circle cx="33.19" cy="33.188" r="1.541"/>
			<circle cx="14.907" cy="25.617" r="2.465"/>
			<circle cx="36.326" cy="25.616" r="1.232"/>
			<path d="M16.519,31.665c-0.843,0.843-0.843,2.207,0,3.05c0.842,0.843,2.208,0.843,3.05,0c0.843-0.843,0.843-2.207,0-3.05
				C18.727,30.821,17.362,30.814,16.519,31.665z"/>
			<circle cx="33.189" cy="18.043" r="0.924"/>
		</g>
	</svg>
</div>
