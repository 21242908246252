import { UntypedFormGroup, UntypedFormControl, UntypedFormArray, Validators } from '@angular/forms';

import { PcgTableColumn } from '../../../table/pcg-table-column.interface';

// tslint:disable:quotemark
// tslint:disable:max-line-length
// tslint:disable:whitespace
export class SilkierHistoryVm {
	fireInstanceId: number;
	schedulerInstanceId?: string;
	schedulerName?: string;
	job?: string;
	trigger?: string;
	scheduledFireTimeUtc?: Date;
	scheduledFireTimeUtcStr?: string;
	actualFireTimeUtc: Date;
	actualFireTimeUtcStr?: string;
	recovering: boolean;
	finishedTimeUtc?: Date;
	finishedTimeUtcStr?: string;
	logMessage?: string;
	exceptionMessage?: string;
	wasSuccessful?: boolean;

	static get FormData() {
		return this.getFormData();
	}

	static getFormData(validators = {}) {
		return {
			'fireInstanceId': new UntypedFormControl(0, [...(validators['fireInstanceId'] ?? []), ...[Validators.required]]),
			'schedulerInstanceId': new UntypedFormControl(null, [...(validators['schedulerInstanceId'] ?? []), ...[Validators.maxLength(50), Validators.minLength(0)]]),
			'schedulerName': new UntypedFormControl(null, [...(validators['schedulerName'] ?? []), ...[Validators.maxLength(50), Validators.minLength(0)]]),
			'job': new UntypedFormControl(null, [...(validators['job'] ?? []), ...[Validators.maxLength(200), Validators.minLength(0)]]),
			'trigger': new UntypedFormControl(null, [...(validators['trigger'] ?? []), ...[Validators.maxLength(200), Validators.minLength(0)]]),
			'scheduledFireTimeUtc': new UntypedFormControl(null, [...(validators['scheduledFireTimeUtc'] ?? []), ...[]]),
			'scheduledFireTimeUtcStr': new UntypedFormControl(null, [...(validators['scheduledFireTimeUtcStr'] ?? []), ...[]]),
			'actualFireTimeUtc': new UntypedFormControl('', [...(validators['actualFireTimeUtc'] ?? []), ...[Validators.required]]),
			'actualFireTimeUtcStr': new UntypedFormControl(null, [...(validators['actualFireTimeUtcStr'] ?? []), ...[]]),
			'recovering': new UntypedFormControl(false, [...(validators['recovering'] ?? []), ...[]]),
			'finishedTimeUtc': new UntypedFormControl(null, [...(validators['finishedTimeUtc'] ?? []), ...[]]),
			'finishedTimeUtcStr': new UntypedFormControl(null, [...(validators['finishedTimeUtcStr'] ?? []), ...[]]),
			'logMessage': new UntypedFormControl(null, [...(validators['logMessage'] ?? []), ...[]]),
			'exceptionMessage': new UntypedFormControl(null, [...(validators['exceptionMessage'] ?? []), ...[]]),
			'wasSuccessful': new UntypedFormControl(null, [...(validators['wasSuccessful'] ?? []), ...[]])
		};
	}

	static get Form() {
		return new UntypedFormGroup(this.FormData);
	}

	static get ColumnDefs(): Map<string, PcgTableColumn> {
		return new Map([
			['schedulerName', {"orderId":0,"sortColumn":"schedulerName","searchColumn":"schedulerName","includeInExcel":true,"exportColumn":null,"displayName":"Scheduler Name","isVisible":false,"canSearch":true,"isNdc":false,"isNdc10":false,"multiSearchCellClasses":null}],
			['job', {"orderId":1,"sortColumn":"job","searchColumn":"job","includeInExcel":true,"exportColumn":null,"displayName":"Job","isVisible":true,"canSearch":true,"isNdc":false,"isNdc10":false,"multiSearchCellClasses":null}],
			['trigger', {"orderId":2,"sortColumn":"trigger","searchColumn":"trigger","includeInExcel":true,"exportColumn":null,"displayName":"Trigger","isVisible":false,"canSearch":true,"isNdc":false,"isNdc10":false,"multiSearchCellClasses":null}],
			['scheduledFireTimeUtcStr', {"orderId":3,"sortColumn":"scheduledFireTimeUtc","searchColumn":"scheduledFireTimeUtcStr","includeInExcel":true,"exportColumn":null,"displayName":"Scheduled Fire Time","isVisible":true,"canSearch":true,"isNdc":false,"isNdc10":false,"multiSearchCellClasses":null}],
			['actualFireTimeUtcStr', {"orderId":4,"sortColumn":"actualFireTimeUtc","searchColumn":"actualFireTimeUtcStr","includeInExcel":true,"exportColumn":null,"displayName":"Actual Fire Time","isVisible":true,"canSearch":true,"isNdc":false,"isNdc10":false,"multiSearchCellClasses":null}],
			['recovering', {"orderId":5,"sortColumn":"recovering","searchColumn":"recovering","includeInExcel":true,"exportColumn":null,"displayName":"Recovering","isVisible":false,"canSearch":true,"isNdc":false,"isNdc10":false,"multiSearchCellClasses":null}],
			['finishedTimeUtcStr', {"orderId":6,"sortColumn":"finishedTimeUtc","searchColumn":"finishedTimeUtcStr","includeInExcel":true,"exportColumn":null,"displayName":"Finished Time","isVisible":true,"canSearch":true,"isNdc":false,"isNdc10":false,"multiSearchCellClasses":null}],
			['logMessage', {"orderId":7,"sortColumn":"logMessage","searchColumn":"logMessage","includeInExcel":true,"exportColumn":null,"displayName":"Log","isVisible":true,"canSearch":false,"isNdc":false,"isNdc10":false,"multiSearchCellClasses":null}],
			['exceptionMessage', {"orderId":8,"sortColumn":"exceptionMessage","searchColumn":"exceptionMessage","includeInExcel":true,"exportColumn":null,"displayName":"Exception","isVisible":true,"canSearch":false,"isNdc":false,"isNdc10":false,"multiSearchCellClasses":null}]
		]);
	}

	static GetModel(fg: UntypedFormGroup): SilkierHistoryVmFromFg {
		return new SilkierHistoryVmFromFg(fg);
	}
}

export class SilkierHistoryVmFromFg {
	constructor(public fg: UntypedFormGroup) { }


	get fireInstanceId(): number {
		return this.fg.get('fireInstanceId').value;
	}
	set fireInstanceId(val) {
		this.fg.get('fireInstanceId').setValue(val);
	}

	get schedulerInstanceId(): string {
		return this.fg.get('schedulerInstanceId').value;
	}
	set schedulerInstanceId(val) {
		this.fg.get('schedulerInstanceId').setValue(val);
	}

	get schedulerName(): string {
		return this.fg.get('schedulerName').value;
	}
	set schedulerName(val) {
		this.fg.get('schedulerName').setValue(val);
	}

	get job(): string {
		return this.fg.get('job').value;
	}
	set job(val) {
		this.fg.get('job').setValue(val);
	}

	get trigger(): string {
		return this.fg.get('trigger').value;
	}
	set trigger(val) {
		this.fg.get('trigger').setValue(val);
	}

	get scheduledFireTimeUtc(): Date {
		return this.fg.get('scheduledFireTimeUtc').value;
	}
	set scheduledFireTimeUtc(val) {
		this.fg.get('scheduledFireTimeUtc').setValue(val);
	}

	get scheduledFireTimeUtcStr(): string {
		return this.fg.get('scheduledFireTimeUtcStr').value;
	}
	set scheduledFireTimeUtcStr(val) {
		this.fg.get('scheduledFireTimeUtcStr').setValue(val);
	}

	get actualFireTimeUtc(): Date {
		return this.fg.get('actualFireTimeUtc').value;
	}
	set actualFireTimeUtc(val) {
		this.fg.get('actualFireTimeUtc').setValue(val);
	}

	get actualFireTimeUtcStr(): string {
		return this.fg.get('actualFireTimeUtcStr').value;
	}
	set actualFireTimeUtcStr(val) {
		this.fg.get('actualFireTimeUtcStr').setValue(val);
	}

	get recovering(): boolean {
		return this.fg.get('recovering').value;
	}
	set recovering(val) {
		this.fg.get('recovering').setValue(val);
	}

	get finishedTimeUtc(): Date {
		return this.fg.get('finishedTimeUtc').value;
	}
	set finishedTimeUtc(val) {
		this.fg.get('finishedTimeUtc').setValue(val);
	}

	get finishedTimeUtcStr(): string {
		return this.fg.get('finishedTimeUtcStr').value;
	}
	set finishedTimeUtcStr(val) {
		this.fg.get('finishedTimeUtcStr').setValue(val);
	}

	get logMessage(): string {
		return this.fg.get('logMessage').value;
	}
	set logMessage(val) {
		this.fg.get('logMessage').setValue(val);
	}

	get exceptionMessage(): string {
		return this.fg.get('exceptionMessage').value;
	}
	set exceptionMessage(val) {
		this.fg.get('exceptionMessage').setValue(val);
	}

	get wasSuccessful(): boolean {
		return this.fg.get('wasSuccessful').value;
	}
	set wasSuccessful(val) {
		this.fg.get('wasSuccessful').setValue(val);
	}
}
