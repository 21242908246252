import { Component } from '@angular/core';
import { Title } from '@angular/platform-browser';

import { FooterVm } from 'app/shared/generated/Administration/Models/FooterVm';
import { FooterService } from '../footer.service';
import { FooterTypeEnum } from '../../../core/enums/generated/FooterTypeEnum';

@Component({
    selector: 'pcg-view-privacy-policy-footer',
    templateUrl: './view-privacy-policy-footer.component.html',
    styleUrl: './view-privacy-policy-footer.component.scss'
})
export class ViewPrivacyPolicyFooterComponent {

    footerDescription: string;

    constructor( 
        title: Title
        , private footerService: FooterService
    )
    { title.setTitle('Privacy Policy'); }

    ngOnInit() {
        this.footerService.getFooterItem(FooterTypeEnum.PrivacyPolicy)
            .subscribe((vm: FooterVm) => {
                this.footerDescription = vm?.footerDescription;
            });
    }
}