<div class="table-top-container clearfix">
	<div class="top-left-container">
		<!-- Show/Hide button -->
		<pcg-button-hide-show 
			*ngIf="canShowHideColumns"
			[columnDefs]="columnDefs" 					
		></pcg-button-hide-show>

		<!-- Apply Filters button -->
		<pcg-button-filter 
			*ngIf='filterId' 
			id="pcg-button-filter" 
			[filterId]="filterId"
		></pcg-button-filter>

		<!-- Reset Filters button -->
		<pcg-button-reset-filter *ngIf='showResetFilterBtn()'></pcg-button-reset-filter>

		<!-- Units/Vials/Vol toggle -->
		<mat-button-toggle-group 
			*ngIf="canToggleUnits"
			#vialToggleBtn="matButtonToggleGroup" 
			class="vial-unit-toggle" 
			[value]="unitType" 
		>
			<mat-button-toggle 
				(click)="setUnitType(unitTypeEnum.Units)" 
				[value]="unitTypeEnum.Units"
			>Units</mat-button-toggle>
			<mat-button-toggle 
				(click)="setUnitType(unitTypeEnum.Vials)" 
				[value]="unitTypeEnum.Vials"
			>Vials</mat-button-toggle>
			<mat-button-toggle 
				(click)="setUnitType(unitTypeEnum.Volume)" 
				[value]="unitTypeEnum.Volume"
			>Volume</mat-button-toggle>
		</mat-button-toggle-group>

		<!-- Applied Filters -->
		<div
			*ngIf="
				filterMap 
				&& btnFilterService.getActiveFilters(ajaxData, filterMap)
			"
			class="applied-filters" 
		>
			Applied Filters: {{ btnFilterService.getActiveFilters(ajaxData, filterMap) }}
		</div>
	</div>	
	
	<div class="top-right-container">
		<div class="table-actions">
			<!-- Tiles/List toggle -->
			<mat-button-toggle-group
				*ngIf="
					!isMobile 
					&& showTilesBtn
				" 
				class="vial-unit-toggle" 
			>
				<mat-button-toggle (click)="tableViewClick(true)">
					<fa-icon 
						[icon]="faIconName.faTableCellsLarge" 
						class="table-action-icon"							
					></fa-icon>
				</mat-button-toggle>

				<mat-button-toggle (click)="tableViewClick(false)">
					<fa-icon 
						[icon]="faIconName.faTableList" 
						class="table-action-icon"
					></fa-icon>
				</mat-button-toggle>
			</mat-button-toggle-group>

			<!-- Column tooltips button -->
			<button 
				*ngIf="
					hasTooltips 
					&& isMobile
				"
				(click)="tooltipButtonClicked()" 
				class="table-action-btn" 					
			>
				<fa-icon 
					[icon]="faIconName.faQuestion" 
					class="table-action-icon"
				></fa-icon>
			</button>

			<!-- Excel button -->
			<button 
				*ngIf="canExportTableToExcel"
				ngbTooltip="Export to Excel" 
				(click)="table.getExcel()" 
				class="table-action-btn"
			>
				<img 
					src="assets/images/actions/xlsxgreen.svg" 
					class="table-action-icon" 
				/>
			</button>

			<!-- Print button -->
			<button 
				*ngIf="canPrintTable"
				ngbTooltip="Print the Report" 
				(click)="table.print()" 
				class="table-action-btn" 					
			>
				<img 
					src="assets/images/actions/printBlue.svg" 
					class="table-action-icon"
				/>
			</button>
		</div>		

		<!-- Table search -->
		<div 
			*ngIf="canGlobalSearch" 
			class="search-container"
		>
			<input
				aria-label="Table search"
				type="search"
				class="form-control form-control-sm"
				placeholder="Search..."
				(keyup)="filterSource.next($event.target.value)"
				(click)="clearSearch($event)"
			/>
		</div>
	</div>

	<!-- Column tooltip modal -->
	<ng-template 
		#tooltipModal 
		let-modal
	>
		<div class="modal-header">
			<h5>Column Tooltips</h5>
			<button 
				type="button" 
				class="close" 
				aria-label="Close" 
				(click)="modal.dismiss()"
			><span aria-hidden="true">&times;</span></button>
		</div>

		<div class="modal-body flex-body">
			<pcg-table-top-tooltip [innerHTML]="tooltipContent"></pcg-table-top-tooltip>
		</div>
	</ng-template>
</div>