import { Component, OnInit, OnDestroy } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { UntypedFormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs';

import { SystemMessageService } from 'app/core/system-message/system-message-service';
import { validateForm } from 'app/shared/form-elements/form-validateForm.function';
import { ChangePasswordForm } from './change-password.form';
import { UserGuidVm } from 'app/shared/generated/Models/UserGuidVm';

@Component({
	selector: 'pcg-change-password',
	templateUrl: './change-password.component.html',
	styleUrls: ['./change-password.component.scss']
})
export class ChangePasswordComponent implements OnInit, OnDestroy {

	changePasswordForm: UntypedFormGroup = null; 
	changePasswordSub: Subscription = new Subscription();

	userGUID = '0';
	code = "";
	userId = '0';
	errorMessage = "";
	apiUrl = "";

	isExpired = false;
	isApiUser = false;

	constructor(
		private route: ActivatedRoute
		, private ms: SystemMessageService
		, private router: Router
		, private http: HttpClient
	) {
		const changePasswordForm = new ChangePasswordForm(http);
		this.changePasswordForm = changePasswordForm.formGroup;
	}

	ngOnInit() {
		this.userGUID = this.route.snapshot.params.id;
		this.code = this.route.snapshot.queryParams.code;
		var url = window.location.href;

		if (url.indexOf('localhost') > -1) { this.apiUrl = "https://localhost:44396/"; } 
        else if (url.indexOf('demoapp.schedule2.it/change-password') > -1) { this.apiUrl = "https://apibeta.schedule2.it/swagger/index.html"; } 
        else { this.apiUrl = "https://api.schedule2.it/swagger/index.html"; }
		
		if (this.userGUID != null) {
			this.changePasswordSub.add(
				this.http.get(`api/Account/GetUserGuidVm?guid=${this.userGUID}`)
					.subscribe((vm: UserGuidVm) => {
						if (vm.isExpired === false) {
							this.userId = vm.id.toString();
							this.isExpired = vm.isExpired;
						} else if (vm.message === "This email has expired because a newer one has been sent. Please use the latest email.") { 
							this.isExpired = vm.isExpired; 
							this.errorMessage = vm.message;
						} else if (vm.message === "This email has expired because it has been more than 7 days since it was sent." && vm.isExpired) {
							this.isExpired = vm.isExpired; 
							this.errorMessage = vm.message;
						}
					})
			);
		} else { this.ms.setSystemMessage("There was a problem processing you change passowrd request", "Error"); }
	}

	onSubmit() {
		this.isApiUser = false;

		if (this.changePasswordForm.valid) {
			this.changePasswordSub.add(
				this.ms.getHttpObservable(
					this
					, 'api/Account/ChangePassword'
					, this.changePasswordForm
					, { 
						userId: this.userId
						, code: this.code 
					}
				).subscribe(msg => { 
					if (msg && msg.isSuccessful) { 
						if (msg.value === 1) {
							this.isApiUser = true;
						} else { this.router.navigate(['/']); }						
					} 
				})
			);
		} else { validateForm(this.changePasswordForm); }
	}

	ngOnDestroy() { this.changePasswordSub.unsubscribe(); }
}
