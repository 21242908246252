import { UserJsVm } from '../../shared/generated/Administration/Models/UserJsVm';
import { NavRoute } from '../../shared/navigation/nav.route.interface';
import { PermissionConst } from '../security/generated/Permission';

export const formsNav: () => NavRoute = () => ({
	name: 'Forms'
	, security: PermissionConst.createAndEditInventories
	, href: 'forms'
    , children: getFormsAreas()
});

function getFormsAreas() : NavRoute[] {
    const user = getUser();
    let navRoutes: Array<NavRoute> = [];

    navRoutes.push(
        {
            name: 'Biennial Inventory Reports'
            , exact: true
            , path: 'forms/biennial-inventory-reports'
        }
    );

    if (user?.clientStateAbr == 'NY') {
        navRoutes.push(
            {
                name: 'NY Form 3848'
                , exact: true
                , path: 'forms/ny-form-3848'
            }
        );
    } 

    return navRoutes;
}

function getUser(): UserJsVm {
	const userJson: string = localStorage.getItem('user') !== null 
        ? localStorage.getItem('user') 
        : null;
	if (userJson !== null) { return JSON.parse(userJson); }
	return null;
}