import { UntypedFormGroup, UntypedFormControl, UntypedFormArray, Validators } from '@angular/forms';

import { PcgTableColumn } from '../../../table/pcg-table-column.interface';

// tslint:disable:quotemark
// tslint:disable:max-line-length
// tslint:disable:whitespace
export class SwapClientVm {
	clientId: number;

	static get FormData() {
		return this.getFormData();
	}

	static getFormData(validators = {}) {
		return {
			'clientId': new UntypedFormControl(0, [...(validators['clientId'] ?? []), ...[Validators.required]])
		};
	}

	static get Form() {
		return new UntypedFormGroup(this.FormData);
	}

	static GetModel(fg: UntypedFormGroup): SwapClientVmFromFg {
		return new SwapClientVmFromFg(fg);
	}
}

export class SwapClientVmFromFg {
	constructor(public fg: UntypedFormGroup) { }


	get clientId(): number {
		return this.fg.get('clientId').value;
	}
	set clientId(val) {
		this.fg.get('clientId').setValue(val);
	}
}
