import { NavRoute } from '../../shared/navigation/nav.route.interface';
import { PermissionProfileEnum } from '../enums/generated/PermissionProfileEnum';
import { UserJsVm } from '../../shared/generated/Administration/Models/UserJsVm';
import { PermissionConst } from '../security/generated/Permission';

const user = getUser();

export const getSysAdminNav: () => NavRoute = () => ({
	name: 'SysAdmin'
	, security: PermissionConst.createAndEditInventories
	, href: 'sysadmin'
    , children: [
        { 
            name: 'Customers'
            , security: sec => sec.clients
            , exact: true
            , path: 'sysadmin/clients' 
        }
        , { 
            name: 'Integrations'
            , security: sec => sec.clients && sec.permissionProfile === PermissionProfileEnum.SysAdmin
            , href: 'sysadmin/integrations/list' 
            , exact: true
            , children: getIntegrationChildren()
        }
        , { 
            name: 'Global Users'
            , security: sec => sec.clients
            , exact: true
            , path: 'sysadmin/global-user-list' 
        }
        , {
            name: 'States'
            , path: 'sysadmin/states/list'
            , security: PermissionConst.clients
        }
        , {
            name: 'Footer Admin'
            , security: sec => sec.releaseNotes 
            , href: 'sysadmin/release-notes/web-release-notes-list'
            , children: getFooterAdminChildren()
        }
        , {
            name: 'System Wide'
            , security: sec => sec.releaseNotes
            , href: 'sysadmin/sys-admin-settings'
            , children: getSystemWideChildren()
        }
        , {
            name: 'Permissions Info'
            , security: sec => sec.releaseNotes
            , href: 'sysadmin/view-permission-list'
            , children: getPermissionsChildren()
        }
        , { 
			name: 'Quartz'
			, security: sec => sec.viewQuartzJobs === true
			, exact: true
			, path: 'quartz'
			, openNewTab: true 
		}
    ]
});

function getFooterAdminChildren() {
    var id: string = "";
    var url = window.location.href;

    if (url.indexOf('release-notes/edit-web-release-notes') > -1) { id = url.substring(url.lastIndexOf('/') + 1); }

    if(id === "") {
        return [
            { 
                name: 'Release Notes'
                , security: sec => sec.releaseNotes
                , exact: true
                , path: 'sysadmin/release-notes/web-release-notes-list' 
            }
            , { 
                name: 'EULA'
                , security: sec => sec.releaseNotes
                , exact: true
                , path: 'sysadmin/edit-footer-item?item=eula' 
            }
            , { 
                name: 'Privacy Policy'
                , security: sec => sec.releaseNotes
                , exact: true
                , path: 'sysadmin/edit-footer-item?item=privacy-policy' 
            }
            , { 
                name: 'Terms and Conditions'
                , security: sec => sec.releaseNotes
                , exact: true
                , path: 'sysadmin/edit-footer-item?item=terms-and-conditions' 
            }
        ]
    }

    if(id != null) {
        return [
            { 
                name: 'Release Notes'
                , security: sec => sec.releaseNotes
                , exact: true
                , path: 'sysadmin/release-notes/web-release-notes-list' 
            }
            , {
                name: 'Release Notes Info'
                , security: sec => sec.releaseNotes
                , exact: true
                , path: `sysadmin/release-notes/edit-web-release-notes/${id}`
            }
            , { 
                name: 'EULA'
                , security: sec => sec.releaseNotes
                , exact: true
                , path: 'sysadmin/edit-footer-item?item=eula' 
            }
            , { 
                name: 'Privacy Policy'
                , security: sec => sec.releaseNotes
                , exact: true
                , path: 'sysadmin/edit-footer-item?item=privacy-policy' 
            }
            , { 
                name: 'Terms and Conditions'
                , security: sec => sec.releaseNotes
                , exact: true
                , path: 'sysadmin/edit-footer-item?item=terms-and-conditions' 
            }
        ]
    }
}

function getSystemWideChildren() {
    var id: string = "";
    var url = window.location.href;

    if (url.indexOf('edit-downtime-notification/') > -1) { id = url.substring(url.lastIndexOf('/') + 1); }

    if(id === "") {
        return [
            { 
                name: 'System Settings'
                , security: sec => sec.releaseNotes
                , exact: true
                , path: 'sysadmin/sys-admin-settings' 
            }
            , { 
                name: 'Downtime Notifications'
                , security: sec => sec.releaseNotes
                , exact: true
                , path: 'sysadmin/downtime-notifications' 
            }
            , { 
                name: 'Consolidate Drug Forms'
                , security: sec => sec.clients
                , exact: true
                , path: 'sysadmin/drug-forms' 
            }
        ]
    }

    if(id != null) {
        return [
            { 
                name: 'System Settings'
                , security: sec => sec.releaseNotes
                , exact: true
                , path: 'sysadmin/sys-admin-settings' 
            }
            , { 
                name: 'Downtime Notifications'
                , security: sec => sec.releaseNotes
                , exact: true
                , path: 'sysadmin/downtime-notifications' 
            }
            , {
                name: 'Downtime Notification Info'
                , security: sec => sec.releaseNotes
                , exact: true
                , path: `sysadmin/edit-downtime-notification/${id}`
            }
            , { 
                name: 'Consolidate Drug Forms'
                , security: sec => sec.clients
                , exact: true
                , path: 'sysadmin/drug-forms' 
            }
        ]
    }
}

function getIntegrationChildren() {
	var id: string = "";
	var url = window.location.href;

	if (url.indexOf('integrations/integration-info/') > -1) { id = url.substring(url.lastIndexOf('/') + 1); }
	if (url.indexOf('integrations/users/') > -1) { id = url.substring(url.lastIndexOf('/') + 1); }
	if (url.indexOf('integrations/audit-history/') > -1) { id = url.substring(url.lastIndexOf('/') + 1); }

	if (id === "") {
		return [
			{
				name: 'Integrations'
				, exact: true
				, path: `sysadmin/integrations/list`
			}
		];
	}

	if (id === "0") {
		return [
			{
				name: 'Integrations'
				, exact: true
				, path: `sysadmin/integrations/list`
			}
			, {
				name: 'Integration Info'
				, exact: true
				, path: `sysadmin/integrations/integration-info/${id}`
			}
		];
	}

	return [
		{
			name: 'Integrations'
			, exact: true
			, path: `sysadmin/integrations/list`
		}
		, {
			name: 'Integration Info'
			, exact: true
			, path: `sysadmin/integrations/integration-info/${id}`
		}
		, {
			name: 'Users'
			, exact: true
			, path: `sysadmin/integrations/users/${id}`
		}
		, {
			name: 'Audit History'
			, exact: true
			, path: `sysadmin/integrations/audit-history/${id}`
		}
	]
}

function getPermissionsChildren() {
	var id: string = "";
	var url = window.location.href;

	if (url.indexOf('edit-permission-list/') > -1) { id = url.substring(url.lastIndexOf('/') + 1); }
	if (url.indexOf('edit-permission-text/') > -1) { id = url.substring(url.lastIndexOf('/') + 1); }

	if (
        id === "" 
        && !url.includes('edit-permission-text')
    ) {
		return [
			{ 
                name: 'Permissions'
                , security: sec => sec.releaseNotes
                , exact: true
                , path: 'sysadmin/view-permission-list' 
            }
            , { 
                name: 'Permissions Audit History'
                , security: sec => sec.releaseNotes
                , exact: true
                , path: 'sysadmin/permission-audit-history' 
            }
		];
	}

	if (
        id != null 
        && url.includes('edit-permission-list')
    ) {
		return [
			{ 
                name: 'Permissions'
                , security: sec => sec.releaseNotes
                , exact: true
                , path: 'sysadmin/view-permission-list' 
            }
            , {
                name: 'Permissions Info'
                , security: sec => sec.releaseNotes
                , exact: true
                , path: `sysadmin/edit-permission-list/${id}`
            }
            , { 
                name: 'Permissions Audit History'
                , security: sec => sec.releaseNotes
                , exact: true
                , path: 'sysadmin/permission-audit-history' 
            }
		];
	}

	if (url.includes('edit-permission-text')) {
		return [
			{ 
                name: 'Permissions'
                , security: sec => sec.releaseNotes
                , exact: true
                , path: 'sysadmin/view-permission-list' 
            }
            , {
                name: 'Permissions Info'
                , security: sec => sec.releaseNotes
                , exact: true
                , path: `sysadmin/edit-permission-text`
            }
            , { 
                name: 'Permissions Audit History'
                , security: sec => sec.releaseNotes
                , exact: true
                , path: 'sysadmin/permission-audit-history' 
            }
		];
	}
}


function getUser(): UserJsVm {
	const userJson: string = localStorage.getItem('user') !== null ? localStorage.getItem('user') : null;
	if (userJson !== null) { return JSON.parse(userJson); }
	return null;
}
