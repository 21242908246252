import { ApplicationRef, Injectable, NgZone } from '@angular/core';
import { SwUpdate } from '@angular/service-worker';
import { concat, interval, Subscription } from 'rxjs';
import { first } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class CheckForUpdateService {

    isNewVersionAvailable: boolean = false;
    intervalSource = interval(4 * 60 * 60 * 1000); // every 4 hours
    intervalSubscription: Subscription;

    constructor(
        private appRef: ApplicationRef
        , private swUpdate: SwUpdate
        , private zone: NgZone
    ) { this.checkForUpdates(); }

    public checkForUpdates(): void { 
        this.intervalSubscription?.unsubscribe();
        if (!this.swUpdate.isEnabled) { return; }

        // Allow the app to stabilize first, before starting polling for updates with `interval()`.
        const appIsStable$ = this.appRef.isStable.pipe(first(isStable => isStable === true));
        const onceAppIsStable$ = concat(appIsStable$, this.intervalSource);      

        this.zone.runOutsideAngular(() => {
            onceAppIsStable$.subscribe(async () => {
                this.intervalSubscription = this.intervalSource.subscribe(async () => {
                    try {
                        this.isNewVersionAvailable = await this.swUpdate.checkForUpdate();
                        if (this.isNewVersionAvailable) { this.promptUser(); }
                        console.log(this.isNewVersionAvailable ? 'A new version is available.' : 'Already on the latest version.');
                    } catch (error) {
                        console.error('Failed to check for updates:', error);
                    }
                });
            });
        });
    }

    applyUpdate(): void {
        // Reload the page to update to the latest version after the new version is activated
        this.swUpdate.activateUpdate()
            .then(() => document.location.reload())
            .catch(error => console.error('Failed to apply updates:', error));
    }

    promptUser(): void {
        if (confirm('A new version of the app is available. Please reload.')) {
            this.swUpdate.activateUpdate()
                .then(() => window.location.reload())
                .catch(error => console.error('Failed to apply updates:', error));
        }
    }
}
