// tslint:disable: quotemark
export enum CustomFieldMaskEnum {
	ZipCode = 0,
	Phone = 1,
	PhoneExt = 2,
	Percentage = 3,
	Currency = 4,
	Ndc = 5
}

export namespace CustomFieldMaskEnum {
	export function toDescription(val: CustomFieldMaskEnum) {
		switch (val) {
			case 0:
				return "Zip Code";
			case 1:
				return "Phone";
			case 2:
				return "Phone w/ Extension";
			case 3:
				return "Percentage";
			case 4:
				return "Currency";
			case 5:
				return "NDC";
		}
		return null;
	}
}
