// tslint:disable: quotemark
export enum PermissionProfileEnum {
	Technician = 0,
	Manager = 1,
	Admin = 2,
	SysAdmin = 3
}

export namespace PermissionProfileEnum {
	export function toDescription(val: PermissionProfileEnum) {
		switch (val) {
			case 0:
				return "Technician";
			case 1:
				return "Manager";
			case 2:
				return "Admin";
			case 3:
				return "System Admin";
		}
		return null;
	}
}
