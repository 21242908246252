<ul [attr.role]="isRoot ? 'navigation' : 'none'" [attr.aria-label]="isRoot ? 'Primary' : undefined" [ngClass]="ulClasses">
	<ng-container *ngFor="let route of loopNavRoutes">
		<li #linkLi *ngIf="navService.shouldShowRouteLink(route, navRoutes, 0)"
			[class.small-screen-only]="route.isInUserNav" [routerLinkActive]="['link-active']"
			[class.link-active]="route.id === currForceSelect" [routerLinkActiveOptions]="{ exact: route.exact }">
			<a class="d-block" [queryParams]="route.queryParams" [routerLink]="[route.path]" (click)="clickLink()" [target]="route.openNewTab ? '_blank' : ''"
			>
				{{ route.name }}
			</a>
		</li>
		<li *ngIf="route && route.children && !route.hasSideNav" [class.small-screen-only]="route.isInUserNav"
			class="dropdown" [ngClass]="{
				'bottom-arrow':
					isRoot &&
					!isSecondary &&
					(currSelectedNavMenuItem.startsWith(route.id) || currOpenSecondaryNavMenu.startsWith(route.id)),
				'dropdown-submenu': !isRoot
			}"
			(pointerleave)="closeDropdown(route.id)">
			<a 	[id]="getHtmlId(route.id) + '_collapser'" 
				role="button"
				[attr.aria-expanded]="currNavMenuItem !== '' && currNavMenuItem.startsWith(route.id)"
				[attr.aria-controls]="getHtmlId(route.id)"
				class="d-block ddlMenu" 
				style="white-space: nowrap"
				[attr.href]="!isMobile ? route.href : null"
				(pointerenter)="openDropdown(route.id)"
				(pointerdown)="pointerClickDropdown(route.id, route.href)"
				[target]="route.openNewTab ? '_blank' : ''"
				[ngClass]="{
					'underline-top-nav-style':
						isRoot &&
						!isSecondary &&
						(currSelectedNavMenuItem.startsWith(route.id) || currOpenSecondaryNavMenu.startsWith(route.id)),
					'dropdown-submenu': !isRoot 
				}"
			>
				{{ route.name }}
				<span class="dropdown-toggle" [class.isOpen]="!isRoot && currNavMenuItem !== '' && currNavMenuItem.startsWith(route.id)"></span>
				<span *ngIf="route.isFavorited" style="color: gold;" class="fa route-favorite">★</span>
				<div class="favorite-spacer"></div>
			</a>

			<pcg-top-click-nav [id]="getHtmlId(route.id)" [attr.aria-labelledby]="getHtmlId(route.id) + '_collapser'"
				[isRoot]="false" [menuItemId]="route.id" [navRoutes]="route.children"></pcg-top-click-nav>
		</li>
	</ng-container>
	<!-- User dropdown -->
	<li class="dropdown" *ngIf="isRoot && sec.isLoggedOn()">
		<a *ngIf="user" href="#" (click)="clickDropdown('user')" class="d-block small-screen-only dropdown-toggle">
			{{ user.fullName }} 
		</a>
		<ul class="dropdown-menu top-nav-bg" [class.nav-open]="userNavOpen">
			<li [routerLinkActive]="['link-active']" [routerLinkActiveOptions]="{ exact: true }">
				<a 
					(click)="clickLink()" 
					class="d-block small-screen-only" 
					[routerLink]="'/administration/users/edit-user/details/' + user?.id"
				>My Profile</a>
			</li>
			<li [routerLinkActive]="['link-active']">
				<a (click)="clickLink()" class="d-block small-screen-only" routerLink="/help"> Help </a>
			</li>
			<li>
				<a class="d-block small-screen-only" href="#" (click)="logout(); clickLink()"> Logout </a>
			</li>
		</ul>
	</li>
	<!-- Global search -->
	<li *ngIf="isRoot && sec.isLoggedOn()">
		<a class="d-block small-screen-only" href="#" (click)="openSearch()"> Search... </a>
	</li>
</ul>
