import { UntypedFormGroup, UntypedFormControl, UntypedFormArray, Validators } from '@angular/forms';

import { PcgTableColumn } from '../../../table/pcg-table-column.interface';

// tslint:disable:quotemark
// tslint:disable:max-line-length
// tslint:disable:whitespace
export class HelpFilesVm {
	helpFileId: number;
	name?: string;
	description?: string;
	moduleId: number;
	moduleName?: string;
	fileName?: string;
	file?: any;
	sourceFileName?: string;
	sourceFile?: any;
	videoId?: string;

	static get FormData() {
		return this.getFormData();
	}

	static getFormData(validators = {}) {
		return {
			'helpFileId': new UntypedFormControl(0, [...(validators['helpFileId'] ?? []), ...[Validators.required]]),
			'name': new UntypedFormControl(null, [...(validators['name'] ?? []), ...[Validators.required, Validators.maxLength(100)]]),
			'description': new UntypedFormControl(null, [...(validators['description'] ?? []), ...[Validators.maxLength(1000)]]),
			'moduleId': new UntypedFormControl(0, [...(validators['moduleId'] ?? []), ...[Validators.required]]),
			'moduleName': new UntypedFormControl(null, [...(validators['moduleName'] ?? []), ...[Validators.maxLength(100)]]),
			'fileName': new UntypedFormControl(null, [...(validators['fileName'] ?? []), ...[]]),
			'file': new UntypedFormControl(null, [...(validators['file'] ?? []), ...[Validators.required]]),
			'sourceFileName': new UntypedFormControl(null, [...(validators['sourceFileName'] ?? []), ...[]]),
			'sourceFile': new UntypedFormControl(null, [...(validators['sourceFile'] ?? []), ...[]]),
			'videoId': new UntypedFormControl(null, [...(validators['videoId'] ?? []), ...[Validators.required, Validators.maxLength(20)]])
		};
	}

	static get Form() {
		return new UntypedFormGroup(this.FormData);
	}

	static get ColumnDefs(): Map<string, PcgTableColumn> {
		return new Map([
			['helpFileId', {"orderId":0,"sortColumn":"helpFileId","searchColumn":"helpFileId","includeInExcel":true,"exportColumn":null,"displayName":"Edit","isVisible":true,"canSearch":false,"isNdc":false,"isNdc10":false,"multiSearchCellClasses":null}],
			['name', {"orderId":1,"sortColumn":"name","searchColumn":"name","includeInExcel":true,"exportColumn":null,"displayName":"Name","isVisible":true,"canSearch":true,"isNdc":false,"isNdc10":false,"multiSearchCellClasses":null}],
			['moduleName', {"orderId":2,"sortColumn":"moduleName","searchColumn":"moduleName","includeInExcel":true,"exportColumn":null,"displayName":"Module","isVisible":true,"canSearch":true,"isNdc":false,"isNdc10":false,"multiSearchCellClasses":null}],
			['description', {"orderId":3,"sortColumn":"description","searchColumn":"description","includeInExcel":true,"exportColumn":null,"displayName":"Description","isVisible":true,"canSearch":true,"isNdc":false,"isNdc10":false,"multiSearchCellClasses":null}],
			['delete', {"orderId":4,"sortColumn":"delete","searchColumn":"delete","includeInExcel":true,"exportColumn":null,"displayName":"Delete","isVisible":false,"canSearch":false,"isNdc":false,"isNdc10":false,"multiSearchCellClasses":null}]
		]);
	}

	static GetModel(fg: UntypedFormGroup): HelpFilesVmFromFg {
		return new HelpFilesVmFromFg(fg);
	}
}

export class HelpFilesVmFromFg {
	constructor(public fg: UntypedFormGroup) { }


	get helpFileId(): number {
		return this.fg.get('helpFileId').value;
	}
	set helpFileId(val) {
		this.fg.get('helpFileId').setValue(val);
	}

	get name(): string {
		return this.fg.get('name').value;
	}
	set name(val) {
		this.fg.get('name').setValue(val);
	}

	get description(): string {
		return this.fg.get('description').value;
	}
	set description(val) {
		this.fg.get('description').setValue(val);
	}

	get moduleId(): number {
		return this.fg.get('moduleId').value;
	}
	set moduleId(val) {
		this.fg.get('moduleId').setValue(val);
	}

	get moduleName(): string {
		return this.fg.get('moduleName').value;
	}
	set moduleName(val) {
		this.fg.get('moduleName').setValue(val);
	}

	get fileName(): string {
		return this.fg.get('fileName').value;
	}
	set fileName(val) {
		this.fg.get('fileName').setValue(val);
	}

	get file(): any {
		return this.fg.get('file').value;
	}
	set file(val) {
		this.fg.get('file').setValue(val);
	}

	get sourceFileName(): string {
		return this.fg.get('sourceFileName').value;
	}
	set sourceFileName(val) {
		this.fg.get('sourceFileName').setValue(val);
	}

	get sourceFile(): any {
		return this.fg.get('sourceFile').value;
	}
	set sourceFile(val) {
		this.fg.get('sourceFile').setValue(val);
	}

	get videoId(): string {
		return this.fg.get('videoId').value;
	}
	set videoId(val) {
		this.fg.get('videoId').setValue(val);
	}
}
