<div class="top-shift">
	<br /><br />
	<div 
		*ngIf="
			downtimeNotification 
			&& showDowntimeNotification
		" 
		class="alert alert-warning text-center no-products-msg"
	>
		<button 
			type="button" 
			class="close" 
			aria-label="Close" 
			(click)="dismiss(downtimeNotification)"
		>
			<span aria-hidden="true">&times;</span>
		</button>
		<h5>{{downtimeNotification?.title}}</h5>
		<div>{{downtimeNotification?.message}}</div>
		<div>The window will be from {{formattedStartDate}} to {{formattedEndDate}}</div>
	</div>
	
	<br />
	<img 
		alt="Schedule2" 
		class="system-logo" 
		src="assets/images/logos/s2-Logo-dropshadow.png"
	/>
	<br />
	<div style="vertical-align: middle; text-align: center">Narcotics Tracking Software</div>
	<br />

	<div 
		*ngIf="!isPasswordlessLogin" 
		class="login-container clearfix"
	>
		<div 
			*ngIf="isError" 
			class="text-danger text-center"
		>{{ errorString }}</div>
		<!-- <div 
			*ngIf="iosVersion !== ''" 
			class="text-danger text-center"
		>
			You are on iOS version {{ iosVersion }}. 
			This app is supported on iOS 16 and above and some features may not work on your version. 
			Consider upgrading your iOS version or use another device to take advantage of all functionality.
		</div> -->

		<form 
			#myForm 
			(submit)="onSubmit()" 
			[formGroup]="loginForm"
		>
			<div class="row">
				<pcg-control-group 
					class="col-sm-12" 
					label="Username"
				>
					<input 
						pcgControlRef 
						formControlName="userName" 
						type="text" 
						maxlength="51"
						class="form-control" 
					/>
				</pcg-control-group>
			</div>

			<div class="row">
				<pcg-show-hide-password 
					class="col-sm-12" 
					fcName="password" 
					[parentForm]="loginForm" 
					label="Password" 
					inputCssClass="form-control"
				></pcg-show-hide-password>
			</div>

			<button 
				type="submit" 
				class="btn btn-secondary btn-lg"
			>Login</button>
			<a 
				class="float-right" 
				routerLink="/forgot-password"
			>Forgot your password?</a>
		</form>
	</div>

	<div 
		*ngIf="
			isPasswordlessLogin 
			&& !isError
		"
		class="login-container clearfix"
	>
		<br />
		<div class="alert alert-warning text-center">
			Would you like to set up passwordless login? Press yes to continue or no to enter the site.
			<br /><br />
			<button 
				type="button" 
				class="btn btn-md btn-save" 
				(click)="yesClick()"
			>Yes</button>
			<br /><br />
			<button 
				type="button" 
				class="btn btn-md btn-cancel" 
				(click)="noClick()"
			>No</button>
		</div>
	</div>

	<div 
		*ngIf="isPasswordlessError" 
		class="login-container clearfix divider-content"
	>
		<br />
		<div class="alert alert-danger text-center">
			{{ passwordlessErrorString }}
			<br /><br />
			Press the Continue button to continue into the site.
			<br /><br />
			<button 
				type="button" 
				class="btn btn-md btn-cancel" 
				(click)="continueClick()"
			>Continue</button>
		</div>
	</div>
</div>