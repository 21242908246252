<pcg-table-top
	*ngIf="showTableTop"
	#tableTop
	[canGlobalSearch]="canGlobalSearch"
	[canShowHideColumns]="canShowHideColumns" 
	[pageSizeSource]="pageSizeSource"
	[filterSource]="filterSource"
	[pageLengths]="pageLengths"
	[exactMatchSource]="exactMatchSource"
	[columnDefs]="columnDefs"
	[filterId]="filterId"
	[filters]="(filterId ? ajaxData : null)"
	[emptyFilters]="emptyFilters"
	[customResetFiltersMap]="customResetFiltersMap"
	[ajaxData]="ajaxData"
	[canResetFilters]="canResetFilters"
	[filterMap]="filterMap"
	[canExportTableToExcel]="canExportTableToExcel"
	[canPrintTable]="canPrintTable"
	[hasTooltips]="hasTooltips"
	[tooltipContent]="tooltipContent"
	[canToggleUnits]="canToggleUnits"
	[canToggleTableHeaders]="canToggleTableHeaders"
	[table]="this"
	[showTilesBtn]="showTilesBtn"
	(isUsingTiles)="tableViewClick($event)"
	[tableViewStorage]="tableViewStorage"
></pcg-table-top>

<tr 
	class="search-row" 
	*matHeaderRowDef="getColHeaderDefs()" 
	mat-header-row
></tr>
<tr 
	class="footer-row" 
	*matFooterRowDef="getVisibleColDefs()" 
	mat-footer-row
></tr>

<!-- Mobile cards -->
<div 
	*ngIf="
		isMobile 
		&& isUsingMobileCard
	"
>
	<div 
		*ngFor="let data of tableData?.data" 
		class="list-card" 
		(click)="redirectFunction(data)"
	>
		<div 
			class="unique-field" 
			[ngClass]="{'discrepancy-color' : data?.isDiscrepancy}"
		>
			<div class="alert-field">
				<h8 
					*ngIf="data?.isDiscrepancy" 
					class="alert-bubble"
				>DISCREPANCY</h8>
			</div>
			<div class="unique-item w-100">
				<p *ngIf="!ignoreUniqueColumnName">{{ columnDefs.get(uniqueColumn).displayName  }}</p>
				<div class="w-100 card-list-item p-0"><h6>{{ data[uniqueColumn] }}</h6></div>
			</div>
			<div class="delete-field">
				<pcg-delete-button
					*ngIf="
						getVisibleColDefs().includes('canDelete') 
						&& (
							data['canDelete'] 
							|| isAdmin
						)
					"
					class="delete-btn-table"
					[table]="this"
					[confirmMessage]="confirmMessage"
					[url]="deleteUrl"
					[id]="data[identificationColumn]"
					[isUsingComment]="isUsingDeleteComment || data.useDeleteComment"
				></pcg-delete-button>
			</div>
		</div>
		<div 
			*ngIf="getMobileColDefs().length > 0" 
			class="list-field"
		>
			<div 
				*ngFor="let row of getMobileColDefs()" 
				class="card-list-item"
			>
				<p>{{ columnDefs.get(row).displayName }}</p>
				<h6 *ngIf="!mobileMap?.has(row)">{{ data[row] }}</h6>
				<h6 
					*ngIf="mobileMap?.has(row)" 
					[innerHTML]="mobileMap?.get(row)(data)"
				></h6>
			</div>
		</div>
	</div>
	<div 
		*ngIf="tableData?.data?.length === 0" 
		class="no-records-table"
	>No records to display</div>
</div>

<!-- Desktop Table -->
<div
	[class.d-none]="
		isMobile
		&& isUsingMobileCard
	"
	role="region"
	aria-label="Table"
	#tableContainer
	[class.pcg-table-responsive]="isResponsive"
	[class.pcg-table-fixed-footer]="fixedFooter"
	[class.pcg-table-fixed-header]="fixedHeader"
	class="pcg-table-container mb-1"
>
	<!-- Table Tiles -->
	<div 
		[class.d-none]="!showTiles" 
		class="row mt-2"
	>
		<div class="tile-card-container">
			<div 
				*ngFor="let data of tableData?.data" 
				style="flex: .25; min-width: 20%;"
			>
				<div class="card mb-2 tile-card">
					<div class="tile-card-header">
						<div class="row">
							<div class="col-md-9">
								<h6>{{ data[tileHeaderColumn] }}</h6>
							</div>
							<div class="col-md-3 text-right">
								<pcg-edit-link [url]="editLink + data[identificationColumn]">
									<fa-icon 
										[icon]="faPenToSquare" 
										class="pen-to-square"
									></fa-icon>
								</pcg-edit-link>
							</div>
						</div>
						<div 
							*ngIf="data[tileSubHeaderColumn] !== ''" 
							class="row"
						>
							<div class="col-md-12">
								<h6 class="card-subtitle mt-2 text-muted">
									{{ data[tileSubHeaderColumn] }}
								</h6>
							</div>
						</div>
					</div>

					<div 
						class="text-nowrap text-left"
						(click)="redirectFunction(data)"
					>
						<ul class="list-group list-group-flush">
							<li class="list-group-item">
								<div 
									*ngFor="let row of tilesMap" 
									class="tile-list-item"
								>
									<h6 
										class="left" 
										[innerHtml]="columnDefs.get(row[0]).displayName"
									></h6>
									<span 
										class="right" 
										[innerHTML]="row[1](data)"
									></span>
								</div>
							</li>
							<li 
								*ngIf="
									getVisibleColDefs().includes('canDelete') 
									&& (
										data['canDelete'] 
										|| isAdmin
									)
								" 
								class="list-group-item"
							>
								<pcg-delete-button
									class="delete-btn-table"
									[table]="this"
									[confirmMessage]="confirmMessage"
									[url]="deleteUrl"
									[id]="data[identificationColumn]"
									[isUsingComment]="isUsingDeleteComment"
								></pcg-delete-button>
							</li>
						</ul>
					</div>
				</div>
			</div>
		</div>

		<div 
			*ngIf="tableData?.data?.length === 0" 
			class="no-records-table"
		>No records to display</div>
	</div>

	<!-- Table list -->
	<table 
		[class.d-none]="showTiles"
		[dataSource]="dataOnPageSource" 
		mat-table 
		class="data-grid" 
		[class]="'pcg-table-' + tableNum"
	>
		<!-- Displayed if no records are in the table -->
		<ng-container matColumnDef="noRecords">
			<td 
				colspan="999" 
				mat-cell 
				*matCellDef="let row"
			>
				<div *ngIf="hasCompletedServerRequest || !serverSide">No records to display</div>
				<div *ngIf="!hasCompletedServerRequest && serverSide">Loading...</div>
			</td>
		</ng-container>

		<!-- Optional per column search -->
		<ng-container 
			*ngFor="let col of getColDefs(); let i = index" 
			[matColumnDef]="col + '_search'"
		>
			<th 
				[class]="getColSearchClasses(col)" 
				mat-header-cell 
				*matHeaderCellDef="let row"
			>
				<input
					*ngIf="
						canSearch(col) 
						&& isNdc(col)
					"
					[value]="perColumnSearchSource.value[i]"
					(keyup)="updatePerColumnSearch(i, $event.target.value)"
					(search)="updatePerColumnSearch(i, $event.target.value)"
					[attr.aria-label]="'Search ' + columnDefs.get(col)?.displayName"
					placeholder="Search..."
					class="form-control form-control-sm hide-on-print"
					mask="00000-0000-00"
					type="search"					
				/>
				<input
					*ngIf="
						canSearch(col) 
						&& isNdc10(col)
					"
					[value]="perColumnSearchSource.value[i]"
					(keyup)="updatePerColumnSearch(i, $event.target.value)"
					(search)="updatePerColumnSearch(i, $event.target.value)"
					[attr.aria-label]="'Search ' + columnDefs.get(col)?.displayName"
					placeholder="Search..."
					class="form-control form-control-sm hide-on-print"
					mask="0000000000"
					type="search"					
				/>
				<input
					*ngIf="
						canSearch(col) 
						&& (
							!isNdc(col) 
							&& !isNdc10(col)
						)
					"
					[value]="perColumnSearchSource.value[i]"
					(keyup)="updatePerColumnSearch(i, $event.target.value)"
					(search)="updatePerColumnSearch(i, $event.target.value)"
					[attr.aria-label]="'Search ' + columnDefs.get(col)?.displayName"
					placeholder="Search..."
					class="form-control form-control-sm hide-on-print"
					type="search"					
				/>
			</th>
		</ng-container>

		<!-- Header and Row Declarations -->
		<tr 
			mat-header-row 
			*matHeaderRowDef="getVisibleColDefs()" 
			class="reduce-height"
		></tr>
		<tr 
			mat-row 
			*matRowDef="let row; when: hasData; columns: getVisibleColDefs()" 
			(click)="emitClick(row)" 
			[class]="isRowClickable === true ? 'clickable-row' : ''"
		></tr>
		<tr 
			mat-row 
			*matRowDef="let row; when: hasNoData; columns: ['noRecords']"
		></tr>
	</table>
</div>

<!-- Pagination and page count -->
<div 
	*ngIf="showNumRows" 
	class="d-flex-table align-items-start"
>
	<div class="bottom-left-container filter-count-container form-inline hide-on-print">
		<div class="d-flex-table align-items-start">
			<span class="sm">Show</span>
			<select 
				(change)="pageSizeSource.next($event.target.value)" 
				[class]="pageSizeSource.value < 1000 
					? 'form-control form-control-sm pageSize mx-sm-1 ml-1' 
					: 'form-control form-control-sm mx-sm-1 ml-1'
				"
			>
				<option 
					*ngFor="let length of pageLengths"
					aria-label="Table page length" 
					[selected]="pageSizeSource.value === length" 					
				>{{ length }}</option>
			</select>
		</div>		
	</div>
	
	<div 
		*ngIf="showPagination" 
		class="w-100 mb-2 clearfix"
	>
		<div 
			role="status" 
			aria-live="polite" 
			class="filter-count-container"
		>
			Showing
			{{ filteredDataCount === 0 ? 0 : ((currentPageSource.value - 1) * pageSizeSource.value + 1 | number) }}
			to
			{{
				(filteredDataCount < currentPageSource.value * pageSizeSource.value
					? filteredDataCount
					: currentPageSource.value * pageSizeSource.value
				) | number
			}}
			of
			{{ filteredDataCount | number }}
			entries
			<span *ngIf="filteredDataCount !== totalDataCount">
				(filtered from {{ totalDataCount | number }} total entries)
			</span>
		</div>
		<!-- Page switcher -->
		<div class="pagination-container hide-on-print">
			<ngb-pagination
				role="navigation"
				aria-label="Table paging"
				[collectionSize]="filteredDataCount"
				[pageSize]="pageSizeSource.value"
				[page]="currentPageSource.value"
				(pageChange)="currentPageSource.next($event)"
				[maxSize]="5"
				[rotate]="true"
			></ngb-pagination>
		</div>
	</div>
</div>