/* eslint-disable prefer-arrow/prefer-arrow-functions */
// Based on code for $.param in jquery
export function param(a, nullAsUndefined = false) {
	let prefix;
	const s = [];
	const add = (key, valueOrFunction) => { // If value is a function, invoke it and use its return value
		const value = typeof valueOrFunction === 'function' ? valueOrFunction() : valueOrFunction;
		if (!nullAsUndefined || (value !== null && value !== undefined))
			s.push(encodeURIComponent(key) + '=' + encodeURIComponent(value == null ? '' : value));
	};

	for (prefix in a) {
		if (a.hasOwnProperty(prefix)) {
			buildParams(prefix, a[prefix], add);
		}
	}
	return s.join('&'); // Return the resulting serialization
}

const rbracket = /\[\]$/;
function buildParams(prefix, obj, add) { // Used by param function above
	let name;
	if (Array.isArray(obj)) { // Serialize array item.
		for (let i = 0; i < obj.length; ++i) {
			const v = obj[i];
			if (rbracket.test(prefix)) { // Treat each array item as a scalar.
				add(prefix, v);
			} else { // Item is non-scalar (array or object), encode its numeric index.
				buildParams(prefix + '[' + (typeof v === 'object' && v != null ? i : '') + ']', v, add);
			}
		}
	}
	else if (obj instanceof Date) { // Serialize date item.
		add(prefix, obj.toISOString());
	}
	else if (typeof obj === 'object') { // Serialize object item.
		for (name in obj) {
			if (obj.hasOwnProperty(name)) {
				buildParams(prefix + '.' + name, obj[name], add);
			}
		}
	} else { // Serialize scalar item.
		add(prefix, obj);
	}
}
