import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { FooterTypeEnum } from '../../core/enums/generated/FooterTypeEnum';

@Injectable({
    providedIn: 'root'
})
export class FooterService {

    constructor(private httpClient: HttpClient) { }

    getFooterItem(footerType: FooterTypeEnum) {
        return this.httpClient.get(`api/Administration/Footer/GetFooterDetails?footerType=${footerType}`);
    }
}
