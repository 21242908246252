import { UntypedFormGroup, UntypedFormControl, UntypedFormArray, Validators } from '@angular/forms';

import { PcgTableColumn } from '../../../../../../table/pcg-table-column.interface';
import { DynamicFormFieldVm, DynamicFormFieldVmFromFg } from '../../../../../Models/DynamicFormFieldVm';

// tslint:disable:quotemark
// tslint:disable:max-line-length
// tslint:disable:whitespace
export class EditUserVm {
	userId: number;
	fullName?: string;
	userName?: string;
	phoneNumber?: string;
	email?: string;
	stateLicenseNumber?: string;
	userProfileIds?: number[];
	userProfiles?: string[];
	isActive: boolean;
	isLockedOut: boolean;
	inactivityTimeout: boolean;
	permissionId?: number;
	permission?: string;
	permissionProfile?: string;
	clientId?: number;
	clientName?: string;
	departmentIds?: number[];
	inventoryIds?: number[];
	activeInventoryIds?: number[];
	inventories?: string[];
	inventorySiteAssociation?: any;
	activeInventories?: string[];
	hasBiometricClientSettings: boolean;
	hasFidoClientSettings: boolean;
	fields?: DynamicFormFieldVm[];
	customFields?: any;
	friendlyId?: string;
	userSignature?: any;
	userPin?: string;
	showDepartments: boolean;
	isNewUser: boolean;
	isFromIops: boolean;
	userType?: any;
	logoFileName?: string;
	companyName?: string;
	lastLoginDate?: Date;
	lastLoginDateStr?: string;
	isUpdatedOffline?: string;

	static get FormData() {
		return this.getFormData();
	}

	static getFormData(validators = {}) {
		return {
			'userId': new UntypedFormControl(0, [...(validators['userId'] ?? []), ...[Validators.required]]),
			'fullName': new UntypedFormControl(null, [...(validators['fullName'] ?? []), ...[Validators.required, Validators.minLength(2), Validators.maxLength(100), Validators.pattern("^[A-Za-z0-9-_ ,.']+$")]]),
			'userName': new UntypedFormControl(null, [...(validators['userName'] ?? []), ...[Validators.required, Validators.minLength(5), Validators.maxLength(50), Validators.pattern("^[A-Za-z0-9.@-_']+$")]]),
			'phoneNumber': new UntypedFormControl(null, [...(validators['phoneNumber'] ?? []), ...[Validators.pattern("(\\+\\d{1,3}\\s?)?((\\(\\d{3}\\)\\s?)|(\\d{3})(\\s|-?))(\\d{3}(\\s|-?))(\\d{4})(\\s?(([E|e]xt[:|.|]?)|x|X)(\\s?\\d+))?")]]),
			'email': new UntypedFormControl(null, [...(validators['email'] ?? []), ...[Validators.required, Validators.email, Validators.maxLength(100)]]),
			'stateLicenseNumber': new UntypedFormControl(null, [...(validators['stateLicenseNumber'] ?? []), ...[Validators.maxLength(50)]]),
			'userProfileIds': new UntypedFormControl([], [...(validators['userProfileIds'] ?? []), ...[]]),
			'userProfiles': new UntypedFormControl([], [...(validators['userProfiles'] ?? []), ...[]]),
			'isActive': new UntypedFormControl(false, [...(validators['isActive'] ?? []), ...[]]),
			'isLockedOut': new UntypedFormControl(false, [...(validators['isLockedOut'] ?? []), ...[]]),
			'inactivityTimeout': new UntypedFormControl(false, [...(validators['inactivityTimeout'] ?? []), ...[]]),
			'permissionId': new UntypedFormControl(null, [...(validators['permissionId'] ?? []), ...[Validators.required]]),
			'permission': new UntypedFormControl(null, [...(validators['permission'] ?? []), ...[]]),
			'permissionProfile': new UntypedFormControl(null, [...(validators['permissionProfile'] ?? []), ...[]]),
			'clientId': new UntypedFormControl(null, [...(validators['clientId'] ?? []), ...[Validators.required]]),
			'clientName': new UntypedFormControl(null, [...(validators['clientName'] ?? []), ...[]]),
			'departmentIds': new UntypedFormControl([], [...(validators['departmentIds'] ?? []), ...[]]),
			'inventoryIds': new UntypedFormControl([], [...(validators['inventoryIds'] ?? []), ...[]]),
			'activeInventoryIds': new UntypedFormControl([], [...(validators['activeInventoryIds'] ?? []), ...[]]),
			'inventories': new UntypedFormControl([], [...(validators['inventories'] ?? []), ...[]]),
			'inventorySiteAssociation': new UntypedFormControl([], [...(validators['inventorySiteAssociation'] ?? []), ...[]]),
			'activeInventories': new UntypedFormControl([], [...(validators['activeInventories'] ?? []), ...[]]),
			'hasBiometricClientSettings': new UntypedFormControl(false, [...(validators['hasBiometricClientSettings'] ?? []), ...[]]),
			'hasFidoClientSettings': new UntypedFormControl(false, [...(validators['hasFidoClientSettings'] ?? []), ...[]]),
			'fields': new UntypedFormArray([]),
			'customFields': new UntypedFormControl(null, [...(validators['customFields'] ?? []), ...[]]),
			'friendlyId': new UntypedFormControl(null, [...(validators['friendlyId'] ?? []), ...[]]),
			'userSignature': new UntypedFormControl(null, [...(validators['userSignature'] ?? []), ...[]]),
			'userPin': new UntypedFormControl(null, [...(validators['userPin'] ?? []), ...[]]),
			'showDepartments': new UntypedFormControl(false, [...(validators['showDepartments'] ?? []), ...[]]),
			'isNewUser': new UntypedFormControl(false, [...(validators['isNewUser'] ?? []), ...[]]),
			'isFromIops': new UntypedFormControl(false, [...(validators['isFromIops'] ?? []), ...[]]),
			'userType': new UntypedFormControl('', [...(validators['userType'] ?? []), ...[Validators.required]]),
			'logoFileName': new UntypedFormControl(null, [...(validators['logoFileName'] ?? []), ...[]]),
			'companyName': new UntypedFormControl(null, [...(validators['companyName'] ?? []), ...[]]),
			'lastLoginDate': new UntypedFormControl(null, [...(validators['lastLoginDate'] ?? []), ...[]]),
			'lastLoginDateStr': new UntypedFormControl(null, [...(validators['lastLoginDateStr'] ?? []), ...[]]),
			'isUpdatedOffline': new UntypedFormControl(null, [...(validators['isUpdatedOffline'] ?? []), ...[]])
		};
	}

	static get Form() {
		return new UntypedFormGroup(this.FormData);
	}

	static get ColumnDefs(): Map<string, PcgTableColumn> {
		return new Map([
			['fullName', {"orderId":0,"sortColumn":"fullName","searchColumn":"fullName","includeInExcel":true,"exportColumn":null,"displayName":"Full Name","isVisible":true,"canSearch":true,"isNdc":false,"isNdc10":false,"multiSearchCellClasses":null}],
			['email', {"orderId":1,"sortColumn":"email","searchColumn":"email","includeInExcel":true,"exportColumn":null,"displayName":"Email","isVisible":true,"canSearch":true,"isNdc":false,"isNdc10":false,"multiSearchCellClasses":null}],
			['phoneNumber', {"orderId":2,"sortColumn":"phoneNumber","searchColumn":"phoneNumber","includeInExcel":true,"exportColumn":null,"displayName":"Phone Number","isVisible":true,"canSearch":true,"isNdc":false,"isNdc10":false,"multiSearchCellClasses":null}],
			['lastLoginDate', {"orderId":3,"sortColumn":"lastLoginDate","searchColumn":"lastLoginDateStr","includeInExcel":true,"exportColumn":null,"displayName":"Last Login","isVisible":false,"canSearch":true,"isNdc":false,"isNdc10":false,"multiSearchCellClasses":null}],
			['permissionProfile', {"orderId":4,"sortColumn":"permissionProfile","searchColumn":"permissionProfile","includeInExcel":true,"exportColumn":null,"displayName":"Permission","isVisible":true,"canSearch":true,"isNdc":false,"isNdc10":false,"multiSearchCellClasses":null}],
			['isActive', {"orderId":5,"sortColumn":"isActive","searchColumn":"isActive","includeInExcel":true,"exportColumn":null,"displayName":"Is Active?","isVisible":false,"canSearch":true,"isNdc":false,"isNdc10":false,"multiSearchCellClasses":null}],
			['delete', {"orderId":6,"sortColumn":"delete","searchColumn":"delete","includeInExcel":true,"exportColumn":null,"displayName":"Delete","isVisible":false,"canSearch":false,"isNdc":false,"isNdc10":false,"multiSearchCellClasses":null}]
		]);
	}

	static GetModel(fg: UntypedFormGroup): EditUserVmFromFg {
		return new EditUserVmFromFg(fg);
	}
}

export class EditUserVmFromFg {
	constructor(public fg: UntypedFormGroup) { }


	get userId(): number {
		return this.fg.get('userId').value;
	}
	set userId(val) {
		this.fg.get('userId').setValue(val);
	}

	get fullName(): string {
		return this.fg.get('fullName').value;
	}
	set fullName(val) {
		this.fg.get('fullName').setValue(val);
	}

	get userName(): string {
		return this.fg.get('userName').value;
	}
	set userName(val) {
		this.fg.get('userName').setValue(val);
	}

	get phoneNumber(): string {
		return this.fg.get('phoneNumber').value;
	}
	set phoneNumber(val) {
		this.fg.get('phoneNumber').setValue(val);
	}

	get email(): string {
		return this.fg.get('email').value;
	}
	set email(val) {
		this.fg.get('email').setValue(val);
	}

	get stateLicenseNumber(): string {
		return this.fg.get('stateLicenseNumber').value;
	}
	set stateLicenseNumber(val) {
		this.fg.get('stateLicenseNumber').setValue(val);
	}

	get isActive(): boolean {
		return this.fg.get('isActive').value;
	}
	set isActive(val) {
		this.fg.get('isActive').setValue(val);
	}

	get isLockedOut(): boolean {
		return this.fg.get('isLockedOut').value;
	}
	set isLockedOut(val) {
		this.fg.get('isLockedOut').setValue(val);
	}

	get inactivityTimeout(): boolean {
		return this.fg.get('inactivityTimeout').value;
	}
	set inactivityTimeout(val) {
		this.fg.get('inactivityTimeout').setValue(val);
	}

	get permissionId(): number {
		return this.fg.get('permissionId').value;
	}
	set permissionId(val) {
		this.fg.get('permissionId').setValue(val);
	}

	get permission(): string {
		return this.fg.get('permission').value;
	}
	set permission(val) {
		this.fg.get('permission').setValue(val);
	}

	get permissionProfile(): string {
		return this.fg.get('permissionProfile').value;
	}
	set permissionProfile(val) {
		this.fg.get('permissionProfile').setValue(val);
	}

	get clientId(): number {
		return this.fg.get('clientId').value;
	}
	set clientId(val) {
		this.fg.get('clientId').setValue(val);
	}

	get clientName(): string {
		return this.fg.get('clientName').value;
	}
	set clientName(val) {
		this.fg.get('clientName').setValue(val);
	}

	get hasBiometricClientSettings(): boolean {
		return this.fg.get('hasBiometricClientSettings').value;
	}
	set hasBiometricClientSettings(val) {
		this.fg.get('hasBiometricClientSettings').setValue(val);
	}

	get hasFidoClientSettings(): boolean {
		return this.fg.get('hasFidoClientSettings').value;
	}
	set hasFidoClientSettings(val) {
		this.fg.get('hasFidoClientSettings').setValue(val);
	}

	get customFields(): any {
		return this.fg.get('customFields').value;
	}
	set customFields(val) {
		this.fg.get('customFields').setValue(val);
	}

	get friendlyId(): string {
		return this.fg.get('friendlyId').value;
	}
	set friendlyId(val) {
		this.fg.get('friendlyId').setValue(val);
	}

	get userSignature(): any {
		return this.fg.get('userSignature').value;
	}
	set userSignature(val) {
		this.fg.get('userSignature').setValue(val);
	}

	get userPin(): string {
		return this.fg.get('userPin').value;
	}
	set userPin(val) {
		this.fg.get('userPin').setValue(val);
	}

	get showDepartments(): boolean {
		return this.fg.get('showDepartments').value;
	}
	set showDepartments(val) {
		this.fg.get('showDepartments').setValue(val);
	}

	get isNewUser(): boolean {
		return this.fg.get('isNewUser').value;
	}
	set isNewUser(val) {
		this.fg.get('isNewUser').setValue(val);
	}

	get isFromIops(): boolean {
		return this.fg.get('isFromIops').value;
	}
	set isFromIops(val) {
		this.fg.get('isFromIops').setValue(val);
	}

	get userType(): any {
		return this.fg.get('userType').value;
	}
	set userType(val) {
		this.fg.get('userType').setValue(val);
	}

	get logoFileName(): string {
		return this.fg.get('logoFileName').value;
	}
	set logoFileName(val) {
		this.fg.get('logoFileName').setValue(val);
	}

	get companyName(): string {
		return this.fg.get('companyName').value;
	}
	set companyName(val) {
		this.fg.get('companyName').setValue(val);
	}

	get lastLoginDate(): Date {
		return this.fg.get('lastLoginDate').value;
	}
	set lastLoginDate(val) {
		this.fg.get('lastLoginDate').setValue(val);
	}

	get lastLoginDateStr(): string {
		return this.fg.get('lastLoginDateStr').value;
	}
	set lastLoginDateStr(val) {
		this.fg.get('lastLoginDateStr').setValue(val);
	}

	get isUpdatedOffline(): string {
		return this.fg.get('isUpdatedOffline').value;
	}
	set isUpdatedOffline(val) {
		this.fg.get('isUpdatedOffline').setValue(val);
	}
}
