<ng-select
	#theSelect
	class="pcg-select"
	[class.d-none]="showNativeSelect == null ? false : showNativeSelect"
	[multiple]="multiple"
	[placeholder]="placeholder ?? ''"
	[labelForId]="labelForId"
	[(ngModel)]="value"
	[items]="items"
	(change)="onChange"
	(touch)="onTouched"
	groupBy="groupBy"
	bindLabel="text"
	bindValue="value"
	[clearable]="clearable"
	[virtualScroll]="virtualScroll"
	[searchable]="!isMobile"
	[disabled]="disabled"
></ng-select>
<span class="disabled-only-text" [class.d-none]="showNativeSelect == null ? false : showNativeSelect">{{ text }}</span>

<ng-container *ngIf="showNativeSelect == null ? false : showNativeSelect">
	<select
		#theNativeSelect
		class="form-control"
		[(ngModel)]="value"
		[id]="labelForId"
		[name]="labelForId"
		(change)="onChange"
		(touch)="onTouched"
		[disabled]="disabled"
	>
		<option *ngIf="clearable" [ngValue]="value === undefined ? undefined : null">{{ placeholder }}</option>
		<option *ngFor="let item of items" [ngValue]="item.value">{{ item.text }}</option>
	</select>
</ng-container>
