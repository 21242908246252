import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot, UrlSegment, Route, Router } from '@angular/router';

import { SecurityService } from './security.service';
import { SystemMessageService } from '../system-message/system-message-service';
import { Permission } from './generated/Permission';

@Injectable()
export class AuthGuard  {

	constructor(
		private sec: SecurityService
		, private router: Router
		, private ms: SystemMessageService
	) { }

	/**
	 * Controls whether or not a user can access a component
	 */
	canActivate(
		route: ActivatedRouteSnapshot
		, state: RouterStateSnapshot
	) {
		const security = !route.data 
			? undefined 
			: (route.data.security as string | ((sec: Permission) => boolean));
		if (this.checkSecurity(state.url, security)) { return true; }

		const user = this.sec.getUser();
		if (user) { this.ms.setSystemMessage('Access Denied. You do not have sufficient permission level.', 'error'); }

		return this.router.createUrlTree(['/dashboard']);
	}

	/**
	 * Controls whether or not a module will be lazy loaded
	 */
	canLoad(
		route: Route
		, urlSegment: UrlSegment[]
	) {
		const security = !route.data 
			? undefined 
			: (route.data.security as string | ((sec: Permission) => boolean));
		let redirectUrl = null;
		const redirect = window.location.href.replace(document.getElementsByTagName('base')[0].href, '');
		if (
			redirect !== null 
			&& redirect !== '' 
			&& redirect !== undefined
		) { redirectUrl = '/' + redirect; }	
		
		if (this.checkSecurity(redirectUrl, security)) { return true; } 
		
		const user = this.sec.getUser();
		if (user) { this.ms.setSystemMessage('Access denied.', 'error'); }

		return this.router.createUrlTree(['/dashboard']);
	}

	/**
	 * Right now it just calls checkSecurity in the security service
	 * to ensure they are logged in and saves the redirect URL.
	 *
	 * We may want to eventually use this to lock people out
	 * of entire modules of the website in one place, based on
	 * a single security setting.
	 */
	checkSecurity(
		url: string
		, security: string | ((sec: Permission) => boolean)
	) {
		const isValid = this.sec.checkSecurity(security);

		// If they are not logged on,
		// save the URL they tried to go to
		if (
			!isValid 
			&& !this.sec.isLoggedOn()
		) {
			if (url == null) { this.router.navigateByUrl(`/login`); }
			else { this.router.navigateByUrl(`/login?redirectUrl=${encodeURIComponent(url)}`); }
		}

		return isValid;
	}
}
