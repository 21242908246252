import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import { AuditHistoryVm } from 'app/shared/generated/Models/AuditHistoryVm';
import { TableComponent } from '../table/table.component';
import { GlobalVariablesService } from 'app/services/global-variables.service';
import { NavigationService } from '../navigation/navigation.service';
import { SecurityService } from 'app/core/security/security.service';
import { PermissionProfileEnum } from 'app/core/enums/generated/PermissionProfileEnum';
import { UserJsVm } from '../generated/Administration/Models/UserJsVm';
import { SystemMessageService } from 'app/core/system-message/system-message-service';

@Component({
	selector: 'pcg-shared-audit-history',
	templateUrl: './audit-history.component.html',
	styleUrls: ['./audit-history.component.scss']
})
export class AuditHistoryComponent implements OnInit {

	@ViewChild(TableComponent, { static: true }) table: TableComponent<AuditHistoryVm>;

	@Input() id: number;
    @Input() viewModel: string;
    @Input() auditHistoryName: string;
	@Input() clientName = "";
	@Input() integrationName = "";

	user: UserJsVm;
	columns = AuditHistoryVm.ColumnDefs;

	auditName: string = "";
	filters = {
		id: null,
		viewModel: null,
		auditName: null
	};

	constructor(
		private activatedRoute: ActivatedRoute
		, public globalVariablesService: GlobalVariablesService
		, public nav: NavigationService
		, public sec: SecurityService
		, public messageService: SystemMessageService
		, public router: Router
	) { }

	ngOnInit() {
		this.user = this.sec.getUser();
		
        if (this.id && this.viewModel && this.auditHistoryName) {
            this.filters.id = this.id;
            this.filters.viewModel = this.viewModel;
            this.filters.auditName = this.auditHistoryName;
        } else { this.getFiltersFromLocalStorage(); }

		if (this.filters.auditName === "Client Detail") { this.checkSecurity(); }
		if (this.clientName !== "") { this.globalVariablesService.clientName.next(this.clientName); }
		if (this.integrationName !== "") { this.globalVariablesService.integrationName.next(this.integrationName); }
    }

	getFiltersFromLocalStorage() {
		this.filters.id = this.activatedRoute.snapshot.params['id'];
		this.filters.viewModel = localStorage?.getItem('vmType');
		this.filters.auditName = localStorage?.getItem('auditName');

		if (this.filters.auditName === "Client Detail") {
			this.nav.setOpenSecondaryMenu(['Admin', 'Settings', 'Audit History']);
		}

		if (this.filters.auditName === "Integration Detail") {
			this.nav.setOpenSecondaryMenu(['SysAdmin', 'Integrations', 'Audit History']);
		}
	}

	checkSecurity() {
		if (this.sec.getSecurity()?.permissionProfile === PermissionProfileEnum.Admin) {
			if (this.user.clientId != this.filters.id) {
				this.router.navigateByUrl('dashboard');
				this.messageService.setSystemMessage("Access Denied. You do not have sufficient permission level.", "error");
			}
		}
	}

	setClientName(event) {
		if (event?.value?.clientName !== "" && this.filters.auditName === "Client Detail") { 
			this.globalVariablesService.clientName.next(event.value.clientName); 
		}
		if (event?.value?.integrationName !== "") { this.globalVariablesService.integrationName.next(event.value.integrationName); }
		if (this.filters.auditName === "Global NDC" && event?.value?.clientName !== "") {
			this.auditName = event.value.clientName;
		} else {
			this.auditName = "";
		}
	}
}