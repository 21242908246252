<div class="modal-body">
	<input
		(keydown.shift)="onShift(true)"
		(keyup)="onKeyUp($event)"
		(keydown)="onKeyDown($event)"
		type="search"
		class="mb-1 form-control"
		[(ngModel)]="search"
		aria-label="Type to search and use the up and down arrows to select a link."
		placeholder="Search..."
	/>
	<div
		[class.currently-selected]="i === currentlySelected"
		(click)="router.navigate([route.path], { queryParams: route.queryParams }); activeModal.close()"
		class="search-link p-2"
		*ngFor="let route of matchingPages; let i = index"
	>
		{{ route.name }}<br />
		{{ getReadableLocation(route.id) }}
	</div>
</div>
