import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class GlobalVariablesService {

    userName: BehaviorSubject<string> = new BehaviorSubject("");
    clientName: BehaviorSubject<string> = new BehaviorSubject("");
    integrationName: BehaviorSubject<string> = new BehaviorSubject("");
    siteName: BehaviorSubject<string> = new BehaviorSubject("");
    productName: BehaviorSubject<string> = new BehaviorSubject("");
    currentClientName: BehaviorSubject<string> = new BehaviorSubject("");
    currentClientId: BehaviorSubject<number> = new BehaviorSubject(0);
    supplierName: BehaviorSubject<string> = new BehaviorSubject("");
    stateName: BehaviorSubject<string> = new BehaviorSubject("");
    inventoryActionName: BehaviorSubject<string> = new BehaviorSubject("");
    isNavFixed: BehaviorSubject<boolean> = new BehaviorSubject(null);
    onChangePasswordScreen: BehaviorSubject<boolean> = new BehaviorSubject(null);

    constructor() { }
}