import { Component, OnInit, HostListener, ElementRef, ViewChild, Input, EventEmitter, Output, OnDestroy } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { filter } from 'rxjs/operators';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Subscription } from 'rxjs';
import { faBars } from '@fortawesome/pro-solid-svg-icons';

import { NavRoute } from 'app/shared/navigation/nav.route.interface';
import { SecurityService } from 'app/core/security/security.service';
import { UserJsVm } from 'app/shared/generated/Administration/Models/UserJsVm';
import { NavigationService, NavDividerCharacter } from 'app/shared/navigation/navigation.service';
import { TopClickNavComponent } from 'app/shared/navigation/top-click-nav/top-click-nav.component';
import { SearchNavComponent } from 'app/shared/navigation/search-nav/search-nav.component';
import { pcgSettings } from 'app/shared/generated/pcg-settings';
import { getMainNavRoutes } from 'app/core/main-nav-routes';
import { GlobalService } from 'app/shared/service/global.service';
import { GlobalVariablesService } from 'app/services/global-variables.service';

@Component({
	selector: 'pcg-header',
	templateUrl: './header.component.html',
	styleUrls: ['./header.component.scss']
})
export class TopHeaderComponent implements OnDestroy, OnInit {

	@ViewChild(TopClickNavComponent, { static: true }) nav: TopClickNavComponent;
	@ViewChild('userNav') userNav: ElementRef;
	@ViewChild('mainHamburger', { static: true }) mainHamburger: ElementRef;
	@ViewChild('btnSearch') btnSearch: ElementRef;

	@Input() tabPanel: any;
	@Input() onChangePasswordScreen: boolean;

	@Output() hamburgerClick = new EventEmitter<boolean>();

	user: UserJsVm;
	subscriptions: Subscription = new Subscription();
	formGroup = UserJsVm.Form;
	
	navRoutes: NavRoute[];
	currSelectedNavMenu: NavRoute;

	currForceSelect: string;
	currMainNavMenu: string;	
	mode = pcgSettings.mode;	
	navDividerCharacter = NavDividerCharacter;

	shouldOpenSearch = false;
	userNavOpen = false;
	adminAccess = false;
	canChangeClient = false;
	isMobile: boolean;
	isLoggedOn: boolean;
	isNavFixed: boolean;

	navigationOffset = 0;

	faIconName = { faBars };

	constructor(
		private sec: SecurityService
		, private router: Router
		, private navService: NavigationService
		, private modalService: NgbModal
		, public globalService: GlobalService
		, private globalVariablesService: GlobalVariablesService
	) {}

	@HostListener('window:resize')
	onResize() { this.isMobile = GlobalService.setIsMobile(window.innerWidth); }

	// Close the main navigation when they click outside of it
	// and are not clicking the mobile hamburger button
	@HostListener('document:click', ['$event'])
	clickDoc(event) {
		if (
			!this.mainHamburger?.nativeElement.contains(event.target) &&
			!this.nav?.elRef?.nativeElement.contains(event.target) &&
			!this.userNav?.nativeElement.contains(event.target)
		) {
			this.navService.setCurrOpenNavMenu('');
			this.userNavOpen = false;
		}
	}

	// Open search when they hit the '/' (or '`') key
	@HostListener('document:keydown', ['$event'])
	onKeydown(event: KeyboardEvent) {
		if ((event.key === '/' || event.key === '`') && !this.isFormField(event.target)) {
			event.preventDefault();
			if (!this.modalService.hasOpenModals()) { this.modalService.open(SearchNavComponent); }
		}
	}

	ngOnInit() {
		this.isMobile = GlobalService.setIsMobile(window.innerWidth);

		this.subscriptions.add(this.sec.user$.subscribe(user => { this.user = user; }));	
		this.subscriptions.add(
			this.navService.navRoutes$.subscribe(navRoutes => {
				this.navRoutes = this.navService.getFlatMenu(navRoutes).filter(o => !o?.parentNav);
			})
		);
		this.subscriptions.add(
			this.navService.currOpenPrimaryNavMenu$.subscribe(currMainNavMenu => { this.currMainNavMenu = currMainNavMenu; })
		);
		this.subscriptions.add(
			this.navService.currSelectedMainNavMenu$.subscribe(currSelectedNavMenu => {
				this.currSelectedNavMenu = currSelectedNavMenu;
				if (!currSelectedNavMenu || currSelectedNavMenu.id === 'root') { this.navService.setOpenSecondaryMenu([]); }
			})
		);
		
		this.subscriptions.add(
			this.navService.currOpenSecondaryNavMenu$.subscribe(currSecondaryNavMenu => { 
				this.currSelectedNavMenu = currSecondaryNavMenu; 
				if (this.currSelectedNavMenu !== null && typeof this.currSelectedNavMenu !== "undefined") {
					switch (this.currSelectedNavMenu?.name) {
						case "Edit User": {
							this.globalVariablesService.userName.subscribe((data)=>{ 
								if (
									data != null 
									&& data !== "" 
									&& typeof this.currSelectedNavMenu !== "undefined"
								) { this.currSelectedNavMenu.name = data; } 
							});
							break;
						}
						case "Edit Supplier": {
							this.globalVariablesService.supplierName.subscribe((data)=>{ 
								if (
									data != null 
									&& data !== "" 
									&& typeof this.currSelectedNavMenu !== "undefined"
								) { this.currSelectedNavMenu.name = data; } 
							});
							break;
						}
						case "View State": {
							this.globalVariablesService.stateName.subscribe((data)=>{ 
								if (
									data != null 
									&& data !== "" 
									&& typeof this.currSelectedNavMenu !== "undefined"
								) { this.currSelectedNavMenu.name = data; } 
							});
							break;
						}
						case "Edit Inventory Action": {
							this.globalVariablesService.inventoryActionName.subscribe((data)=>{ 
								if (
									data != null 
									&& data !== "" 
									&& typeof this.currSelectedNavMenu !== "undefined"
								) { this.currSelectedNavMenu.name = data; } 
							});
							break;
						}
						case "Edit Customer": {
							this.globalVariablesService.clientName.subscribe((data)=>{ 
								if (
									data != null 
									&& data !== "" 
									&& typeof this.currSelectedNavMenu !== "undefined"
								) { this.currSelectedNavMenu.name = data; }  
							});
							break;
						}
						case "Edit Integration": {
							this.globalVariablesService.integrationName.subscribe((data)=>{ 
								if (
									data != null 
									&& data !== "" 
									&& typeof this.currSelectedNavMenu !== "undefined"
								) { this.currSelectedNavMenu.name = data; }  
							});
							break;
						}
						case "Integrations": {
							this.globalVariablesService.integrationName.subscribe((data)=>{ 
								if (
									data != null 
									&& data !== "" 
									&& typeof this.currSelectedNavMenu !== "undefined"
								) { this.currSelectedNavMenu.name = data; }  
							});
							break;
						}
						case "Edit Product": {
							this.globalVariablesService.productName.subscribe((data)=>{ 
								if (
									data != null 
									&& data !== "" 
									&& typeof this.currSelectedNavMenu !== "undefined"
								) { this.currSelectedNavMenu.name = data; }  
							});	
							break;
						}
						case "Settings": {
							this.globalVariablesService.clientName.subscribe((data)=>{ 
								if (
									data != null 
									&& data !== "" 
									&& typeof this.currSelectedNavMenu !== "undefined"
								) { this.currSelectedNavMenu.name = data; }  
							});	
							break;
						}
						case "Products": {
							this.globalVariablesService.siteName.subscribe((data)=>{ 
								if (
									data != null 
									&& data !== "" 
									&& typeof this.currSelectedNavMenu !== "undefined"
								) { this.currSelectedNavMenu.name = data; }  
							});	
							break;
						}
						default: { break; }
					}
				}				
			})
		);
		
		this.subscriptions.add(this.navService.currForceSelect$.subscribe(currForceSelect => { this.currForceSelect = currForceSelect; }));

		// Subscribe to route changes and update the navs when they happen
		this.subscriptions.add(this.router.events
			.pipe(filter(event => event instanceof NavigationEnd))
			.subscribe(() => { this.navService.navRoutes.next(this.sec.getSecureNavItems(getMainNavRoutes())); }));

		setTimeout(() => {
			let secondaryNav = document.getElementById("secondaryNav");
			if (secondaryNav != null) { secondaryNav.scrollLeft = Number(localStorage?.getItem("subNavPosition")); }
		}, 250)

		localStorage.getItem("isNavFixed") === "true" ? this.isNavFixed = true : this.isNavFixed = false;
	}

	ngDoCheck() { this.isLoggedOn = this.sec.isLoggedOn(); }	//Needed to check if user is logged in (to toggle header)
	setSubNavPosition() { localStorage.setItem("subNavPosition", document.getElementById("secondaryNav").scrollLeft.toString()); }
	toggleNav() { this.hamburgerClick.emit(true); } // Toggle whether or not the main navigation is open
	openSearch() { this.modalService.open(SearchNavComponent); } // Open the global navigation search

	// Used in the keyup function belew to prevent bringing up the search if they are focused in a form field
	isFormField(e) {
		if (!(e instanceof HTMLElement)) { return false; }
		const t = e.nodeName.toLowerCase();
		const n = (e.getAttribute('type') || '').toLowerCase();
		return (
			'select' === t 
			|| 'textarea' === t 
			|| (
				'input' === t 
				&& 'submit' !== n 
				&& 'reset' !== n
			) 
			|| e.isContentEditable
		);
	}	

	setDynamicStyle(name: string, styles: string) {
		let dynamicStyle = document.getElementById(name);
		if (dynamicStyle) { document.head.removeChild(dynamicStyle); }
		dynamicStyle = document.createElement('style');
		dynamicStyle.id = name;
		dynamicStyle.innerHTML = styles;
		document.head.appendChild(dynamicStyle);
	}

	ngOnDestroy() { this.subscriptions.unsubscribe(); }
}