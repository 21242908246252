import { ErrorHandler, Injectable } from '@angular/core';
import { datadogLogs } from '@datadog/browser-logs';

import { pcgSettings }  from './app/shared/generated/pcg-settings';

@Injectable()
export class DatadogErrorHandler implements ErrorHandler {

    constructor() {}

    handleError(err: any) {
        if (pcgSettings.mode.toLowerCase() === 'development') { console.error(err.originalError || err); }
        else { datadogLogs.logger.error(err.message, undefined, err); }
    }
}