import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgbDatepickerModule, NgbTooltipModule, NgbModalModule, NgbTypeaheadModule, NgbNavModule } from '@ng-bootstrap/ng-bootstrap';
import { NgSelectModule } from '@ng-select/ng-select';
import { CdkStepperModule } from '@angular/cdk/stepper';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { RouterModule } from '@angular/router';
import { MatTableModule } from '@angular/material/table';
import { MatNativeDateModule } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatListModule } from '@angular/material/list'; 

import { NavigationModule } from './navigation/navigation.module';
import { FormElementsModule } from './form-elements/form-elements.module';
import { TableModule } from './table/table.module';
import { ScanProductComponent } from '../inventory/scan-product/scan-product.component';
import { DoubleAuthComponent } from './authentication/double-auth/double-auth.component';
import { SingleAuthComponent } from './authentication/single-auth/single-auth.component';
import { SignatureBoxComponent } from './authentication/signature-box/signature-box.component';
import { StepperComponent } from './stepper/stepper.component';
import { PermissionService } from './service/permission/permission.service';
import { InventorySeedingComponent } from './inventory-seeding/inventory-seeding.component';
import { FaCountComponent } from "./fa-num-icon/fa-count/fa-count.component";
import { FaNumIconComponent } from "./fa-num-icon/fa-num-icon.component";
import { AuditHistoryComponent } from './audit-history/audit-history.component';
import { TooltipLabelComponent } from './tooltip-label/tooltip-label.component';
import { AuthGuard } from 'app/core/security/auth.guard';
import { PermissionConst } from 'app/core/security/generated/Permission';
import { ChildNdcTooltipComponent } from './child-ndc-tooltip/child-ndc-tooltip.component';
import { PackageNdcTooltipComponent } from './package-ndc-tooltip/package-ndc-tooltip.component';
import { IsrefrigeratedTooltipComponent } from './isrefrigerated-tooltip/isrefrigerated-tooltip.component';
import { SafePipe } from './pipes/safe.pipe';
import { BarcodeScannerStrichComponent } from './barcode-scanner-strich/barcode-scanner-strich.component';
import { NewHelpModuleButtonComponent } from './new-help-module-button/new-help-module-button.component';

const routes = [
	{
		path: 'audit-history/:id'
		, component: AuditHistoryComponent
		, canActivate: [ AuthGuard ]
		, canLoad: [ AuthGuard ]
		, data: { security: PermissionConst.coreFeatures }
	}
	, { 
		path: 'audit-history/:id/:vmType/:auditName'
		, component: AuditHistoryComponent
		, canActivate: [ AuthGuard ]
		, canLoad: [ AuthGuard ] 
		, data: { security: PermissionConst.coreFeatures }
	}
];

/**
 * SharedModule exists to make commonly used components, directives,
 * and pipes available for use in the templates of components throughout
 * the application.
 */
@NgModule({
	declarations: [
		ScanProductComponent
		, DoubleAuthComponent
		, SingleAuthComponent
		, SignatureBoxComponent
		, StepperComponent
		, InventorySeedingComponent
		, FaCountComponent
		, FaNumIconComponent
		, AuditHistoryComponent
		, TooltipLabelComponent
		, ChildNdcTooltipComponent
		, PackageNdcTooltipComponent
		, IsrefrigeratedTooltipComponent
		, SafePipe
		, BarcodeScannerStrichComponent
		, NewHelpModuleButtonComponent
	],
	imports: [
		CdkStepperModule		
		, CommonModule
		, FontAwesomeModule
		, FormElementsModule
		, FormsModule
		, MatDatepickerModule
		, MatFormFieldModule
		, MatInputModule
		, MatNativeDateModule
		, MatTableModule
		, MatSlideToggleModule
		, MatButtonToggleModule
		, NavigationModule
		, NgbDatepickerModule
		, NgbModalModule
		, NgbNavModule
		, NgbTooltipModule
		, NgbTypeaheadModule
		, NgSelectModule
		, ReactiveFormsModule
		, RouterModule.forChild(routes)
		, TableModule
		, MatCheckboxModule
		, MatSidenavModule
		, MatListModule
	],
	exports: [
		AuditHistoryComponent
		, BarcodeScannerStrichComponent
		, CdkStepperModule
		, ChildNdcTooltipComponent
		, PackageNdcTooltipComponent
		, CommonModule
		, DoubleAuthComponent
		, FaCountComponent
		, FaNumIconComponent
		, FontAwesomeModule
		, FormElementsModule
		, FormsModule
		, InventorySeedingComponent
		, IsrefrigeratedTooltipComponent
		, MatDatepickerModule
		, MatFormFieldModule
		, MatInputModule
		, MatNativeDateModule
		, MatTableModule
		, MatSlideToggleModule
		, MatButtonToggleModule
		, NavigationModule
		, NgbDatepickerModule
		, NgbModalModule
		, NgbNavModule		
		, NgbTooltipModule
		, NgbTypeaheadModule
		, NgSelectModule
		, ReactiveFormsModule
		, SafePipe
		, ScanProductComponent
		, SignatureBoxComponent		
		, SingleAuthComponent
		, StepperComponent		
		, TableModule
		, TooltipLabelComponent
		, NewHelpModuleButtonComponent
		, MatCheckboxModule
		, MatSidenavModule
		, MatListModule
	],
	providers: [ 
		PermissionService 
	]
})
export class SharedModule {}