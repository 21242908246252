<br />
<img 
	alt="Schedule2" 
	class="system-logo" 
	src="assets/images/logos/s2-Logo-dropshadow.png"
/>
<br />
<h2 class="text-center">Change Password</h2>
<br />

<div class='change-password-container clearfix'>
	<form 
		*ngIf="!isExpired && !isApiUser" 
		(ngSubmit)="onSubmit()" 
		[formGroup]="changePasswordForm"
	>
		<div class='row'>
			<pcg-show-hide-password 
				class="col-sm-12" 
				fcName="password" 
				[parentForm]="changePasswordForm" 
				label="New Password"
				[validations]="{ commonPassword: 'Pick a less common password.' }" 
				inputCssClass="form-control"
			></pcg-show-hide-password>
		</div>

		<div class='row'>
			<pcg-show-hide-password 
				class="col-sm-12" 
				fcName="confirmPassword" 
				[parentForm]="changePasswordForm" 
				label="Confirm New Password"
				[validations]="{ passwordsNotEqual: 'Passwords do not match.' }" 
				inputCssClass="form-control"
			></pcg-show-hide-password>
		</div>

		<button 
			type="submit" 
			class="btn btn-secondary btn-lg"
		>Change Password</button>
	</form>

	<div 
		*ngIf="isExpired && !isApiUser" 
		class="text-danger text-center expired-message"
	>{{errorMessage}}</div>

	<div 
		*ngIf="isApiUser"
		class="text-success text-center"
	>
		Your API account password has been set and you can now log into the 
		<a [href]="apiUrl">Schedule2 API</a> 
		site.
	</div>
</div>
<br /><br />

<div class="text-center">
	<u>Password Requirements</u><br />
	Must be at least 10 characters in Length.<br />
	Cannot match any of the passwords in our list of commonly used passwords.<br />
	Cannot contain context-specific words such as your username or the name of the service.<br />
	Cannot use too many repeated (e.g. "aaaa") or sequential (e.g. "1234") characters.<br />
</div>
