import { NavRoute } from '../../shared/navigation/nav.route.interface';

export const inventoryActionsNav: () => NavRoute = () => ({
	name: getName()
	, security: sec => sec.coreFeatures || sec.security
	, href: 'users'
	, children: getInventoryActionAreas()
});

function getName() : string {
	var name: string;
	var url = window.location.href;
	
	if (
		url.indexOf('administration/inventory/inventory-actions/edit/') > -1
		|| url.indexOf('administration/inventory/inventory-actions/custom-fields/') > -1
		|| url.indexOf('administration/inventory/inventory-actions/edit-user/audit-history/') > -1
	) { name = "Edit Inventory Action"; } 
	else { name = "Inventory Actions"; }

	return name;
}

function getInventoryActionAreas() : NavRoute[] {
	var id: string = "";
	var url = window.location.href;

	if (url.indexOf('administration/inventory/inventory-actions/edit/') > -1) { id = url.substring(url.lastIndexOf('/') + 1); }
	if (url.indexOf('administration/inventory/inventory-actions/custom-fields/') > -1) { id = url.substring(url.lastIndexOf('/') + 1); }
	if (url.indexOf('administration/inventory/inventory-actions/audit-history/') > -1) { id = url.substring(url.lastIndexOf('/') + 1); }

	if (id === "") { 
		return [
			{
				name: 'Inventory Actions'
				, exact: true
				, path: 'administration/inventory/inventory-actions'
			}
		]; 
	}

	if (+id === 0) {
		return [
			{
				name: 'Back'
				, exact: true
				, path: `administration/inventory/inventory-actions`
			},
			{
				name: 'Details'
				, exact: true
				, path: `administration/inventory/inventory-actions/edit/${id}`
			}
		];
	}

	return [
		{
			name: 'Back'
			, exact: true
			, path: `administration/inventory/inventory-actions`
		}
		, {
			name: 'Details'
			, exact: true
			, path: `administration/inventory/inventory-actions/edit/${id}`
			, security: sec => sec.createAndEditCustomFields
		}
		, {
			name: 'Custom Fields'
			, exact: true
			, path: `administration/inventory/inventory-actions/custom-fields/${id}`
			, security: sec => sec.createAndEditCustomFields
		}
		, {
			name: 'Audit History'
			, exact: true
			, path: `administration/inventory/inventory-actions/audit-history/${id}`
			, security: sec => sec.createAndEditCustomFields
		}
	];
}
