import { Component, OnInit, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';
import { ActivatedRoute } from '@angular/router';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { HttpClient } from '@angular/common/http';

import { HelpFilesVm } from 'app/shared/generated/HelpFiles/Models/HelpFilesVm';

@Component({
	selector: 'pcg-help-videos',
	templateUrl: './help-videos.component.html',
	styleUrls: ['./help-videos.component.scss'],
})
export class HelpVideosComponent implements OnInit, OnDestroy {
	id: number;
	name: string;
	description: string;
	moduleName: string;
	url: SafeUrl;
	subscriptions = new Subscription();

	constructor(private activeRoute: ActivatedRoute, private sanitizer: DomSanitizer, private httpClient: HttpClient) {}

	ngOnInit() {
		// Get the video id
		this.id = parseInt(this.activeRoute.snapshot.paramMap.get('id'), 10);
		this.getVideoContent();
	}

	ngOnDestroy() {
		this.subscriptions.unsubscribe();
	}

	/** Get Video data from the server */
	getVideoContent() {
		this.subscriptions.add(
			this.httpClient.get(`api/HelpFiles/HelpFiles/GetDetails/${this.id}`).subscribe((model: HelpFilesVm) => {
				const videoId = Number(model.videoId);
				this.url = `https://player.vimeo.com/video/${videoId}`;
				this.name = model.name;
				this.description = model.description;
				this.moduleName = model.moduleName;
			})
		);
	}
}
