import { NavRoute } from '../../shared/navigation/nav.route.interface';

export const inventorySiteNav: () => NavRoute = () => ({
	name: getName()
	, security: sec => sec.manageUsers || sec.security
	, href: 'inventory-list'
	, children: getInventoryAreas()
});

function getName() : string {
	var name: string;
	var url = window.location.href;
	
	if (
		url.indexOf('inventory/edit-inventory/details') > -1
		|| url.indexOf('inventory/edit-inventory/par-levels/') > -1
		|| url.indexOf('inventory/edit-inventory/associated-users/') > -1
		|| url.indexOf('inventory/edit-inventory/product-custom-fields/') > -1
		|| url.indexOf('inventory/edit-inventory/audit-history/') > -1
	) { name = "Edit Inventory"; } 
	else { name = "Inventories"; }

	return name;
}

function getInventoryAreas() : NavRoute[] {
	var id: string = "";
	var url = window.location.href;

	if (url.indexOf('inventory/edit-inventory/') > -1) { id = url.substring(url.lastIndexOf('/') + 1); }
	if (url.indexOf('inventory/edit-inventory/par-levels/') > -1) { id = url.substring(url.lastIndexOf('/') + 1); }
	if (url.indexOf('inventory/edit-inventory/associated-users/') > -1) { id = url.substring(url.lastIndexOf('/') + 1); }	
	if (url.indexOf('inventory/edit-inventory/product-custom-fields/') > -1) { id = url.substring(url.lastIndexOf('/') + 1); }
	if (url.indexOf('inventory/edit-inventory/audit-history/') > -1) { id = url.substring(url.lastIndexOf('/') + 1); }

	if (id === "") { 
		return [
			{
				name: 'All Inventories'
				, exact: true
				, path: 'inventory/inventory-list'
			}
		]; 
	}

	// Creating a new
	if (+id === 0) {
		return [
			{
				name: 'Back'
				, exact: true
				, path: `inventory/inventory-list`
			},
			{
				name: 'Details'
				, exact: true
				, path: `inventory/edit-inventory/details/${id}`
			}
		];
	}

	// details
	return [
		{
			name: 'Back'
			, exact: true
			, path: `inventory/inventory-list`
		}
		, {
			name: 'Details'
			, exact: true
			, path: `inventory/edit-inventory/details/${id}`
		}
		, {
			name: 'Par Levels'
			, exact: true
			, path: `inventory/edit-inventory/par-levels/${id}`
		}
		, {
			name: 'Associated Users'
			, exact: true
			, path: `inventory/edit-inventory/associated-users/${id}`
		}
		, {
			name: 'Product Custom Fields'
			, exact: true
			, path: `inventory/edit-inventory/product-custom-fields/${id}`
		}
		, {
			name: 'Audit History'
			, exact: true
			, path: `inventory/edit-inventory/audit-history/${id}`
		}
	];
}