// tslint:disable: quotemark
export enum UserProfileTypeEnum {
	Emt = 0,
	EmtAdmin = 1,
	Pcg = 2
}

export namespace UserProfileTypeEnum {
	export function toDescription(val: UserProfileTypeEnum) {
		switch (val) {
			case 0:
				return "EMT non-admin";
			case 1:
				return "EMT admin";
			case 2:
				return "PCG";
		}
		return null;
	}
}
