/* eslint-disable prefer-arrow/prefer-arrow-functions */
import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { User } from '@datadog/browser-core';
import { datadogRum } from '@datadog/browser-rum';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';

export function getBaseUrl() { return document.getElementsByTagName('base')[0].href; }

const providers = [{ provide: 'BASE_URL', useFactory: getBaseUrl, deps: [] }];

if (environment.production) { enableProdMode(); }

// Get the JSON web token from local storage
const jwt = localStorage.getItem('jwt') !== null 
	? (localStorage.getItem('jwt') && localStorage.getItem('jwt')?.substr(0, 1) !== '"')
		? localStorage.getItem('jwt')
		: JSON.parse(localStorage.getItem('jwt')) 
	: null;

// If we have a JSON web token, send AJAX request to get user information
// before bootstrapping Angular
if (jwt) {
	if (window.navigator.onLine) {
		const xhr = new XMLHttpRequest();
		xhr.open('POST', getBaseUrl() + 'api/Account/GetUserInfo');
		xhr.setRequestHeader('Content-Type', 'application/json');
		xhr.setRequestHeader('Authorization', `Bearer ${jwt}`);
		xhr.onload = function () {
			if (xhr.status === 200) {
				const model = JSON.parse(xhr.response);
				if (model.user === null) { localStorage.removeItem('jwt'); }
				localStorage.setItem('dynamicSecurity', JSON.stringify(model.security));
				localStorage.setItem('user', JSON.stringify(model.user));
				localStorage.setItem('jwt', JSON.stringify(jwt));
			}
			bootstrapAngular();
			bootstrapDataDogRum();
		};
		xhr.send();
	} else { bootstrapAngular(); }
} else {
	// If we don't have a token, just bootstrap Angular
	localStorage.removeItem('user');
	bootstrapAngular();
}

function bootstrapAngular() {
	//platformBrowserDynamic(providers).bootstrapModule(AppModule).catch(err => console.log(err));
	platformBrowserDynamic().bootstrapModule(AppModule).then(() => {
		if ( 'serviceWorker' in navigator) { navigator.serviceWorker.register('ngsw-worker.js'); }
	}).catch(err => console.log(err));
}

function bootstrapDataDogRum() {
    if (environment.production) {
        datadogRum.init({
            applicationId: '5644c563-eb74-464d-9a87-906aca9555ae'
			, clientToken: 'pubb88d62dcd0ff96b9521be101ea167e0c'
			, site: 'us5.datadoghq.com'
			, service: 's2'
			, allowedTracingUrls: ["https://app.schedule2.it"]
			, env: 'production'
            // Specify a version number to identify the deployed version of your application in Datadog
            // This will be set within a task in your CI pipeline.
            , version: '33d2516'
			, sessionSampleRate: 100
			, sessionReplaySampleRate: 100
			, trackUserInteractions: true
			, trackResources: true
			, trackLongTasks: true
			, defaultPrivacyLevel: 'allow'
        });
		setDatadogUser();
	}
}

function setDatadogUser() {
	let user = JSON.parse(localStorage.getItem('user'))
	if (user != null) {
		let ddUser : User = { 
			id: user?.id
			, email: user?.email
			, name: `${user?.fullName}` 
		}
		datadogRum.setUser(ddUser);
	}
}
