import { Component, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Subscription } from 'rxjs';
import { faCheck} from '@fortawesome/pro-solid-svg-icons';
import { HttpClient } from '@angular/common/http';

import { SystemMessage } from 'app/core/system-message/system-message-service';
import { PermissionVm } from 'app/shared/generated/Administration/Models/Security/Permissions/PermissionVm';
import { SecurityService } from 'app/core/security/security.service';
import { UserJsVm } from 'app/shared/generated/Administration/Models/UserJsVm';
import { PermissionInfoTextVm } from 'app/shared/generated/Administration/Models/PermissionInfoTextVm';

@Component({
	selector: 'pcg-user-permissions-modal',
	templateUrl: './user-permissions-modal.component.html',
	styleUrls: ['./user-permissions-modal.component.scss'],
})
export class UserPermissionsModalComponent implements OnInit, OnDestroy {

	subscriptions: Subscription = new Subscription();
	permissions: PermissionVm[];
	formGroup: UntypedFormGroup = PermissionVm.Form;
	user: UserJsVm;
	faCheck = faCheck;
	
	clientShiftChangeName = "Shift Change";
	editorString: string;
	hasDescriptions= false;
	jsonData: any;

	constructor(
		public activeModal: NgbActiveModal,
		private sec: SecurityService,
		private http: HttpClient
	) {}
	
	ngOnInit(): void {
		this.user = this.sec.getUser();
		this.clientShiftChangeName = this.user?.clientShiftChangeName ?? "Shift Change";
		this.getText();
		this.getModalData();
	}

	getModalData() {
        this.http.get(`api/Administration/PermissionInfo/GetPermissionForModal`).subscribe((msg: SystemMessage) => {
            this.jsonData = JSON.parse(msg.model);
			this.checkForDescriptions();
		})	
		
	}

	getText() {
		this.http.get(`api/Administration/PermissionInfoText/GetPermissionText`)
          .subscribe((vm: PermissionInfoTextVm) => { 
            this.formGroup.patchValue(vm);
            this.editorString = vm.text;
          });
	}

	checkPermissionLevel(permissionProfilePermissionInfos: Array<object>, permissionId: number) {
		var hit = false;
		permissionProfilePermissionInfos.forEach(p => {
			if (p["PermissionProfile"] == permissionId) { hit = true; } 
		});
		return hit;
	}

	checkForDescriptions(){
		for( let data in this.jsonData){
			if((this.jsonData[data].PermissionDescription != "") && (this.jsonData[data].PermissionDescription != null)){
				this.hasDescriptions=true;
			}
		}
	}

	ngOnDestroy() { this.subscriptions.unsubscribe(); }
}
