import { Component, OnInit, Input, ViewChild, TemplateRef, HostListener, Output, EventEmitter } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { faQuestion, faTableList, faTableCellsLarge } from '@fortawesome/pro-solid-svg-icons';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

import { DateRange } from '../../models/date-range';
import { PcgTableColumn } from '../pcg-table-column.interface';
import { ButtonFilterService } from './button-filter/button-filter.service';
import { GlobalService } from 'app/shared/service/global.service';
import { UnitType } from '../table.component';

@Component({
	selector: 'pcg-table-top',
	templateUrl: './table-top.component.html',
	styleUrls: ['./table-top.component.scss']
})
export class TableTopComponent implements OnInit {

	@ViewChild('tooltipModal', { static: true }) tooltipModal: TemplateRef<any>;

	@Input() exactMatchSource: BehaviorSubject<boolean>;
	@Input() filterSource: BehaviorSubject<string>;
	@Input() columnDefs: Map<string, PcgTableColumn>;
	@Input() canGlobalSearch = true;
	@Input() canExactMatchSearch = true;
	@Input() canShowHideColumns = true;
	@Input() ajaxData: any;
	@Input() pageSizeSource: BehaviorSubject<number>;
	@Input() pageLengths: [number];
	@Input() table: any;
	@Input() canExportTableToExcel = false;
	@Input() canPrintTable = false;
	@Input() hasTooltips = false;
	@Input() tooltipContent: string;
	@Input() canToggleUnits = false;
	@Input() showTilesBtn = false;
	@Input() tableViewStorage = "";

	// Inputs for implementing the table dropdown filter features
	/** Filter ID to implement table dropdown filters. Passed from table component */
	@Input() filterId: string = null;
	/** Pass through a filtermap to show filters being utilized. Ex. src\app\shared\business-areas\order-list\order-list */
	@Input() filterMap: {} = null;
	/** Filters utilized for the table dropdown filters. Passed from table component */
	@Input() filters: any;
	/** Filter array for special reset button visibility behavior. Passed from table component */
	@Input() emptyFilters: {} = null;
	/** Whether the table dropdown filters have reset capabilities. Passed from table component*/
	@Input() canResetFilters = true;

	/* map of string and and function pointer key value pairs where the key is the key to the filter and the value is a function return a boolean of whether 
	or not to show reset filters*/
	@Input() customResetFiltersMap: Map<string, (args: any) => boolean>;

	@Output() isUsingTiles = new EventEmitter<boolean>();

	// Get a unique id to append to the id of controls to make it distinct
	// Doing this to have a distinct id to add to the for attribute of the exact match label
	uniqueId = `${new Date().getDay()}-${new Date().getSeconds()}-${new Date().getMilliseconds()}`;
	unitType: UnitType = UnitType.Vials;
	unitTypeEnum = UnitType;

	faIconName = { faQuestion, faTableList, faTableCellsLarge };

	isMobile: boolean;
	@HostListener('window:resize')
	onResize() { this.isMobile = GlobalService.setIsMobile(window.innerWidth); }

	constructor(
		public btnFilterService: ButtonFilterService
		, public modalService: NgbModal
	) { }

	ngOnInit() {
		if (this.canResetFilters) { this.emptyFilters ??= { ...this.filters }; }
		this.isMobile = GlobalService.setIsMobile(window.innerWidth);
	}

	setUnitType(unitType: UnitType) {
		this.unitType = unitType;
		this.table.onToggleShowVials(this.unitType);
	}

	clearSearch(event) {
		setTimeout(() => { if (event.target.value == "") { this.filterSource.next(""); } }, 1);
	}

	showResetFilterBtn() {
		if (this.customResetFiltersMap != null) {
			return this.canResetFilters 
				&& Object.entries(this.filters).filter(([key, val]) => (
					this.customResetFiltersMap?.has(key) && this.customResetFiltersMap?.get(key)(val)
				)).length;
		}

		return this.canResetFilters 
			&& Object.entries(this.filters).filter(([key, val]) => (
				key in this.emptyFilters 
				&& val !== false 
				&& !(
					this.emptyFilters[key] === val || (
						// Checking dateranges due to clearing range sets value to an object.
						this.emptyFilters[key] instanceof DateRange 
							&& this.emptyFilters[key].dateBegin === (val['dateBegin'] ?? null) 
							&& this.emptyFilters[key].dateEnd === (val['dateEnd'] ?? null)
					)
				)
			)).length;
	}

	tooltipButtonClicked() { this.modalService.open(this.tooltipModal); }
	tableViewClick(isTiles: boolean) { this.isUsingTiles.emit(isTiles); }
}