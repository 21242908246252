<form 
	pcgSubmitUrl="api/Administration/Users/SaveUser" 
	(messageSuccess)="onMessageSuccess($event)"
	[formGroup]="formGroup"
	[localDbTbl]="'EditUserTbl'"
>
	<input type="hidden" formControlName="userId" />
	<input type="hidden" formControlName="userType" />

	<div class="activation-ctnr">
		<!-- Locked Out button -->
		<div 
			*ngIf="isLockedOut" 
			class="unlock-btn" 
			(click)="unlockUser()"
			ngbTooltip="This user is locked out. Click here to unlock the user and send them an email with login instructions."
		>
			<fa-icon 
				[icon]="faIconName.faUnlock" 
				class="unlock-icon" 
			></fa-icon>	
			<p>Locked Out</p>
		</div>

		<!-- Activate/Deactivate button -->
		<pcg-control-group class="activate-btn">
			<div class="row">
				<div class="col-sm-1">					
					<a 					
						*ngIf="
							(canChangePermissions ? true : null) 
							&& id !== 0
						"
						[class.btn-danger]="model.isActive"
						[class.btn-save]="!model.isActive"
						class="btn btn-sm"
						(click)="
							activateDeactivate(); 
							model.isActive = !model.isActive;
						"
					>{{ model.isActive ? 'Deactivate' : 'Reactivate' }}</a>
					<button #submit [hidden]="true"></button>
				</div>
			</div>
			<p 
				[class.text-danger]="!model.isActive" 
				[class.d-none]="model.isActive"
				class="ml-2"
			>This user is deactivated.</p>
		</pcg-control-group>
	</div>

	<div 
		*ngIf="model.isFromIops" 
		class="section-ctnr top-ctnr"
	>
		<div class="integration-indication">
			<div class="integration-image-ctnr">
				<img 
					src="../assets/images/logos/iOps360Logo.jpg" 
					draggable="false"
				/>
			</div>
			<p>Account originated in iOps360</p>
		</div>
		<div 
			*ngIf="false" 
			class="iops-btn"
		>Visit iOps</div>
	</div>

	<div class="section-ctnr">
		<div class="row">
			<pcg-control-group 
				class="col-md-3" 
				label="Full Name" 
				[validations]="fullNameValidation"
			>
				<input 
					pcgControlRef 
					class="form-control" 
					maxlength="101"
					type="text" 
					formControlName="fullName"
				/>
				<input 
					*ngIf="!isSysAdmin" 
					type="hidden" 
					formControlName="clientId" 
				/>
			</pcg-control-group>
	
			<pcg-control-group 
				*ngIf="
					id !== 0 
					&& !model.isNewUser
				" 
				class="col-md-3" 
				label="Phone" 
				[validations]="phoneValidation"
			> 
				<input 
					pcgPhoneExt 
					pcgControlRef 
					class="form-control" 
					type="text" 
					inputmode="tel" 
					formControlName="phoneNumber" 
					maxlength="21"
				/>
			</pcg-control-group>
	
			<pcg-control-group 
				class="col-md-3" 
				label="Email"
			>
				<input 
					pcgControlRef 
					class="form-control email" 
					maxlength="101"
					type="text" 
					formControlName="email" 
				/>
			</pcg-control-group>
	
			<div 
				*ngIf="
					canSeePasswordButtons 
					&& isNewUser
				" 
				class="col-md-3 flexMobile"
			>
				<input
					type="button"
					(click)="resetPassword(true, false)"
					class="btn btn-save btn-sm"
					ngbTooltip="Send user a welcome email with their username and the ability to choose a new password."
					value="Send Welcome Email" 
					id="btnSendWelcomeMessage" 
				/>
			</div>
		</div>

		<hr *ngIf="isNewUser">

		<div class="row tablet">			
			<pcg-control-group 
				*ngIf="isSysAdmin"
				class="col-md-3" 
				label="Customer"
			>
				<pcg-select 
					placeholder="Select a Customer" 
					pcgControlRef 
					dataSource="api/Select/Clients" 
					[sendFormData]="false" 
					formControlName="clientId"
				></pcg-select>
			</pcg-control-group>

			<pcg-control-group
				*ngIf="
					model.showDepartments 
					&& hasDepartmentPermissions 
					&& id !== 0
				" 
				class="col-md-3"
			>
				<span 
					class="dept-label" 
					ngbTooltip="Open to see what inventories are associated to what departments."
				>
					Department
					<fa-icon 
						class="icon-tooltip ml-1" 
						[icon]="faIconName.faInfoCircle" 
						(click)="openInfoModal()"
					></fa-icon>
				</span>
				<div>
					<pcg-select 
						placeholder="Select a Department" 
						[multiple]="true" 
						[sendFormData]="false" 
						pcgControlRef
						dataSource="api/Select/Departments" 
						formControlName="departmentIds"
					></pcg-select>
				</div>
			</pcg-control-group>

			<pcg-control-group 
				class="col-md-3" 
				label="State License Number"
			>
				<input 
					pcgControlRef 
					pcgNumeric
					class="form-control stateLicenseNumber w-100" 
					maxlength="51"
					type="text" 
					inputmode="numeric"
					formControlName="stateLicenseNumber" 
				/>
			</pcg-control-group>
		</div>
	
		<!-- Create user fields -->
		<div 
			*ngIf="
				id === 0 
				|| model.isNewUser
			" 
			class="row"
		>
			<pcg-control-group 
				class="smallInputWidth beginRow" 
				label="Phone" 
				[validations]="phoneValidation"
			>
				<input 
					pcgPhoneExt 
					pcgControlRef 
					class="form-control" 
					type="text" 
					inputmode="tel" 
					formControlName="phoneNumber" 
					maxlength="21"
				/>
			</pcg-control-group>
	
			<pcg-control-group 
				class="smallInputWidth correctSpacing" 
				label="Username" 
				[validations]="userNameValidation"
			>
				<input 
					pcgControlRef 
					class="form-control"
					maxlength="51" 
					type="text" 
					formControlName="userName" 
				/>
			</pcg-control-group>
	
			<pcg-control-group 
				class="smallInputWidth correctSpacing" 
				label="Permission"
			>
				<pcg-select 
					placeholder="Select a Permission" 
					[sendFormData]="false" 
					pcgControlRef
					dataSource="api/Select/Permissions" 
					[sendFormData]="false"
					formControlName="permissionId"
				></pcg-select>
			</pcg-control-group>
	
			<div class="col-md-1 mx-md-0 mx-lg-5 mx-xxl-2 col-lg-1 flexForMobile">
				<input 
					[class.permission-info-current-user]="isCurrentUser" 
					[class.permissionInfoNotCurrentUser]="!isCurrentUser"
					type="button" 
					value="Permissions Info" 
					(click)="openPermissionsInfoModal()" 
					class="btn btn-save btn-md permissions-info-mobile correctSpacing" 
					tabindex="-1"
				/>
			</div>
		</div>
	
		<div *ngIf="customFieldsCount > 0">
			<hr />
			<pcg-dynamic-form 
				[fields]="fields" 
				[fg]="formGroup" 
				fgName="customFields"
			></pcg-dynamic-form>
		</div>		
	</div>

	<pcg-fixed-buttons>
		<a 
			*ngIf="
				(
					!isCurrentUser 
					|| canManageUser
				) 
				&& !model.isNewUser
			" 
			class="btn btn-cancel btn-lg" 
			routerLink="/administration/users/list"
		>Back</a>
		<a 
			*ngIf="
				(
					!isCurrentUser 
					|| canManageUser
				) 
				&& model.isNewUser
			" 
			class="btn btn-cancel btn-lg" 
			(click)="handleBackDetails()"
		>Back</a>
		<button 
			*ngIf="id !== 0" 
			class="btn btn-save btn-lg"
		>Save</button>
		<button 
			*ngIf="id === 0" 
			class="btn btn-save btn-lg"
		>Next</button>
	</pcg-fixed-buttons>
</form>