import { LoadedStores } from '../tables/loaded.store';
import { EditUserTbl } from '../tables/Administration/Models/User/EditUser/ViewModel/EditUserTbl';

const EditUserTblInstance = new EditUserTbl();
const loadedStoresInstance = new LoadedStores();

function generateColumns<T extends Record<string, any>>(instance: T): string {
	return (Object.keys(instance) as (keyof T)[]).join(',');
}

export const DBStores = {
	EditUserTbl: {
		TableName: 'EditUserTbl',
		Columns: generateColumns(EditUserTblInstance)
	},
	LoadedStores: {
		TableName: 'LoadedStores',
		Columns: generateColumns(loadedStoresInstance)
	}
};

