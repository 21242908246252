<mat-sidenav-container class="nav-container">
	<!-- Navigation menu -->
	<mat-sidenav 
		*ngIf="
			isLoggedOn 
			&& !onChangePasswordScreen
		"
		#sidenav 
		[mode]="
			isNavFixed 
			&& !isMobile ? 'side' : 'over'
		" 
		[(opened)]="navOpened" 
		(mouseleave)="onNavOpenedChange(isNavFixed)"	
		class="no-print"	
	>
		<pcg-mat-side-nav 
			[user]="user" 
			[navFixedInput]="isNavFixed"
			(navFixedOutput)="setNavFixed($event)"
			(closeNav)="setNavOpened($event)"
			(clickedSearchCv)="deactivateReports()"
			id="sidenav"
			class="no-print"			
		></pcg-mat-side-nav>
	</mat-sidenav>

	<!-- Rest of the site -->
	<mat-sidenav-content [ngClass]="{'darker': navOpened }">
		<!-- Nav drawer -->
		<nav 
			*ngIf="
				!isMobile 
				&& isLoggedOn 
				&& !onChangePasswordScreen 
				&& !isNavFixed
			" 
			class="nav-drawer no-print" 
			(mouseover)="onNavOpenedChange(true)"
		>
			<div class="nav-drawer-image">
				<img 
					[src]="fileLogoUrl" 
					height="35px" 
				/>
			</div>
			<fa-icon 
				[icon]="faIconName.faShelves" 
				class="drawer-icon fa-sm" 
				[class]="{'active-drawer-item' : inventoriesSelected}"
			></fa-icon>	
			<fa-icon 
				[icon]="faIconName.faClipboardList" 
				class="drawer-icon" 
				[class]="{'active-drawer-item' : reportsSelected}"
			></fa-icon>
			<!-- No Techs can see Forms Icon-->
			<fa-icon 
				*ngIf="(!isTechnician)" 
				[icon]="faIconName.faPenField" 
				class="drawer-icon" 
				[class]="{'active-drawer-item' : formsSelected}"
			></fa-icon>
			<fa-icon 
				[icon]="faIconName.faGear" 
				class="drawer-icon" 
				[class]="{'active-drawer-item' : adminSelected}"
			></fa-icon>	
			<fa-icon
				*ngIf="(isSysAdmin)"
				[icon]="faIconName.faGears"
				class="drawer-icon"
				[class]="{'active-drawer-item' : sysAdminSelected}"
			></fa-icon>
			<fa-icon 
				*ngIf="user?.controlValues"
				[icon]="faIconName.faBarcodeRead" 
				class="drawer-icon" 
				[class]="{'active-drawer-item' : searchCvSelected}"
			></fa-icon>
			<fa-icon 
				[icon]="faIconName.faUser" 
				class="drawer-icon" 
				[class]="{'active-drawer-item' : userProfileSelected}"
			></fa-icon>
			<fa-icon 
				[icon]="faIconName.faQuestionCircle" 
				class="drawer-icon" 
				[class]="{'active-drawer-item' : helpSelected}"
			></fa-icon>
			<fa-icon 
				class="drawer-icon fa-lg" 
				[icon]="faIconName.faStaffSnake" 
			></fa-icon>	
		</nav>

		<!-- Everything else -->
		<div [ngClass]="isNavFixed ? 'left-shift-fixed' : 'left-shift'">
			<pcg-header
				id="mainTopHeader"
				(hamburgerClick)="setNavOpened($event)"
				[tabPanel]="tabPanel"
				[onChangePasswordScreen]="onChangePasswordScreen"
			></pcg-header>
			
			<div 
				[ngClass]="isNavFixed ? 'top-shift-fixed' : 'top-shift'" 
				class="mb-3"
			>
				<div 
					role="main" 
					class="main-container"
				>
					<mat-tab-nav-panel 
						#tabPanel 
						class="container"
					>
						<router-outlet></router-outlet>
					</mat-tab-nav-panel>
				</div>
				<pcg-system-message></pcg-system-message>
				<pcg-spinner></pcg-spinner>
			</div>
		</div>
		
		<!-- Footer -->
		<div class="footer-ctnr no-print">
			<div class="footer">
				<span id="versionHistory">
					<div 
						*ngIf="isLoggedOn"
						class="blue-link" 
						(click)="toReleaseNotes()"
					>Release Notes</div>
					<span *ngIf="isLoggedOn">&nbsp;|&nbsp;</span>
					<a 
						href="https://www.schedule2.it/" 
						rel="noreferrer noopener" 
						target="_blank"
					>Schedule 2</a>
					&nbsp;|&nbsp;
					<a 
						class="blue-link" 
						href="/eula" 
						target="_blank"
					>EULA</a>
					&nbsp;|&nbsp;
					<a 
						class="blue-link" 
						href="/privacy-policy" 
						target="_blank"
					>Privacy Policy</a>
					&nbsp;|&nbsp;
					<a 
						class="blue-link" 
						href="/terms-conditions" 
						target="_blank"
					>Terms and Conditions</a>
					&nbsp;|&nbsp;
					<a 
						href="https://apps.apple.com/us/app/schedule2-it/id6462732846" 
						rel="noreferrer noopener" 
						target="_blank"
					>iOS App Store</a>
					&nbsp;|&nbsp;
					<a 
						href="https://play.google.com/store/apps/details?id=schedule2it.app.twa&pcampaignid=web_share" 
						rel="noreferrer noopener" 
						target="_blank"
					>Google Play Store</a>
				</span>
			</div>
		</div>
	</mat-sidenav-content>
</mat-sidenav-container>