import { UntypedFormGroup, UntypedFormControl, UntypedFormArray, Validators } from '@angular/forms';

import { PcgTableColumn } from '../../../table/pcg-table-column.interface';

// tslint:disable:quotemark
// tslint:disable:max-line-length
// tslint:disable:whitespace
export class DepartmentInventoryVm {
	department?: string;
	associatedInventories?: string[];

	static get FormData() {
		return this.getFormData();
	}

	static getFormData(validators = {}) {
		return {
			'department': new UntypedFormControl(null, [...(validators['department'] ?? []), ...[]]),
			'associatedInventories': new UntypedFormControl([], [...(validators['associatedInventories'] ?? []), ...[]])
		};
	}

	static get Form() {
		return new UntypedFormGroup(this.FormData);
	}

	static GetModel(fg: UntypedFormGroup): DepartmentInventoryVmFromFg {
		return new DepartmentInventoryVmFromFg(fg);
	}
}

export class DepartmentInventoryVmFromFg {
	constructor(public fg: UntypedFormGroup) { }


	get department(): string {
		return this.fg.get('department').value;
	}
	set department(val) {
		this.fg.get('department').setValue(val);
	}
}
