// tslint:disable: quotemark
export enum CustomFieldTypeEnum {
	TextBox = 0,
	TextArea = 1,
	Number = 2,
	DropDown = 3,
	MultiSelect = 4,
	Date = 5,
	Count = 6
}

export namespace CustomFieldTypeEnum {
	export function toDescription(val: CustomFieldTypeEnum) {
		switch (val) {
			case 0:
				return "TextBox";
			case 1:
				return "TextArea";
			case 2:
				return "Number";
			case 3:
				return "DropDown";
			case 4:
				return "MultiSelect";
			case 5:
				return "Date";
			case 6:
				return "Count";
		}
		return null;
	}
}
