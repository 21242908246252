import { UserJsVm } from 'app/shared/generated/Administration/Models/UserJsVm';
import { NavRoute } from '../../shared/navigation/nav.route.interface';

export const productNav: () => NavRoute = () => {
	const user = getUser();
	return {
		name: getName()
		, security: sec => sec.coreFeatures || sec.security
		, href: 'products'
		, children: getProductAreas(user)
	}
};

function getName() : string {
	var name: string;
	var url = window.location.href;
	
	if (
		url.indexOf('products/edit-product/details/') > -1
		|| url.indexOf('products/edit-product/control-values/') > -1
		|| url.indexOf('products/product-lot-exp/') > -1
		|| url.indexOf('products/product-transaction-history/') > -1
		|| url.indexOf('products/product-audit-history/') > -1
	) { name = "Edit Product"; } 
	else { name = "Products"; }

	return name;
}

function getProductAreas(user: UserJsVm) : NavRoute[] {
	var id: string = "";
	var url = window.location.href;
	var isEditProduct = false;
	var urlSegments = url.split('/');

	if (url.indexOf('products/edit-product/details/') > -1) { 
		id = url.substring(url.lastIndexOf('/') + 1); 
		isEditProduct = true;
	}
	if (url.indexOf('products/edit-product/control-values/') > -1) { 
		id = url.substring(url.lastIndexOf('/') + 1); 
		isEditProduct = true;
	}
	if (url.indexOf('products/product-lot-exp/') > -1) { 
		id = url.substring(url.lastIndexOf('/') + 1); 
		isEditProduct = true;
	}
	if (url.indexOf('products/product-transaction-history/') > -1) { 
		id = url.substring(url.lastIndexOf('/') + 1); 
		isEditProduct = true;
	}	
	if (url.indexOf('products/product-audit-history/') > -1) { 
		id = url.substring(url.lastIndexOf('/') + 1); 
		isEditProduct = true;
	}
	if (url.indexOf('inventory/products/') > -1) { id = url.substring(url.lastIndexOf('/') + 1); }
	if (url.indexOf('inventory/shift-change/') > -1) { id = url.substring(url.lastIndexOf('/') + 1); }
	if (url.indexOf('inventory/inventory-actions/') > -1) {
		let urlSegments = url.split('/');
		if (
			!isNaN(Number(urlSegments[urlSegments.length - 1])) 
			&& !isNaN(Number(urlSegments[urlSegments.length - 2]))
		) {
			id = urlSegments[urlSegments.length - 2];
		} else {
			id = url.substring(url.lastIndexOf('/') + 1); 
		}
	}
	if (url.indexOf('inventory/replenishment/replenishment-audit-history/') > -1) { id = url.substring(url.lastIndexOf('/') + 1); }
	if (url.indexOf('inventory/replenishment/') > -1) { id = url.substring(url.lastIndexOf('/') + 1); }
	if (url.indexOf('inventory/transfers/') > -1) { id = url.substring(url.lastIndexOf('/') + 1); }
	if (url.indexOf('inventory/reconciliation/') > -1) { id = url.substring(url.lastIndexOf('/') + 1); }
	if (url.indexOf('inventory/dea-form/') > -1) { id = url.substring(url.lastIndexOf('/') + 1); }
	if (url.indexOf('inventory/edit-dea-form/') > -1) { id = url.substring(url.lastIndexOf('/') + 1); }
	if (url.indexOf('inventory/return-logs/') > -1) { id = url.substring(url.lastIndexOf('/') + 1); }
	if (url.indexOf('inventory/edit-return-log/') > -1) { id = url.substring(url.lastIndexOf('/') + 1); }
	if (url.indexOf('inventory/site-transaction-history/') > -1) { id = url.substring(url.lastIndexOf('/') + 1); }
	if (url.indexOf('inventory/transaction-history/lot-exp') > -1) { id = url.substring(url.lastIndexOf('/') + 1); }
	if (url.indexOf('inventory/transaction-history/control-value') > -1) { id = url.substring(url.lastIndexOf('/') + 1); }
	if (url.indexOf('?medUse=true') > -1) { 
		var afterSlash = url.substring(url.lastIndexOf('/') + 1);
		id = afterSlash.split('?')[0]; 
	}

	let navRoutes: Array<NavRoute> = [];

	var invActionName = getInventoryActionName(Number(id), user);
	
	navRoutes.push({
		name: 'Back'
		, exact: true
		, path: `inventory/inventory-list`
	});
	navRoutes.push({
		name: 'Products'
		, exact: true
		, path: `inventory/products/${id}`
	});
	navRoutes.push({
		name: user?.clientShiftChangeName ?? 'Shift Change'
		, exact: true
		, path: `inventory/shift-change/${id}`
	});
	navRoutes.push({
		name: invActionName ?? 'Update Inventory'
		, exact: true
		, path: `inventory/inventory-actions/${id}`
	});
	navRoutes.push({
		name: 'Transfers'
		, exact: true
		, path: `inventory/transfers/${id}`
	});

	// Inventory
	if (!isEditProduct) {
		if (user?.permissionId > 1) {
			// Need to check for inventory type and inventory actions
			let inventoryType = getInventoryType(Number(id), user);

			navRoutes.push({
				name: 'Reconciliation'
				, exact: true
				, path: `inventory/reconciliation/${id}`
			});
			
			switch (inventoryType) {
				case 1: {
					navRoutes.push({
						name: 'Replenishment'
						, exact: true
						, path: `inventory/replenishment/${id}`
					});
					break;
				}
				case 2: {
					navRoutes.push({
						name: 'Return Logs'
						, exact: true
						, path: `inventory/return-logs/${id}`
					});
					break;
				}
				case 3: {
					navRoutes.push({
						name: 'DEA Form 41'
						, exact: true
						, path: `inventory/dea-form/${id}`
					});
					break;
				}
				default: {
					break;
				}
			}
		}

		navRoutes.push({
			name: 'Transaction History'
			, exact: true
			, path: `inventory/site-transaction-history/${id}`
		});

		return navRoutes;
	}

	// Details for edit product
	if (!user?.controlValues) {
		return [
			{
				name: 'Back'
				, exact: true
				, path: `inventory/products/${urlSegments[urlSegments.length - 2]}`
			}
			, {
				name: 'Product Details'
				, exact: true
				, path: `inventory/products/edit-product/details/${urlSegments[urlSegments.length - 2]}/${id}`
			}
			, {
				name: 'Lot Numbers & Expiration Dates'
				, exact: true
				, path: `inventory/products/product-lot-exp/${urlSegments[urlSegments.length - 2]}/${id}`
			}
			, {
				name: 'Transaction History'
				, exact: true
				, path: `inventory/products/product-transaction-history/${urlSegments[urlSegments.length - 2]}/${id}`
			}
			, {
				name: 'Audit History'
				, exact: true
				, path: `inventory/products/product-audit-history/${urlSegments[urlSegments.length - 2]}/${id}`
			}
		];
	} else {
		return [
			{
				name: 'Back'
				, exact: true
				, path: `inventory/products/${urlSegments[urlSegments.length - 2]}`
			}
			, {
				name: 'Product Details'
				, exact: true
				, path: `inventory/products/edit-product/details/${urlSegments[urlSegments.length - 2]}/${id}`
			}
			, {
				name: 'Lot Numbers & Expiration Dates'
				, exact: true
				, path: `inventory/products/product-lot-exp/${urlSegments[urlSegments.length - 2]}/${id}`
			}
			, {
				name: 'Control Values'
				, exact: true
				, path: `inventory/products/edit-product/control-values/${urlSegments[urlSegments.length - 2]}/${id}`
			}
			, {
				name: 'Transaction History'
				, exact: true
				, path: `inventory/products/product-transaction-history/${urlSegments[urlSegments.length - 2]}/${id}`
			}
			, {
				name: 'Audit History'
				, exact: true
				, path: `inventory/products/product-audit-history/${urlSegments[urlSegments.length - 2]}/${id}`
			}
		]
	}
}

function getUser(): UserJsVm {
	const userJson: string = localStorage.getItem('user') !== null ? localStorage.getItem('user') : null;
	if (userJson !== null) { return JSON.parse(userJson); }
	return null;
}

function getInventoryType(id: number, user): number {
	let inventoryType = 0;
	user?.inventorySites?.forEach((site) => {
		if (site?.id === id) { inventoryType = site?.inventoryType; } 
	});
	return inventoryType;
}

function getInventoryActionName(id: number, user: UserJsVm): string {
	let inventoryActionName = "Update Inventory";
	if (user?.clientInventoryActionsCount <= 1) {
		inventoryActionName = user?.clientMedUseName ?? "Medication Use";
	}
	return inventoryActionName;
}
