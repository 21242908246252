export interface IEditUserTbl {
	userId: number;
	fullName?: string;
	userName?: string;
	phoneNumber?: string;
	email?: string;
	stateLicenseNumber?: string;
	userProfileIds?: number[];
	userProfiles?: string[];
	isActive: boolean;
	isLockedOut: boolean;
	inactivityTimeout: boolean;
	permissionId?: number;
	permission?: string;
	permissionProfile?: string;
	clientId?: number;
	clientName?: string;
	departmentIds?: number[];
	inventoryIds?: number[];
	activeInventoryIds?: number[];
	inventories?: string[];
	inventorySiteAssociation?: any;
	activeInventories?: string[];
	hasBiometricClientSettings: boolean;
	hasFidoClientSettings: boolean;
	fields?: any;
	customFields?: any;
	friendlyId?: string;
	userSignature?: any;
	userPin?: string;
	showDepartments: boolean;
	isNewUser: boolean;
	isFromIops: boolean;
	userType?: any;
	logoFileName?: string;
	companyName?: string;
	lastLoginDate?: Date;
	lastLoginDateStr?: string;
	isUpdatedOffline?: string;
};

export class EditUserTbl implements IEditUserTbl {
	userId = 0;
	fullName = '';
	userName = '';
	phoneNumber = '';
	email = '';
	stateLicenseNumber = '';
	userProfileIds = [];
	userProfiles = [];
	isActive = false;
	isLockedOut = false;
	inactivityTimeout = false;
	permissionId = 0;
	permission = '';
	permissionProfile = '';
	clientId = 0;
	clientName = '';
	departmentIds = [];
	inventoryIds = [];
	activeInventoryIds = [];
	inventories = [];
	inventorySiteAssociation = '';
	activeInventories = [];
	hasBiometricClientSettings = false;
	hasFidoClientSettings = false;
	fields = '';
	customFields = '';
	friendlyId = '';
	userSignature = '';
	userPin = '';
	showDepartments = false;
	isNewUser = false;
	isFromIops = false;
	userType = '';
	logoFileName = '';
	companyName = '';
	lastLoginDate = new Date();
	lastLoginDateStr = '';
	isUpdatedOffline = '';
};
